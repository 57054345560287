<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--留言时间-->
          <el-form-item :label="$t('GlobalSetObj.CreationTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 起点  -->
          <el-form-item prop="siteIdType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.siteIdType"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('navbar.startDot')" :value="1" />
                  <el-option key="2" :label="$t('navbar.endDot')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-select
              v-model="queryForm.params.siteId"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in siteIdList"
                :key="index"
                :label="item.siteName"
                :value="item.siteId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  状态   -->
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.status"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" :value="null" />
              <el-option :label="$t('GlobalSetObj.enable')" :value="1" />
              <el-option :label="$t('GlobalSetObj.stop')" :value="0" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div style="margin-top: 27px">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <el-button
        size="small" style="margin-bottom: 15px" type="primary"
        @click="addCusTime"
      >{{ $t('navbar.newTranslateTime') }}
      </el-button>
      <el-button size="small" type="primary" @click="bulkImportDialog = true">{{ $t('navbar.orderImport') }}</el-button>
      <el-button size="small" @click="allExportOrder">{{ $t('navbar.export') }}</el-button>
      <el-button
        plain size="small" type="primary"
        @click="bathUpdataStatus(1)"
      >{{ $t('GlobalSetObj.enable') }}
      </el-button>
      <el-button
        plain size="small" type="danger"
        @click="bathUpdataStatus(0)"
      >{{ $t('GlobalSetObj.stop') }}
      </el-button>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :align="item.center"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div v-if="item.prop==='statusTypeObjName'">
            <el-tag
              v-if="scope.row.status === 0" effect="dark" size="small"
              type="danger"
            >{{
              scope.row.statusTypeObjName
            }}
            </el-tag>
            <el-tag
              v-else effect="dark" size="small"
              type=""
            >{{ scope.row.statusTypeObjName }}
            </el-tag>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="100"
      >
        <template slot-scope="scope">
          <div style="display: flex;justify-content: space-around;align-items: center;">
            <el-tooltip
              :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-edit textOperator" @click="editDialog(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.status === 0"
              :content="$t('GlobalSetObj.enable')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-circle-check textOperator" @click="updateStatus(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-else
              :content="$t('GlobalSetObj.stop')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-remove-outline textOperator1" @click="updateStatus(scope.row)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 新增 -->
    <el-drawer
      :append-to-body="false"
      :title="titleHeader"
      :visible.sync="addCusTimeDialog"
      direction="ltr"
      size="700px"
      @close="closeDrawer1"
    >
      <div style="padding: 0 20px;">
        <el-form
          ref="ruleForm"
          :key="addCusTimeDialog"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="auto"
        >
          <el-form-item :label="$t('navbar.startDot')" prop="startSiteId">
            <el-select
              v-model="ruleForm.startSiteId"
              clearable
              filterable
              style="width: 100%"
              @change="handleStartSiteId"
            >
              <el-option
                v-for="(item,index) in siteIdList"
                :key="index"
                :label="item.siteName"
                :value="item.siteId"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('navbar.endDot')" prop="endSiteId">
            <el-select
              v-model="ruleForm.endSiteId"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in endSiteIdList"
                :key="index"
                :label="item.siteName"
                :value="item.siteId"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('navbar.duration')" prop="duration">
            <el-input v-model="ruleForm.duration" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-form>
      </div>
      <div style="padding: 20px;text-align: right;">
        <el-button size="small" @click="cusSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="cusSubmit(2)">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
      </div>
    </el-drawer>
    <!-- 新增客户视角 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('navbar.orderImport')"
      :visible.sync="bulkImportDialog"
      width="500px"
      @close="handleBulkImportDialog"
    >
      <el-form
        ref="ruleForm1"
        :key="bulkImportDialog"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="auto"
      >
        <el-form-item prop="">
          <template slot="label">
            <el-button size="small" type="primary" @click="triggerFileInput">{{
              $t('customerManagements.clickUpload')
            }}
            </el-button>
          </template>
          <input
            ref="referenceUpload"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style="display: none"
            type="file"
            @change="uploadFile($event)"
          >
          <div class="">
            <span class="downModule" @click="downLoadFile">{{ $t('orderCenterCont.DownloadTemplate') }}</span>
            <span style="font-size: 12px;color: red;">{{ $t('customerManagements.pleaseUploadLI') }}</span>
          </div>
        </el-form-item>
        <div v-if="fileName" class="downLink">
          <div>{{ fileName }}</div>
          <div><i class="el-icon-error" @click="delFile" /></div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="bulkImportDialog = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="cusSubmit1(2)">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  getEnableSiteList,
  timelinessmodifyStatus,
  timelinessPage,
  timelinesstransferPagemoimportData,
  timelinesstransportAdd,
  timelinessTransportexport
} from '@/api/routerPlan';
import { timelinessdownloadmodule } from '@/api/customer';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'DurationTransit',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          siteIdType: 1,
          status: null, // 状态：1、启用，2、停用
          siteId: '' // 网点Id
        }
      },
      tableData: [],
      tableHeaderList: [
        // 创建时间
        { id: 2, label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', cloWidth: '160', center: 'center' },
        // 起点
        { id: 3, label: this.$t('navbar.startDot'), prop: 'startSiteName', cloWidth: '150', center: 'center' },
        // 终点
        { id: 4, label: this.$t('navbar.endDot'), prop: 'endSiteName', cloWidth: '150', center: 'center' },
        // 运输时长(H)
        { id: 7, label: this.$t('navbar.tDuration'), prop: 'duration', cloWidth: '160', center: 'center' },
        // 状态
        { id: 6, label: this.$t('GlobalSetObj.status'), prop: 'statusName', cloWidth: '100', center: 'center' },
        // 创建人
        { id: 8, label: this.$t('GlobalSetObj.CreatePeople'), prop: 'createUserName', cloWidth: '150', center: 'center' },
        // 最新操作时间
        { id: 11, label: this.$t('GlobalSetObj.LastModified'), prop: 'updateTime', cloWidth: '160', center: 'center' },
        // 最新操作人
        {
          id: 9,
          label: this.$t('GlobalSetObj.LatestModifiedBy'),
          prop: 'updateUserName',
          cloWidth: '150',
          center: 'center'
        }
      ],
      siteIdList: [], // 起点 终点列表
      addCusTimeDialog: false,
      ruleForm: {
        startSiteId: '',
        endSiteId: '',
        duration: ''
      },
      rules: {
        startSiteId: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        endSiteId: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        duration: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: (rule, value, callback) => { // 正则表达式用于匹配非负数字，并允许最多一位小数
              const regex = /^\d+(\.\d{1})?$/;
              if (value === '') {
                callback();
              } else if (regex.test(value)) {
                // 检查数值是否大于零
                const numValue = parseFloat(value);
                if (numValue > 0) {
                  callback(); // 通过验证
                } else {
                  callback(new Error(this.$t('navbar.inputdayu1')));
                }
              } else {
                callback(new Error(this.$t('navbar.inputdayuzuoduo1')));
              }
            },
            trigger: 'change'
          }
        ]
      },
      editId: null,
      titleHeader: this.$t('navbar.newTranslateTime'),
      multipleSelection: [],
      bulkImportDialog: false,
      fileName: '',
      fileData: null,
      endSiteIdList: []
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '180px' : '110px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
    this.getSiteList();
  },
  methods: {
    triggerFileInput() {
      this.$refs.referenceUpload.click();
    },
    // 上传文件
    uploadFile(e) {
      console.log(e, 'LKLK');
      this.fileData = e.target.files[0];
      this.fileName = e.target.files[0].name;
    },
    downLoadFile() {
      timelinessdownloadmodule().then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'table_data.csv'); // 更改为实际的文件名和扩展名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 清理 URL
      });
    },
    // 删除文件
    delFile() {
      this.fileName = '';
      this.fileData = null;
      this.$refs.referenceUpload.value = '';
    },
    cusSubmit1() {
      const formData = new FormData();
      // 如果有文件上传
      if (this.fileName) {
        formData.append('file', this.fileData, this.fileName);
      } else {
        this.$message.error(this.$t('customerManagements.pleaseUploadFile'));
        return;
      }
      timelinesstransferPagemoimportData(formData).then(res => {
        if (res.status === 'OK') {
          this.addCusTimeDialog = false;
          this.$message.success(res.msg);
          this.bulkImportDialog = false;
          this.getSearch();
        }
      });
    },
    handleStartSiteId(val) {
      // 过滤掉 id 等于 val 的项，得到一个新的数组
      console.log(val, 'handleStartSiteId');
      this.ruleForm.endSiteId = '';
      this.endSiteIdList = this.siteIdList.filter((item) => item.siteId !== val);
      console.log(this.endSiteIdList, 'this.endSiteIdList');
    },
    handleBulkImportDialog() {
      this.fileName = '';
      this.fileData = null;
      this.$refs.referenceUpload.value = '';
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.startTime = this.timeValue[0];
        data.params.endTime = this.timeValue[1];
      } else {
        data.params.startTime = null;
        data.params.endTime = null;
      }
      if (this.queryForm.params.siteIdType === 1) {
        data.params.startSiteId = this.queryForm.params.siteId;
        data.params.endSiteId = null;
      } else {
        data.params.startSiteId = null;
        data.params.endSiteId = this.queryForm.params.siteId;
      }
      timelinessPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    addCusTime() {
      this.editId = null;
      this.titleHeader = this.$t('navbar.newTranslateTime');
      this.endSiteIdList = [];
      this.addCusTimeDialog = true;
    },
    // 点击编辑
    editDialog(row) {
      this.ruleForm = cloneDeep(row);
      this.editId = row.id;
      this.titleHeader = this.$t('navbar.editTranslateTime');
      this.endSiteIdList = this.siteIdList;
      this.addCusTimeDialog = true;
    },
    // 点击确定
    cusSubmit(val) {
      if (val === 1) {
        this.addCusTimeDialog = false;
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const data = this.ruleForm;
            if (this.editId) {
              data.id = this.editId;
              timelinesstransportAdd(data).then(res => {
                if (res.status === 'OK') {
                  this.addCusTimeDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                  this.getSearch();
                }
              });
            } else {
              timelinesstransportAdd(data).then(res => {
                if (res.status === 'OK') {
                  this.addCusTimeDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 启用停用
    updateStatus(row) {
      const data = {
        idList: [row.id],
        status: row.status === 1 ? 0 : 1
      };
      this.$confirm(this.$t('customerManagements.areSureStop1'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      }).then(async() => {
        timelinessmodifyStatus(data).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
            this.getSearch();
          }
        });
      }).catch(() => {
        console.log('取消关闭');
      });
    },
    bathUpdataStatus(status) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
        return;
      }
      const data = {
        idList: [],
        status: status
      };
      this.multipleSelection.forEach(item => {
        data.idList.push(item.id);
      });
      this.$confirm(this.$t('customerManagements.areSureStop'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      }).then(async() => {
        timelinessmodifyStatus(data).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
            this.getSearch();
          }
        });
      }).catch(() => {
        console.log('取消关闭');
      });
    },
    // 获取最近一周时间
    getTimer() {
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(29, 'day').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        siteIdType: 1,
        status: null, // 状态：1、启用，2、停用
        customerId: '' // 客户id
      };
      this.getSearch();
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 查起点, 终点
    getSiteList() {
      getEnableSiteList().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = res.data;
        }
      });
    },
    closeDrawer1() {
      console.log('关闭');
      this.ruleForm = {
        startSiteId: '',
        endSiteId: '',
        duration: ''
      };
      this.$refs.ruleForm.resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val, 'multipleSelection');
    },
    allExportOrder() {
      const data = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.startTime = this.timeValue[0];
        data.endTime = this.timeValue[1];
      } else {
        data.startTime = null;
        data.endTime = null;
      }
      if (this.queryForm.params.siteIdType === 1) {
        data.startSiteId = this.queryForm.params.siteId;
        data.endSiteId = null;
      } else {
        data.startSiteId = null;
        data.endSiteId = this.queryForm.params.siteId;
      }
      timelinessTransportexport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              if (res.data.taskId) {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              } else {
                this.$router.push({ path: '/views/resource/exportList' });
              }
            }
          }).catch(() => {
          });
        }
      });
    }
  }
};

</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}
</style>


// 订单

const orderCenterCont = {
  ViewTheImportResults: {
    zh: '查看导入结果',
    en: 'View the import results',
    pu: 'Exibir os resultados da importação'
  },
  partition: {
    zh: '分区：',
    en: 'partition：',
    pu: 'Zona：'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher o botão Consulta'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expanda o botão Consulta'
  },
  OrderStatus: {
    zh: '订单状态',
    en: 'Order status',
    pu: 'status do pedido'
  },
  CustomerChannels: {
    zh: '客户渠道',
    en: 'Customer channels',
    pu: 'Canal do cliente'
  },
  AirBillLadingNumber: {
    zh: '航空提单号',
    en: 'Air bill of lading number',
    pu: 'AWB'
  },
  BagNumber: {
    zh: '袋号',
    en: 'Bag number',
    pu: 'número de Saca'
  },
  PleaseSelectACountry: {
    zh: '请选择国家',
    en: 'Please select a country',
    pu: 'Por favor, selecione um país'
  },
  PleaseSelectAProvince: {
    zh: '请选择省份',
    en: 'Please select a province',
    pu: 'Por favor, selecione uma província'
  },
  PleaseSelectACity: {
    zh: '请选择城市',
    en: 'Please select a city',
    pu: 'Por favor, selecione uma cidade'
  },
  MultipleLineBreaks: {
    zh: '多个请换行输入',
    en: 'Multiple line breaks are entered',
    pu: 'Mais de um, por favor, entre em uma nova linha'
  },
  PleaseSelectDataPush: {
    zh: '请选择要推送的数据',
    en: 'Please select the data you want to push',
    pu: 'Por favor, selecione os dados que deseja empurrar'
  },
  ForecastOrders: {
    zh: '预报订单',
    en: 'Forecast orders',
    pu: 'Ordens de previsão'
  },
  HandedOver: {
    zh: '已交接',
    en: 'Handed over',
    pu: 'Entregue'
  },
  CancelForecast: {
    zh: '取消预报',
    en: 'Cancel the forecast',
    pu: 'Cancele a previsão'
  },
  Received: {
    zh: '已收货',
    en: 'Received',
    pu: 'Recebido'
  },
  Shipping: {
    zh: '出货',
    en: 'Shipping',
    pu: 'enviar'
  },
  OrderVoid: {
    zh: '订单作废',
    en: 'Order void',
    pu: 'Pedido inválido'
  },
  orderWasPlacedSuccessfully: {
    zh: '下单成功',
    en: 'The order was placed successfully',
    pu: 'O pedido foi feito com sucesso'
  },
  PositionBeenOpened: {
    zh: '已入仓',
    en: 'Position has been opened',
    pu: 'entrar no armazém'
  },
  Bagged: {
    zh: '已装袋',
    en: 'Bagged',
    pu: 'Ensacado'
  },
  OutLibrary: {
    zh: '已出库',
    en: 'Out of the library',
    pu: 'Fora do armazém'
  },
  OutLibraryIng: {
    zh: '出库',
    en: 'Out of the library',
    pu: 'Fora do armazém'
  },
  ProblemPiece: {
    zh: '问题件',
    en: 'Problem piece',
    pu: 'Peça problemática'
  },
  RecipientCountry: {
    zh: '收件人国家',
    en: 'Recipient country',
    pu: 'País de destinatário'
  },
  RecipientName: {
    zh: '收件人姓名',
    en: 'Recipient name',
    pu: 'Nome do destinatario'
  },
  StartEndTimes: {
    zh: '起止时间',
    en: 'Start and end times',
    pu: 'tempo de início e de fim'
  },
  logisticsSlipNumber: {
    zh: '物流单号',
    en: 'The number of the logistics slip',
    pu: 'Codigo de rastreamento'
  },
  DownloadFaceSheets: {
    zh: '批量下载面单',
    en: 'Download face sheets in bulk',
    pu: 'Exportar etiquetas em lote'
  },
  SelectShowColumn: {
    zh: '选择显示栏目',
    en: 'Select show column',
    pu: 'Selecione e mostre a coluna'
  },
  refunded: {
    zh: '退回',
    en: 'refunded',
    pu: 'devolvido'
  },
  NoRefunded: {
    zh: '不退回',
    en: 'No refunded',
    pu: 'não devolvido'
  },
  intercept: {
    zh: '拦截',
    en: 'intercept',
    pu: 'interceptar'
  },
  ExchangeOrders: {
    zh: '换单',
    en: 'Exchange orders',
    pu: 'alterar pedido'
  },
  ChangeOrderException: {
    zh: '换单异常',
    en: 'Change order exception',
    pu: 'Exceção de alteração de pedido'
  },
  SuccessfulOrderExchange: {
    zh: '换单成功',
    en: 'Successful order exchange',
    pu: 'alteração de pedidos bem sucedida'
  },
  NoOrdersExchanged: {
    zh: '未换单',
    en: 'No orders exchanged',
    pu: 'Nenhum pedido alterado'
  },
  other: {
    zh: '其他',
    en: 'other',
    pu: 'Outro'
  },
  ChannelError: {
    zh: '渠道错误',
    en: 'Channel error',
    pu: 'Erro do canal'
  },
  ChannelName: {
    zh: '渠道名称',
    en: 'Channel name',
    pu: 'Nome do canal'
  },
  orderInformationIncorrect: {
    zh: '订单信息错误',
    en: 'The order information is incorrect',
    pu: 'As informações do pedido estão incorretas'
  },

  LogisticsOrders: {
    zh: '物流订单',
    en: 'Logistics orders',
    pu: 'pedido de logística'
  },
  OrderDetails: {
    zh: '订单详情',
    en: 'Order details',
    pu: 'Detalhes do pedido'
  },
  OrderOperationLogs: {
    zh: '订单操作日志',
    en: 'Order operation logs',
    pu: 'Logs de operação de pedido'
  },
  ActionContent: {
    zh: '操作内容',
    en: 'Action content',
    pu: 'Conteúdo de operação'
  },
  typeOperation: {
    zh: '操作类型',
    en: 'The type of operation',
    pu: 'O tipo de operação'
  },
  operationIPAddress: {
    zh: '操作IP地址',
    en: 'The operation IP address',
    pu: 'O endereço IP da operação'
  },
  SelectColumn: {
    zh: '选择栏目',
    en: 'Select a column',
    pu: 'Selecione uma coluna'
  },
  Renminbi: {
    zh: '人民币',
    en: 'Renminbi',
    pu: 'chinês Yuan'
  },
  Dollar: {
    zh: '美元',
    en: 'Dollar',
    pu: 'Dólar americano'
  },
  Canadian: {
    zh: '加元',
    en: 'Canadian',
    pu: 'Canadense'
  },
  poundSterling: {
    zh: '英镑',
    en: 'pound sterling',
    pu: 'libra'
  },
  euro: {
    zh: '欧元',
    en: 'euro',
    pu: 'euro'
  },
  SwissFranc: {
    zh: '瑞士法郎',
    en: 'Swiss franc',
    pu: 'Franco suíço'
  },
  HkDollar: {
    zh: '港币',
    en: 'Hk dollar',
    pu: 'Hk dólar'
  },
  AustralianDollar: {
    zh: '澳元',
    en: 'Australian dollar',
    pu: 'Dólar australiano'
  },
  yen: {
    zh: '日元',
    en: 'yen',
    pu: 'iene japonês'
  },
  gift: {
    zh: '礼物',
    en: 'gift',
    pu: 'presente'
  },
  file: {
    zh: '文件',
    en: 'file',
    pu: 'arquivo'
  },
  commodity: {
    zh: '商品',
    en: 'commodity',
    pu: 'mercadoria'
  },
  parcel: {
    zh: '包裹',
    en: 'parcel',
    pu: 'pacote'
  },
  ReferenceNumberPricing: {
    zh: '参考号计价',
    en: 'Reference number pricing',
    pu: 'Preços de números de referência'
  },
  TheTypeOfExpense: {
    zh: '费用类型',
    en: 'The type of expense',
    pu: 'Tipo de custo'
  },
  expenses: {
    zh: '费用',
    en: 'expenses',
    pu: 'Despesas'
  },
  TotalCost: {
    zh: '总费用',
    en: 'Total cost',
    pu: 'Custo total'
  },
  AverageCostPerTicket: {
    zh: '平均每票费用',
    en: 'Average cost per ticket',
    pu: 'Custo médio por bilhete'
  },
  CustomerName: {
    zh: '客户名称',
    en: 'Customer name',
    pu: 'Nome do cliente'
  },
  CustomerAlias: {
    zh: '客户别称',
    en: 'CustomerAlias',
    pu: 'nome do cliente'
  },
  LargePackageNumber: {
    zh: '大包号',
    en: 'Large package number',
    pu: 'número de Saca'
  },
  Currency: {
    zh: '币种',
    en: 'Currency type',
    pu: 'Tipo de moeda'
  },
  WhetherReturnNot: {
    zh: '是否退回',
    en: 'Whether to return or not',
    pu: 'Quer retornar ou não'
  },
  OrderSource: {
    zh: '订单来源',
    en: 'Order source',
    pu: 'Fonte de ordem'
  },
  problemPieceType: {
    zh: '问题件类型',
    en: 'The type of problem piece',
    pu: 'Tipo de problema'
  },
  InboundTime: {
    zh: '入库时间',
    en: 'Inbound time',
    pu: 'data de entrada no armazém'
  },
  CreateTime: {
    zh: '创建时间',
    en: 'Create time',
    pu: 'Tempo de criação'
  },
  totalOrderNumber: {
    zh: '总单号',
    en: 'total order number',
    pu: 'No. PLP'
  },
  VatNumber: {
    zh: 'vat号',
    en: 'Vat number',
    pu: 'Número vat'
  },
  WhetherCharged: {
    zh: '是否带电',
    en: 'Whether it is charged or not',
    pu: 'Se é elétrico ou não'
  },
  BatteryType: {
    zh: '电池类型',
    en: 'Battery type',
    pu: 'Tipo de bateria'
  },
  NumberPackages: {
    zh: '包裹数量',
    en: 'Number of packages',
    pu: 'Número de pacotes'
  },
  StartTime: {
    zh: '开始时间',
    en: 'Start time',
    pu: 'Hora de início'
  },
  EndTime: {
    zh: '结束时间',
    en: 'End time',
    pu: 'Hora de fim'
  },
  PackageType: {
    zh: '包裹类型',
    en: 'Package type',
    pu: 'Tipo de pacote'
  },
  WithholdingTaxMethod: {
    zh: '预缴税方式',
    en: 'Withholding tax method',
    pu: 'Método do imposto pré-pago'
  },
  NextHomeFee: {
    zh: '下家费用',
    en: 'Next home fee',
    pu: 'Próxima taxa de casa'
  },
  NextHomeWeight: {
    zh: '下家重量',
    en: 'Next home weight',
    pu: 'Próximo peso doméstico'
  },
  PaidWeight: {
    zh: '收费重量',
    en: 'Paid weight',
    pu: 'peso cobrado'
  },
  DeclaredValue: {
    zh: '申报价值',
    en: 'Declared value',
    pu: 'Valor declarado'
  },
  TermsTrade: {
    zh: '贸易条款',
    en: 'Terms of Trade',
    pu: 'Termos de Comércio'
  },
  FulfillmentInformation: {
    zh: '配货信息',
    en: 'Fulfillment information',
    pu: 'Informações de cumprimento'
  },
  insurance: {
    zh: '保险',
    en: 'insurance',
    pu: 'seguro'
  },
  FaceSheetAddress: {
    zh: '面单地址',
    en: 'Face sheet address',
    pu: 'Endereço da etiqueta'
  },
  InternetNumber: {
    zh: '上网单号(17单号)',
    en: 'Internet number (17 numbers)',
    pu: 'Número de objeto postado (17 números)'
  },
  OtherExpenses: {
    zh: '其他费用',
    en: 'Other expenses',
    pu: 'Outras despesas'
  },
  RecipientAddress1: {
    zh: '收件人地址1',
    en: 'Recipient address 1',
    pu: 'Endereço do destinatário 1'
  },
  RecipientAddress2: {
    zh: '收件人地址2',
    en: 'Recipient address 2',
    pu: 'Endereço do destinatário 2'
  },
  RecipientArea: {
    zh: '收件人区',
    en: 'Recipient area',
    pu: 'Área do destinatário'
  },
  CertificateType: {
    zh: '证件类型',
    en: 'Document type',
    pu: 'Tipo de identificação'
  },
  CertificateCode: {
    zh: '证件号',
    en: 'Identification Number',
    pu: 'Número de identificação'
  },
  RecipientIDNumber: {
    zh: '收件人证件号码',
    en: 'Recipient\'s ID number',
    pu: 'Número de identificação do destinatário'
  },
  recipientCredentialType: {
    zh: '收件人证件类型',
    en: 'The recipient\'s credential type',
    pu: 'O tipo de identificação do destinatário'
  },
  RecipientCity: {
    zh: '收件人城市',
    en: 'Recipient city',
    pu: 'Cidade beneficiária'
  },
  RecipientCompany: {
    zh: '收件人公司',
    en: 'Recipient company',
    pu: 'Empresa beneficiária'
  },
  RecipientHouseNumber: {
    zh: '收件人门牌号',
    en: 'Recipient\'s house number',
    pu: 'Número da porta do destinatário'
  },
  RecipientMailbox: {
    zh: '收件人邮箱',
    en: 'Recipient mailbox',
    pu: 'Caixa de correio do destinatário'
  },
  RecipientMobilePhone: {
    zh: '收件人手机',
    en: 'The recipient\'s mobile phone number',
    pu: 'O número do telefone celular do destinatário'
  },
  RecipientPassportNumber: {
    zh: '收件人护照号',
    en: 'Recipient\'s passport number',
    pu: 'Número do passaporte do destinatário'
  },
  RecipientPhone: {
    zh: '收件人电话',
    en: 'Recipient\'s phone',
    pu: 'Telefone do destinatário'
  },
  RecipientProvince: {
    zh: '收件人省份',
    en: 'Recipient province',
    pu: 'Província do destinatário'
  },
  RecipientStreet: {
    zh: '街道',
    en: 'Recipient street',
    pu: 'Rua do destinatário'
  },
  Street: {
    zh: '收件人街道',
    en: '',
    pu: 'Rua do destinatário'
  },
  RecipientTaxID: {
    zh: '收件人税号',
    en: 'Recipient tax ID',
    pu: 'ID de imposto de destinatário'
  },
  RecipientzipCode: {
    zh: '收件人邮编',
    en: 'Recipient\'s zip code',
    pu: 'CEP do destinatário'
  },
  RemarksInformation: {
    zh: '备注信息',
    en: 'Remarks information',
    pu: 'Informações sobre comentários'
  },
  SalesPlatform: {
    zh: '销售平台',
    en: 'Sales platform',
    pu: 'Plataforma de vendas'
  },
  SenderAddress: {
    zh: '发件人地址',
    en: 'Sender address',
    pu: 'Endereço de remetente'
  },
  SenderArea: {
    zh: '发件人区',
    en: 'Sender area',
    pu: 'Área de remetente'
  },
  senderIDNumber: {
    zh: '发件人证件号码',
    en: 'The sender\'s ID number',
    pu: 'O número de identificação do remetente'
  },
  senderCredentialType: {
    zh: '发件人证件类型',
    en: 'The sender credential type',
    pu: 'O tipo de identificação do remetente'
  },
  SenderCity: {
    zh: '发件人城市',
    en: 'Sender city',
    pu: 'cidade do remetente'
  },
  SenderCompany: {
    zh: '发件人公司',
    en: 'Sender company',
    pu: 'Empresa de remetente'
  },
  SenderCountry: {
    zh: '发件人国家',
    en: 'Sender country',
    pu: 'País de remetente'
  },
  HouseNumber: {
    zh: '门牌号',
    en: 'HouseNumber',
    pu: 'Número'
  },
  senderHouseNumber: {
    zh: '发件人门牌号',
    en: 'The sender\'s house number',
    pu: 'O número da casa do remetente'
  },
  SenderMailbox: {
    zh: '发件人邮箱',
    en: 'Sender mailbox',
    pu: 'email de remetente'
  },
  senderMobilePhone: {
    zh: '发件人手机',
    en: 'The sender\'s mobile phone number',
    pu: 'O número do celular do remetente'
  },
  SenderName: {
    zh: '发件人姓名',
    en: 'Sender name',
    pu: 'Nome do remetente'
  },
  senderPassportNumber: {
    zh: '发件人护照号',
    en: 'The sender\'s passport number',
    pu: 'O número do passaporte do remetente'
  },
  senderPhone: {
    zh: '发件人电话',
    en: 'The sender\'s phone',
    pu: 'O telefone do remetente'
  },
  SenderProvince: {
    zh: '发件人省份',
    en: 'Sender province',
    pu: 'província do remetente'
  },
  SenderStreet: {
    zh: '发件人街道',
    en: 'Sender street',
    pu: 'Rua Remetente'
  },
  SenderTaxID: {
    zh: '发件人税号',
    en: 'Sender tax ID',
    pu: 'ID de imposto de remetente'
  },
  SenderzipCode: {
    zh: '发件人邮编',
    en: 'Sender zip code',
    pu: 'CEP de remetente'
  },
  StandardFees: {
    zh: '标准费用',
    en: 'Standard fees',
    pu: 'Taxas padrão'
  },
  AccumulatedWeight: {
    zh: '材积重量',
    en: 'Accumulated weight',
    pu: 'Peso cubado'
  },
  WeighAndWeigh: {
    zh: '称重重量',
    en: 'Weigh and weigh',
    pu: 'Peso de pesagem'
  },
  ForecastWeight: {
    zh: '预报重量',
    en: 'Forecast weight',
    pu: 'Peso previsto'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'data de criação'
  },
  startThanEnd: {
    zh: '开始时间不能大于结束时间',
    en: 'The start time cannot be greater than the end time',
    pu: 'O tempo de início não pode ser maior do que o tempo final'
  },
  queriesCan100: {
    zh: '一次最多查询100条',
    en: 'Up to 100 queries can be made at a time',
    pu: 'Até 100 consultas podem ser feitas por vez'
  },
  FaceSheet: {
    zh: '面单',
    en: '',
    pu: 'Etiqueta'
  },
  exchangeOrderResult: {
    zh: '换单结果',
    en: 'The result of the exchange order',
    pu: 'O resultado da alteração de pedido'
  },
  LogisticsStatus: {
    zh: '物流状态',
    en: 'Logistics status',
    pu: 'status logistico'
  },
  InitialLogisticsSlipNumber: {
    zh: '初始物流单号',
    en: 'Initial logistics slip number',
    pu: 'Número inicial de pedido logístico'
  },
  finalLogisticsOrderNumber: {
    zh: '最终物流单号',
    en: 'The final logistics order number',
    pu: 'O número final de pedido logístico'
  },
  ReTakeNumber: {
    zh: '重新取号',
    en: 'Re-take the number',
    pu: 'Re-peça o número'
  },
  first50: {
    zh: '注意：每次选择超过50条数据，只会取号前50条',
    en: 'Note: Each time you select more than 50 pieces of data, only the first 50 items will be taken',
    pu: 'Nota: Cada vez que você selecionar mais de 50 dados, apenas os primeiros 50 itens serão retirados'
  },
  QueryTrack: {
    zh: '查询轨迹',
    en: 'Query the track',
    pu: 'consultar o tracking'
  },
  TheDeliveryAddress: {
    zh: '收件地址',
    en: 'The delivery address',
    pu: 'O endereço de entrega'
  },
  notReNumbered: {
    zh: '选中的订单都已换单成功，无需重新取号',
    en: 'The selected orders have been successfully exchanged and do not need to be re-numbered',
    pu: 'As ordens selecionadas foram trocadas com sucesso e não precisam ser re-numeradas'
  },
  numberWasTakenSuccessfully: {
    zh: '取号成功',
    en: 'The number was taken successfully',
    pu: 'O número foi tomado com sucesso'
  },
  startTimeThanEndTime: {
    zh: '开始时间不能大于结束时间',
    en: 'The start time cannot be greater than the end time',
    pu: 'O tempo de início não pode ser maior do que o tempo final'
  },
  PleaseSelectEndTime: {
    zh: '请选择结束时间',
    en: 'Please select an end time',
    pu: 'Por favor, selecione um tempo final'
  },
  PleaseSelectStartTime: {
    zh: '请选择开始时间',
    en: 'Please select a start time',
    pu: 'Por favor, selecione uma hora de início'
  },
  queried2000pieces: {
    zh: '每次最多查询2000条数据',
    en: 'Up to 2,000 pieces of data can be queried at a time',
    pu: 'Até 2.000 dados podem ser consultados de cada vez'
  },
  PleaseSelectSpecificCustomer: {
    zh: '请选择指定客户',
    en: 'Please select a specific customer',
    pu: 'Selecione um cliente específico'
  },
  SelectChannel: {
    zh: '选择渠道',
    en: 'Select a channel',
    pu: 'Selecione um canal'
  },
  ServiceProviderOrderNumber: {
    zh: '服务商订单号',
    en: 'Service provider order number',
    pu: 'Número do pedido do provedor de serviços'
  },
  yes: {
    zh: '是',
    en: 'yes',
    pu: 'Sim'
  },
  No: {
    zh: '否',
    en: 'No',
    pu: 'Não'
  },
  customer: {
    zh: '客户',
    en: 'customer',
    pu: 'cliente'
  },
  CustomerAccountNumber: {
    zh: '客户账号',
    en: 'Customer account number',
    pu: 'conta do cliente'
  },
  providerChannel: {
    zh: '服务商渠道',
    en: 'providerChannel',
    pu: 'Canal de fornecedor de serviços'
  },
  signedSent: {
    zh: '包裹未签收或未寄出后退回寄件人',
    en: 'Parcels are returned to the sender after they have not been signed or sent',
    pu: 'Os pacotes são devolvidos ao remetente depois de não terem sido entregues ou enviados'
  },
  SmallPacketPicture: {
    zh: '小包图片',
    en: 'Small packet picture',
    pu: 'imagem de pacote pequeno'
  },
  ClickImageLargerView: {
    zh: '点击图片查看大图',
    en: 'Click on the image for a larger view',
    pu: 'Clique na imagem para uma exibição maior'
  },
  AddProducts: {
    zh: '添加商品',
    en: 'Add products',
    pu: 'Adicionar produtos'
  },
  neutral: {
    zh: '不带电',
    en: 'neutral',
    pu: 'neutro'
  },
  BuiltBattery: {
    zh: '内置电池',
    en: 'Built-in battery',
    pu: 'Bateria embutida'
  },
  ExternalBattery: {
    zh: '外置电池',
    en: 'External battery',
    pu: 'Bateria externa'
  },
  liquid: {
    zh: '液体',
    en: 'liquid',
    pu: 'líquido'
  },
  NonLiquid: {
    zh: '非液体',
    en: 'Non-liquid',
    pu: 'Não líquido'
  },
  powder: {
    zh: '粉末',
    en: 'powder',
    pu: 'pó'
  },
  NonPowdered: {
    zh: '非粉末',
    en: 'Non-powdered',
    pu: 'Não em pó'
  },
  Magnetic: {
    zh: '带磁',
    en: 'Magnetic',
    pu: 'Magnético'
  },
  NonMagnetic: {
    zh: '非带磁',
    en: 'Non-magnetic',
    pu: 'Não-magnético'
  },
  DeleteProduct: {
    zh: '删除商品',
    en: 'Delete a product',
    pu: 'Exclua um produto'
  },
  ChineseProductName: {
    zh: '中文品名',
    en: 'Chinese product name',
    pu: 'Nome do produto em chinês'
  },
  EnglishProductName: {
    zh: '英文品名',
    en: 'English product name',
    pu: 'Nome do produto em inglês'
  },
  ProductQuantity: {
    zh: '商品数量',
    en: 'Product quantity',
    pu: 'Quantidade do produto'
  },
  unit: {
    zh: '单位',
    en: 'unit',
    pu: 'unidade'
  },
  DeclareUnitPrice: {
    zh: '申报单价',
    en: 'Declare the unit price',
    pu: 'Declare o preço unitário'
  },
  DeclaredWeight: {
    zh: '申报重量(KG)',
    en: 'Declared weight(KG)',
    pu: 'Peso declarado(KG)'
  },
  TheFormatIsIncorrect: {
    zh: '格式不正确',
    en: 'The format is incorrect',
    pu: 'O formato está incorreto'
  },
  WhetherLiquid: {
    zh: '是否液体',
    en: 'Whether it is liquid',
    pu: 'Se é líquido'
  },
  WhetherMagneticOrNot: {
    zh: '是否带磁',
    en: 'Whether magnetic or not',
    pu: 'Magnético ou não'
  },
  WhetherPowder: {
    zh: '是否粉末',
    en: 'Whether it is powder',
    pu: 'Se é pó'
  },
  BoxNumber: {
    zh: '箱号',
    en: 'Box number',
    pu: 'Número da caixa'
  },
  brand: {
    zh: '品牌',
    en: 'brand',
    pu: 'marca'
  },
  Material: {
    zh: '材质',
    en: 'Material',
    pu: 'Material'
  },
  use: {
    zh: '用途',
    en: 'use',
    pu: 'usar'
  },
  Origin: {
    zh: '产地',
    en: 'Origin',
    pu: 'Origem'
  },
  ProductLinks: {
    zh: '商品链接',
    en: 'Product links',
    pu: 'Links de produtos'
  },
  PictureLink: {
    zh: '图片链接',
    en: 'Picture link',
    pu: 'Link de imagem'
  },
  specification: {
    zh: '规格',
    en: 'specification',
    pu: 'especificação'
  },
  ImportSelfFlyingOrders: {
    zh: '自飞订单导入',
    en: 'Import of self-flying orders',
    pu: 'Importação de pedidos de auto-vôo'
  },
  orders: {
    zh: '订单',
    en: 'orders',
    pu: 'Ordens'
  },
  BulkPush: {
    zh: '批量推送',
    en: 'Bulk push',
    pu: 'Empurrar em lote'
  },
  NumberOfPushes: {
    zh: '推送次数',
    en: 'Number of pushes',
    pu: 'Número de empurrões'
  },
  PushNodes: {
    zh: '推送节点',
    en: 'Push nodes',
    pu: 'Empurrar acenos'
  },
  PushTheResults: {
    zh: '推送结果',
    en: 'Push the results',
    pu: 'Empurre os resultados'
  },
  FaceSinglePath: {
    zh: '面单路径',
    en: 'Face single path',
    pu: 'roteiro de etiqueta'
  },
  TheRequestAddress: {
    zh: '请求地址',
    en: 'The request address',
    pu: 'O endereço de solicitação'
  },
  Repetition: {
    zh: '复重',
    en: 'Repetition',
    pu: 'Repetição'
  },
  TheOrderNumber: {
    zh: '订单号',
    en: 'The order number',
    pu: 'Número do pedido'
  },
  TheOrderNumberCustomer: {
    zh: '订单号',
    en: 'The order number of the customer\'s order',
    pu: 'Número do pedido'
  },
  ReferenceNumber: {
    zh: '参考号',
    en: 'Reference number',
    pu: 'Codigo de referência'
  },
  AnjunTrackingNumber: {
    zh: '参考号',
    en: 'Anjun tracking number',
    pu: 'No. Ref. Da Anjun'
  },
  ImportOrders: {
    zh: '导入订单',
    en: 'Import orders',
    pu: 'importar pedido'
  },
  Uploaded: {
    zh: '已上传',
    en: 'Uploaded',
    pu: 'Carregado'
  },
  pieceOfData: {
    zh: '条数据',
    en: 'piece of data',
    pu: 'dados'
  },
  TimeFormat: {
    zh: '时间格式',
    en: 'Time format',
    pu: 'Formato de tempo'
  },
  PleaseUploadYourOrder: {
    zh: '请上传订单',
    en: 'Please upload your order',
    pu: 'Por favor, carregue seu pedido'
  },
  UploadSuccessful: {
    zh: '上传成功',
    en: 'Upload successful',
    pu: 'Carregar bem-sucedido'
  },
  AddOrder: {
    zh: '添加订单',
    en: 'Add an order',
    pu: 'Adicione um pedido'
  },
  ModifyOrder: {
    zh: '修改订单',
    en: 'Modify the order',
    pu: 'Modifique a ordem'
  },
  TrajectoryQuery: {
    zh: '轨迹查询',
    en: 'Trajectory query',
    pu: 'Rastrear objeto'
  },
  SystemException: {
    zh: '系统异常',
    en: 'System exception',
    pu: 'Exceção do sistema'
  },
  DateBusiness: {
    zh: '业务日期',
    en: 'Date of business',
    pu: 'Data do negócio'
  },
  exchangeError: {
    zh: '换单错误原因',
    en: 'The reason for the order exchange error',
    pu: 'A razão para o erro de troca de pedidos'
  },
  DestinationCountry: {
    zh: '目的国家',
    en: 'Destination country',
    pu: 'País de destino'
  },
  OrderFees: {
    zh: '订单费用',
    en: 'Order Fees',
    pu: 'Taxas de Pedidos'
  },
  Article: {
    zh: '第',
    en: 'Article',
    pu: 'Artigo'
  },
  ThereDataThatHasBeenSuccessfullyPushed: {
    zh: '存在推送成功的数据，无法继续推送',
    en: 'There is data that has been successfully pushed, and the push cannot be continued',
    pu: 'Há dados que foram empurrados com sucesso, e o empurrão não pode ser continuado'
  },
  timeFormatIncorrect: {
    zh: '时间格式错误',
    en: 'The time format is incorrect',
    pu: 'O formato de tempo está incorreto'
  },
  CourierName: {
    zh: '快递名称',
    en: 'Courier name',
    pu: 'Nome do express'
  },
  CourierCode: {
    zh: '快递代码',
    en: 'Courier code',
    pu: 'Código de express'
  },
  AddedSuccessfully: {
    zh: '添加成功',
    en: 'Added successfully',
    pu: 'Adicionado com sucesso'
  },
  EditSuccessful: {
    zh: '编辑成功',
    en: 'Edit successful',
    pu: 'Editar bem-sucedido'
  },
  add: {
    zh: '添加',
    en: 'add',
    pu: 'adicionar'
  },
  Editor: {
    zh: '编辑人',
    en: 'Editor',
    pu: 'Editor'
  },
  EditTime: {
    zh: '编辑时间',
    en: 'Edit time',
    pu: 'Hora de edição'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  deleteCourierSource: {
    zh: '确定删除该快递来源？',
    en: 'Are you sure to delete the courier source?',
    pu: 'Tem certeza de excluir a fonte do express?'
  },
  AddCourierSource: {
    zh: '添加快递来源',
    en: 'Add a courier source',
    pu: 'Adicione uma fonte de express'
  },
  ViewDetails: {
    zh: '查看详情',
    en: 'View details',
    pu: 'Ver detalhes'
  },
  ModifyCourierSource: {
    zh: '修改快递来源',
    en: 'Modify the courier source',
    pu: 'Modifique a fonte do express'
  },
  ticket: {
    zh: '票',
    en: 'ticket',
    pu: 'bilhete'
  },
  WarehouseSituationToday: {
    zh: '今日仓库情况',
    en: 'Warehouse situation today',
    pu: 'Situação do armazém hoje'
  },
  ShippingWithin7Days: {
    zh: '7天内发货情况',
    en: 'Shipping within 7 days',
    pu: 'Envio em 7 dias'
  },
  AnomalyStatistics: {
    zh: '异常件统计',
    en: 'Anomaly statistics',
    pu: 'Estatísticas de anomalias'
  },
  AnnualCompletionPlan: {
    zh: '年度完成计划',
    en: 'Annual completion plan',
    pu: 'Plano de conclusão anual'
  },
  Shipments: {
    zh: '发货量',
    en: 'Shipments',
    pu: 'Transferências'
  },
  quantity: {
    zh: '数量',
    en: 'quantity',
    pu: 'quantidade'
  },
  month: {
    zh: '月份',
    en: 'month',
    pu: 'mês'
  },
  PlanShipments: {
    zh: '计划出货量',
    en: 'Plan shipments',
    pu: 'quantidade de envio'
  },
  ActualShipments: {
    zh: '实际出货量',
    en: 'Actual shipments',
    pu: 'quantidade real de envio'
  },
  months: {
    zh: '月',
    en: 'month',
    pu: 'mês'
  },
  PercentageRegionalShipments: {
    zh: '地区发货占比(本月)',
    en: 'Percentage of regional shipments (this month)',
    pu: 'Percentual de embarques regionais (este mês)'
  },
  OrdersShippedMonth: {
    zh: '本月发货订单',
    en: 'Orders shipped this month',
    pu: 'Encomendas enviadas deste mês'
  },
  TargetCompletionRateToday: {
    zh: '今日目标完成率',
    en: 'Target completion rate today',
    pu: 'Taxa de conclusão de metas hoje'
  },
  selectAHierarchy: {
    zh: '选择层级',
    en: 'Select a hierarchy',
    pu: 'Selecione uma hierarquia'
  },
  region: {
    zh: '地区',
    en: 'region',
    pu: 'região'
  },
  Area: {
    zh: '区',
    en: 'Area',
    pu: 'Bairro'
  },
  ChannelVolume: {
    zh: '渠道货量',
    en: 'Channel volume',
    pu: 'Volume do canal'
  },
  TodayCompletionRate: {
    zh: '今日完成率',
    en: 'Today\'s completion rate',
    pu: 'Taxa de conclusão de hoje'
  },
  CumulativeRuns: {
    zh: '累计运行',
    en: 'Cumulative runs',
    pu: 'Corridas cumulativas'
  },
  days: {
    zh: '天',
    en: 'days',
    pu: 'dia'
  },
  CumulativeOrders: {
    zh: '累计订单',
    en: 'Cumulative orders',
    pu: 'pedidos acumulados'
  },
  Previous: {
    zh: '上一步',
    en: 'Previous',
    pu: 'Anterior'
  },
  Next: {
    zh: '下一步',
    en: 'Next',
    pu: 'Próximo'
  },
  ReplaceDetails: {
    zh: '换单详情',
    en: '',
    pu: 'Detalhe de mudança de pedido'
  },
  OrderBasicInformation: {
    zh: '订单基本信息',
    en: 'Order basic information',
    pu: 'informações básicas de pedido'
  },
  RecipientInformation: {
    zh: '收件人信息',
    en: 'Recipient information',
    pu: 'Informações do destinatário'
  },
  ProductInformation: {
    zh: '商品信息',
    en: 'Product information',
    pu: 'Informações sobre produtos'
  },
  SenderInformation: {
    zh: '发件人信息',
    en: 'Sender information',
    pu: 'Informações sobre remetente'
  },
  passport: {
    zh: '护照',
    en: 'passport',
    pu: 'passaporte'
  },
  identityCard: {
    zh: '身份证',
    en: 'identityCard',
    pu: 'bilhete de identidade'
  },
  DrivingLicense: {
    zh: '驾驶证',
    en: 'Driving license',
    pu: 'licença de Condução'
  },
  StudentID: {
    zh: '学生证',
    en: 'StudentID',
    pu: 'Cartão de identidade de aluno'
  },
  requiredNotFilled: {
    zh: '存在必填项未填',
    en: 'There are required fields that are not filled in',
    pu: 'Existem campos obrigatórios que não são preenchidos'
  },
  SaveSuccessfully: {
    zh: '保存成功',
    en: 'Save successfully',
    pu: 'Salvar com sucesso'
  },
  ModificationSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  DeleteSuccessful: {
    zh: '删除成功',
    en: 'Delete successful',
    pu: 'Excluir bem-sucedido'
  },
  Province: {
    zh: '所属省份',
    en: 'Province',
    pu: 'Província'
  },
  NameCityChinese: {
    zh: '城市中文名',
    en: 'The name of the city Chinese',
    pu: 'O nome da cidade em chinês'
  },
  NameCityEnglish: {
    zh: '城市英文名',
    en: 'The English name of the city',
    pu: 'O nome da cidade em inglês'
  },
  TheCityCode: {
    zh: '城市二字代码',
    en: 'The city code',
    pu: 'O código de três caracteres da cidade'
  },
  CityThreeWordCode: {
    zh: '城市三字代码',
    en: 'City three-word code',
    pu: 'Código de três caracteres da cidade'
  },
  Zip: {
    zh: '邮编',
    en: 'Zip',
    pu: 'Código postal'
  },
  AddCity: {
    zh: '添加城市',
    en: 'Add a city',
    pu: 'Adicione cidade'
  },
  ModifyCity: {
    zh: '修改城市',
    en: 'Modify the city',
    pu: 'Modifique a cidade'
  },
  operationWillDeleteCity: {
    zh: '此操作将会删除该数据, 是否继续？',
    en: 'This operation will delete the city, will it continue?',
    pu: 'Esta operação vai apagar dados, vai continuar?'
  },
  ChineseNameCountry: {
    zh: '国家中文名',
    en: 'Chinese name of the country',
    pu: 'Nome do país em chinês'
  },
  EnglishNameCountry: {
    zh: '国家英文名',
    en: 'The English name of the country',
    pu: 'O nome do país em inglês'
  },
  CountryCode: {
    zh: '国家二字代码',
    en: 'Country code',
    pu: 'Código de dois caracteres país'
  },
  CountryThreeWordCode: {
    zh: '国家三字代码',
    en: 'Country three-word code',
    pu: 'Código de três caracteres do país'
  },
  PlatformWhichBelongs: {
    zh: '所属平台',
    en: 'The platform to which it belongs',
    pu: 'A plataforma à qual pertence'
  },
  OutboundTime: {
    zh: '出库时间',
    en: 'Outbound time',
    pu: 'tempo de sair do Armazem'
  },
  TrackNumber: {
    zh: '追踪号',
    en: 'Tracking number',
    pu: 'Número de rastreamento'
  },
  WhetherPushWasSuccessful: {
    zh: '是否推送成功',
    en: 'Whether the push was successful',
    pu: 'Se o empurrão foi bem sucedido'
  },
  LogDescription: {
    zh: '日志描述',
    en: 'Log description',
    pu: 'Descrição do registro'
  },
  DownloadTemplate: {
    zh: '下载模板',
    en: 'Download the template',
    pu: 'Download do modelo'
  },
  CustomizeListColumn: {
    zh: '自定义列表栏目',
    en: 'Customize the list column',
    pu: 'Personalizar coluna'
  },
  PushFastHandCharge: {
    zh: '推送快手揽收费',
    en: 'Push fast hand charge',
    pu: 'Empurre a taxa de solicitação rápida'
  },
  BulkExport: {
    zh: '批量导出',
    en: 'Bulk export',
    pu: 'Exportar em lote'
  },
  impounded: {
    zh: '扣件',
    en: 'impounded',
    pu: 'apreendido'
  },
  CancellationImpounded: {
    zh: '取消扣件',
    en: 'Cancellation of impounded',
    pu: 'Liberado'
  },
  CauseProblem: {
    zh: '问题件原因',
    en: 'The cause of the problem',
    pu: 'A causa do problema'
  },
  FinalFaceSheet: {
    zh: '最终面单',
    en: 'Final face sheet',
    pu: 'etiqueta final'
  },
  SelectScenario: {
    zh: '请按需求选择以下两种导出方案中的一种',
    en: 'Select one of the following two export scenarios as needed',
    pu: 'Selecione um dos dois propostas de exportação a seguir, conforme necessário'
  },
  Export10000Attached: {
    zh: '可导出10000条订单，附带商品',
    en: 'Export 10,000 orders with merchandise attached',
    pu: 'Exportar 10.000 pedidos com mercadorias anexadas'
  },
  Exported50000: {
    zh: '可导出100000条订单，不附带商品',
    en: '100,000 orders can be exported without merchandise',
    pu: '100.000 pedidos podem ser exportados sem mercadoria'
  },
  ProblemPieceSettings: {
    zh: '问题件设置',
    en: 'Problem piece settings',
    pu: 'Configurações de pacotes de problema'
  },
  CannotBeEmpty: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  AddressName: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  SetupSucceeded: {
    zh: '设置成功',
    en: 'Setup succeeded',
    pu: 'Configuração bem sucedida'
  },
  WillBeVoided: {
    zh: '此操作会将选中的订单作废, 是否继续?',
    en: 'This action will void the selected order, do you want to continue?',
    pu: 'Esta ação anulará a ordem selecionada, você quer continuar?'
  },
  AreYouSureUnholdSomething: {
    zh: '确定取消扣件?',
    en: 'Are you sure you want to unhold something?',
    pu: 'Tem certeza que quer desempresar alguma coisa?'
  },
  CancellationSuccessful: {
    zh: '取消成功',
    en: 'Cancellation successful',
    pu: 'Cancelar bem sucedido'
  },
  CancellationFailed: {
    zh: '取消失败',
    en: 'Cancellation failed',
    pu: 'Falha no cancelamento'
  },
  ImportWasSuccessful: {
    zh: '导入成功',
    en: 'The import was successful',
    pu: 'A importação foi bem sucedida'
  },
  TheImportIsComplete: {
    zh: '导入完成，导入结果请到系统管理中的资源导出列表中下载',
    en: 'After the import is complete, please download the import result from the resource export list in System Administration',
    pu: 'Após a importação, baixe o resultado da importação da lista de exportação de recursos na Administração do Sistema'
  },
  templateWillBeGenerated: {
    zh: '模板将生成，请到系统管理中的资源导出列表中下载，',
    en: 'The template will be generated, please download it from the resource export list in System Administration,',
    pu: 'O modelo será gerado, por favor, baixe-o da lista de exportação de recursos em Administração de Sistemas,'
  },
  notRepeatDownload: {
    zh: '请勿重复点击下载模板！',
    en: 'Do not repeat the click to download the template!',
    pu: 'Não repita a clicar para baixar o modelo!'
  },
  GoNow: {
    zh: '立即前往',
    en: 'Go now',
    pu: 'Vá agora.'
  },
  ShutDown: {
    zh: '关闭',
    en: '',
    pu: 'Fechar'
  },
  Queries5000RerQuery: {
    zh: '每次最多查询5000条',
    en: 'A maximum of 5,000 queries can be made at a time',
    pu: 'Um máximo de 5.000 consultas podem ser feitas por vez'
  },
  DesperatelyLoading: {
    zh: '拼命加载中',
    en: 'Desperately loading',
    pu: 'Carregando desesperadamente'
  },
  noDataExport: {
    zh: '无数据可导出，请先查询到数据再导出',
    en: 'There is no data to export, please query the data before exporting',
    pu: 'Não há dados para exportar, consulte os dados antes de exportar'
  },
  canOnlyExport10000: {
    zh: '最多只能导出10000条数据',
    en: 'You can only export up to 10,000 pieces of data',
    pu: 'Você só pode exportar até 10.000 dados'
  },
  canOnlyExport50000: {
    zh: '最多只能导出50000条数据',
    en: 'You can only export up to 50,000 pieces of data',
    pu: 'Você só pode exportar até 50.000 dados'
  },
  canOnlyExport200000: {
    zh: '最多只能导出100000条数据',
    en: 'You can only export up to 100,000 pieces of data',
    pu: 'Você só pode exportar até 100.000 dados'
  },
  ThereIs: {
    zh: '当前有',
    en: 'There is',
    pu: 'Há'
  },
  PleaseAdjustSearchScope: {
    zh: '请调整搜索范围',
    en: 'Please adjust the search scope',
    pu: 'Por favor, ajuste o escopo de pesquisa'
  },
  ExportSuccessful: {
    zh: '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，',
    en: 'If the export is successful, the data will be generated within 1~10 minutes, please download it from the resource export list in system management.',
    pu: 'Se a exportação for bem sucedida, os dados serão gerados dentro de 1~10 minutos, por favor, baixe-os da lista de exportação de recursos no gerenciamento do sistema.'
  },
  ExportSuccessful14: {
    zh: '下载成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源列表中下载，',
    en: '',
    pu: 'Faça o download com sucesso, os dados serão gerados em 1 a 10 minutos. Acesse a lista de recursos no gerenciamento do sistema para fazer o download.'
  },
  notRepeatTheExport: {
    zh: '请勿重复导出',
    en: 'Do not repeat the export！',
    pu: 'Não repita a exportação！'
  },
  WhetherSuccessful: {
    zh: '是否成功',
    en: 'Whether it was successful',
    pu: 'Se foi bem sucedido'
  },
  RequestMessages: {
    zh: '请求报文',
    en: 'Request messages',
    pu: 'Solicitar mensagens'
  },
  OriginalMessage: {
    zh: '原始报文',
    en: 'Original message',
    pu: 'Mensagem original'
  },
  ResponseMessages: {
    zh: '响应报文',
    en: 'Response messages',
    pu: 'Mensagens de resposta'
  },
  copy: {
    zh: '复制',
    en: 'copy',
    pu: 'Cópia'
  },
  ReplicationSucceeded: {
    zh: '复制成功',
    en: 'Replication succeeded',
    pu: 'Replicação bem sucedida'
  },
  CountryOrigin: {
    zh: '所属国家',
    en: 'Country of origin',
    pu: 'País de origem'
  },
  ChineseNameProvince: {
    zh: '省份中文名',
    en: 'Chinese name of the province',
    pu: 'Nome da província em chinês'
  },
  EnglishNameProvince: {
    zh: '省份英文名',
    en: 'The English name of the province',
    pu: 'O nome da província em inglês'
  },
  ProvinceCode: {
    zh: '省份二字代码',
    en: 'The province code',
    pu: 'O código de dois caracteres da província'
  },
  ThreeWordCodeProvinces: {
    zh: '省份三字代码',
    en: 'Three-word code for provinces',
    pu: 'Código de três caracteres para províncias'
  },
  Region: {
    zh: '所属区',
    en: 'Region',
    pu: 'Região'
  },
  LevelOfOwnership: {
    zh: '所属层级',
    en: 'Level of ownership',
    pu: 'Nível de propriedade'
  },
  NameRegionChinese: {
    zh: '区中文',
    en: 'The name of the region Chinese',
    pu: 'O nome da região em chinês'
  },
  ChineseName: {
    zh: '中文名',
    en: 'Chinese name',
    pu: 'Nome em chinês'
  },
  EnglishName: {
    zh: '英文名',
    en: 'English name',
    pu: 'Nome em inglês'
  },
  extra: {
    zh: '扩展字段',
    en: 'Extended fields',
    pu: 'Campos estendidos'
  },
  MultipleFields: {
    zh: '多个字段用‘，’隔开',
    en: 'Multiple fields are separated by \',\'',
    pu: 'Vários campos são separados por \',\''
  },
  TwoWordCode: {
    zh: '二字代码',
    en: 'Two-word code',
    pu: 'Código de dois caracteres'
  },
  ThreeWordCode: {
    zh: '三字代码',
    en: 'Three-word code',
    pu: 'Código de três caracteres'
  },
  EnglishNameRegion: {
    zh: '区英文',
    en: 'The English name of the region',
    pu: 'O nome da região em inglês'
  },
  TheAreaCode: {
    zh: '区二字代码',
    en: 'The area code',
    pu: 'O código de dois caracteres de área'
  },
  ThreeWordCodeRegion: {
    zh: '区三字代码',
    en: 'Three-word code for the region',
    pu: 'Código de três caracteres para a região'
  },
  BelongsToTheCity: {
    zh: '所属城市: ',
    en: 'Belongs to the city: ',
    pu: 'cidade.: '
  },
  SubordinateSuperior: {
    zh: '所属上级',
    en: 'Subordinate superior',
    pu: 'Superior'
  },
  RecipientSender: {
    zh: '收件人/发件人',
    en: 'Recipient/Sender',
    pu: 'Destinatário/Remetente'
  },
  name: {
    zh: '姓名',
    en: 'name',
    pu: 'nome'
  },
  MobilePhoneNumber: {
    zh: '手机号',
    en: 'Mobile phone number',
    pu: 'Número de telefone celular'
  },
  sender: {
    zh: '发件人',
    en: 'sender',
    pu: 'remetente'
  },
  addressee: {
    zh: '收件人',
    en: 'addressee',
    pu: 'destinatário'
  },
  telephoneNumber: {
    zh: '电话号码',
    en: 'telephone number',
    pu: 'número telefônico'
  },
  country: {
    zh: '国家',
    en: 'country',
    pu: 'país'
  },
  province: {
    zh: '省份',
    en: 'province',
    pu: 'Província'
  },
  state: {
    zh: '州',
    en: 'state',
    pu: 'Estado'
  },
  city: {
    zh: '城市',
    en: 'city',
    pu: 'Cidade'
  },
  company: {
    zh: '公司',
    en: 'company',
    pu: 'companhia'
  },
  addressBook: {
    zh: '地址簿名称',
    en: 'Address book',
    pu: 'Address book'
  },
  address: {
    zh: '地址',
    en: 'address',
    pu: 'endereço'
  },
  TaxID: {
    zh: '税号',
    en: 'Tax ID',
    pu: 'número de taxa'
  },
  // WhetherItIsDefault: {
  //   zh: '是否公开',
  //   en: 'Public or not',
  //   pu: 'Público ou não'
  // },
  applicationType: {
    zh: '地址簿应用类型',
    en: 'Address book type',
    pu: 'Tipo de livro de endereços'
  },
  SetToDefault: {
    zh: '设为默认',
    en: 'Set to default',
    pu: 'Definido como padrão'
  },
  ItIsSetToDefault: {
    zh: '确定设为默认?',
    en: 'Are you sure it\'s set to default?',
    pu: 'Tem certeza que está definido como padrão?'
  },
  SettingSucceedsDefault: {
    zh: '设置默认成功',
    en: 'The setting succeeds by default',
    pu: 'A configuração de padrão é bem sucedida'
  },
  SellerName: {
    zh: '卖家姓名',
    en: 'Seller Name',
    pu: 'Nome do vendedor'
  },
  PlatformOrderNumber: {
    zh: '平台订单号',
    en: 'Platform order number',
    pu: 'Número do pedido da plataforma'
  },
  DownloadImportOrderTemplate: {
    zh: '下载导入订单模板',
    en: 'Download the import order template',
    pu: 'Baixe o modelo de pedido de importação'
  },
  PlatformOrderTime: {
    zh: '平台接单时间',
    en: 'Platform order time',
    pu: 'Tempo de receber o pedido da plataforma'
  },
  AnjunReferenceNumber: {
    zh: '安骏参考号',
    en: 'Anjun reference number',
    pu: 'Codigo de Ref. de Anjun'
  },
  LogisticsTicketNumber: {
    zh: '物流单号(转单号)',
    en: 'Logistics ticket number (transfer number)',
    pu: 'Codigo de rastreamento (número de transferência)'
  },
  CourierSignOffTime: {
    zh: '快递签收时间',
    en: 'Courier sign-off time',
    pu: 'Tempo de entrega do express'
  },
  FlightDepartureTime: {
    zh: '航班起飞时间',
    en: 'Flight departure time',
    pu: 'Horário de partida do voo'
  },
  FlightLandingTime: {
    zh: '航班落地时间',
    en: 'Flight landing time',
    pu: 'Tempo de aterrissagem de vôo'
  },
  SigningTime: {
    zh: '签收时间',
    en: 'Signing time',
    pu: 'Hora da assinatura'
  },
  LatestTracks: {
    zh: '最新轨迹',
    en: 'Latest tracks',
    pu: 'Últimos trackings'
  },
  TheLatestTraceTime: {
    zh: '最新轨迹时间',
    en: 'The latest trace time',
    pu: 'O último tempo de rastreamento'
  },
  PostOfficeBranchName: {
    zh: '邮局分行名称',
    en: 'Post office branch name',
    pu: 'Nome da agência dos Correios'
  },
  TrajectoryPresentation: {
    zh: '轨迹展示',
    en: 'Trajectory presentation',
    pu: 'Apresentação de tracking'
  },
  SearchCriteriaCannotEmpty: {
    zh: '搜索条件不能为空',
    en: 'The search criteria cannot be empty',
    pu: 'Os critérios de pesquisa não podem estar vazios'
  },
  TrackingNumber: {
    zh: '快递单号',
    en: 'Tracking number',
    pu: 'Número de pedido de express'
  },
  DeliveryTime: {
    zh: '寄快递时间',
    en: 'Delivery time',
    pu: 'Tempo de envio'
  },
  NotTrack: {
    zh: '暂未匹配到轨迹',
    en: 'Not matched to the track yet',
    pu: 'Ainda não combina com o tracking.'
  },
  hours: {
    zh: '时',
    en: 'hours',
    pu: 'Hora'
  },
  minute: {
    zh: '分',
    en: 'minute',
    pu: 'minuto'
  },
  NotFound: {
    zh: '查询不到',
    en: 'Not found',
    pu: 'Não encontrado'
  },
  ArrivedWaitingPickedUp: {
    zh: '到达待取',
    en: 'Arrived waiting to be picked up',
    pu: 'Chegou esperando para ser retirado'
  },
  DeliveryFailed: {
    zh: '投递失败',
    en: 'Delivery failed',
    pu: 'Falha na entrega'
  },
  SignedOffSuccessfully: {
    zh: '签收成功',
    en: 'Signed off successfully',
    pu: 'Entregue com sucesso'
  },
  ThereMayBeAnException: {
    zh: '可能异常',
    en: 'There may be an exception',
    pu: 'Pode haver uma exceção.'
  },
  InTransit: {
    zh: '运输途中',
    en: 'In transit',
    pu: 'Em trânsito'
  },
  OrderInquiryPleaseComeLater: {
    zh: '订单查询中，请稍后',
    en: 'Order inquiry, please come later',
    pu: 'consultado o pedido, por favor, venha mais tarde'
  },
  TheDataPushWasSuccessful: {
    zh: '数据推送成功',
    en: 'The data push was successful',
    pu: 'Dados empurrados com sucesso'
  },
  TheTypeOfCharge: {
    zh: '扣费类型',
    en: 'The type of charge',
    pu: 'O tipo de cobrar'
  },
  TheAmountOfDeduction: {
    zh: '扣费金额',
    en: 'The amount of the deduction',
    pu: 'O valor de cobrar'
  },
  EnquiryNumber: {
    zh: '查询号码',
    en: 'Enquiry number',
    pu: 'Número de consulta'
  },
  UpdateForecastWeightTemplate: {
    zh: '预报重量更新模板',
    en: 'Forecast Weight Update Template',
    pu: 'atualização de Modelo de peso previsto'
  },
  UpdateOrderForecastWeightsBulk: {
    zh: '预报重量更新',
    en: 'Forecast weight updates',
    pu: 'atualizações de peso previsto'
  },
  TrackingNumberNotFoundInTheOrder: {
    zh: '在订单中未找到的追踪号',
    en: 'Tracking number not found in the order',
    pu: 'Número de rastreamento não encontrado no pedido'
  },
  NotTheOrderTrackingNumberForecastStatus: {
    zh: '不是预报状态的订单追踪号',
    en: 'Not the order tracking number of the forecast status',
    pu: 'Números de tracking de pedidos que não são status previsto'
  },
  RepeatTrackingNumber: {
    zh: '重复追踪号',
    en: 'Repeat the tracking number',
    pu: 'Repita o número de rastreamento'
  },
  PleaseModifyItBeforeImporting: {
    zh: '请修改后再导入',
    en: 'Please modify it before importing',
    pu: 'Favor modificar e reimportar'
  },
  ModifyHSCODE: {
    zh: '修改HSCODE',
    en: 'Modify HSCODE',
    pu: 'Modificar hscode'
  },
  PleaseEnterHSCODE: {
    zh: '请输入HSCODE',
    en: 'Please enter HSCODE',
    pu: 'Por favor, digite HSCODE'
  },
  SelectAtLeastOnePieceOfData: {
    zh: '至少选择一条数据',
    en: 'Select at least one piece of data',
    pu: 'Selecione pelo menos um dados'
  },
  ConfirmTheModification: {
    zh: '确认修改这些订单的HSCODE?',
    en: 'Confirm the modification of HSCODE for these orders?',
    pu: 'Confirmar a modificação do HSCODE destes pedidos?'
  },
  HSCODECannotExceed20Length: {
    zh: 'HSCODE长度不能超过20个字符',
    en: 'HSCODE cannot exceed 20 characters in length',
    pu: 'HSCODE não pode exceder 20 caracteres em comprimento'
  },
  ScanQueries: {
    zh: '扫描查询',
    en: 'Scan queries',
    pu: 'Consultas de varredura'
  },
  OrderInquiry: {
    zh: '订单查询',
    en: 'Order Inquiry',
    pu: 'consulta de pedido'
  },
  ScanTheOrderNumberInquiry: {
    zh: '扫描单号查询(请扫描订单号、参考号、物流单号、最终物流单号)',
    en: 'Scan the order number inquiry (please scan the order number, reference number, logistics ticket number, final logistics order number)',
    pu: 'Escaneie número do pedido para consultar (escaneie o número do pedido, número de referência, número do pedido logístico, número final do pedido de logística)'
  },
  EnterTheOrderNumberInquiry: {
    zh: '输入单号查询(请输入订单号、参考号、物流单号、最终物流单号)',
    en: 'Enter the order number inquiry (please enter the order number, reference number, logistics ticket number, final logistics ticket number)',
    pu: 'digite número do pedido para consultar (escaneie o número do pedido, número de referência, número do pedido logístico, número final do pedido de logística)'
  },
  total: {
    zh: '总计',
    en: 'total',
    pu: 'Total'
  },
  NumberOfOrders: {
    zh: '订单数',
    en: 'Number of orders',
    pu: 'Número de pedidos'
  },
  remove: {
    zh: '清除',
    en: 'remove',
    pu: 'remover'
  },
  clearTheCurrentSelection: {
    zh: '确定清除当前选中项?',
    en: 'Are you sure to clear the current selection?',
    pu: 'Tem certeza de limpar a seleção atual?'
  },
  ImportAndSendSMSMessages: {
    zh: '导入发送短信',
    en: 'Import and send SMS messages',
    pu: 'Importar e enviar mensagens SMS'
  },
  AddImportOrders: {
    zh: '添加/导入订单',
    en: 'Add/import orders',
    pu: 'adicionar/importar Pedidos'
  },
  DownloadInvoicesInBulk: {
    zh: '批量下载发票',
    en: 'Download invoices in bulk',
    pu: 'Baixe faturas em lote'
  },
  BulkDownload: {
    zh: '批量下载',
    en: 'Bulk download',
    pu: 'Download em lote'
  },
  FastenerVoid: {
    zh: '扣件 / 作废',
    en: 'Fastener / void',
    pu: 'apreendido / anulado'
  },
  BrazilOrderImport: {
    zh: '巴西订单导入',
    en: 'Brazil order import',
    pu: 'importar pedidos brasileiros'
  },
  GenerateInvoices: {
    zh: '生成发票',
    en: 'Generate invoices',
    pu: 'Gerar faturas'
  },
  InvoicePreview: {
    zh: '发票预览',
    en: 'Invoice preview',
    pu: 'Visualização de fatura'
  },
  InvoiceDownloadComplete: {
    zh: '发票下载完成',
    en: 'Invoice download complete',
    pu: 'Download concluído de fatura'
  },
  InvoiceGenerationWillLittleSlow: {
    zh: '发票生成中，会有点慢，请耐心等待，勿重复操作',
    en: 'Invoice generation, will be a little slow, please be patient, do not repeat the operation',
    pu: 'Geração de faturas em andamento, vai ser um pouco lento, por favor seja paciente, não repita a operação'
  },
  importSuccessfulPleaseDownload: {
    zh: '导入成功，有数据未成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载',
    en: 'The import is successful, the data is not successful, the data will be generated within 1~10 minutes, please download it from the resource export list in the system management',
    pu: 'A importação foi bem sucedida, há dados mal sucedidos, os dados serão gerados dentro de 1 a 10 minutos, por favor, baixe-os da lista de exportação de recursos no gerenciamento do sistema'
  },
  ImportFailed: {
    zh: '导入失败',
    en: 'Import failed',
    pu: 'Falha na importação'
  },
  InvoiceExport: {
    zh: '发票导出',
    en: 'Invoice export',
    pu: 'Exportação de notas fiscais'
  },
  OrderExport: {
    zh: '订单导出',
    en: 'Order export',
    pu: 'Exportação de pedidos'
  },
  OnlyEnglishLetters: {
    zh: '只能输入英文字母',
    en: 'Only English letters can be entered',
    pu: 'Apenas letras em inglês podem ser inseridas'
  },
  wordCountLengthMust: {
    zh: '字数长度必须大于',
    en: 'The word count length must be greater than',
    pu: 'O comprimento de caracteres deve ser maior do que'
  },
  TheOrderDoesNotExist: {
    zh: '订单不存在',
    en: 'The order does not exist',
    pu: 'O pedido não existe.'
  },
  BelongsToTheRegion: {
    zh: '所属大区',
    en: 'Belongs to the region',
    pu: 'Pertence à região'
  },
  back: {
    zh: '返回',
    en: 'back',
    pu: 'Voltar'
  },
  list: {
    zh: '列表',
    en: '',
    pu: 'lista'
  },
  stateList: {
    zh: '州列表', // 返回州列表...
    en: '',
    pu: 'lista de Estados'
  },
  cityList: {
    zh: '城市列表',
    en: 'List of cities',
    pu: 'Lista de cidades'
  },
  AreaList: {
    zh: '地区列表',
    en: '',
    pu: 'Lista de distrito'
  },
  SubordinateState: {
    zh: '所属州：',
    en: '',
    pu: 'Estado'
  },
  startZipCode: {
    zh: '起始邮编:',
    en: '',
    pu: 'CEP do início'
  },
  endZipCode: {
    zh: '结束邮编:',
    en: '',
    pu: 'CEP do fim'
  },
  nameCn: {
    zh: '中文名: ',
    en: 'Chinese name: ',
    pu: 'Nome em chinês: '
  },
  nameEn: {
    zh: '英文名: ',
    en: 'English name: ',
    pu: 'Nome em inglês: '
  },
  expandText: {
    zh: '扩展字段: ',
    en: 'Extended : ',
    pu: 'Campos estendidos: '
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  zipCode: {
    zh: '邮编: ',
    en: 'Zip: ',
    pu: 'Código postal '
  },
  writeZipCode: {
    zh: '填写邮编。填写示例如：10~20,21,22~30',
    en: '',
    pu: 'Preencha CEP. Preencha como o exemplo: 10~20,21,22~30'
  },
  inputNum: {
    zh: '请输入数字',
    en: '',
    pu: 'Favor digitar número'
  },
  input8Sum: {
    zh: '请输入8位数字',
    en: '',
    pu: 'favro digitar 8 dígitos'
  },
  inputChinese: {
    zh: '请输入中文',
    en: '',
    pu: 'favor inserir em chinês'
  },
  notEnglish: {
    zh: '不能有英文字符',
    en: '',
    pu: 'Sem caracteres ingleses'
  },
  inputEnglish: {
    zh: '请输入英文',
    en: '',
    pu: 'favor inserir em inglês'
  },
  notChinese: {
    zh: '不能有中文字符',
    en: '',
    pu: 'Sem caracteres chineses'
  },
  basicInformation: {
    zh: '基本信息',
    en: '',
    pu: 'Informações básicas'
  },
  inputCnNamw: {
    zh: '请输入中文名',
    en: '',
    pu: 'favor digitar nome em chinês'
  },
  inputEnName: {
    zh: '请输入英文名',
    en: '',
    pu: 'favor digitar nome em inglês'
  },
  inputEmail: {
    zh: '请输入邮编',
    en: '',
    pu: 'Favor inserir CEP'
  },
  chooseRegion: {
    zh: '请选择大区',
    en: '',
    pu: 'favor selecionar distrito'
  },
  // 偏远地区邮编管理 --------------------------
  zip: {
    zh: '邮编',
    en: 'Zip',
    pu: 'Código postal'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitar'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'Consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  areaID: {
    zh: '地区id',
    en: '',
    pu: 'id de distrito'
  },
  longitude: {
    zh: '经度',
    en: '',
    pu: 'longitude'
  },
  latitude: {
    zh: '纬度',
    en: '',
    pu: 'latitude'
  },
  PleaseSelectTheWaybill: {
    zh: '请选择运单',
    en: 'Please select the waybill',
    pu: 'Selecione a carta de porte'
  },
  TheNumberOfSelectedPolygonsExceedsTheMaximumLimit200: {
    zh: '选中面单数超出最大限制：200',
    en: 'The number of selected polygons exceeds the maximum limit: 200',
    pu: 'O número de polígonos selecionados excede o limite máximo: 200'
  },
  TheNumberOfSelectedWaybillsExceedsTheMaximumLimit200: {
    zh: '选中运单数超出最大限制：200',
    en: 'The number of selected waybills exceeds the maximum limit: 200',
    pu: 'O número de cartas de porte selecionados excede o limite máximo: 200'
  },
  PickUpBagNumber: {
    zh: '揽收袋号',
    en: 'Pick up bag number',
    pu: 'Recolha do número do saco'
  },
  TransitBinBagNumber: {
    zh: '中转仓袋号',
    en: 'Transit bin bag number',
    pu: 'Número da saca em trânsi'
  },
  updateBagNumber: {
    zh: '更新袋号',
    en: '',
    pu: 'Actualizar Nº de saca'
  },
  CorrespondingBagNumber: {
    zh: '请扫描或者手动录入袋子对应袋号。',
    en: '',
    pu: 'Escanear ou registar Nº das sacas por manual'
  },
  AutomaticBagSealing: {
    zh: '如当前有未封袋的任务，请先完成并封袋，否则系统将自动封袋。',
    en: '',
    pu: 'Se actualmente existe as tarefas não seladas, faça favor de terminar e selar,senão a saca irá automaticamente selada pelo sistema'
  },
  Multiple: {
    zh: '多个换行输入',
    en: 'Multiple line break inputs',
    pu: 'Entradas de quebra de linha múltipla'
  },
  AnjunDatagram: {
    zh: '安骏报文',
    en: 'Anjun message',
    pu: 'Mensagem de Anjun'
  },
  CustomerDatagram: {
    zh: '客户报文',
    en: 'Client message',
    pu: 'Mensagem do cliente'
  },
  RequestTime: {
    zh: '请求时间',
    en: 'Request time',
    pu: 'Tempo de solicitação'
  },
  AnjunParameters: {
    zh: '安骏参数',
    en: 'Anjun Parameters',
    pu: 'Parâmetros Anjun'
  },
  AnjunReturnsParameters: {
    zh: '安骏返回参数',
    en: 'Anjun Return Parameters',
    pu: 'Parâmetros de retorno do Anjun'
  },
  SupplierParameters: {
    zh: '供应商参数',
    en: 'Supplier parameters',
    pu: 'Parâmetros do fornecedor'
  },
  SupplierReturnsParameters: {
    zh: '供应商返回参数',
    en: 'Vendor return parameters',
    pu: 'Parâmetros de retorno do fornecedor'
  },
  CustomerParameters: {
    zh: '客户参数',
    en: 'Client parameters',
    pu: 'Parâmetros do cliente'
  },
  CustomerReturnParameters: {
    zh: '客户返回参数',
    en: 'Client return parameters',
    pu: 'Parâmetros de retorno do cliente'
  },
  ChildOrderInvoiceInformation: {
    zh: '子单发票信息',
    en: 'Child order invoice information',
    pu: 'Informações da fatura do pedido da criança'
  },
  SubpackageNumber: {
    zh: '子包号',
    en: 'Subpackage number',
    pu: 'Número da subembalagem'
  },
  OrderInformationFileUpload: {
    zh: '订单信息文件上传',
    en: 'Order information file upload',
    pu: 'Carregamento do arquivo de informações do pedido'
  },
  OnlyExcelFilesCanBeUploaded: {
    zh: '只能上传Excel文件',
    en: 'Only Excel files can be uploaded',
    pu: 'Só arquivos de Excel podem ser carregados'
  },
  ImportOrdersBulk: {
    zh: '批量导入订单',
    en: 'Import orders in bulk',
    pu: 'Importar pedidos em lotes'
  },
  SelectCustomers: {
    zh: '选择客户',
    en: 'Select Customers',
    pu: 'Escolher cliente'
  },
  OrdersImported: {
    zh: '订单正在导入，请稍等...',
    en: 'Orders are being imported, please wait...',
    pu: 'O pedido está sendo carregado, faça o favor de esperar...'
  },
  TheOrderImportComplete: {
    zh: '订单导入完成',
    en: 'The order import is complete',
    pu: 'A importação de pedido concluída'
  },
  DownloadImportFailureOrderDetails: {
    zh: '下载导入失败订单明细',
    en: 'Download the import failure order details',
    pu: 'Baixar detalhes do pedido que não foi importado com sucesso'
  },
  PleaseAddImportOrderFile: {
    zh: '请添加导入订单文件',
    en: 'Please add the import order file',
    pu: 'Adicione o arquivo do pedido importado'
  },
  TheCustomerAndImportOrderFilesCannotBeEmpty: {
    zh: '客户和导入订单文件不能为空',
    en: 'The customer and import order files cannot be empty',
    pu: 'Os arquivos de clientes e pedidos importados não podem ficar vazios'
  },
  TheDownloadFailed: {
    zh: '下载失败',
    en: 'The download failed',
    pu: 'O download falhou'
  },
  ImportOrderFailureData: {
    zh: '导入订单失败数据',
    en: 'Import order failure data',
    pu: 'Importar dados de pedido falhado'
  },
  RedistributionOfOutlets: {
    zh: '重新分配网点',
    en: '',
    pu: 'Reatribuir o ponto'
  },
  registerExceptions: {
    zh: '登记异常件',
    en: '',
    pu: 'Registar o pacote anormal'
  },
  downloadExpressLabels: {
    zh: '下载快递标签',
    en: '',
    pu: 'Baixar etiqueta do expresso'
  },
  exportOrderData: {
    zh: '导出订单数据',
    en: '',
    pu: 'Exportar o dado do pedido'
  },
  moreActions: {
    zh: '更多操作',
    en: '',
    pu: 'Mais operações'
  },
  customizingDisplayColumns: {
    zh: '自定义显示列',
    en: '',
    pu: 'Coluna exibida personalizada'
  },
  printIATA: {
    zh: '打印IATA',
    en: '',
    pu: 'Imprimir IATA'
  },
  latestStatusTime: {
    zh: '最新状态时间',
    en: '',
    pu: 'Hora do status mais recente'
  },
  anjunWaybillNumber: {
    zh: '安骏运单号',
    en: '',
    pu: 'Número de rastreio da Anjun'
  },
  incomingWeightOfTransitWarehouse: {
    zh: '中转仓入库重量(kg)',
    en: '',
    pu: 'Peso na entrada do armazém da transferência'
  },
  invoiceMoney: {
    zh: '开票金额(BRL)',
    en: '',
    pu: 'Valor da Fatura (BRL)'
  },
  recipientProvince: {
    zh: '收件人省份',
    en: '',
    pu: 'Província do destinatário'
  },
  deliveryPerson: {
    zh: '派送员',
    en: '',
    pu: 'Entregador'
  },
  clearCurrentContent: {
    zh: '清空当前内容',
    en: 'Clear current content',
    pu: 'Limpar conteúdo atual'
  },
  scanTab: {
    zh: '扫描',
    en: 'scanning',
    pu: 'escanear'
  },
  Returning: {
    zh: '正在退回',
    en: 'Returning',
    pu: 'Está devolvendo'
  },
  theNumberOfLargePackagesIs0: {
    zh: '大包数为0',
    en: 'The number of large packages is 0',
    pu: 'O número de pacotes grandes é 0'
  },
  DeliveryFailureReasons: {
    zh: '投递失败原因',
    en: '',
    pu: 'Motivo de falha'
  },
  TimeoutOrNot: {
    zh: '是否超时',
    en: '',
    pu: 'Houve atraso'
  },
  FirstDeliveryTime: {
    zh: '首派时间',
    en: '',
    pu: 'Tempo de entrega primeira'
  },
  firstSendLong: {
    zh: '首派所用时长(H)',
    en: '',
    pu: 'Prazo usado de entrega primeira(H)'
  },
  SHEINTime: {
    zh: 'SHEIN时效要求(天)',
    en: '',
    pu: 'Requisito de prazo de shein(Dia)'
  },
  TimeSpent: {
    zh: '运单耗时',
    en: '',
    pu: 'Consumo de tempo do pedido'
  },
  TimeoutDuration: {
    zh: '超时时长',
    en: '',
    pu: 'Tempo Excedido'
  },
  TimeoutDay: {
    zh: '超时天数',
    en: '',
    pu: 'Dias de atraso'
  },
  TimeoutDay1: {
    zh: '第一次投递失败的时间或签收时间',
    en: '',
    pu: 'Tempo da primeira falha de entrega ou tempo de assinatura'
  },
  TimeoutDay2: {
    zh: '首派时间减去客户出仓时间',
    en: '',
    pu: 'Tempo de primeira entrega menos tempo de saída do cliente'
  },
  TimeoutDay3: {
    zh: '签收时间减去客户出仓时间',
    en: '',
    pu: 'Tempo de entregado menos tempo de saída do cliente'
  },
  TimeoutDay4: {
    zh: '当前时间减去SHEIN时效到期时间',
    en: '',
    pu: 'Tempo atual menos o horário limite de entrega da SHEIN.'
  },
  TimeoutDay5: {
    zh: '超时时长对应的天数',
    en: '',
    pu: 'Número de dias correspondentes à duração do atraso'
  },
  customerDeliveryTime: {
    zh: '客户出仓时间',
    en: '',
    pu: 'Hora de Saída da Encomenda'
  },
  customerDeliveryStatusDescription: {
    zh: '客户发货状态描述',
    en: '',
    pu: 'Descrição do Estado de Envio do Cliente'
  },
  zuixinguijineir: {
    zh: '最新轨迹内容',
    en: '',
    pu: 'Conteúdo do rastreio mais novo'
  },
  wentijianyuanyin: {
    zh: '问题原因',
    en: '',
    pu: 'Motivo de problema'
  },
  deliveryTime: {
    zh: '网点派件时间',
    en: '',
    pu: 'Horário de Entrega do Ponto'
  },
  returnPackage: {
    zh: '退件包裹',
    en: '',
    pu: 'Pacote para devolver'
  },
  dueTime01: {
    zh: '理应送达时间',
    en: '',
    pu: 'Horário em que deve ser entregue'
  },
  deliveryCar: {
    zh: '派送车辆类型',
    en: '',
    pu: 'Tipo de veículo de entrega'
  },
  accountType: {
    zh: '账号类型',
    en: '',
    pu: 'Tipo da conta'
  },
  hedan: {
    zh: '合单',
    en: '',
    pu: 'Embalagem'
  },
  notrepeatOperation: {
    zh: '请保持下一站一致',
    en: '',
    pu: 'Mantenha a próxima parada consistente'
  },
  proportions: {
    zh: '比例',
    en: '',
    pu: 'proporção'
  },
  votesDetails: {
    zh: '票数详情',
    en: '',
    pu: 'Detalhes de quantidade'
  },
  packageMsg: {
    zh: '包裹详情',
    en: '',
    pu: 'Detalhes da parcela'
  },
  problemTime: {
    zh: '问题件登记时间',
    en: '',
    pu: 'Horário de registro de problema'
  },
  problemSite: {
    zh: '问题件登记网点',
    en: '',
    pu: 'Ponto de registro de problema'
  },
  claimInformation: {
    zh: '理赔信息',
    en: '',
    pu: 'Informações sobre o reembolso'
  },
  newPushTime: {
    zh: '最新推送时间',
    en: '',
    pu: 'Último tempo de envio'
  },
  todaySingData: {
    zh: '今日签收票数(SHEIN)',
    en: '',
    pu: 'Qtd. de pacotes entregados hoje(SHEIN)'
  },
  todaySingPushData: {
    zh: '今日已签收轨迹推送数(SHEIN)',
    en: '',
    pu: 'Qtd. de rastreio enviado de pacotes entregados hoje(SHEIN)'
  },
  todayFailPushData: {
    zh: '今日轨迹推送失败数(SHEIN)',
    en: '',
    pu: 'Qtd. de rastreio enviado falhado hoje(SHEIN)'
  },
  trajectoryContent: {
    zh: '轨迹内容',
    en: '',
    pu: 'Conteúdo do rastreio'
  },
  receiverURL: {
    zh: '接收方URL',
    en: '',
    pu: 'Recipiente URL'
  },
  pushFailedNode: {
    zh: '推送失败节点',
    en: '',
    pu: 'Nó de envio falhado'
  },
  final: {
    zh: '最终状态',
    en: '',
    pu: 'Estado final'
  },
  serviceProviderConflict: {
    zh: '服务商与其他条件冲突, 请重新选择',
    en: '',
    pu: 'O parceiro está em conflito com outras condições, por favor seleccione novamente'
  },
  siteConflict: {
    zh: '网点与其他条件冲突, 请重新选择',
    en: '',
    pu: 'O ponto está em conflito com outras condições, por favor seleccione novamente'
  },
  transferCenterConflict: {
    zh: '转运中心与其他条件冲突, 请重新选择',
    en: '',
    pu: 'O centro de trânsito está em conflito com outras condições, selecione novamente'
  },
  gridNumConflict: {
    zh: '格口与其他条件冲突, 请重新选择',
    en: '',
    pu: 'A grade está em conflito com outras condições, por favor seleccione novamente'
  },
  endThanstart: {
    zh: '要求结束邮编要比起始邮编大',
    en: '',
    pu: 'O código postal final deve ser maior do que o código postal inicial'
  },
  customerTime: {
    zh: '客户时效要求天数',
    en: '',
    pu: 'Dias de prazo requisitados por cliente'
  },
  selected: {
    zh: '已选中',
    en: '',
    pu: 'Seleccionado'
  },
  upLoad3: {
    zh: '上传凭证,最多上传3张图片',
    en: '',
    pu: 'Enviar comprovantes, no máximo 3 fotos'
  },
  cancelPedido: {
    zh: '取消订单凭证',
    en: '',
    pu: 'Cancelar comprovante do pedido'
  },
  Pedido1: {
    zh: '凭证',
    en: '',
    pu: 'Comprovante'
  },
  Pedido2: {
    zh: '凭证2',
    en: '',
    pu: 'Comprovante 2'
  },
  todayTotalOperationData: {
    zh: '今日总操作数据',
    en: '',
    pu: 'Dados totais de operações de hoje'
  },
  orderQuantity: {
    zh: '下单量数',
    en: '',
    pu: 'Qtd. de pedidos'
  },
  endCollectionTicketCount: {
    zh: '末端揽收票数',
    en: '',
    pu: 'Qtd. de coleta final'
  },
  deliveryPointStorageCount: {
    zh: '派送网点入库票数',
    en: '',
    pu: 'Qtd. de pacotes recebidos no ponto'
  },
  dispatchTicketCount: {
    zh: '派件票数',
    en: '',
    pu: 'Qtd. de pacotes entregues'
  },
  signedReceiptCount: {
    zh: '签收票数',
    en: '',
    pu: 'Qtd. de pacotes assinados'
  },
  issueTicketCount: {
    zh: '问题件票数',
    en: '',
    pu: 'Qtd. de pacotes com problemas'
  },
  canceledTicketCount: {
    zh: '已取消票数',
    en: '',
    pu: 'Qtd. de pacotes cancelados'
  },
  branchReturnTicketCount: {
    zh: '网点退回票数',
    en: '',
    pu: 'Qtd. de pacotes devolvidos pelo ponto de entrega'
  },
  reverseDeliveryCount: {
    zh: '逆向派件票数',
    en: '',
    pu: 'Qtd. de pacotes de entrega reversa'
  },
  returnedItemReceiptCount: {
    zh: '退件签收票数',
    en: '',
    pu: 'Qtd. de pacotes devolvidos assinados'
  },
  orderFailureTicketCount: {
    zh: '下单失败票数',
    en: '',
    pu: 'Qtd. de pedidos falhados'
  },
  distributionOperationData: {
    zh: '分拨操作数据',
    en: '',
    pu: 'Dados de operação do centro de triagem'
  },
  customerOrderStatus: {
    zh: '客户下单情况',
    en: '',
    pu: 'Situação de pedidos de clientes'
  },
  configCustomer: {
    zh: '配置客户',
    en: '',
    pu: 'Configurar cliente'
  },
  recentSevenDaysCustomerOrderTrend: {
    zh: '近7天客户订单走势',
    en: '',
    pu: 'Tendência de pedidos de clientes nos últimos 7 dias'
  },
  questionItemOperationData: {
    zh: '问题件操作数据',
    en: '',
    pu: 'Dados de operação de pacotes com problemas'
  },
  comparedToYesterday: {
    zh: '较昨日',
    en: '',
    pu: 'Comparado ao dia anterior'
  },
  totalCount: {
    zh: '总计',
    en: '',
    pu: 'Total'
  },
  packageLost: {
    zh: '包裹丢失',
    en: '',
    pu: 'Pacotes perdidos'
  },
  packageDamaged: {
    zh: '包裹破损',
    en: '',
    pu: 'Pacotes danificados'
  },
  packageWasStolen: {
    zh: '包裹被抢',
    en: '',
    pu: 'Pacotes roubados'
  },
  InvoiceInformation: {
    zh: '发票信息',
    en: '',
    pu: 'Informações da fatura'
  },
  replaceScanner: {
    zh: '换单扫描',
    en: '',
    pu: 'Troca de verificação de ordem'
  }
};
export {
  orderCenterCont
};

import request from '@/utils/request';
// import axios from 'axios';

// const requestUser = axios.create({
//   baseURL: process.env.VUE_APP_BASE_APILogin,
//   timeout: 30000
// });
const baseUserURL = process.env.VUE_APP_USER;

export function userExportAll(data) {
  return request({
    url: '/user/exportAll',
    method: 'post',
    data
  });
}

export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  });
}

export function getUserInfo() {
  return request({
    url: '/user/getUserInfo',
    method: 'get'
  });
}

// 注册
export function getRegister(data) {
  return request({
    url: '/customer/register',
    method: 'post',
    data
  });
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  });
}

export function addUser(data) {
  return request({
    url: '/user/add',
    method: 'post',
    data
  });
}

export function checkCodeUnique(data) {
  return request({
    url: '/user/checkCodeUnique/' + data,
    method: 'get',
    data
  });
}

export function roleByOrg(data) {
  return request({
    url: '/role/roleByOrg?userType=' + data,
    method: 'get',
    data
  });
}

export function editUser(data) {
  return request({
    url: '/user/edit',
    method: 'post',
    data
  });
}

/**
 * 用户状态控制
 * @param data {Object} {type: '状态的类型 1:用户状态 2.账户状态',ids: '用户ID',value: '状态'}
 * @returns
 */
export function handlerUserState(data) {
  return request({
    url: '/user/stateHandler',
    method: 'post',
    data
  });
}

/* 校验用户参数 type  检查类型 1:账户名称  2:手机号*/
export function checkUserParameter(type, value) {
  return request({
    url: '/user/check',
    method: 'get',
    params: { type, value },
    isLoading: false
  });
}

// 获取用户列表
export function getUserPage(data, isBool = true) {
  return request({
    url: '/user/page',
    method: 'post',
    data,
    isLoading: isBool
  });
}

/* 获取用户完整信息*/
export function getUserInfoById(userId) {
  return request({
    url: '/user/findById/' + userId,
    method: 'get'
  });
}

// 个性化授权用户权限
export function authorizationUser(data) {
  return request({
    url: '/user/authorizationUser',
    method: 'post',
    data
  });
}

/**
 * 获取指派人的集合
 */
export const apiGetUserList = (data, isBool = true) => {
  // return request.post('/user/user/list', data);
  return request({
    url: '/user/list',
    method: 'post',
    data,
    isLoading: isBool
  });
};
export const getContractUseType = (data, isBool = true) => {
  return request({
    url: '/user/contractUseType/getUser',
    method: 'GET',
    data,
    isLoading: isBool
  });
};

/**
 * 修改密码
 */
export const apiResetPassword = (data) => {
  return request.post('/user/resetPassword', data);
};

/**
 * 获取用户中心附件信息
 */
export const apiGetUserAdditionalInformation = (data) => {
  return request({
    url: '/user/getUserAdditionalInformation',
    method: 'get',
    params: { ...data }
  });
};
/**
 * 获取用户登录工具信息
 */
export const getUserIpInformation = (data) => {
  return request.post('/loginLog/add', data);
};

/**
 * 职务分页列表
 */
export const apiGetPositionPage = (data) => {
  return request.post('/position/page', data);
};

/**
 * 职务列表
 */
export const apiGetPositionList = (data) => {
  return request.post('/position/list', data);
};

/**
 * 职务修改
 */
export const apiGetPositionEdit = (data) => {
  // eslint-disable-next-line no-undef
  return request.put('/position/edit/' + data.id, data);
};

/**
 * 职务添加
 */
export const apiGetPositionAdd = (data) => {
  return request.post('/position/add', data);
};

/**
 * 用户导出
 */
export const apiExportUser = (data) => {
  return request.post('/user/export', data);
};
// 州列表  城市列表
export const areaList = () => {
  return request.post('/area/listAll');
};

/**
 * @description 人员登记分页
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterPage = (data) => {
  return request.post('/user/userRegister/page', data);
};

/**
 * @description 人员登记详情
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterGetInfo = (data) => {
  return request.get(`/user/userRegister/getInfo?id=${data}`);
};

/**
 * @description 人员登记工作记录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterGetWorkRecord = (data) => {
  return request.get(`/user/userRegister/getWorkRecord?id=${data}`);
};

/**
 * @description 人员登记评价记录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterGetEvaluateRecord = (data) => {
  return request.post(`/user/userRegister/getEvaluateRecord?id=${data}`);
};

/**
 * @description 修改人员登记状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterUpdateStatus = (data) => {
  return request.get(`/user/userRegister/updateStatus?id=${data.ids}&state=${data.state}`);
};

/**
 * @description 新增人员登记评价记录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterAddEvaluateRecord = (data) => {
  return request.post(`/user/userRegister/addEvaluateRecord`, data);
};

/**
 * @description 人员登记导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterExportExcel = (data) => {
  return request.post(`/user/userRegister/export/excel`, data);
};

/**
 * @description 人员登记导入
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterBatchAdd = (data) => {
  return request.post(`/user/userRegister/batch/add`, data);
};

/**
 * @description 添加人员登记
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterAdd = (data) => {
  return request.post(`/user/userRegister/add`, data);
};

/**
 * @description 修改人员登记
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userUserRegisterEdit = (data) => {
  return request.post(`/user/userRegister/edit`, data);
};

// 获取操作人
export function getoprateUserPage(data, isBool = true) {
  return request({
    url: '/pmUser/queryPage',
    method: 'post',
    data,
    baseURL: baseUserURL,
    isLoading: isBool
  });
}

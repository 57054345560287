<template>
  <div class="Box">
    <!-- 为了初始加载时替代背景图展示，多加一层 -->
    <div v-if="firstTab" class="boxUrl containerData">
      <div class="topBox"><span>{{ $t('collectionCenter.dataVisual') }}</span></div>
      <div v-show="updateTime" class="updateTimeClass">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</div>
      <div class="dateBox">
        <span :class="{'d1': type == 4}" @click="getData(4)">{{ $t('collectionCenter.beforeYesterday') }}</span><!-- 前天 -->
        <span :class="{'d1': type == 0}" @click="getData(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
        <span :class="{'d1': type == 1}" @click="getData(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
        <span :class="{'d1': type == 2}" @click="getData(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
        <span :class="{'d1': type == 3}" @click="getData(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
      </div>
      <div class="blueLineClass">
        <img :src="transmitNode1" alt=""><img :src="arrow" alt="" class="arrowClass">
        <img :src="transmitNode2" alt=""><img :src="arrow" alt="" class="arrowClass">
        <img :src="transmitNode3" alt=""><img :src="arrow" alt="" class="arrowClass">
        <img :src="transmitNode4" alt=""><img :src="arrow" alt="" class="arrowClass">
        <img :src="transmitNode5" alt=""><img :src="arrow" alt="" class="arrowClass">
        <img :src="transmitNode6" alt="">
      </div>
      <div class="table-tab">
        <div class="tt-box">
          <!-- MD环节 -->
          <span class="ttbox-top">{{ $t('collectionCenter.MDLink') }}</span>
          <span style="cursor: default">
            <!-- MD揽收总量 -->
            {{ $t('collectionCenter.MDRangeTotal') }}：<span style="color: #99bce8;display: inline !important">{{ list1.value1 }}</span>
          </span>
          <span style="cursor: default">
            <!-- MD滞留总量 -->
            {{ $t('collectionCenter.MDRetention') }}：<span style="color: #99bce8;display: inline !important">{{ list1.value2 }}</span>
          </span>
          <!-- 各区域MD揽收情况分布: -->
          <div class="ttb-title">{{ $t('collectionCenter.eachRegionMD') }}:</div>
          <span v-for="(item, index) in tableData1" :key="index">
            <span
              style="cursor: pointer;opacity: 0.6"
              @click="routeClick(item.val1)"
            >{{ item.msg }}：<span style="color: #99bce8;display: inline !important">{{ item.sum }}</span></span>
          </span>
        </div>
        <div class="tt-box">
          <!-- MD至转运中心 -->
          <span class="ttbox-top">{{ $t('collectionCenter.MDToTransitCenter') }}</span>
          <span style="cursor: default">
            <!-- MD揽收总量 -->
            {{ $t('collectionCenter.MDRangeTotal') }}：<span style="color: #99bce8;display: inline !important">{{ list2.value1 }}</span>
          </span>
          <span style="cursor: default">
            <!-- 实际装车总量 -->
            {{ $t('collectionCenter.actualLoadingTotal') }}：<span style="color: #99bce8;display: inline !important">{{ list2.value2 }}</span>
          </span>
          <!-- MD发往各转运中心情况分布: -->
          <div class="ttb-title">{{ $t('collectionCenter.MDSendTo') }}:</div>
          <span v-for="(item, index) in tableData2" :key="index">
            <span
              :style="item.val1 ? 'cursor: pointer;opacity: 0.6;' : 'cursor: default;'"
              @click="detailClick(item.val1, item.val2, item.val3)"
            >{{ item.msg }}：<span style="color: #99bce8;display: inline !important">{{ item.sum }}</span></span>
          </span>
        </div>
        <div class="tt-box">
          <!-- 到达***转运中心 -->
          <span class="ttbox-top">{{ $t('collectionCenter.arrivexxx') }}</span>
          <span style="cursor: default">{{ $t('collectionCenter.loadTotal') }}：<span style="color: #99bce8;display: inline !important">{{ totalLoad }}</span></span><!-- 装车总量 -->
          <span style="cursor: default">{{ $t('collectionCenter.actualUnloadedTotal') }}：<span style="color: #99bce8;display: inline !important">{{ totalUnload }}</span></span><!-- 实际卸车总量 -->
          <span
            style="color: #8fcadb; text-decoration: underline;text-underline-offset: 2px;cursor: pointer"
            @click="transAnomaly(2, '', $t('collectionCenter.arrivexxx'))"
          >
            {{ $t('collectionCenter.transitCenterAnomaly') }}<!-- 转运中心异常 -->
          </span>
          <!-- 到达各转运中心情况分布: -->
          <div class="ttb-title">{{ $t('collectionCenter.arriveTransitCenters') }}:</div>
          <span v-for="(item, index) in tableData3" :key="index">
            <span
              :style="item.val1 ? 'cursor: pointer;opacity: 0.6;' : 'cursor: default;'"
              @click="detailClick(item.val1, item.val2, item.val3)"
            >{{ item.msg }}：<span style="color: #99bce8;display: inline !important">{{ item.sum }}</span></span>
          </span>
        </div>
        <div class="tt-box">
          <!-- ***转运中心发往GLP -->
          <span class="ttbox-top">{{ $t('collectionCenter.transferCenterToGLP') }}</span>

          <!-- 实际卸车总量 -->
          <span style="cursor: default">{{ $t('collectionCenter.actualUnloadedTotal') }}：<span style="color: #99bce8;display: inline !important">{{ list4.value1 }}</span></span>
          <!-- 实际装车总量 -->
          <span style="cursor: default">{{ $t('collectionCenter.actualLoadingTotal') }}：<span style="color: #99bce8;display: inline !important">{{ list4.value2 }}</span></span>

          <!-- 各区域发往GLP情况分布: -->
          <div class="ttb-title">{{ $t('collectionCenter.SendToGLP') }}:</div>
          <span v-for="(item, index) in tableData4" :key="index">
            <span
              :style="item.val1 ? 'cursor: pointer;opacity: 0.6;' : 'cursor: default;'"
              @click="detailClick(item.val1, item.val2, item.val3)"
            >{{ item.msg }}：<span style="color: #99bce8;display: inline !important">{{ item.sum }}</span></span>
          </span>
        </div>
        <div class="tt-box">
          <span class="ttbox-top">{{ $t('collectionCenter.GLPUnloadingProcess') }}</span><!-- GLP卸车环节 -->
          <span style="cursor: default">{{ $t('collectionCenter.shouldArrivedGLPTotal') }}：<span style="color: #99bce8;display: inline !important">{{ dataList.shouldArrivalGlpCount }}</span></span><!-- 应到达GLP总量 -->
          <span style="cursor: default">{{ $t('collectionCenter.actuallyArrivedGLPTotal') }}：<span style="color: #99bce8;display: inline !important">{{ dataList.actualArrivalGlpCount }}</span></span><!-- 实际到达GLP总量 -->
          <span
            style="cursor: pointer"
            @click="detailClick(4, 1, $t('collectionCenter.glpUnloadTotal'))"
          >
            <!-- GLP未卸车总量 -->
            {{ $t('collectionCenter.glpUnloadTotal') }}：<span style="color: #99bce8;display: inline !important">{{ dataList.glpNoLoadCount }}</span>
          </span>
        </div>
        <div class="tt-box">
          <!-- 与SHEIN交接 -->
          <span class="ttbox-top">{{ $t('collectionCenter.handoverToSHEIN') }}</span>
          <span style="cursor: default">
            {{ $t('collectionCenter.shouldSignedGrossAmount') }}：
            <span style="color: #99bce8;display: inline !important">{{ leftAll.shouldSignCount }}</span>
          </span>
          <span style="cursor: default">
            {{ $t('collectionCenter.actualSignGrossAmount') }}：
            <span style="color: #99bce8;display: inline !important">{{ leftAll.actualSign }}</span>
          </span>
          <span style="cursor: default">
            {{ $t('collectionCenter.unsignedGrossAmount') }}：
            <span style="color: #99bce8;display: inline !important">{{ leftAll.unSignCount }}</span>
          </span>
          <span style="cursor: default">
            {{ $t('collectionCenter.RateOfSigning') }}：
            <span style="color: #99bce8;display: inline !important">{{ leftAll.signRate }}%</span>
          </span>
          <span
            style="cursor: pointer"
            @click="toSecondaryLose($t('collectionCenter.SuspectedLostPackage'))"
          ><!-- 异常包裹总数 -->
            {{ $t('collectionCenter.abnormalParcelsTotal') }}：
            <span style="color: #99bce8;display: inline !important">{{ leftAll.suspectedLostCount }}</span>
          </span>
        </div>
      </div>
      <div class="tab-2">
        <el-row type="flex" justify="start" style="flex-wrap: wrap;">
          <el-col :span="9">
            <span><!-- 揽收准时率 -->
              {{ $t('collectionCenter.CollectionPunctualityRate') }}
            </span>
            <el-row style="margin-right: 38px;margin-left: 5px;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap">
              <el-col
                v-for="(item, index) in tableData5" :key="index" :span="8"
                style="margin-bottom: 10px;"
              >
                <div class="percentBox" @click="toSecondarySummary(item.val1, item.val2)">
                  <img :src="percentBG" alt="">
                  <span
                    :style="item.sum > 50 ? 'color: #fff' :
                      item.sum == 50 ? 'color: yellow' :
                      item.sum < 50 ? 'color: red' :
                      ''"
                  >
                    {{ item.sum }}%
                  </span>
                </div>
                <div style="text-align: center;margin-top: 5px;" @click="toSecondarySummary(item.val1, item.val2)">
                  <span
                    :style="item.sum > 50 ? 'color: #fff;cursor: pointer' :
                      item.sum == 50 ? 'color: yellow;cursor: pointer' :
                      item.sum < 50 ? 'color: red;cursor: pointer' :
                      ''"
                  >
                    {{ item.msg }}
                  </span>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9"><!-- 稽查环节 -->
            <span style="display: inline-block;margin-right: 10px;margin-left: 22px;">
              {{ $t('collectionCenter.auditLink') }}
            </span>
            <div style="margin-left: 32px;display: flex;justify-content: space-around;align-items: center;flex-wrap: wrap">
              <div class="bagTicket-box" @click="inspectClick(1)">
                <span style="white-space: nowrap;font-size: 14px">{{ $t('collectionCenter.actualCheckedBagsNum') }}</span><!-- 实际稽查袋数 -->
                <div class="bt-sum">{{ inspectList.actualAuditBagCount }}</div>
                <div class="bt-img">
                  <img :src="wavy" alt="" style="width: 100%">
                </div>
              </div>
              <div class="bagTicket-box" @click="inspectClick(2)">
                <span style="white-space: nowrap;font-size: 14px">{{ $t('collectionCenter.abnormalBagNum') }}</span><!-- 异常袋数 -->
                <div class="bt-sum" style="color: yellow">{{ inspectList.auditAbnormalBagCount }}</div>
                <div class="bt-img">
                  <img :src="wavy" alt="" style="width: 100%">
                </div>
              </div>
              <div class="bagTicket-box" @click="inspectClick(3)">
                <span style="white-space: nowrap;font-size: 14px">{{ $t('collectionCenter.actualAuditVotes') }}</span><!-- 实际稽查票数 -->
                <div class="bt-sum">{{ inspectList.actualAuditTicketCount }}</div>
                <div class="bt-img">
                  <img :src="wavy" alt="" style="width: 100%">
                </div>
              </div>
              <div class="bagTicket-box" @click="inspectClick(4)">
                <span style="white-space: nowrap;font-size: 14px">{{ $t('collectionCenter.abnormalTotalVotes') }}</span><!-- 异常总票数 -->
                <div class="bt-sum" style="color: yellow">{{ inspectList.auditAbnormalTicketCount }}</div>
                <div class="bt-img">
                  <img :src="wavy" alt="" style="width: 100%">
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="6" style="min-width: 242px">
            <span style="display: inline-block;margin-left: 22px;"><!-- 异常率 -->
              {{ $t('collectionCenter.anomalyRate') }}
            </span>
            <div class="abnormal-box">
              <img :src="abnormalBG" alt="">
              <span v-if="anomalyRate <= 0" class="circle-span">{{ anomalyRate }}</span>
              <el-progress
                v-else
                type="circle" :percentage="anomalyRate" :format="format"
                :width="188" text-color="yellow" color="#36A5ED"
                class="circle"
              />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else class="tabsBox">
      <div v-if="showPunctualityCountry" style="position:absolute;top: 0;left: 0;bottom: 0;right: 0;z-index: 1000;background:#fff;">
        <punctuality
          :key="showPunctualityCountry"
          :time-form="timeForm"
          :date-type="type"
          :tab-name="tabName"
          @closeWindow="closeWindow"
        />
      </div>
      <!--疑似丢失包裹-->
      <div v-if="showSuspectedLoss" style="position:absolute;top: 0;left: 0;bottom: 0;right: 0;z-index: 1000;background:#fff;">
        <suspected-loss
          :key="showSuspectedLoss"
          :time-form="timeForm"
          :date-type="type"
          :tab-name="tabName"
          @closeWindow="closeWindow"
        />
      </div>
      <div v-if="showDetailMsg">
        <div class="tabsClose">
          <el-button
            type="primary"
            plain
            size="small"
            @click="closeTabsClick"
          >
            {{ $t('collectionCenter.close') }}
          </el-button>
        </div>
        <detailMsg :form="queryFormList" />
      </div>
      <div v-if="showGlpNotUnloaded" style="position:absolute;top: 0;left: 0;bottom: 0;right: 0;z-index: 1000;background:#fff;">
        <glp-not-unloaded
          :key="showGlpNotUnloaded"
          :time-form="timeForm"
          :date-type="type"
          :tab-name="tabName"
          @closeWindow="closeWindow"
        />
      </div>
      <div v-if="transAnomalyShow">
        <div class="tabsClose">
          <el-button
            type="primary"
            plain
            size="small"
            @click="closeTaClick"
          >
            {{ $t('collectionCenter.close') }}
          </el-button>
        </div>
        <transAnomaly :ta-form="transAnomalyForm" :click-val="clickValue" />
      </div>
      <div v-if="inspectShow">
        <div class="tabsClose">
          <el-button
            type="primary"
            plain
            size="small"
            @click="closeTaClick"
          >
            {{ $t('collectionCenter.close') }}
          </el-button>
        </div>
        <inspect :in-form="inspectForm" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  dashboard
} from '@/api/lanshou';
import dayjs from 'dayjs';
import detailMsg from '@/views/collectionCenter/CollectKanban/components/yy/index';

export default {
  name: 'CollectOperationalData',
  components: {
    detailMsg,
    punctuality: () => import('./cmp/punctuality'),
    'suspected-loss': () => import('./cmp/suspectedLoss'),
    'glp-not-unloaded': () => import('./cmp/glpNotUnloaded'),
    transAnomaly: () => import('./cmp/transAnomaly'),
    inspect: () => import('./cmp/inspect')
  },
  data() {
    return {
      clickValue: [],
      inspectShow: false,
      inspectForm: {
        queryForm: '',
        updateTime: '',
        dateType: ''
      },
      transAnomalyShow: false,
      transAnomalyForm: {
        queryForm: '',
        tableName: '',
        updateTime: '',
        dateType: '' // 选择的时间
      },
      arrow: '/static/cod/arrow.png',
      transmitNode1: '/static/cod/transmitNode_1.png',
      transmitNode2: '/static/cod/transmitNode_2.png',
      transmitNode3: '/static/cod/transmitNode_3.png',
      transmitNode4: '/static/cod/transmitNode_4.png',
      transmitNode5: '/static/cod/transmitNode_5.png',
      transmitNode6: '/static/cod/transmitNode_6.png',
      abnormalBG: '/static/cod/abnormalBG.png', // 异常率图标
      wavy: '/static/cod/wavy.png', // 稽查环节波浪
      percentBG: '/static/cod/percentBG.png', // 揽收准时率图标
      ring: '/static/cod/ring.png',
      national: '/static/cod/NationalPunctualityRate.png', // 全国
      franca: '/static/cod/FrancaPunctualityRate.png', // Fr
      jau: '/static/cod/JauPunctualityRate.png', // Ja
      mg: '/static/cod/MGPunctualityRate.png', // MG
      rj: '/static/cod/RJPunctualityRate.png', // RJ
      sp: '/static/cod/SPPunctualityRate.png', // SP
      backgroundUrl: '/static/cod/background.png',
      leftAll: {
        shouldSignCount: 0,
        actualSign: 0,
        unSignCount: 0,
        signRate: 0,
        suspectedLostCount: 0
      },
      tableData1: [],
      list1: {
        value1: '',
        value2: ''
      },
      tableData2: [],
      list2: {
        value1: '',
        value2: ''
      },
      tableData3: [],
      tableData4: [],
      list4: {
        value1: '',
        value2: ''
      },
      tableData5: [],
      totalLoad: 0, // 装车总量
      totalUnload: 0, // 实际卸车总量
      dataList: {}, // GLP卸车环节
      inspectList: {}, // 稽查环节
      anomalyRate: 0, // 异常率
      type: 1,
      updateTime: '',
      time: [],
      firstTab: true,
      showDetailMsg: false,
      queryForm: {},
      queryFormList: {},
      showPunctualityCountry: false,
      showSuspectedLoss: false,
      showGlpNotUnloaded: false,
      timeForm: {
        startTime: '',
        endTime: '',
        mdStayType: '',
        timeType: '',
        updateTime: ''
      },
      tabName: ''
    };
  },
  created() {
    this.type = 1;
    this.firstTab = true;
    this.showSuspectedLoss = false;
    this.showPunctualityCountry = false;
    this.showGlpNotUnloaded = false;
    this.showDetailMsg = false;
    this.transAnomalyShow = false;
    this.inspectShow = false;
    this.getData(1);
  },
  activated() {
    this.type = 1;
    this.firstTab = true;
    this.showSuspectedLoss = false;
    this.showPunctualityCountry = false;
    this.showGlpNotUnloaded = false;
    this.showDetailMsg = false;
    this.transAnomalyShow = false;
    this.inspectShow = false;
    this.getData(1);
  },
  methods: {
    inspectClick(val) {
      this.firstTab = false;
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.transAnomalyShow = false;
      this.inspectShow = true;
      const queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.time[0],
          endTime: this.time[1],
          auditDetailType: val,
          latestBatchTime: this.updateTime
        }
      };
      this.inspectForm = {
        queryForm,
        updateTime: this.updateTime,
        dateType: this.type // 选择的时间
      };
    },
    closeTaClick() {
      this.firstTab = true;
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.transAnomalyShow = false;
      this.inspectShow = false;
    },
    transAnomaly(detailType, siteType, tableName) {
      this.firstTab = false;
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.inspectShow = false;
      this.transAnomalyShow = true;
      const queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.time[0],
          endTime: this.time[1],
          dashboardDetailType: detailType,
          transitType: siteType,
          latestBatchTime: this.updateTime
        }
      };
      this.transAnomalyForm = {
        queryForm,
        tableName,
        updateTime: this.updateTime,
        dateType: this.type // 选择的时间
      };
    },
    format(val) {
      return val + '%';
    },
    closeWindow() {
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.transAnomalyShow = false;
      this.inspectShow = false;
      this.firstTab = true;
    },
    // 进入二级页面
    toSecondarySummary(type, name) {
      this.firstTab = false;
      this.showPunctualityCountry = true;
      this.showSuspectedLoss = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.transAnomalyShow = false;
      this.inspectShow = false;
      this.timeForm = {
        startTime: this.time[0],
        endTime: this.time[1],
        mdStayType: type,
        timeType: this.type,
        updateTime: this.updateTime
      };
      this.tabName = name;
    },
    toSecondaryLose(name) {
      this.firstTab = false;
      this.showPunctualityCountry = false;
      this.showSuspectedLoss = true;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.timeForm = {
        startTime: this.time[0],
        endTime: this.time[1],
        mdStayType: '',
        timeType: this.type,
        updateTime: this.updateTime
      };
      this.tabName = name;
    },
    getData(type) {
      this.type = type;
      const d = new Date();
      const day4 = dayjs(d).subtract(2, 'day').format('YYYY-MM-DD'); // 前天
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD'); // 昨天
      const day1 = dayjs(d).format('YYYY-MM-DD'); // 当天
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD'); // 近一月
      // 获取当前周的开始日期（周一）
      dayjs.locale('en', {
        weekStart: 1
      });
      const day5 = dayjs(d).startOf('week').format('YYYY-MM-DD');
      let date = {};
      if (type === 4) { // 前天
        date = {
          startTime: day4,
          endTime: day4
        };
        this.time = [day4, day4];
      }
      if (type === 0) { // 昨天
        date = {
          startTime: day0,
          endTime: day0
        };
        this.time = [day0, day0];
      }
      if (type === 1) { // 今日
        date = {
          startTime: day1,
          endTime: day1
        };
        this.time = [day1, day1];
      }
      if (type === 2) { // 近一周
        date = {
          startTime: day5,
          endTime: day1
        };
        this.time = [day5, day1];
      }
      if (type === 3) { // 近一月
        date = {
          startTime: day3,
          endTime: day1
        };
        this.time = [day3, day1];
      }
      dashboard(date).then(res => {
        if (res.status === 'OK') {
          this.updateTime = res.data.createTime;

          // 与SHEIN交接
          this.leftAll = {
            shouldSignCount: res.data.shouldSignCount,
            actualSign: res.data.actualSign,
            unSignCount: res.data.unSignCount,
            signRate: res.data.signRate,
            suspectedLostCount: res.data.suspectedLostCount
          };

          // GLP卸车环节
          this.dataList = {
            shouldArrivalGlpCount: res.data.shouldArrivalGlpCount,
            actualArrivalGlpCount: res.data.actualArrivalGlpCount,
            glpNoLoadCount: res.data.glpNoLoadCount
          };

          // MD环节
          this.list1 = {
            value1: res.data.mdCollectCount, // MD揽收总量
            value2: res.data.mdStayCount // MD滞留总量
          };
          this.tableData1 = [
            { msg: 'SP-MD', sum: res.data.spMdStayCount, val1: 1 },
            { msg: 'Franca-MD', sum: res.data.francaMdStayCount, val1: 2 },
            { msg: 'Jau-MD', sum: res.data.jauMdStayCount, val1: 3 },
            { msg: 'RJ-MD', sum: res.data.rjMdStayCount, val1: 4 },
            { msg: 'MG-MD', sum: res.data.mgMdStayCount, val1: 5 }
          ];

          // MD至转运中心
          this.list2 = {
            value1: res.data.mdToHubCollectCount, // MD揽收总量
            value2: res.data.mdToHubActualLoadCount // 实际装车总量
          };
          this.tableData2 = [
            { msg: 'SP-Z-H005', sum: res.data.mdSpH5Count, val1: 1, val2: 1, val3: this.$t('collectionCenter.mdToSpzh005') },
            { msg: 'SP-Z-H006', sum: res.data.mdSpH6Count, val1: 1, val2: 2, val3: this.$t('collectionCenter.mdToSpzh006') },
            { msg: 'SP-Z-H002', sum: res.data.mdSpH2Count, val1: 1, val2: 3, val3: this.$t('collectionCenter.mdToSpzh002') },
            { msg: 'SP-Z-H004', sum: res.data.mdSpH4Count, val1: 1, val2: 4, val3: this.$t('collectionCenter.mdToSpzh004') },
            { msg: 'RJ-Z-H001', sum: res.data.mdRjH1count, val1: 1, val2: 6, val3: this.$t('collectionCenter.mdToRjwh001') },
            { msg: 'MG-Z-H001', sum: res.data.mdMgH1Count, val1: 1, val2: 5, val3: this.$t('collectionCenter.mdToMgzh001') }
          ];

          // 到达***转运中心
          this.clickValue = [ // 转运中心异常
            {
              'SP-Z-H005': res.data.arrSpH5AbnormalCount,
              'SP-Z-H006': res.data.arrSpH6AbnormalCount,
              'SP-Z-H002': res.data.arrSpH2AbnormalCount,
              'SP-Z-H004': res.data.arrSpH4AbnormalCount,
              'MG-Z-H001': res.data.arrMgH1AbnormalCount,
              'RJ-Z-H001': res.data.arrRjH1AbnormalCount
            }
          ];
          this.totalLoad = res.data.arrHubLoadCount; // 装车总量
          this.totalUnload = res.data.arrHubActualUnloadCount;
          this.tableData3 = [
            { msg: 'SP-Z-H005', sum: res.data.arrivalSpH5Count, val1: 2, val2: 1, val3: 'SP-Z-H005' },
            { msg: 'SP-Z-H006', sum: res.data.arrivalSpH6Count, val1: 2, val2: 2, val3: 'SP-Z-H006' },
            { msg: 'SP-Z-H002', sum: res.data.arrivalSpH2Count, val1: 2, val2: 3, val3: 'SP-Z-H002' },
            { msg: 'SP-Z-H004', sum: res.data.arrivalSpH4Count, val1: 2, val2: 4, val3: 'SP-Z-H004' },
            { msg: 'RJ-Z-H001', sum: res.data.arrivalRjH1Count, val1: 2, val2: 6, val3: 'RJ-Z-H001' },
            { msg: 'MG-Z-H001', sum: res.data.arrivalMgH1Count, val1: 2, val2: 5, val3: 'MG-Z-H001' }
          ];

          // ***转运中心发往GLP
          this.list4 = {
            value1: res.data.hubToGlpActualUnloadCount, // 实际卸车总量
            value2: res.data.hubToGlpActualLoadCount // 实际装车总量
          };
          this.tableData4 = [
            { msg: 'SP-Z-H005', sum: res.data.spH5ToGlp1, val1: 3, val2: 1, val3: this.$t('collectionCenter.spzh005ToWrglp01') },
            { msg: 'SP-Z-H006', sum: res.data.spH6ToGlp1, val1: 3, val2: 2, val3: this.$t('collectionCenter.spzh006ToWrglp01') },
            { msg: 'SP-Z-H002', sum: res.data.spH2ToGlp1, val1: 3, val2: 3, val3: this.$t('collectionCenter.spzh002ToWrglp01') },
            { msg: 'SP-Z-H004', sum: res.data.spH4ToGlp1, val1: 3, val2: 4, val3: this.$t('collectionCenter.spzh004ToWrglp01') },
            { msg: 'RJ-Z-H001', sum: res.data.rjH1ToGlp1, val1: 3, val2: 6, val3: this.$t('collectionCenter.rjwh001ToWrglp01') },
            { msg: 'MG-Z-H001', sum: res.data.mgH1ToGlp1, val1: 3, val2: 5, val3: this.$t('collectionCenter.mgzh001ToWrglp01') }
          ];

          // 揽收准时率
          this.tableData5 = [
            // 全国
            { sum: res.data.nationalPunctualRate, msg: this.$t('collectionCenter.wholeCountry'), val1: 0, val2: this.$t('collectionCenter.NationalPunctualityRate') },
            { sum: res.data.spPunctualRate, msg: 'SP', val1: 1, val2: this.$t('collectionCenter.SPPunctualityRate') },
            { sum: res.data.francaPunctualRate, msg: 'Franca', val1: 2, val2: this.$t('collectionCenter.FrancaPunctualityRate') },
            { sum: res.data.jauPunctualRate, msg: 'Jau', val1: 3, val2: this.$t('collectionCenter.JauPunctualityRate') },
            { sum: res.data.rjPunctualRate, msg: 'RJ', val1: 4, val2: this.$t('collectionCenter.RJPunctualityRate') },
            { sum: res.data.mgPunctualRate, msg: 'MG', val1: 5, val2: this.$t('collectionCenter.MGPunctualityRate') }
          ];

          // 稽查环节
          this.inspectList = {
            actualAuditBagCount: res.data.actualAuditBagCount,
            auditAbnormalBagCount: res.data.auditAbnormalBagCount,
            actualAuditTicketCount: res.data.actualAuditTicketCount,
            auditAbnormalTicketCount: res.data.auditAbnormalTicketCount
          };

          // 异常率
          this.anomalyRate = res.data.auditAbnormalRate;
        }
      });
    },
    routeClick(type) {
      if (!type) return;
      this.$router.push({
        path: '/collectionCenter/CollectKanban/mdHoldupTable',
        query: {
          t1: this.time[0],
          t2: this.time[1],
          mdStayType: type,
          type: this.type
        }
      });
    },
    closeTabsClick() {
      this.firstTab = true;
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      this.transAnomalyShow = false;
      this.inspectShow = false;
    },
    detailClick(detailType, siteType, tableName) {
      if (!detailType) return;
      // detailType  营运看板明细类型 1:MD至转运中心 2:到达转运中心 3:转运中心至WR-GLP-01
      // siteType  转运类型 1:SP-Z-H005 2:SP-Z-H006 3:SP-Z-H002 4:SP-Z-H004 5:MG-Z-H001 6:RJ-W-H001
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.time[0],
          endTime: this.time[1],
          dashboardDetailType: detailType,
          transitType: siteType
        }
      };
      this.queryFormList = {
        queryForm: this.queryForm,
        activeName: 'hz',
        tableName,
        updateTime: this.updateTime,
        dateType: this.type
      };
      this.timeForm = {
        startTime: this.time[0],
        endTime: this.time[1],
        mdStayType: '',
        timeType: this.type,
        updateTime: this.updateTime
      };
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.firstTab = false;
      this.showGlpNotUnloaded = false;
      this.showDetailMsg = false;
      if (detailType === 4) {
        this.showGlpNotUnloaded = true;
      } else {
        this.showDetailMsg = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.abnormal-box .circle ::v-deep .el-progress__text{
  font-size: 50px !important;
}
.abnormal-box ::v-deep .el-progress path:first-child{
  stroke: rgba(0, 0, 0, 0.1);
}
.abnormal-box{
  width: 200px;height: 200px;margin-top: 15px;margin-left: 75px;
  position: relative;
  img{
    width: 100%;height: 100%
  }
  .circle-span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px !important;
    color: yellow;
  }
  .circle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::before{
      content: "";
      position: absolute;
      top: 1px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-top: 30px solid #36A5ED;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }
}
.bagTicket-box:hover{
  border: 1px solid #74fafe;
  border-left: 3px solid #1C87B1;
  box-sizing: border-box;
}
.bagTicket-box{
  border-left: 3px solid #1C87B1;border-radius: 2px;padding-left: 10px;padding-top: 2px;box-sizing: border-box;
  height: 87px;
  width: 48%;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  position: relative;
  .bt-sum{
    font-size: 25px;
    font-weight: 100;
    letter-spacing: 1px;
  }
  .bt-img{
    width: 90%;
    position: absolute;
    bottom: 1px;
    left: 10px;
  }
}
.tab-2{
  border: 1px solid #2D4682;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 20px;
  margin-top: 0;
  .percentBox{
    width: 90px;
    height: 90px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    span{
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -38%);
      font-size: 20px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.table-tab{
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .tt-box{
    width: 185px;
    height: 305px;
    overflow-y: auto;
    background: url("/static/cod/tabBG.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 12px;
    padding-bottom: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .ttbox-top{ margin-bottom: 10px;font-size: 17px;cursor: default;line-height: 17px; }
    .ttb-title{ color: #FFE6B6;margin-top: 17px;margin-bottom: 5px;font-size: 14px; }
    span{
      display: block;
      word-wrap: break-word;
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 10px;
    }
  }
}
.blueLineClass{
  width: 100%;
  height: 35px;
  margin-top: 60px;
  margin-bottom: 27px;
  background: url("/static/cod/transmit.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 40px;
  box-sizing: border-box;
  img{
    width: 60px;
    margin-bottom: 8px;
  }
  img.arrowClass{
    width: 50px;
    margin-bottom: 10px;
  }
}
.grid-row {
  display: flex;
}

.left-column {
  height: 100%;
}
.tabsBox{
  min-height: calc(100vh - 85px);padding: 20px;box-sizing: border-box;background: #fff;
  position: relative;
  .tabsClose{
    z-index: 9;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
}
.containerData .updateTimeClass{
  position: absolute;
  right: 20px;
  top: 34px;
}
.containerData .skipClass{
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.containerData .skipClass:hover{
  border: 1px solid #00fcf9;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
}
.Box{
  background-color: #03043d;
  position: absolute;
  width: 100%;
  height: 100%;
}
.boxUrl{
  box-sizing: border-box;
  color: #fff;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  background: url("/static/cod/background.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  height: inherit;
}
.containerData .topBox{
  background: url("/static/cod/top.png") no-repeat;
  background-size: 100% 100%;
  width: 873px;
  height: 57px;
  margin: 0 auto;
  text-align: center;
  line-height: 38px;
  span{
    font-size: 23px;
    background-image: linear-gradient(0deg, #60c7fc, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}
.containerData .dateBox{
  position: absolute;
  left: 20px;
  top: 25px;
  span{display: inline-block;border: 1px solid #71AFFF;padding: 1px 15px;box-sizing: border-box;cursor: pointer;}
  span:hover, span.d1:hover{background: #71afff;}
  span.d1:hover{border: 1px solid #71AFFF;box-sizing: border-box;}
  span.d1{border: none;background: linear-gradient(90deg, #0161C0, transparent);padding: 2px 20px;box-sizing: border-box;}
}
.containerData .leftBox, .containerData .rightBox{
  padding: 20px;
  border: 1px solid rgba(113, 175, 255, 0.3);
  box-sizing: border-box;
  min-height: 50px;
  position: relative;
  .angle{width: 8px;height: 8px;position: absolute;}
  .angle.lt{top: -1px; left: -1px; border-left: 2px solid #206ED4; border-top: 2px solid #206ED4;}
  .angle.rt{top: -1px; right: -1px; border-right: 2px solid #206ED4; border-top: 2px solid #206ED4;}
  .angle.lb{bottom: -1px; left: -1px; border-left: 2px solid #206ED4; border-bottom: 2px solid #206ED4;}
  .angle.rb{bottom: -1px; right: -1px; border-right: 2px solid #206ED4; border-bottom: 2px solid #206ED4;}

  .totalBox{padding: 10px;padding-left: 15px;box-sizing: border-box;margin-top: 15px;min-width: 100%;}
  .totalBox.orange{border: 1px solid #CB7429;box-shadow: inset 0px 0px 7px 0px #CB7429;}
  .totalBox.blue{border: 1px solid #5DB1FF;box-shadow: inset 0px 0px 7px 0px #5DB1FF;}
  .totalBox.green{border: 1px solid #47E3FF;box-shadow: inset 0px 0px 7px 0px #47E3FF;}
  .s1{display: inline-block;font-size: 17px;font-weight: bold;letter-spacing: 1px;}
  .s2{font-size: 26px;font-weight: bold;letter-spacing: 3px;word-wrap: break-word}
  .org.s1{color: #CB7429}
  .bl.s1{color: #5DB1FF}
  .gr.s1{color: #47E3FF}
}
.containerData .leftBox {
  margin-right: 20px;
  .progressClass{
    margin-top: 20px;
    text-align: center;
    .progressBox{
      position: relative;
      display: inline-block;
      span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #00FCF9;
        font-size: 1.5vw;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }
}
.containerData .rightBox{flex: 1;min-width: 300px;}

.containerData .tableBox, .containerData .tableBox ::v-deep .el-table__cell, .containerData .tableBox ::v-deep tr{
  background: transparent !important;
}
.containerData .tableBox{
  margin-top: 5px;
  margin-bottom: 25px;
}
.containerData .table-cell{
  border: 1px solid #86C4FD;
  color: #00FCF9;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin-top: 7px;
}
.containerData .tableBox ::v-deep .el-table__row {line-height: 30px;}
.containerData .tableBox ::v-deep .el-table__empty-block{height: 30px !important;min-height: 30px !important;}
.containerData .tableBox ::v-deep .el-table__cell {padding: 0 !important;height: 35px !important;}

.containerData .tableBox.el-table::before, .tableBox.el-table::after{
  background-color: #86C4FD !important;
}
.containerData .tableBox ::v-deep .el-table__header th,
.containerData .tableBox ::v-deep .el-table__body td,
.containerData .tableBox ::v-deep el-table__body-wrapper,
.containerData .tableBox ::v-deep .el-table__empty-block,
.containerData ::v-deep .el-table--border
{
  color: #00FCF9 !important;
  border-color: #86C4FD !important;
  border-bottom-color: #86C4FD !important;
  border-right-color: #86C4FD !important;
}
.containerData .tableBox ::v-deep .el-table__empty-text{color: #00FCF9 !important;}

.containerData .tableBox ::v-deep .el-table__header th{
  color: #86C4FD !important;
}
</style>

<template>
  <div class="app-container">
    <el-card class="box-card mb5">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <!--报价方案名称-->
            <el-form-item :label="$t('basicData.TheNameOfTheQuoteProposal')" prop="name">
              <el-input v-model="queryForm.params.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="item in BUSINESS_TYPE_TWO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--使用状态-->
            <el-form-item :label="$t('basicData.UsageStatus')" prop="offerPriceStatus">
              <el-select
                v-model="queryForm.params.offerPriceStatus" clearable :placeholder="$t('basicData.pleaseChoose')"
                style="width: 100%;"
              >
                <!--全部-->
                <el-option :key="5" :label="$t('basicData.all')" :value="null" />
                <el-option
                  v-for="item in STATUS"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--应付主体-->
            <el-form-item :label="$t('basicData.PayablePrincipal')" prop="payableEntityId">
              <el-select
                v-model="queryForm.params.payableEntityId"
                filterable
                clearable
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                @change="providerInfoChange()"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--网点-->
            <el-form-item :label="$t('basicData.Outlets')" prop="siteId">
              <QuerySiteCmp
                :val="queryForm.params.siteId"
                :provider-id="queryForm.params.payableEntityId"
                @change="val => queryForm.params.siteId = val"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div style="margin-top: 10px;">
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="small"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="mb10">
        <!--添加-->
        <el-button
          v-permit:remove="'btn:payable:quoteManagement:addEdit'"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="handlerOperation(1)"
        >{{ $t('basicData.Add') }}
        </el-button>

        <!--导出-->
        <el-button
          v-permit:remove="'btn:payable:quoteManagement:addEdit'"
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="onExportData"
        >{{ $t('CenterForSorting.export') }}
        </el-button>
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border class="table-height"
        :height="tableHeightRow.height"
        @sort-change="tableSortChange"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--报价方案名称-->
        <el-table-column
          prop="name" :label="$t('basicData.TheNameOfTheQuoteProposal')" width="180"
          align="left" sortable="custom" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-popover placement="top-end" popper-class="copy" trigger="hover">
              <el-button style="color: black;font-size: 12px" type="text" @click="copy(scope.row.name)">
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="WaybillNumber" @click="handlerOperation(2, scope.row)">
                {{ scope.row.name }}
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <!--分区方案名称-->
        <el-table-column
          prop="partitionName" :label="$t('basicData.PartitionSchemeName')" align="left"
          width="180" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div class="WaybillNumber" @click="handlerOperation(3, scope.row)">
              {{ scope.row.partitionName }}
            </div>
          </template>
        </el-table-column>
        <!--应付主体-->
        <el-table-column
          prop="payableEntityName" :label="$t('basicData.PayablePrincipal')" align="center"
          sortable="custom" :show-overflow-tooltip="true" width="160"
        />
        <!--网点-->
        <el-table-column
          prop="siteName"
          :label="$t('basicData.Outlets')"
          align="center"
          :show-overflow-tooltip="true"
        />
        <!--业务类型-->
        <el-table-column
          prop="businessType"
          :label="$t('collectionCenter.businessT')"
          align="center" width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>
              {{ BUSINESS_TYPE_TWO[scope.row.businessType] && BUSINESS_TYPE_TWO[scope.row.businessType].label || '' }}
            </span>
          </template>
        </el-table-column>
        <!--使用状态-->
        <el-table-column
          prop="offerPriceStatus"
          :label="$t('basicData.UsageStatus')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag :type="STATUS[scope.row.offerPriceStatus] && STATUS[scope.row.offerPriceStatus].type">{{ STATUS[scope.row.offerPriceStatus] && STATUS[scope.row.offerPriceStatus].label || '' }}</el-tag>
          </template>
        </el-table-column>
        <!--有效期开始-->
        <el-table-column
          prop="startOfValidityPeriod"
          :label="$t('basicData.TheValidityPeriodBegins')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.startOfValidityPeriod.replace('T', ' ') }}
            </div>
          </template>
        </el-table-column>
        <!--最后修改人-->
        <el-table-column
          prop="updateUserStr"
          :label="$t('basicData.LastModifier')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--最后修改时间-->
        <el-table-column
          prop="updateTime"
          :label="$t('basicData.LastModified')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          :label="$t('basicData.operate')" fixed="right" align="center"
          width="120"
        >
          <template slot-scope="scope">
            <!--确定禁用吗？-->
            <el-popconfirm
              v-if="scope.row.applyType === true"
              v-permit:remove="'btn:payable:quoteManagement:addEdit'"
              :title="$t('basicData.AreYouSureDisabled')"
              @confirm="asyncPartitionEdit(scope.row, 0)"
            >
              <el-button
                slot="reference" class="ml" type="danger"
                size="mini"
              >{{ $t('basicData.disable') }}</el-button>
            </el-popconfirm>
            <!--确定启用吗？-->
            <el-popconfirm
              v-if="scope.row.applyType === false"
              v-permit:remove="'btn:payable:quoteManagement:addEdit'"
              :title="$t('basicData.AreYouSureEnabled')"
              @confirm="asyncPartitionEdit(scope.row, 1)"
            >
              <el-button
                slot="reference" class="ml" type="success"
                size="mini"
              >{{ $t('basicData.enable') }}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentData"
          @size-change="getSizeData"
        />
      </div>
    </el-card>
    <!-- 添加、详情 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      :with-header="false"
      direction="ltr"
      size="90%"
    >
      <detail-log
        :key="editKey"
        :edit-data="drawerObj.editData"
        :operation-type="drawerObj.typeIndex"
        @saveChange="updatePage"
      />
    </el-drawer>
    <!--分区详情-->
    <el-drawer
      :visible.sync="showPartitionDetail"
      :with-header="false"
      direction="ltr"
      size="80%"
    >
      <details-cmp
        :key="editKeyPartition"
        :type-index="2"
        :edit-data="drawerObj.editData"
        :all-state="allState"
        :all-city="allCity"
        style="height: 100%;"
        @updatePage="updatePage"
      />
    </el-drawer>
  </div>
</template>

<script>
import {
  apiPayableOfferPriceQueryList,
  apiProviderPage,
  apiPayableOfferPriceSwitch, apiAreaPage,
  apiExportOfferPriceInfo
} from '@/api/finance/basicData';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { copyData } from '@/utils/copyData';
// import { getDistributionDotList } from '@/api/logisticsOrders';
import { cloneDeep } from 'lodash-es';
import { goResourceTaskList } from '@/utils/goExportList';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { BUSINESS_TYPE_TWO, STATUS } from '@/views/finance/utils.js';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp';

export default {
  name: 'QuotationManagement',
  components: {
    'detail-log': () => import('./cmp/detailLog'),
    'details-cmp': () => import('./cmp/partitionDetails'),
    QuerySiteCmp
  },
  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        orderDirection: 'DESC',
        needToSort: true,
        orderProperty: 'start_of_validity_period',
        params: {
          name: '', // 报价名称
          payableEntityId: '', // 应付主体id
          businessType: null, // 业务类型
          siteId: '', // 网点id
          offerPriceStatus: null // 报价状态 已过期:0 已使用:1 未启用:2
        }
      },
      tableData: [],
      total: 0,
      drawerObj: {
        visible: false,
        typeIndex: 1,
        editData: {}
      },
      editKey: new Date().getTime(),
      quoteTypeList: [
        { name: this.$t('basicData.StandardPrice'), id: 1 }, // '标准价'
        { name: this.$t('basicData.BusinessEntityPrice'), id: 2 }, // '应收主体价'
        { name: this.$t('basicData.SitePrice'), id: 3 } // '网点价'
      ], // 报价类型
      userIdAndNameObj: null,
      providerList: [], // 应收主体选项
      showPartitionDetail: false,
      editKeyPartition: new Date().getTime(),
      allState: [],
      allCity: [],
      STATUS: STATUS // 报价状态
    };
  },
  created() {
    this.BUSINESS_TYPE_TWO = BUSINESS_TYPE_TWO; // 业务类型

    this.userIdAndNameObj = getUserIdAndNameObj();
    // 获取列表数据
    this.getData();
    // 获取应付主体选项
    this.getProviderPage();
    // 获取州市
    this.getAllState({ level: 1, parentId: '' });
    this.getAllState({ level: 2, parentId: '' });
  },
  methods: {
    // 获取所有的州
    getAllState(row) {
      const param = {
        pageNumber: 1,
        pageSize: 99999,
        params: {
          level: row.level,
          parentId: row.parentId
        }
      };
      apiAreaPage(param).then(res => {
        if (res.code === 200) {
          if (row.level === 1) {
            this.allState = res.data.records;
          } else {
            this.allCity = res.data.records;
          }
        }
      });
    },
    // 翻页
    getCurrentData(val) {
      this.queryForm.pageNumber = val;
      this.getData();
    },
    // 每页显示数量切换
    getSizeData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getData();
    },
    // 切换应付主体重置网点id
    providerInfoChange() {
      this.queryForm.params.siteId = '';
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 获取应付主体选项
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.orderDirection = 'DESC';
      this.queryForm.orderProperty = 'start_of_validity_period';
      this.$refs.elTable.clearSort();
      this.queryForm.params = {
        name: '', // 报价名称
        payableEntityId: '', // 主体id
        businessType: null, // 业务类型
        siteId: '', // 网点id
        offerPriceStatus: null // 报价状态 已过期:0 使用中:1 未启用:2 禁用:3
      };

      this.getData();
    },
    /**
     * 操作按钮
     * @param type  1 新增 2 查看详情 3 查看分区
     * @param row
     */
    handlerOperation(type, row) {
      this.drawerObj.editData = row || {};

      if (type === 3) {
        this.showPartitionDetail = true;
        this.editKeyPartition = new Date().getTime();
        return;
      }

      this.drawerObj.typeIndex = type;
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },

    // 导出数据
    onExportData() {
      const query = this.setQuery();
      apiExportOfferPriceInfo(query.params).then(res => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      });
    },

    // 请求数据格式调整
    setQuery() {
      const queryForm = cloneDeep(this.queryForm);

      queryForm.params.name = queryForm.params.name ? queryForm.params.name.trim() : '';
      return queryForm;
    },

    // 获取列表数据
    getData() {
      const query = this.setQuery();
      apiPayableOfferPriceQueryList(query).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
          this.total = res.data.total;
        }
      });
    },
    // 启用、禁用
    asyncPartitionEdit(row, type) {
      const param = {
        id: row.id,
        sw: type
      };
      apiPayableOfferPriceSwitch(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('basicData.TheOperationSucceeded')); // 操作成功
          this.getDataSearch();
        }
      });
    },
    // 删除
    deleteAccount(row) {},
    // 数据更新
    updatePage(type) {
      if (type) {
        this.getDataSearch();
      }
      this.drawerObj.visible = false;
      this.showPartitionDetail = false;
    },
    /**
     * @description 表格排序
     */
    tableSortChange({ column, prop, order }) {
      if (order === 'ascending') {
        this.queryForm.orderProperty = prop;
        this.queryForm.orderDirection = 'ASC';
      } else if (order === 'descending') {
        this.queryForm.orderProperty = prop;
        this.queryForm.orderDirection = 'DESC';
      }
      if (prop === 'payableEntityName') {
        this.queryForm.orderProperty = 'provider_name';
      }
      if (!order) {
        this.queryForm.orderProperty = 'start_of_validity_period';
        this.queryForm.orderDirection = 'DESC';
      }
      this.getData();
    }
  }
};
</script>

<style scoped>
.WaybillNumber {
  color: #409eff;
  cursor: pointer;
}
.queryFormClass .el-form-item--mini.el-form-item,
.queryFormClass .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
</style>

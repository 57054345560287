<template>
  <div class="Box">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row
          class="selectClass" justify="start" style="flex-wrap: wrap;"
          type="flex"
        >
          <el-col class="col2">
            <el-select
              v-model="selectTime"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              class="autoWidth"
              @change="changeTime"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === selectTime) || {}).time }}
              </template>
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :label="item.time"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-form-item class="delMl">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                :end-placeholder="$t('collectionCenter.EndTime')"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                type="datetimerange"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getAllCustomer"
              >
                <el-option
                  v-for="(item, index) in customerNameList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!--所属平台 -->
            <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
              <el-select
                v-model="queryForm.params.platform"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
              >
                <el-option :value="1" label="shein" /><!-- shein -->
                <el-option :value="2" label="kwai" /><!-- kwai -->
                <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
                <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:report:deliveryData:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:deliveryData:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:deliveryData:export'"
            icon="el-icon-upload2"
            size="mini"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
        </el-col>
      </el-row>
    </div>

    <el-divider />
    <el-tabs v-model="active" type="border-card">
      <el-tab-pane :label="$t('collectionCenter.summary')" name="gather">
        <el-table
          ref="hzTable"
          :max-height="600"
          border
          :data="tableDateHZ"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            :label="$t('collectionCenter.ind')" type="index" width="60"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="customerId"
            :label="$t('collectionCenter.clientID')"
          /><!-- 客户编号 -->
          <el-table-column
            align="center"
            prop="customerName"
            :label="$t('collectionCenter.CustomerName')"
          /><!-- 客户名称 -->
          <el-table-column
            align="center"
            prop="platformName"
            :label="$t('collectionCenter.platform')"
          /><!-- 所属平台 -->
          <el-table-column
            align="center"
            prop="scanTime"
            :label="$t('collectionCenter.lanshouT')"
          /><!-- 揽收时间 -->
          <el-table-column
            align="center"
            prop="signerTime"
            :label="$t('collectionCenter.signT')"
          /><!-- 签收时间 -->
          <el-table-column
            align="center"
            prop="closeTime"
            :label="$t('collectionCenter.closeTime')"
          /><!-- 闭环时间 -->
          <el-table-column
            align="center"
            prop="collectNumberCount"
            :label="$t('collectionCenter.receivedParcelsTotal')"
          ><!-- 揽收包裹总数 -->
            <template slot-scope="scope">
              <el-button size="mini" @click.stop="detailClick(scope.row, 1)">{{ scope.row.collectNumberCount }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="closeNumberCount"
            :label="$t('collectionCenter.closedTotalParcels')"
          ><!-- 闭环包裹总数 -->
            <template slot-scope="scope">
              <el-button size="mini" @click.stop="detailClick(scope.row, 2)">{{ scope.row.closeNumberCount }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="unloadTimeCount"
            :label="$t('collectionCenter.unloadedParcelsTotalNum')"
          ><!-- 卸车包裹总数 -->
            <template slot-scope="scope">
              <el-button size="mini" @click.stop="detailClick(scope.row, 3)">{{ scope.row.unloadTimeCount }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="signerNumberCount"
            :label="$t('collectionCenter.signTotalParcels')"
          ><!-- 签收包裹总数 -->
            <template slot-scope="scope">
              <el-button size="mini" @click.stop="detailClick(scope.row, 4)">{{ scope.row.signerNumberCount }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="signerUnloadDiscrepancyCount"
            :label="$t('collectionCenter.signedUnloadedDiffPackages')"
          ><!-- 签收与卸车差异包裹总数 -->
            <template slot-scope="scope">
              <el-button size="mini" @click.stop="detailClick(scope.row, 7)">{{ scope.row.signerUnloadDiscrepancyCount }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="closeSignerDiscrepancyCount"
            :label="$t('collectionCenter.closedSignedDiffPackages')"
          ><!-- 闭环与签收差异包裹总数 -->
            <template slot-scope="scope">
              <el-button size="mini" @click.stop="detailClick(scope.row, 8)">{{ scope.row.closeSignerDiscrepancyCount }}</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('collectionCenter.warehouseDeliveryAbnormalData')"><!-- 送仓异常数据 -->
            <el-table-column
              align="center"
              prop="suspectedLostPackages"
              :label="$t('collectionCenter.suspectedMissingItem')"
            ><!-- 疑似丢件 -->
              <template slot-scope="scope">
                <el-button size="mini" @click.stop="detailClick(scope.row, 5)">{{ scope.row.suspectedLostPackages }}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="suspectedMissScanPackages"
              :label="$t('collectionCenter.suspectedMissedScan')"
            ><!-- 疑似漏扫 -->
              <template slot-scope="scope">
                <el-button size="mini" @click.stop="detailClick(scope.row, 6)">{{ scope.row.suspectedMissScanPackages }}</el-button>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getTablePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="active == 'detail'" :label="$t('collectionCenter.particulars')" name="detail">
        <el-table
          ref="hzTable"
          :max-height="600"
          border
          :data="tableDateMX"
        >
          <el-table-column
            :label="$t('collectionCenter.ind')" type="index" width="60"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ (queryFormMX.pageNumber - 1) * queryFormMX.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="orderNumber"
            :label="$t('collectionCenter.orderNumber')"
          /><!-- 订单号 -->
          <el-table-column
            align="center"
            prop="collectTime"
            :label="$t('collectionCenter.lanshouT')"
          /><!-- 揽收时间 -->
          <el-table-column
            align="center"
            prop="collectName"
            :label="$t('collectionCenter.LanShouPeople')"
          /><!-- 揽收人 -->
          <el-table-column
            align="center"
            prop="collectSiteName"
            :label="$t('collectionCenter.LanShouSite')"
          /><!-- 揽收网点 -->
          <el-table-column
            align="center"
            prop="unloadTime"
            :label="$t('collectionCenter.unloadTime')"
          /><!-- 卸车时间 -->
          <el-table-column
            align="center"
            prop="unloadUserName"
            :label="$t('collectionCenter.unloader')"
          /><!-- 卸车人 -->
          <el-table-column
            align="center"
            prop="unloadSiteName"
            :label="$t('collectionCenter.unloadDot')"
          /><!-- 卸车网点 -->
          <el-table-column
            align="center"
            prop="signerTime"
            :label="$t('collectionCenter.signT')"
          /><!-- 签收时间 -->
          <el-table-column
            align="center"
            prop="closeTime"
            :label="$t('collectionCenter.closeTime')"
          /><!-- 闭环时间 -->
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getTablePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  queryAllCustomer,
  collectMonitoringPage,
  collectMonitoringDetail,
  collectMonitoringExportAll,
  collectMonitoringDetailExportAll
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'DeliveryData',
  data() {
    return {
      active: 'gather',
      timeValue: [],
      selectTime: 't0',
      timeList: [
        { value: 't0', time: this.$t('collectionCenter.lanshouT') }, // '揽收时间'
        { value: 't1', time: this.$t('collectionCenter.signT') }, // '签收时间'
        { value: 't2', time: this.$t('collectionCenter.closeTime') } // '闭环时间'
      ],
      customerNameList: [], // 客户列表
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          type: 2, // 报表类型,1:揽收kpi监控报表,2:揽收送仓监控(揽收时间),3:揽收送仓监控(签收时间),4:揽收送仓监控(闭环时间)
          dateType: 2, // 日期类型, 1:创建时间 2:揽收时间 3:封袋时间 4:签收时间 5:闭环时间
          customerId: '',
          platform: ''
        }
      },
      total: 0,
      tableDateHZ: [],
      queryFormMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          queryTime: '',
          customerId: '',
          platform: '',
          queryType: '',
          startTime: '',
          endTime: '',
          reportType: 1
        }
      },
      totalMX: 0,
      tableDateMX: []
    };
  },
  created() {
    this.getAllCustomer();
    this.active = 'gather';
    this.default();
    this.searchClick();
  },
  methods: {
    changeTime() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.startTime = today + ' 00:00:00';
      this.queryForm.params.endTime = today + ' 23:59:59';
      this.queryFormMX.params.reportType = this.selectTime === 't0' ? 1 : this.selectTime === 't1' ? 2 : this.selectTime === 't2' ? 3 : 1;
      // 日期类型, 1:创建时间 2:揽收时间 3:封袋时间 4:签收时间 5:闭环时间
      // 报表类型,1:揽收kpi监控报表,2:揽收送仓监控(揽收时间),3:揽收送仓监控(签收时间),4:揽收送仓监控(闭环时间)
      if (this.selectTime === 't0') {
        this.queryForm.params.type = 2;
        this.queryForm.params.dateType = 2;
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.type = 2;
        this.queryForm.params.dateType = 4;
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.type = 2;
        this.queryForm.params.dateType = 5;
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-14, 'day').format('YYYY-MM-DD 23:59:59');
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到15天的数据
            message: this.$t('collectionCenter.max15DaysDate'),
            type: 'warning'
          });
          this.changeTime();
          return;
        }
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];
    },
    getAllCustomer() {
      // 查询所有客户信息
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerNameList = [];
          res.data.forEach((list) => {
            this.customerNameList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    default() {
      const d = new Date();
      const day = dayjs(d).format('YYYY-MM-DD 00:00:00');
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      this.selectTime = 't0';
      this.timeValue = [day, today];
      this.queryForm.params = {
        startTime: day,
        endTime: today,
        type: 2, // 报表类型,1:揽收kpi监控报表,2:揽收送仓监控(揽收时间),3:揽收送仓监控(签收时间),4:揽收送仓监控(闭环时间)
        dateType: 2, // 日期类型, 1:创建时间 2:揽收时间 3:封袋时间 4:签收时间 5:闭环时间
        customerId: '',
        platform: ''
      };
    },
    resetClick() {
      this.default();
    },
    getTablePage() {
      if (this.active === 'gather') {
        const queryForm = JSON.parse(localStorage.getItem('DeliveryDataForm')) || this.queryForm;
        queryForm.pageNumber = this.queryForm.pageNumber;
        queryForm.pageSize = this.queryForm.pageSize;
        collectMonitoringPage(queryForm).then(res => {
          if (res.status === 'OK') {
            this.total = res.data.total;
            this.tableDateHZ = res.data.records;
          }
        }).catch({});
      }
      if (this.active === 'detail') {
        collectMonitoringDetail(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.totalMX = res.data.total;
            this.tableDateMX = res.data.records;
          }
        }).catch({});
      }
    },
    getPageSize() {
      if (this.active === 'gather') {
        this.queryForm.pageNumber = 1;
        const query = JSON.parse(localStorage.getItem('DeliveryDataForm')) || this.queryForm;
        const queryForm = {
          pageNumber: 1,
          pageSize: this.queryForm.pageSize,
          params: query.params
        };
        collectMonitoringPage(queryForm).then(res => {
          if (res.status === 'OK') {
            this.total = res.data.total;
            this.tableDateHZ = res.data.records;
          }
        }).catch({});
      }
      if (this.active === 'detail') {
        this.queryFormMX.pageNumber = 1;
        collectMonitoringDetail(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.totalMX = res.data.total;
            this.tableDateMX = res.data.records;
          }
        }).catch({});
      }
    },
    searchClick() {
      if (this.active === 'gather') {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('DeliveryDataForm', JSON.stringify(this.queryForm));
        this.getTablePage();
      }
    },
    download() {
      if (this.active === 'gather') {
        const query = JSON.parse(localStorage.getItem('DeliveryDataForm')) || this.queryForm;
        collectMonitoringExportAll(query.params).then(res => {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }).catch(err => {
          console.log(err);
        });
      }
      if (this.active === 'detail') {
        collectMonitoringDetailExportAll(this.queryFormMX.params).then(res => {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }).catch(err => {
          console.log(err);
        });
      }
    },
    // 跳转明细
    detailClick(row, idx) {
      this.active = 'detail';
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          queryTime: row.scanTime,
          customerId: row.customerId,
          platform: row.platform,
          queryType: idx, // 查询类型（ 1揽收总包裹数 2.闭环包裹总数 3.卸车总包裹数 4.签收包裹总数  5.疑似丢件 6.疑似漏扫 7.签收与卸车差异包裹总数 8.闭环与卸车差异包裹总数）
          startTime: this.timeValue[0],
          endTime: this.timeValue[1],
          reportType: this.queryFormMX.params.reportType,
          signerNumberCount: row.signerNumberCount,
          closeNumberCount: row.closeNumberCount
        }
      };
      if (idx !== 8) {
        delete this.queryFormMX.params.signerNumberCount;
        delete this.queryFormMX.params.closeNumberCount;
      }
      collectMonitoringDetail(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch({});
    },
    // 表格列汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.summary');
          return;
        }
        if (column.label === this.$t('collectionCenter.receivedParcelsTotal') ||
          (column.label === this.$t('collectionCenter.closedTotalParcels')) ||
          (column.label === this.$t('collectionCenter.unloadedParcelsTotalNum')) ||
          (column.label === this.$t('collectionCenter.unloadTotalParcels')) ||
          (column.label === this.$t('collectionCenter.signTotalParcels')) ||
          (column.label === this.$t('collectionCenter.suspectedMissingItem')) ||
          (column.label === this.$t('collectionCenter.suspectedMissedScan'))
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .col2 {
    width: auto !important;
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .autoWidth ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
  .selectClass ::v-deep .el-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label {
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-form-item__content, .selectClass ::v-deep .el-select, .selectClass ::v-deep .el-date-editor {
    width: 100%;
  }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boxInfo" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "100%" },
          attrs: { type: "border-card", value: "first" },
          on: { "tab-click": _vm.handleTabClick },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label:
                  _vm.typeIndex === 1
                    ? _vm.$t("basicData.AddPartition")
                    : _vm.$t("basicData.PartitionDetails"),
                name: "first",
              },
            },
            [
              _c(
                "div",
                { staticClass: "addFormBox" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticClass: "queryFormClass",
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.rules,
                        size: "small",
                        "label-width":
                          _vm.$i18n.locale === "zh" ? "120px" : "auto",
                        "label-position":
                          _vm.$i18n.locale !== "zh" ? "top" : "right",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "basicData.PartitionSchemeName"
                                    ),
                                    prop: "name",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "orderDetails.pleaseInput"
                                      ),
                                      clearable: "",
                                      maxlength: "20",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.formData.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("basicData.RemarkContent"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "orderDetails.pleaseInput"
                                      ),
                                      type: "textarea",
                                      rows: 3,
                                    },
                                    model: {
                                      value: _vm.formData.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "remark", $$v)
                                      },
                                      expression: "formData.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.businessT"),
                                    prop: "businessType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                      },
                                      model: {
                                        value: _vm.formData.businessType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "businessType",
                                            $$v
                                          )
                                        },
                                        expression: "formData.businessType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "0",
                                        attrs: {
                                          label: _vm.$t(
                                            "collectionCenter.expressDelivery"
                                          ),
                                          value: 0,
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "1",
                                        attrs: {
                                          label: _vm.$t(
                                            "collectionCenter.lanshou"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "2",
                                        attrs: {
                                          label: _vm.$t(
                                            "collectionCenter.delivery"
                                          ),
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.$t("orderDetails.desRem") +
                                      _vm.$t("GlobalSetObj.Estado"),
                                  },
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                    },
                                    model: {
                                      value: _vm.formData.stateOfOrigin,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "stateOfOrigin",
                                          $$v
                                        )
                                      },
                                      expression: "formData.stateOfOrigin",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb10" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v(_vm._s(_vm.$t("basicData.AddPartition")) + " ")]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-start",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("import-download", {
                                attrs: {
                                  "table-data": _vm.formData,
                                  "state-list": _vm.stateList,
                                },
                                on: { importPartition: _vm.importPartition },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                slot: "reference",
                                size: "small",
                                plain: "",
                              },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("basicData.ImportDownload")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "tableForm",
                          staticClass: "error-form",
                          attrs: {
                            model: _vm.formData,
                            "label-width": "0px",
                            rules: _vm.rules,
                            size: "small",
                          },
                        },
                        [
                          _c(
                            "virtual-scroll",
                            {
                              key: _vm.key,
                              ref: "virtualScroll",
                              attrs: {
                                data: _vm.formData.partitionAffiliateList,
                                "item-size": 62,
                                "key-prop": "key",
                              },
                              on: {
                                change: function (virtualList) {
                                  return (_vm.list = virtualList)
                                },
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: _vm.list,
                                    "row-key": "key",
                                    border: "",
                                  },
                                },
                                [
                                  _c("virtual-column", {
                                    attrs: { width: "50", type: "index" },
                                  }),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "basicData.PartitionName"
                                        ),
                                        width: "160",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "partitionAffiliateList." +
                                                          scope.$index +
                                                          ".partitionIdentifier",
                                                        rules:
                                                          _vm.rules
                                                            .partitionIdentifier,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          maxlength: "50",
                                                          "show-word-limit": "",
                                                          placeholder: _vm.$t(
                                                            "basicData.PartitionName"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .partitionIdentifier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "partitionIdentifier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.partitionIdentifier",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("template", { slot: "header" }, [
                                        _c("div", [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "basicData.PartitionName"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c("el-table-column", {
                                    attrs: {
                                      label:
                                        _vm.$t("orderDetails.desRem") +
                                        _vm.$t("GlobalSetObj.Estado"),
                                      width: "220",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "partitionAffiliateList." +
                                                        scope.$index +
                                                        ".state",
                                                      rules: _vm.rules.state,
                                                    },
                                                  },
                                                  [
                                                    _c("StateSelectCmp", {
                                                      attrs: {
                                                        disabled:
                                                          _vm.formData
                                                            .stateOfOrigin ===
                                                          0,
                                                        list: _vm.stateList,
                                                        value: scope.row.state,
                                                      },
                                                      on: {
                                                        input: function (val) {
                                                          return (scope.row.state =
                                                            val)
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c(
                                    "el-table-column",
                                    {
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "partitionAffiliateList." +
                                                          scope.$index +
                                                          ".postalCode",
                                                        rules:
                                                          _vm.rules.postalCode,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "basicData.ZipCodeExample"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .postalCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "postalCode",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.postalCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("template", { slot: "header" }, [
                                        _c("div", [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("orderDetails.desRem") +
                                                  _vm.$t("basicData.zipCode")
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("basicData.operate"),
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-popconfirm",
                                              {
                                                attrs: {
                                                  title: _vm.$t(
                                                    "basicData.AreYouSureYouWantToDelete"
                                                  ),
                                                },
                                                on: {
                                                  confirm: function ($event) {
                                                    return _vm.handleDel(scope)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      slot: "reference",
                                                      type: "danger",
                                                      size: "mini",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "basicData.Delete"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "addFooter" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function () {
                          return _vm.handleUpdatePage(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "but:finance:basicData:partition:edit",
                          expression: "'but:finance:basicData:partition:edit'",
                          arg: "remove",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.typeIndex !== 1
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("basicData.OperationalLogs"),
                    name: "second",
                  },
                },
                [
                  _vm.isSecondActive
                    ? _c("operation-log", {
                        attrs: { "edit-data": _vm.editData },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("basicData.tips"),
            visible: _vm.dialogVisible,
            "append-to-body": true,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.errData, function (item, ind) {
              return _c(
                "div",
                {
                  key: ind,
                  staticStyle: { color: "red", "margin-bottom": "10px" },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
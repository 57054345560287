<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--创建时间-->
          <el-form-item :label="$t('GlobalSetObj.operationTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <!--  操作人州 -->
        <el-col :span="4">
          <el-form-item :label="$t('GlobalSetObj.OperationState')">
            <el-select
              v-model="queryForm.params.operationalUserState"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
              @change="getOperationState(1, queryForm.params.operationalUserState)"
            >
              <el-option
                v-for="item in StateList"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   操作人网点   -->
          <el-form-item :label="$t('GlobalSetObj.OperationSite')">
            <el-select
              v-model="queryForm.params.operationalUserSiteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
              @change="getOperationState(2, queryForm.params.operationalUserSiteId)"
            >
              <el-option
                v-for="item in opearateSitelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  操作人   -->
          <el-form-item :label="$t('GlobalSetObj.operator')">
            <el-select
              v-model.trim="queryForm.params.operateUserName"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="getOperationName"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in userList"
                :key="index"
                :label="item.operateUserName"
                :value="item.keyId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item :label="$t('collectionCenter.operationType')">
            <el-select
              v-model="queryForm.params.operationType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" value="" />
              <el-option :label="$t('GlobalSetObj.arrive')" :value="2" />
              <el-option :label="$t('GlobalSetObj.sender')" :value="1" />
              <el-option :label="$t('collectionCenter.truckLoading')" :value="3" />
              <el-option :label="$t('collectionCenter.unloading')" :value="4" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item>
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="3" :label="$t('GlobalSetObj.ScanNumber')" :value="3" />
                  <el-option key="2" :label="$t('GlobalSetObj.bagNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <!--  收件人州 -->
        <el-col :span="4">
          <el-form-item :label="$t('newOrder.RecipientState')">
            <el-select
              v-model="queryForm.params.preDeliverySiteState"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
              @change="handlepreDeliverySiteState"
            >
              <el-option
                v-for="item in StateList"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   预派送网点   -->
          <el-form-item :label="$t('GlobalSetObj.preEntrega')">
            <el-select
              v-model="queryForm.params.preDeliverySiteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
            >
              <el-option
                v-for="item in preDeliverySiteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   下一站   -->
          <el-form-item :label="$t('GlobalSetObj.nextStation')">
            <el-select
              v-model="queryForm.params.nextSiteIds"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width:100%"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!--  车牌号 -->
        <el-col :span="4">
          <el-form-item prop="selectType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.queryType"
                  :style="IsWidth"
                  @change="handleQueryType"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.licensePlateNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.NIV')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-select
              v-model="queryForm.params.numberList"
              :remote-method="getVehicleTagNumber"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="item in nivNumberList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('newOrder.waybillStatus')">
            <el-select
              v-model="queryForm.params.waybillStatus"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              collapse-tags
              filterable
              multiple
              style="width:100%"
              @change="selectStatus"
            >
              <el-option
                v-for="(item, ind) in waybillStatusList"
                :key="ind"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 客户 -->
        <el-col :span="5">
          <el-form-item :label="$t('GlobalSetObj.client')">

            <QueryCustomerV2Cmp
              :multiple-limit="10" :val="queryForm.params.customerIdList" multiple
              style="width: 100%" @change="queryForm.params.customerIdList = $event"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{
            $t('newOrder.search')
          }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small" type="info"
            @click="resetForm"
          >{{
            $t('newOrder.reset')
          }}
          </el-button>
          <el-button
            icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
            @click="allExportOrder"
          >{{
            $t('newOrder.export')
          }}
          </el-button>
          <!--批量单号导出-->
          <el-button
            size="small"
            type="primary"
            @click="clickBtchExport"
          >{{ $t('placeOrder.BatchExport') }}
          </el-button>
          <el-radio-group
            v-model="isTest"
            v-permit:remove="'btn:SortingCenter:outGoRecordData'"
            size="small"
            style="margin-left: 10px"
            @change="handleTest"
          >
            <el-radio-button :label="1">{{ $t("newOrder.Formal") }}</el-radio-button>
            <el-radio-button :label="0">{{ $t("newOrder.test1") }}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="`${$t('GlobalSetObj.serial', 'zh')}（${$t('GlobalSetObj.serial','pu')}）`"
        align="center"
        type="index"
        width="80"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.bagNumber', 'zh')}（${$t('GlobalSetObj.bagNumber','pu')}）`"
        min-width="130"
        prop="bagNumber"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.bagNumber || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="`${$t('newOrder.waybillNumber', 'zh')}（${$t('newOrder.waybillNumber','pu')}）`"
        min-width="160"
        prop="waybillNumber"
      >
        <template slot-scope="scope">
          <div>
            <el-popover placement="top" popper-class="copy" trigger="hover">
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copy(scope.row.waybillNumber)"
              >{{ `${$t('orderDetails.copy', 'zh')}（${$t('orderDetails.copy','pu')}）` }}
              </el-button>
              <div
                slot="reference"
                class="WaybillNumber"
                @click="toOrderDetail(scope.row)"
              >
                {{ scope.row.waybillNumber || '--' }}
              </div>
            </el-popover>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="`${$t('GlobalSetObj.ScanNumber', 'zh')}（${$t('GlobalSetObj.ScanNumber','pu')}）`"
        min-width="160"
        prop="scanOrderNumber"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.scanOrderNumber || '--' }}</div>
        </template>
      </el-table-column>

      <!-- 客户 -->
      <el-table-column
        :label="`${$t('GlobalSetObj.client', 'zh')}（${$t('GlobalSetObj.client','pu')}）`"
        min-width="120"
        prop="customerName"
      />

      <el-table-column
        :label="`${$t('newOrder.waybillStatus', 'zh')}（${$t('newOrder.waybillStatus','pu')}）`"
        min-width="160"
        prop="waybillStatusName"
      />

      <el-table-column
        :label="`${$t('collectionCenter.operationType', 'zh')}（${$t('collectionCenter.operationType','pu')}）`"
        align="center"
        min-width="150"
        prop="operationTypeName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.operationTime', 'zh')}（${$t('GlobalSetObj.operationTime','pu')}）`"
        min-width="170"
        prop="operateTime"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.nextStation', 'zh')}（${$t('GlobalSetObj.nextStation','pu')}）`"
        min-width="170"
        prop="nextSiteName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.licensePlateNumber', 'zh')}（${$t('GlobalSetObj.licensePlateNumber','pu')}）`"
        min-width="160"
        prop="vehicleNumber"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.vehicleNumber || '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="`${$t('GlobalSetObj.NIV', 'zh')}（${$t('GlobalSetObj.NIV','pu')}）`"
        min-width="140"
        prop="vehicleTagNumber"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.vehicleTagNumber || '-' }}</div>
        </template>
      </el-table-column>
      <!-- 州  -->
      <el-table-column
        :label="`${$t('newOrder.RecipientState', 'zh')}（${$t('newOrder.RecipientState','pu')}）`"
        min-width="120"
        prop="preState"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.preEntrega', 'zh')}（${$t('GlobalSetObj.preEntrega','pu')}）`"
        min-width="160"
        prop="preDeliverSiteName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.OperationState', 'zh')}（${$t('GlobalSetObj.OperationState','pu')}）`"
        min-width="100"
        prop="operateUserState"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.OperationSite', 'zh')}（${$t('GlobalSetObj.OperationSite','pu')}）`"
        min-width="120"
        prop="operateSiteName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.operator', 'zh')}（${$t('GlobalSetObj.operator','pu')}）`"
        min-width="150"
        prop="operateUserName"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderDetails.waybillMsg')"
      :visible.sync="waybillDescription"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <detailPage :key="detailKey" :query-track-param="queryTrackParam" query-type="1" />
      </div>
    </el-drawer>
    <!--  批量单号导出  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('placeOrder.BatchExport')"
      :visible.sync="dialogVisibleExport"
      top="10vh"
      width="500px"
      @close="clearForm"
    >
      <el-form
        ref="exportRuleForm"
        :model="exportRuleForm"
        class="demo-exportRuleForm"
        label-position="top"
      >
        <el-form-item
          :rules="{
            required: true,
            message: $t('placeOrder.pleaseInput'),
            trigger: ['blur', 'change']
          }"
          prop="NumberType"
        >
          <el-select v-model="exportRuleForm.NumberType" style="width: 80%">
            <!--运单号-->
            <el-option key="1" :label="$t('orderCenterCont.anjunWaybillNumber')" value="1" />
            <!--单号-->
            <el-option key="5" :label="$t('GlobalSetObj.ScanNumber')" value="5" />
          </el-select>
        </el-form-item>
        <el-form-item
          :rules="{
            required: true,
            message: $t('placeOrder.pleaseInput'),
            trigger: ['blur']
          }"
          prop="inputValeu"
          style="width: 100%"
        >
          <el-input
            v-model="exportRuleForm.inputValeu"
            :placeholder="$t('orderCenterCont.Multiple')"
            clearable
            rows="6"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: right;margin: 15px 0;">
        <el-button size="small" @click="dialogVisibleExport = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="comfireBtchExport">{{ $t('collectionCenter.CheckItOutNow') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLgAreaListData,
  selectInfoByStateTwo,
  selectSiteByState,
  shipmentExportAll,
  shipmentPage,
  vehicleTagNumber
} from '@/api/orderApi';
import QueryCustomerV2Cmp from '@/views/finance/cmp/Query/QueryCustomerV2Cmp.vue';
import detailPage from '@/components/detailComponent/detailPage.vue';
import { getAllsite, shipmentexportAll } from '@/api/logisticsOrders';
import { copyData } from '@/utils/copyData';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { waybillStatusList } from '@/utils/enumData';

const PARAMS = {
  inputValue: '',
  nextSiteIds: [],
  preDeliverySiteId: '',
  operateUserName: '',
  selectType: 1,
  operationType: '',
  preDeliverySiteState: '',
  operationalUserState: '',
  operationalUserSiteId: '',
  waybillStatus: [],
  queryType: 1,
  numberList: [],
  customerIdList: [] // 客户
};

export default {
  name: 'OutGoRecord',
  components: {
    detailPage,
    QueryCustomerV2Cmp
  },
  data() {
    return {
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      queryTrackParam: {},
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: cloneDeep(PARAMS)
      },
      siteList: [], // 网点
      tableData: [],
      userList: [], // 用户列表
      StateList: [], // 州
      preDeliverySiteList: [],
      isTest: 1,
      nivNumberList: [],
      waybillStatusList: waybillStatusList,
      dialogVisibleExport: false,
      exportRuleForm: {
        NumberType: '1',
        inputValeu: ''
      },
      opearateSitelist: [] // 操作人州
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '190px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getVehicleTagNumber();
    this.getAddress();
    this.getAllSite();
    this.getOperationState();
    this.getSearch(1);
  },
  methods: {
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.isTest = 1;
      this.getTimer();
      this.queryForm.params = cloneDeep(PARAMS);
      this.handleQueryType();
      this.getSearch();
    },
    handleTest() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
        if (inputValList.length > 2000) {
          this.$message.warning(this.$t('newOrder.searchMax1000'));
          inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
        }
      }
      const data = {};
      if (this.timeValue && this.timeValue.length > 0) {
        data.operateTimeStart = this.timeValue[0];
        data.operateTimeEnd = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumber = inputValList;
      } else if (this.queryForm.params.selectType === 2) {
        data.bagNumber = inputValList;
      } else {
        data.scanNumber = inputValList;
      }
      // data.params.waybillNumber = inputValList;
      data.nextSiteIds = this.queryForm.params.nextSiteIds;
      data.operationalUserSiteId = this.queryForm.params.operationalUserSiteId;
      data.preDeliverySiteId = this.queryForm.params.preDeliverySiteId;
      const obj = this.userList.find(item => {
        return item.keyId === this.queryForm.params.operateUserName;
      });
      if (obj) {
        if (obj.expressId) {
          data.expressId = obj.expressId;
          data.supplierId = null;
        } else {
          data.supplierId = obj.supplierId;
          data.expressId = null;
        }
      }
      data.preDeliverySiteState = this.queryForm.params.preDeliverySiteState;
      data.operationalUserState = this.queryForm.params.operationalUserState;
      data.isTest = this.isTest;
      data.waybillStatus = this.queryForm.params.waybillStatus;
      data.queryType = this.queryForm.params.queryType;
      data.numberList = this.queryForm.params.numberList;
      data.operationType = this.queryForm.params.operationType === '' ? null : this.queryForm.params.operationType;
      shipmentExportAll(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              if (res.data.taskId) {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              } else {
                this.$router.push({ path: '/views/resource/exportList' });
              }
            }
          }).catch(() => {
          });
        }
      });
    },
    // 查询
    getSearch(val) {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.operateTimeStart = this.timeValue[0];
        data.params.operateTimeEnd = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumber = inputValList;
      } else if (this.queryForm.params.selectType === 2) {
        data.params.bagNumber = inputValList;
      } else {
        data.params.scanNumber = inputValList;
      }
      data.params.nextSiteIds = this.queryForm.params.nextSiteIds;
      data.params.operationalUserSiteId = this.queryForm.params.operationalUserSiteId;
      data.params.preDeliverySiteId = this.queryForm.params.preDeliverySiteId;
      const obj = this.userList.find(item => {
        return item.keyId === this.queryForm.params.operateUserName;
      });
      if (obj) {
        if (obj.expressId) {
          data.params.expressId = obj.expressId;
          data.params.supplierId = null;
        } else {
          data.params.supplierId = obj.supplierId;
          data.params.expressId = null;
        }
      }
      // console.log(obj, 'objobjobj');
      data.params.preDeliverySiteState = this.queryForm.params.preDeliverySiteState;
      data.params.operationalUserState = this.queryForm.params.operationalUserState;
      data.params.isTest = this.isTest;
      let statusList = [];
      if (this.queryForm.params.waybillStatus.includes(3)) {
        statusList = cloneDeep(this.queryForm.params.waybillStatus);
        if (!statusList.includes(19)) {
          statusList.push(19);
        }
      } else {
        statusList = this.queryForm.params.waybillStatus;
      }
      data.params.waybillStatus = statusList;
      data.params.queryType = this.queryForm.params.queryType;
      data.params.numberList = this.queryForm.params.numberList;
      data.params.operationType = this.queryForm.params.operationType === '' ? null : this.queryForm.params.operationType;
      data.params.customerIdList = this.queryForm.params.customerIdList;

      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      shipmentPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          // this.tableData = res.data.records;
          this.tableData = Object.freeze((res.data.records || []).map(item => {
            const obj = {
              ...item
            };

            // if (waybillStatusObj[item.waybillStatus].label) {
            //   obj.statusNamewaybill = waybillStatusObj[item.waybillStatus].label;
            // }

            return obj;
          }));
          if (val === 1) {
            loading.close();
          }
          // console.log(this.tableData, 'tableData');
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    // 批量导出
    clickBtchExport() {
      this.dialogVisibleExport = true;
    },
    clearForm() {
      this.exportRuleForm.NumberType = '1';
      this.exportRuleForm.inputValeu = '';
    },
    // 确定批量导出
    comfireBtchExport() {
      this.$refs['exportRuleForm'].validate((valid) => {
        if (valid) {
          const numList = this.exportRuleForm.inputValeu ? this.exportRuleForm.inputValeu.split('\n') : [];
          const filteredArray = numList.filter(item => /\S/.test(item));
          if (filteredArray.length === 0) {
            this.$message.error(this.$t('GlobalSetObj.notMore10wTip'));
            return;
          }
          if (filteredArray.length > 100000) {
            this.$message.error(this.$t('GlobalSetObj.notMore10w'));
            return;
          }
          const data = {};
          if (this.exportRuleForm.NumberType === '1') {
            data.waybillNumberList = filteredArray;
            data.scanOrderNumberList = [];
          } else {
            data.waybillNumberList = [];
            data.scanOrderNumberList = filteredArray;
          }
          this.dialogVisibleExport = false;
          shipmentexportAll(data).then((res) => {
            if (res.status === 'OK') {
              const h = this.$createElement;
              this.$msgbox({
                title: this.$i18n.t('operationCenter.tips'),
                message: h('p', null, [
                  h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
                  h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
                ]),
                showCancelButton: true,
                confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
                cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
              })
                .then((action) => {
                  if (action === 'confirm') {
                    if (res.data.taskId) {
                      this.$router.push({ path: '/views/resource/exportTaskList' });
                    } else {
                      this.$router.push({ path: '/views/resource/exportList' });
                    }
                  }
                })
                .catch(() => {
                });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleQueryType() {
      this.queryForm.params.numberList = [];
      this.getVehicleTagNumber('');
    },
    // 查询车签号, 车牌号
    getVehicleTagNumber(val) {
      const data = {
        number: val,
        queryType: this.queryForm.params.queryType
      };
      vehicleTagNumber(data).then((res) => {
        if (res.status === 'OK') {
          this.nivNumberList = res.data;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      console.log('handleCurrentChange', val);
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      // const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    // 获取所有网点
    getAllSite() {
      this.siteList = [];
      getAllsite().then(res => {
        if (res.status === 'OK') {
          console.log('获取所有网点', res.data);
          this.siteList = res.data;
          this.preDeliverySiteList = this.siteList;
        }
      });
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.queryTrackParam = row;
      console.log(row, ':query-track-param="queryTrackParam"');
      console.log(row.waybillId, 'row.waybillIdrow.waybillIdrow.waybillId', this.queryTrackParam.waybillId);
      this.waybillDescription = true;
      this.detailKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', row.waybillId);
      }, 100);
    },
    handlepreDeliverySiteState(val) {
      this.queryForm.params.preDeliverySiteId = '';
      if (val) {
        selectSiteByState(val).then(res => {
          if (res.status === 'OK') {
            console.log('获取所有网点', res.data);
            this.preDeliverySiteList = res.data;
          }
        });
      } else {
        this.preDeliverySiteList = this.siteList;
      }
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 州
    getOperationState(falg, val) {
      const data = {};
      if (falg === 1) {
        data.state = val;
        this.queryForm.params.operationalUserSiteId = '';
        this.queryForm.params.operateUserName = '';
      } else if (falg === 2) {
        data.siteId = val;
        this.queryForm.params.operateUserName = '';
      } else if (falg === 3) {
        data.operateUserName = val;
      }
      selectInfoByStateTwo(data).then((res) => {
        if (res.status === 'OK') {
          this.opearateSitelist = res.data.siteList;
          this.userList = res.data.userList;
        }
      });
    },
    getOperationName(val) {
      const data = {};
      data.operateUserName = val;
      selectInfoByStateTwo(data).then((res) => {
        if (res.status === 'OK') {
          this.opearateSitelist = res.data.siteList;
          this.userList = res.data.userList;
        }
      });
    },
    selectStatus(val) {
      if (val.indexOf(0) !== -1) {
        this.queryForm.params.waybillStatus = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
      }
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}
</style>

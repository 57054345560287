import request from '@/utils/request';
import newRequest from '@/utils/new-request';
// import qs from 'qs';

// const orderURL = process.env.VUE_APP_BASE_API1;
const baseFinanceURL = process.env.VUE_APP_FINANCE; // 财务系统接口地址

// 财务分区分页/账单批次详情
export const apiSiteExpenseGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 按条件查询站点
export const apiSiteQueryCondition = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/site/query/condition',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用设置备注
export const apiSetRemark = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/setRemark',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用导出
export const apiExportCustomerBills = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用Shein揽收对账单导出
export const apiShineExportCustomerBills = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/exportShein',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用Shein尾派对账单导出
export const apiShineExportTailgateBills = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/exportExpressShein',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用生成应收
export const apiGenerateBillNumber = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteBill/generateBillNumber',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用账单审核查询分页
export const apiSiteBillGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteBill/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 批量删除费用
export const apiSiteExpenseDeleteFeeSub = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/batch/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 批量添加应收费用
export const apiSiteExpenseAddFeeSub = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/batchAddFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收费用详情
export const apiGetSubsByWaybillNumber = (data, isBool = true) => {
  return newRequest({
    method: 'get',
    url: '/siteExpense/getInfo/' + data.waybillNumber + '/' + data.orderNumber,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收帐单审核
export const apiSiteBillAudit = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteBill/audit',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应收审核撤回
export const apiSiteWithdraw = (data, isBool = true) => {
  return newRequest({
    method: 'put',
    url: '/siteBill/withdraw/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 收款
export const apiSiteBillCrediting = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/SiteReceipt/crediting',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 账单核收撤回
export const apiSiteBillCancellationOfReceipt = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/SiteReceipt/withdraw',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点获取重新核算列表数据
export const apiReCalculatePrice = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/reCalculatePrice',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点获取重新核算列表数据分页查询
export const apiSiteExpenseReCalculatePriceResult = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/getReCalculatePriceResult',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点获取重新核算确认核算
export const apiConfirmReCalculatePriceResult = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: `/siteExpense/confirmReCalculatePriceResult/${data}`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点应收费用详情
export const apiSiteExpenseAddMultiFeeSub = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/addFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户应收费用分页
export const apiCustomerExpenseGetPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户费用详情
export const apiCustomerExpenseGetInfo = (data, isBool = true) => {
  return request({
    method: 'GET',
    url: '/fa/customerExpense/getInfo/' + data.waybillNumber + '/' + data.orderNumber,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户添加费用
export const apiCustomerExpenseAddFee = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: 'fa/customerExpense/addFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户费用删除
export const apiCustomerDeleteDetail = (data, isBool = true) => {
  return request({
    method: 'DELETE',
    url: 'fa/customerExpense/delete/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户批量添加费用
export const apiCustomerExpenseBatchAddFee = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/batchAddFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户重新计算价格
export const apiCustomerExpenseReCalculatePrice = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/reCalculatePrice',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户重算价格分页
export const apiCustomerExpenseReCalculatePriceResult = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/getReCalculatePriceResult',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户确认重新计算价格
export const apiReCalculatePriceResult = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/confirmReCalculatePriceResult/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户删除费用
export const apiReCalculateDeleteFeeDTO = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户应收费用备注
export const apiCustomerExpenseRemark = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/batch/Remark',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户应收费用生成应收
export const apiCustomerGenerateBillNumber = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerBill/generateBillNumber',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户应收费用导出
export const apiCustomerExpenseExport = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户删除
export const apiCustomerExpenseDelete = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/batch/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户帐单审核分页
export const apiCustomerBillPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerBill/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户账单审核
export const apiCustomerBillAudit = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerBill/audit',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户账单撤回
export const apiCustomerBillWithdraw = (data, isBool = true) => {
  return request({
    method: 'PUT',
    url: '/fa/customerBill/withdraw/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户账单撤回
export const apiCustomerReceiptPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerReceipt/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户账单核收
export const apiCustomerReceiptCrediting = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerReceipt/crediting',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户账单撤回
export const apiCustomerReceiptWithdraw = (data, isBool = true) => {
  return request.post('/fa/customerReceipt/withdraw', data);
};

// 客户账户充值
export const apiCustomerRecharge = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/customer/recharge',
    data,
    isLoading: isBool // 是否显示加载中参数
    // baseURL: baseFinanceURL
  });
};
// 客户账户流水详情
export const apiCustomerExpenseSubPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpenseSub/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点导入添加费用
export const apiImportAddFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/importAddFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点导入修改费用模板下载
export const apiImportEditFeeTemplate = (data, isBool = true) => {
  return newRequest({
    method: 'get',
    url: '/customerExpense/importEditFeeTemplate',
    responseType: 'blob',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用导入修改费用模板下载
export const apiPayableSiteExpenseTemplate = (data, isBool = true) => {
  return newRequest({
    method: 'get',
    url: `/payableSiteExpense/importEditFeeTemplate/${data}`,
    responseType: 'blob',
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点导入修改费用
export const apiSiteExpenseImportEditFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/importEditFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户导入添加费用
export const apiCustomerExpenseImportAddFee = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/importAddFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户导入修改费用
export const apiCustomerExpenseImportEditFee = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/importEditFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用分页/应付账户账单详情
export const apiPayableSiteExpenseGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用导出
export const apiPayableSiteExpenseExport = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用运输干支线导出
export const apiPayableTransportSiteExpenseExport = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用备注
export const apiPayableSiteExpenseSetRemark = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/setRemark',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用运输干支线备注
export const apiPayableTransportSiteExpenseSetRemark = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/setRemark',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 应付费用批量添加费用
// export const apiPayableSiteExpenseBatchAddFee = (data, isBool = true) => {
//   return request({
//     method: 'POST',
//     url: '/fa/payableSiteExpense/batchAddFee',
//     data,
//     isLoading: isBool // 是否显示加载中参数
//   });
// };
// 新应付费用批量添加费用
export const apiPayableSiteExpenseBatchAddFee = (data) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/batchAddClAndLgFee',
    data
  });
};
// 应付运输干支线添加费用
export const apiBatchAddClAndLgFee = (data) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/batchAddClAndLgFee',
    data
  });
};
// 应付费用添加费用
export const apiPayableSiteExpenseAddFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/addFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用运输干支线详情页添加费用
export const apiPayableTransportSiteExpenseAddFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/addFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用详情费用明细删除
export const apiPayableSiteExpenseDelete = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用详情
export const apiPayableSiteExpenseGetInfo = (data, isBool = true) => {
  return newRequest({
    method: 'get',
    url: '/payableSiteExpense/getInfo/' + data.waybillNumber + '/' + data.businessType + '/' + data.payableType,
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 运输干支线应付费用详情
export const apiPayableTransportSiteExpenseGetInfo = (data, isBool = true) => {
  return newRequest({
    method: 'get',
    url: '/payableTransportSiteExpense/getInfo/' + data.waybillNumber + '/' + data.siteId,
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付批量导入费用
export const apiPayableSiteExpenseImportAddFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/importAddFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 应付批量导入费用新
// export const apiSiteExpenseImportAddFee = (data, isBool = true) => {
//   return request({
//     method: 'POST',
//     url: '/fa/siteExpense/importAddFee',
//     data,
//     isLoading: isBool // 是否显示加载中参数
//   });
// };

// 应付费用导入修改费用
export const apiPayableSiteExpenseImportEditFee = (obj, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: `/payableSiteExpense/importEditFee/${obj.payableType}`,
    data: obj.list,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用删除费用
export const apiPayableSiteExpenseBatchDelete = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/batch/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用运输干支线批量删除费用
export const apiPayableTransportSiteExpenseBatchDelete = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/batch/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用生成应付
export const apiPayableSiteBillGenerateBillNumber = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteBill/generateBillNumber',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付审核列表
export const apiPayableSiteBillGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteBill/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付账单审核
export const apiPayableSiteBillAudit = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteBill/audit',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付审核撤回
export const apiPayableSiteBillWithdraw = (data, isBool = true) => {
  return newRequest({
    method: 'put',
    url: '/payableSiteBill/withdraw/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付审核发起请款
export const apiPayableSiteBillRequestPaymentOperation = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/payableSiteBill/requestPaymentOperation',
    isLoading: isBool, // 是否显示加载中参数
    data
  });
};
// 应付核付
export const apiSitePayableCrediting = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/sitePayable/crediting',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 核付撤回审核
export const apiSitePayableWithdraw = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/withdraw',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付重新计算价格
export const apiPayableSiteExpenseReCalculatePrice = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/reCalculatePrice',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 运输干支线应付费用重新计算价格
export const apiPayableTransportSiteExpenseReCalculatePrice = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/reCalculatePrice',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 是否重算
export const apiPayableSiteExpensePriceResult = (obj, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: `/payableSiteExpense/confirmReCalculatePriceResult/${obj.payableType}/${obj.isReplace}`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 运输干支线确认重算
export const apiTransportConfirmReCalculatePriceResult = (obj, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: `/payableTransportSiteExpense/confirmReCalculatePriceResult/${obj.isReplace}`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 重算分页查询
export const apiPayableSiteExpenseGetReCalculatePriceResult = (obj, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: `/payableSiteExpense/getReCalculatePriceResult/${obj.payableType}`,
    data: obj.list,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 运输干支线重算分页查询
export const apiPayableTransportSiteExpenseGetReCalculatePriceResult = (obj, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: `/payableTransportSiteExpense/getReCalculatePriceResult`, // /${obj.payableType}
    data: obj.list,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 自定义栏目查询
export const apiCustomColumnTemplateList = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/template/list',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 添加自定义栏目
export const apiCustomColumnTemplateInsert = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/template/insert',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 自定义栏目更新
export const apiCustomColumnTemplateUpdate = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/template/update',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点单票添加快录
export const apiPayableSiteFastInputFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/fastInputFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 公司对网点批量导入快录
export const apiPayableSiteBatchFastInputFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/batchFastInputFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 网点对客户批量导入快录
export const apiCustomerExpenseBatchFastInputFee = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/batchFastInputFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 网点对客户单票添加快录
export const apiCustomerExpenseFastInputFee = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerExpense/fastInputFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付导入添加快录
export const apiPayableSiteExpenseBatchFastInputFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/batchFastInputFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付费用添加快录
export const apiPayableSiteExpenseFastInputFee = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/fastInputFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 推送快手揽收费
export const apiFinancialExpressKwai = (data) => {
  return newRequest({
    method: 'POST',
    url: '/express/kwai/fee/client/push',
    // url: '/kwai/redirect/fee/client/push',
    data
    // baseURL: orderURL
  });
};

// 发送邮件
export const apiSitePayableSendMail = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/sendMail',
    data,
    isLoading: isBool // 是否显示加载中参数
    // baseURL: orderURL
  });
};

// 获取详情总数据
export const apiPayableSiteExpenseCountTotalFree = (data) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/countTotalFree',
    data
  });
};

// 账单审核导出
export const apiPayableSiteBillExport = (data) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteBill/export',
    data
  });
};

// 账单核付导出
export const apiSitePayableExport = (data) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/export',
    data
  });
};

// 应付-账单核付-修改文件
export const apiSitePayableUpdateFile = (data) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/updateFile',
    data
  });
};

/**
 * @description 导入应收生成
 * @param data
 */
export const siteBillImportGenerateBillNumber = (data) => {
  return newRequest({
    method: 'POST',
    url: '/siteBill/importGenerateBillNumber',
    data
  });
};
// 查询路线参照表
export const apiRouteDistanceReferencePage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/routeDistanceReference/page',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 添加路线参照表
export const apiRouteDistanceReferenceAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/routeDistanceReference/add',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 修改路线参照表
export const apiRouteDistanceReferenceEdit = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/routeDistanceReference/edit',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 导入路线参照表
export const apiRouteDistanceReferenceImportAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/routeDistanceReference/importAdd',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 删除路线参照表
export const apiRouteDistanceReferenceDelete = (data, isBool = true) => {
  return newRequest({
    method: 'delete',
    url: '/routeDistanceReference/delete/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 导出路线参照表
export const apiRouteDistanceReferenceExport = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/routeDistanceReference/export',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 更新路线参照表距离
export const apiRouteDistanceReferenceToUpdateDistance = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/routeDistanceReference/toUpdateDistance',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 应付费用运输干支线列表查询
export const apiPayableTransportSiteExpenseGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
/**
 * @description 获取账单和手总计数据
 */
export const apiSiteExpenseExpenseSubTotal = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/siteExpense/expenseSubTotal',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

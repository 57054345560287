// @ts-check

import i18n from '@/lang';

export const TABLE_HEADER = [
  // 应收主体
  { prop: 'providerName', label: i18n.t('basicData.businessPrincipal'), width: 200, fixed: 'left', highlight: false, disabled: false },
  // 网点
  { prop: 'siteName', label: i18n.t('basicData.Outlets'), width: 200, fixed: 'left', highlight: false, disabled: false },
  // 收件人州
  { prop: 'receiveState', label: i18n.t('newOrder.RecipientState'), width: 140, fixed: 'left', highlight: false, disabled: false },
  // 发件人州
  { prop: 'senderState', label: i18n.t('newOrder.SenderState'), width: 140, fixed: 'left', highlight: false, disabled: false },
  // 发件人城市
  { prop: 'senderCity', label: i18n.t('collectionCenter.SenderCity'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 订单号
  { prop: 'orderNumber', label: i18n.t('basicData.OrderNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 所属分区
  { prop: 'partitionName', label: i18n.t('basicData.PartitionName'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人城市
  { prop: 'receiveCity', label: i18n.t('collectionCenter.receiveCity'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人邮编
  { prop: 'receiveZipcode', label: i18n.t('collectionCenter.Recipientzc'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 客户单号
  { prop: 'customerNumber', label: i18n.t('receivablePayable.CustomerTrackingNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 订单状态
  { prop: 'status', label: i18n.t('collectionCenter.OrderStatus'), width: 140, fixed: 'left', highlight: false, disabled: false },
  // 客户名称
  { prop: 'customerAlias', label: i18n.t('basicData.CustomerName'), width: 140, fixed: 'left', highlight: false, disabled: false },
  // 客户编号
  { prop: 'customerId', label: i18n.t('basicData.CustomerCode'), width: 140, fixed: 'left', highlight: false, disabled: false },
  // 客户出仓时间
  { prop: 'customerSendTime', label: i18n.t('newOrder.clientExitTime'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 商户
  { prop: 'merchantName', label: i18n.t('collectionCenter.CommercialOwner'), width: 140, fixed: 'left', highlight: false, disabled: false },
  // 支付方式
  { prop: 'payType', label: i18n.t('ClaimsManagement.PaymentMethod'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 120, fixed: 'left', highlight: true, disabled: false },
  // 应收总费用
  { prop: 'totalFee', label: i18n.t('basicData.TotalFeesReceivable'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 货物类型
  { prop: 'cargoType', label: i18n.t('placeOrder.cargoType'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 揽收方式
  { prop: 'collectType', label: i18n.t('collectionCenter.lanshouTypee'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 揽收类型
  { prop: 'collectionCategory', label: i18n.t('basicData.CollectionType'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 包裹类型
  { prop: 'orderPackageType', label: i18n.t('placeOrder.packageType'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 来源
  { prop: 'feeSource', label: i18n.t('receivablePayable.source'), width: 120, fixed: 'left', highlight: false, disabled: false }
];

// 业务类型
export const BUSINESS_TYPE = Object.freeze({
  0: {
    label: i18n.t('collectionCenter.expressDelivery'), // 快递
    value: 0
  },
  1: {
    label: i18n.t('collectionCenter.lanshou'), // 揽收
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.dispatch'), // 派送
    value: 2
  }

});

export const QUERY_PARAMS = {
  providerId: '', // 应收主体id
  siteId: '', // 网点id
  customerId: '', // 客户id
  customerCodeId: '', // 客户编号
  merchantName: '', // 商户
  timeField: 'operate_time', // 查询时间类型
  startTime: '', // 业务开始时间
  endTime: '', // 业务结束时间
  numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号
  numberList: [], // 业务单号
  isAccept: null, // 是否已支付
  payType: null, // 支付方式
  isGenBill: null, // 是否生成对账单
  businessType: null, // 业务类型 0:快递系统业务 1:揽收系统业务
  subBusinessType: null, // 明细业务类型
  collectionCategory: null, // 揽收类型
  cargoType: null, // 货物类型
  billBatchNumber: '', // 批次号
  feeName: '', // 费用类型名称
  feeCode: '',
  status: '', // 订单状态
  // handlerId: '', // 经办人
  receiveState: '', // 收件人州
  senderState: '' // 发件人州
};

<template>
  <div class="app-container">
    <el-card shadow="never">
      <el-form :inline="false" label-position="top" size="small">
        <el-row :gutter="20">
          <el-col :span="6">
            <!--  运单号   -->
            <el-form-item prop="selectType">
              <template slot="label">
                <div class="selectLabel">
                  <el-select v-model="queryForm.params.selectType" :style="IsWidth">
                    <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                    <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="2" />
                  </el-select>
                </div>
              </template>
              <el-input
                v-model="queryForm.params.inputValue"
                :placeholder="$t('newOrder.PreciseSearch1')"
                :rows="5"
                style="width: 100%"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="20">
              <el-col :span="8">
                <!--时间-->
                <el-form-item prop="timeType">
                  <template slot="label">
                    <div class="selectLabel">
                      <el-select v-model="queryForm.params.timeType" :style="IsWidth01">
                        <el-option key="1" :label="$t('orderCenterCont.newPushTime')" :value="1" />
                        <el-option key="2" :label="$t('collectionCenter.orderCreateT')" :value="2" />
                        <el-option
                          key="3"
                          :label="$t('orderCenterCont.customerDeliveryTime')"
                          :value="3"
                        />
                        <el-option key="4" :label="$t('orderCenterCont.dueTime01')" :value="4" />
                      </el-select>
                    </div>
                  </template>
                  <el-date-picker
                    v-model="timeValue"
                    :clearable="false"
                    :default-time="['00:00:00', '23:59:59']"
                    :format="$i18n.locale == 'zh' ? zhDate : ptDate"
                    :placeholder="$t('newOrder.SelectDatetime')"
                    range-separator="~"
                    style="width: 100%"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
              <!--  推送节点  -->
              <el-col :span="8">
                <el-form-item :label="$t('orderCenterCont.PushNodes')">
                  <el-select
                    v-model="queryForm.params.trackNode"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in nodeList"
                      :key="item.dictName"
                      :label="item.dictDescribe"
                      :value="item.dictName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 客户名称-->
              <el-col :span="8">
                <el-form-item :label="$t('orderCenterCont.CustomerName')">
                  <el-select
                    v-model="queryForm.params.platformCode"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    style="width: 100%"
                  >
                    <el-option label="shein" value="shein" />
                    <el-option label="kwai" value="kwai" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <!--商户号-->
                <el-form-item :label="$t('collectionCenter.merchantCode')" size="small">
                  <el-input
                    v-model.trim="queryForm.params.merchantCode"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    clearable
                    size="small"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--商户名称-->
                <el-form-item :label="$t('collectionCenter.MerchantName')" size="small">
                  <el-select
                    v-model="queryForm.params.merchantNameList"
                    :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
                    :remote-method="getMerchantList"
                    clearable
                    filterable
                    multiple
                    remote
                    reserve-keyword
                    size="small"
                    style="width: 100%"
                    @focus="getMerchantList(searMerchantName)"
                  >
                    <el-option
                      v-for="(item, index) in merchantList"
                      :key="index"
                      :label="`${item.merchantName} - ${item.merchantCode}`"
                      :value="item.merchantName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <div>
              <!--搜索-->
              <el-button
                icon="el-icon-search" size="small" type="primary"
                @click="enterSearch"
              >{{ $t('newOrder.search') }}
              </el-button>
              <!--重置-->
              <el-button
                icon="el-icon-refresh-left" size="small" type="info"
                @click="resetForm()"
              >{{ $t('newOrder.reset') }}
              </el-button>
              <el-button
                icon="el-icon-upload2"
                size="small"
                style="margin-bottom: 10px"
                @click="allExportOrder"
              >{{ $t('newOrder.export') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div class="tabClass">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :key="0" :label="$t('GlobalSetObj.all')" name="all">
          <allRecord v-if="isShow" ref="allRecordRef" :params-data="paramsData" />
        </el-tab-pane>
        <el-tab-pane :key="1" :label="$t('GlobalSetObj.failRecord')" name="fail">
          <failRecord v-if="isShow1" ref="failRecordRef" :params-data="paramsData" />
        </el-tab-pane>
      </el-tabs>
      <div v-if="activeName === 'all'" class="labelNumber">
        <div>{{ $t('orderCenterCont.todaySingData') }} : {{ dashboard.delivered }}</div>
        <div style="margin: 0 20px">
          {{ $t('orderCenterCont.todaySingPushData') }} : {{ dashboard.pushDelivered }}
        </div>
        <div>
          {{ $t('orderCenterCont.todayFailPushData') }} :
          <span style="color: #409eff; cursor: pointer" @click="getPushFail">{{
            dashboard.pushFail
          }}</span>
        </div>
      </div>
      <div v-else class="btnRight">
        <!--手动推送-->
        <el-button
          size="small" type="primary" @click="manualPush"
        >{{ $t('GlobalSetObj.PushManual') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTrackStateMap,
  trackPushLogDashboard,
  trackPushLogExport,
  trackPushLogPUsh
} from '@/api/orderApi';
import dayjs from 'dayjs';
import { goRWExportList } from '@/utils/goExportList.js';
import { apimerchantName } from '@/api/customer';

export default {
  name: 'TrackSendingManagement',
  components: {
    allRecord: () => import('./component/allRecord'),
    failRecord: () => import('./component/failRecord')
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          inputValue: '',
          selectType: 1,
          platformCode: '',
          trackNode: '',
          timeType: 1,
          merchantNameList: [],
          merchantCode: ''
        }
      },
      activeName: 'all',
      isShow: true,
      isShow1: false,
      paramsData: {
        params: {
          beginTime: null,
          endTime: null,
          createBeginTime: null,
          createEndTime: null,
          customerSendBeginTime: null,
          customerSendEndTime: null,
          shouldArriveBeginTime: null,
          shouldArriveEndTime: null
        }
      },
      nodeList: [],
      dashboard: {},
      isJump: false,
      merchantList: [],
      searMerchantName: ''
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '120px'
      };
    },
    IsWidth01() {
      return {
        width: this.isTormat ? '240px' : '150px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.gettrackPushLogDashboard();
    this.getTrackNode();
    this.getSearch(1);
  },
  methods: {
    // 手动推送
    manualPush() {
      const handleSelectionChange = this.$refs.failRecordRef.getSelectData();
      if (handleSelectionChange.length) {
        console.log(handleSelectionChange, 'manualPush');
        const arr = [];
        handleSelectionChange.forEach((item) => {
          arr.push(item.waybillNumber);
        });
        const data = {
          waybillNumbers: arr
        };
        trackPushLogPUsh(data).then(res => {
          if (res.code===200) {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.paramsData.params.pushStatus = false;
            this.$refs.failRecordRef.getSearch();
            this.$refs.failRecordRef.clearSelection();
          }
        });
      } else {
        this.$message.error(this.$t('GlobalSetObj.CheckForm'));
      }
    },
    // 查询
    gettrackPushLogDashboard() {
      trackPushLogDashboard().then(res => {
        if (res.code===200) {
          this.dashboard = res.data;
        }
      });
    },
    // 获取节点
    getTrackNode() {
      getTrackStateMap().then(res => {
        if (res.code===200) {
          this.nodeList = res.data;
        }
      });
    },
    handleClick(tab, event) {
      this.queryForm.pageNumber = 1;
      this.paramsData.pageNumber = 1;
      this.paramsData.params = {
        beginTime: null,
        endTime: null,
        createBeginTime: null,
        createEndTime: null,
        customerSendBeginTime: null,
        customerSendEndTime: null,
        shouldArriveBeginTime: null,
        shouldArriveEndTime: null
      };
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.timeType === 1) {
          this.paramsData.params.beginTime = this.timeValue[0];
          this.paramsData.params.endTime = this.timeValue[1];
          this.paramsData.params.createBeginTime = null;
          this.paramsData.params.createEndTime = null;
        } else if (this.queryForm.params.timeType === 2) {
          this.paramsData.params.createBeginTime = this.timeValue[0];
          this.paramsData.params.createEndTime = this.timeValue[1];
        } else if (this.queryForm.params.timeType === 3) {
          this.paramsData.params.customerSendBeginTime = this.timeValue[0];
          this.paramsData.params.customerSendEndTime = this.timeValue[1];
        } else {
          this.paramsData.params.shouldArriveBeginTime = this.timeValue[0];
          this.paramsData.params.shouldArriveEndTime = this.timeValue[1];
        }
      }
      this.paramsData.params.merchantNameList = this.queryForm.params.merchantNameList || [];
      this.paramsData.params.merchantCode = this.queryForm.params.merchantCode || null;
      if (tab.name === 'all') {
        if (this.isJump) {
          this.getTimer();
        }
        this.paramsData.params.platformCode = '';
        this.queryForm.params.platformCode = '';
        this.paramsData.params.pushStatus = null;
        this.isShow1 = false;
        this.isShow = true;
      }
      if (tab.name === 'fail') {
        if (this.isJump) {
          this.getTimer();
        }
        this.paramsData.params.pushStatus = false;
        this.isShow = false;
        this.isShow1 = true;
      }
      this.isJump = false;
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        inputValue: '',
        selectType: 1,
        platformCode: '',
        trackNode: '',
        timeType: 1,
        merchantNameList: [],
        merchantCode: ''
      };
      this.searMerchantName = '';
      this.getSearch();
    },
    // 查询
    getSearch(val) {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      this.paramsData.pageSize = this.queryForm.pageSize;
      this.paramsData.pageNumber = this.queryForm.pageNumber;
      this.paramsData.total = this.queryForm.total;
      this.paramsData.params = {
        beginTime: null,
        endTime: null,
        createBeginTime: null,
        createEndTime: null,
        customerSendBeginTime: null,
        customerSendEndTime: null,
        shouldArriveBeginTime: null,
        shouldArriveEndTime: null
      };
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.timeType === 1) {
          this.paramsData.params.beginTime = this.timeValue[0];
          this.paramsData.params.endTime = this.timeValue[1];
          this.paramsData.params.createBeginTime = null;
          this.paramsData.params.createEndTime = null;
        } else if (this.queryForm.params.timeType === 2) {
          this.paramsData.params.createBeginTime = this.timeValue[0];
          this.paramsData.params.createEndTime = this.timeValue[1];
        } else if (this.queryForm.params.timeType === 3) {
          this.paramsData.params.customerSendBeginTime = this.timeValue[0];
          this.paramsData.params.customerSendEndTime = this.timeValue[1];
        } else {
          this.paramsData.params.shouldArriveBeginTime = this.timeValue[0];
          this.paramsData.params.shouldArriveEndTime = this.timeValue[1];
        }
      }
      if (this.queryForm.params.selectType === 1) {
        this.paramsData.params.waybillNumberList = inputValList;
      } else {
        this.paramsData.params.scanOrderNumberList = inputValList;
      }
      this.paramsData.params.platformCode = this.queryForm.params.platformCode || null;
      this.paramsData.params.trackNode = this.queryForm.params.trackNode || null;
      this.paramsData.params.merchantNameList = this.queryForm.params.merchantNameList || [];
      this.paramsData.params.merchantCode = this.queryForm.params.merchantCode || null;
      console.log(this.paramsData, ' this.paramsData this.paramsData');
      if (!val) {
        if (this.activeName === 'all') {
          this.$refs.allRecordRef.getSearch();
        } else {
          this.paramsData.params.pushStatus = false;
          this.$refs.failRecordRef.getSearch();
        }
      }
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    getPushFail() {
      const today = dayjs().format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.platformCode = 'shein';
      this.queryForm.params.timeType = 1;
      this.isJump = true;
      console.log(123);
      this.activeName = 'fail';
      this.paramsData.pageNumber = 1;
      this.paramsData.params = {
        beginTime: null,
        endTime: null,
        createBeginTime: null,
        createEndTime: null,
        customerSendBeginTime: null,
        customerSendEndTime: null,
        shouldArriveBeginTime: null,
        shouldArriveEndTime: null
      };
      this.paramsData.params.beginTime = this.timeValue[0];
      this.paramsData.params.endTime = this.timeValue[1];
      this.paramsData.params.pushStatus = false;
      this.paramsData.params.platformCode = 'shein';
      this.paramsData.params.merchantNameList = this.queryForm.params.merchantNameList || [];
      this.paramsData.params.merchantCode = this.queryForm.params.merchantCode || null;
      this.isShow = false;
      this.isShow1 = true;
    },
    // 获取商户列表
    getMerchantList(val = '') {
      this.searMerchantName = val;
      const data = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          merchantName: this.searMerchantName
        }
      };
      apimerchantName(data).then((res) => {
        if (res.code === 200) {
          this.merchantList = res.data.records;
        }
      });
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      const data = {
        beginTime: null,
        endTime: null,
        createBeginTime: null,
        createEndTime: null,
        customerSendBeginTime: null,
        customerSendEndTime: null,
        shouldArriveBeginTime: null,
        shouldArriveEndTime: null
      };
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.timeType === 1) {
          data.beginTime = this.timeValue[0];
          data.endTime = this.timeValue[1];
        } else if (this.queryForm.params.timeType === 2) {
          data.createBeginTime = this.timeValue[0];
          data.createEndTime = this.timeValue[1];
        } else if (this.queryForm.params.timeType === 3) {
          data.customerSendBeginTime = this.timeValue[0];
          data.customerSendEndTime = this.timeValue[1];
        } else {
          data.shouldArriveBeginTime = this.timeValue[0];
          data.shouldArriveEndTime = this.timeValue[1];
        }
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumberList = inputValList;
      } else {
        data.scanOrderNumberList = inputValList;
      }
      data.platformCode = this.queryForm.params.platformCode || null;
      data.trackNode = this.queryForm.params.trackNode || null;
      data.merchantNameList = this.queryForm.params.merchantNameList || [];
      data.merchantCode = this.queryForm.params.merchantCode || null;
      if (this.activeName !== 'all') {
        data.pushStatus = false;
      }
      trackPushLogExport(data).then((res) => {
        goRWExportList(this);
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.app-container >>> .el-card__body {
  padding: 15px;
}

.tabClass {
  position: relative;
  padding-top: 10px;
}

.labelNumber {
  display: flex;
  position: absolute;
  top: 18px;
  left: 200px;
  font-size: 14px;
}

.btnRight {
  position: absolute;
  top: 8px;
  right: 20px;
}
</style>

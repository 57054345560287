<template>
  <!-- 揽收装车记录 -->
  <div class="Box">
    <div>
      <el-form>
        <el-row type="flex" justify="start" style="flex-wrap: wrap;" class="selectClass">
          <el-col :span="12" style="width: auto;">
            <el-form-item :label="$t('changepwd.collectLoadTime') + '：'">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('collectionCenter.EndTime')"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!--客户名称-->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerId"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                filterable
                clearable
                remote
                reserve-keyword
                :remote-method="getCustomerOption"
                @clear="getCustomerOption('')"
              >
                <el-option
                  v-for="item in customerOption"
                  :key="item.id"
                  :label="item.customerAlias + '（' + item.username + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!--揽收员-->
            <el-form-item :label="$t('collectionCenter.collector') + '：'">
              <el-select
                v-model="queryForm.params.loadUserId"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                filterable
                clearable
                remote
                reserve-keyword
                :remote-method="getCollectorOption"
                @clear="getCollectorOption('')"
              >
                <el-option
                  v-for="item in loadUserList"
                  :key="item.id"
                  :label="item.realName + '(' + item.username + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 所属网点： -->
            <el-form-item :label="$t('collectionCenter.belongDot')">
              <el-select
                v-model="queryForm.params.loadSiteId"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getSiteOption"
                @clear="getSiteOption('')"
              >
                <el-option
                  v-for="(item, index) in siteAllList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;"><!-- 网点所属州 -->
            <el-form-item :label="$t('collectionCenter.stateSite')">
              <el-select
                v-model="queryForm.params.siteState"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
                remote
                reserve-keyword
                :remote-method="getState"
                @clear="getState('')"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;"><!-- 车签号 -->
            <el-form-item :label="$t('collectionCenter.bagNumber')">
              <el-input
                v-model="queryForm.params.loadTagNumber"
                :placeholder="$t('collectionCenter.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;"><!-- 车牌号 -->
            <el-form-item :label="$t('collectionCenter.licensePlateNum')">
              <el-select
                v-model="queryForm.params.loadVehicleId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
                remote
                reserve-keyword
                :remote-method="getVehicleNumber"
                @clear="getVehicleNumber('')"
              >
                <el-option
                  v-for="item in vehicleNumberList"
                  :key="item.id"
                  :label="item.vehicleNumber"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;" class="nameTop"><!-- 安骏运单号 -->
            <el-form-item :label="$t('orderCenterCont.anjunWaybillNumber') + '：'">
              <el-input
                v-model="queryForm.params.ordeNumberList"
                :placeholder="$t('collectionCenter.pleaseInput')"
                clearable
                type="textarea"
                @blur="ft"
                @keydown.enter.native="BlockNewNumberline"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:bulky-express:large-job-management:collecting-loading-records:find'"
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}
          </el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:bulky-express:large-job-management:collecting-loading-records:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}
          </el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:bulky-express:large-job-management:collecting-loading-records:export'"
            icon="el-icon-upload2"
            size="mini"
            @click="download"
          >{{ $t('collectionCenter.export1') }}</el-button><!-- 勾选导出 -->
          <el-button
            v-permit:remove="'btn:bulky-express:large-job-management:collecting-loading-records:exportAll'"
            icon="el-icon-upload2"
            size="mini"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <el-table
      ref="tableRef"
      :data="tableDate"
      :max-height="600"
      border
      @select="batchSelect"
      @select-all="batchSelectAll"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" align="center" type="index"
        width="60"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('changepwd.collectLoadTime')"
        prop="siteLoadTime"
        align="center"
      /><!-- 揽收装车时间 -->
      <el-table-column
        :label="$t('collectionCenter.CustomerName')"
        prop="customerName"
        align="center"
      /><!-- 客户名称 -->
      <el-table-column
        :label="$t('orderCenterCont.anjunWaybillNumber')"
        prop="orderNumber"
        align="center"
      /><!-- 安骏运单号 -->
      <el-table-column
        :label="$t('GlobalSetObj.ScanNumber')"
        prop="scanNumber"
        align="center"
      /><!-- 扫描单号 -->
      <el-table-column
        :label="$t('collectionCenter.collector')"
        prop="siteLoadUserName"
        align="center"
      /><!-- 揽收员 -->
      <el-table-column
        :label="$t('collectionCenter.belongD')"
        prop="siteName"
        align="center"
      /><!-- 所属网点 -->
      <el-table-column
        :label="$t('collectionCenter.stateSite1')"
        prop="siteState"
        align="center"
      /><!-- 网点所属州 -->
      <el-table-column
        :label="$t('collectionCenter.carNumber')"
        prop="siteLoadTagNumber"
        align="center"
      /><!-- 车签号 -->
      <el-table-column
        :label="$t('collectionCenter.licensePlateNumber')"
        prop="siteLoadVehicleNumber"
        align="center"
      /><!-- 车牌号 -->
      <el-table-column
        :label="$t('collectionCenter.driver1')"
        prop="vehicleDriver"
        align="center"
      /><!-- 司机 -->
    </el-table>
    <div class="right">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100, 500, 1000, 2000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getPageNumber"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import {
  collectBulkyLoadExportAll,
  collectBulkyLoadPage,
  queryCustomerName,
  query10User,
  query10Site,
  queryState,
  queryVehiclePage
} from '@/api/bulkyExpress';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  name: 'CollectingLoadingRecords',
  data() {
    return {
      customerOption: [],
      loadUserList: [],
      siteAllList: [],
      stateList: [],
      vehicleNumberList: [],
      timeValue: [],
      notEnter: true,
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          customerId: null,
          loadUserId: null,
          loadSiteId: null,
          siteState: null,
          loadTagNumber: null,
          loadVehicleId: null,
          ordeNumberList: ''
        }
      },
      total: 0,
      tableDate: [],
      exportList: []
    };
  },
  watch: {
    'queryForm.params.ordeNumberList'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 2000) {
        number = bghTrim.slice(0, 2000).join('\n');
        this.queryForm.params.ordeNumberList = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.searchMax1000')); // '单次查询最多2000条，已截取前2000条数据'
      }
    }
  },
  created() {
    this.getCustomerOption('');
    this.getCollectorOption('');
    this.getSiteOption('');
    this.getState('');
    this.getVehicleNumber('');
    this.default();
    this.searchClick();
  },
  methods: {
    // 所有车辆
    getVehicleNumber(name) {
      queryVehiclePage(name).then(res => {
        if (res.status === 'OK') {
          this.vehicleNumberList = res.data;
        }
      });
    },
    // 获取客户选项
    getCustomerOption(name) {
      queryCustomerName(name).then(res => {
        if (res.status === 'OK') {
          this.customerOption = res.data;
        }
      });
    },
    // 揽收员
    getCollectorOption(name) {
      query10User(name).then(res => {
        if (res.status === 'OK') {
          this.loadUserList = res.data;
        }
      });
    },
    // 获取所有网点
    getSiteOption(name) {
      query10Site(name).then(res => {
        if (res.status === 'OK') {
          this.siteAllList = res.data;
        }
      });
    },
    // 州
    getState(name) {
      queryState(name).then((res) => {
        if (res.status === 'OK') {
          this.stateList = res.data;
        }
      });
    },

    ft() {
      const val = this.queryForm.params.ordeNumberList.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.ordeNumberList = bghTrim.join('\n');
    },
    BlockNewNumberline() { // 文本域最大输入行数为2000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.ordeNumberList.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 2000) {
          this.$message.warning(this.$t('collectionCenter.searchMax1000')); // '单次查询最多2000条，已截取前2000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];
    },
    default() {
      const d = new Date();
      const start = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 00:00:00');
      const end = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 23:59:59');
      this.timeValue = [start, end];
      this.queryForm.params = {
        startTime: start,
        endTime: end,
        customerId: null,
        loadUserId: null,
        loadSiteId: null,
        siteState: null,
        loadTagNumber: null,
        loadVehicleId: null,
        ordeNumberList: ''
      };
    },
    resetClick() {
      this.default();
    },
    searchClick() {
      this.exportList = [];
      this.queryForm.pageNumber = 1;
      const form = JSON.parse(JSON.stringify(this.queryForm));
      form.params.ordeNumberList = this.queryForm.params.ordeNumberList ? form.params.ordeNumberList.split('\n') : [];
      localStorage.setItem('clrQueryForm', JSON.stringify(form));
      this.getPageSize();
    },
    getPageNumber() {
      const queryForm = JSON.parse(localStorage.getItem('clrQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectBulkyLoadPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('clrQueryForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      collectBulkyLoadPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    downloadAll() {
      const queryForm = JSON.parse(localStorage.getItem('clrQueryForm')) || this.queryForm;
      collectBulkyLoadExportAll(queryForm.params).then(res => {
        const h = this.$createElement;
        this.$msgbox({
          // 提示
          title: this.$t('collectionCenter.tips'),
          message: h('p', null, [
            // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
            h('span', this.$t('collectionCenter.exportChangeTip')),
            // 请勿重复导出!
            h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
          ]),
          showCancelButton: true,
          // 立即前往
          confirmButtonText: this.$t('collectionCenter.Immediately'),
          // 关闭
          cancelButtonText: this.$t('collectionCenter.close')
        }).then(action => {
          if (action === 'confirm') {
            this.$router.push({ path: '/views/resource/exportTaskList' });
          }
        }).catch(() => {});
      }).catch(err => {
        console.log(err);
      });
    },
    batchSelect(selection, row) {
      this.exportList = selection;
    },
    batchSelectAll(selection) {
      this.exportList = selection;
    },
    download() {
      if (this.exportList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('changepwd.collectLoadTime'), // '揽收装车时间'
            key: 'siteLoadTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.CustomerName'), // '客户名称'
            key: 'customerName',
            width: 20
          },
          {
            header: that.$t('orderCenterCont.anjunWaybillNumber'), // '安骏运单号'
            key: 'orderNumber',
            width: 25
          },
          {
            header: that.$t('GlobalSetObj.ScanNumber'), // '扫描单号'
            key: 'scanNumber',
            width: 25
          },
          {
            header: that.$t('collectionCenter.collector'), // '揽收员'
            key: 'siteLoadUserName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.belongD'), // '所属网点'
            key: 'siteName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.stateSite1'), // '网点所属州'
            key: 'siteState',
            width: 15
          },
          {
            header: that.$t('collectionCenter.carNumber'), // '车签号'
            key: 'siteLoadTagNumber',
            width: 15
          },
          {
            header: that.$t('collectionCenter.licensePlateNumber'), // '车牌号'
            key: 'siteLoadVehicleNumber',
            width: 15
          },
          {
            header: that.$t('collectionCenter.driver1'), // '司机'
            key: 'vehicleDriver',
            width: 20
          }
        ];

        Sheet1_data = [];
        that.exportList.map((item) => {
          Sheet1_data.push({
            siteLoadTime: item.siteLoadTime,
            customerName: item.customerName,
            orderNumber: item.orderNumber,
            scanNumber: item.scanNumber,
            siteLoadUserName: item.siteLoadUserName,
            siteName: item.siteName,
            siteState: item.siteState,
            siteLoadTagNumber: item.siteLoadTagNumber,
            siteLoadVehicleNumber: item.siteLoadVehicleNumber,
            vehicleDriver: item.vehicleDriver
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const date = new Date().getTime();
        const saveName = that.$t('collectionCenter.collectingLoadingRecords') + date + '.xlsx'; // '揽收装车记录.xlsx'
        saveAs(blob, saveName);
        return;
      }
      ddd();
      setTimeout(() => {
        this.$refs.tableRef.clearSelection();
        this.exportList = [];
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.selectClass{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__content{
  width: 100%;
  margin-left: 0 !important;
}
.selectClass ::v-deep .el-select{
  width: 100%;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 120px !important;
}
.selectClass .nameTop ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.selectClass .nameTop ::v-deep .el-form-item__label{
  margin-top: 10px;
}
</style>

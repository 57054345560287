import request from '@/utils/request';

const baseURLAdmin = process.env.VUE_APP_ADMIN;
/**
 * 获取消息模板列表
 */
export const getMessageTemplateList = (data) => {
  return request.post('/user/messageTemplate/page', data);
};
/**
 * 添加消息模板
 */
export const createdTemplate = (data) => {
  return request.post('/user/messageTemplate/add', data);
};
/**
 * 编辑修改模板消息
 */
export const changeTemplate = (data) => {
  return request.post('/user/messageTemplate/edit', data);
};
/**
 * 删除模板
 */
export const deleteTemplate = (data) => {
  return request.post('/user/messageTemplate/delete?id=' + data);
};
/**
 * 消息发送
 */
export const sendMsg = (data) => {
  return request.post('/message/sendMessage', data);
};
/**
 * 查询所有消息
 */
export const getMsgAll = (data) => {
  return request.post('/message/page', data);
};
/**
 * 获取未读消息
 */
export const getNoReadMsg = (data) => {
  return request({
    url: '/message/getCurrentUserMessage',
    method: 'post',
    data,
    isLoading: false
  });
};
/**
 * 设置已读
 */
export const setMsgRead = (data, reId) => {
  return request.post('/message/setRead?msgId=' + data + '&receiveId=' + reId);
};
/**
 * 新增消息设置
 */
export const addMsgParam = (data) => {
  return request.post('/user/sysConfig/add', data);
};
/**
 * 获取消息设置列表
 */
export const getMsgSetList = (data) => {
  return request.post('/user/sysConfig/page', data);
};
/**
 * 消息设置修改
 */
export const changeMsgSet = (data) => {
  return request.post('/user/sysConfig/edit', data);
};
/**
 * 消息系统参数删除
 */
export const deleteMsgSet = (data) => {
  return request.post('/user/sysConfig/delete?id=' + data);
};
/**
 * 消息个人参数列表
 */
export const getUserMsgSetList = (data) => {
  return request.post('/user/uConfig/page', data);
};
/**
 * 消息新增个人设置
 */
export const addUserMsgSet = (data) => {
  return request.post('/user/uConfig/add', data);
};
/**
 * 消息修改个人设置
 */
export const changeUserMsgSet = (data) => {
  return request.post('/user/uConfig/edit', data);
};
/**
 * 消息删除个人设置
 */
export const deleteUserMsgSet = (data) => {
  return request.post('/user/uConfig/delete?id=' + data);
};
/**
 * 获取组织结构层级
 */
export const getOrganizationHierarchy = (data) => {
  return request.get('/user/organization/maxLevel', data);
};
/**
 * 获取组织机构用户
 */
export const getOrganizationUser = (data) => {
  return request.get('/user/user/getOrganizationWithUser', data);
};
/**
 * 获取我接收的消息
 */
export const getMyReceiveMsg = (data) => {
  return request.post('/user/messageUser/page', data);
};
/**
 * 获取消息已读、未读人员
 */
export const getMsgIsReadUser = (data) => {
  return request.get('/user/message/messageReceiveList?msgId=' + data);
};
// 系统消息 ==> 设置已读
export const readMessageUser = (data) => {
  // return request.post(`/message/setReadMessage`, params);
  return request({
    url: '/message/setReadMessage',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 查询消息
export const selectMessageUser = (data) => {
  return request({
    url: '/message/receiveMessage',
    method: 'post',
    data,
    baseURL: baseURLAdmin,
    isLoading: false
  });
};

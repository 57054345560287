<template>
  <div class="app-container">
    <!--  表格数据  -->
    <el-table
      :cell-style="{textAlign:'center'}"
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black',textAlign:'center'}"
      border
      style="width: 100%"
    >
      <el-table-column :label="$t('GlobalSetObj.serial')" type="index" width="70" />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'requestParams'">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copyFun(scope.row.requestParams)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.requestParams }}</span>
            </el-popover>
          </div>
          <div v-else-if="item.prop === 'responseParams'">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copyFun(scope.row.responseParams)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.responseParams }}</span>
            </el-popover>
          </div>
          <div v-else-if="item.prop === 'requestAddress'">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copyFun(scope.row.requestAddress)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.requestAddress }}</span>
            </el-popover>
          </div>
          <div v-else-if="item.prop === 'deliveredPhoto' || item.prop === 'deliveredNamePhoto'">
            <div v-if="scope.row[item.prop] === '--'">
              {{ scope.row[item.prop] }}
            </div>
            <div
              v-else-if="scope.row.pushStatus === true"
              class="verifier"
              @click="imageSee(scope.row[item.prop + 'List'])"
            >{{ $t('home.clickSee') }}</div>
            <div v-else style="color: #f00;">{{ $t('GlobalSetObj.Falha') }}</div>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="paramsData.pageNumber"
      :page-size="paramsData.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="paramsData.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 图片弹框   -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('navbar.seeImage')"
      :visible.sync="dialogVisible"
      top="3vh"
      width="540px"
    >
      <div>
        <div v-if="imageList.length > 0" style="width: 500px">
          <div style="text-align: center; color: #409eff; font-size: 18px">
            <span>{{ currentIndex + 1 }}</span>
            <span>/</span>
            <span>{{ imageList.length }}</span>
          </div>
          <el-carousel
            ref="carousel" height="750px" trigger="click"
            @change="handlecarousel"
          >
            <el-carousel-item v-for="(item, index) in imageList" :key="index">
              <div style="margin-left: 10px">
                <el-image :src="item" fit="fill" style="width: 100%; height: 700px" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-else style="width: 500px; text-align: center; height: 750px">
          <el-image style="width: 100%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" style="font-size: 30px" />
            </div>
          </el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { trackPushLogPage } from '@/api/orderApi';
import { copyData } from '@/utils/copyData';

export default {
  name: 'MultipleSignRecords',
  props: {
    paramsData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          inputValue: '',
          selectType: 1,
          operateUserId: ''
        }
      },
      tableData: [],
      tableHeaderList: [
        // 运单号
        { id: 1, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '180' },
        // 扫描单号
        { id: 2, label: this.$t('GlobalSetObj.ScanNumber'), prop: 'scanOrderNumber', cloWidth: '180' },
        // 客户名称
        { id: 3, label: this.$t('orderCenterCont.CustomerName'), prop: 'platformCode', cloWidth: '170' },
        // 签收照片
        { id: 4, label: this.$t('GlobalSetObj.signedNamePhoto'), prop: 'deliveredPhoto', cloWidth: '170' },
        // 签收签名
        { id: 5, label: this.$t('GlobalSetObj.signedPhoto'), prop: 'deliveredNamePhoto', cloWidth: '170' },
        // 最新推送时间
        { id: 6, label: this.$t('orderCenterCont.newPushTime'), prop: 'updateTime', cloWidth: '170' },
        // 推送节点
        { id: 7, label: this.$t('orderCenterCont.PushNodes'), prop: 'trackNode', cloWidth: '170' },
        // 轨迹内容
        { id: 8, label: this.$t('orderCenterCont.trajectoryContent'), prop: 'trackContext', cloWidth: '180' },
        // 接收方URL
        { id: 9, label: this.$t('orderCenterCont.receiverURL'), prop: 'requestAddress', cloWidth: '180' },
        // 请求报文
        { id: 10, label: this.$t('orderCenterCont.RequestMessages'), prop: 'requestParams', cloWidth: '180' },
        // 响应报文
        { id: 11, label: this.$t('orderCenterCont.ResponseMessages'), prop: 'responseParams', cloWidth: '180' },
        // 推送状态
        { id: 12, label: this.$t('GlobalSetObj.pushStatus'), prop: 'pushStatusStr', cloWidth: '180' }
      ],
      orderWaybillType: 0,
      userList: [],
      dialogVisible: false,
      imageList: [],
      currentIndex: 0
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    handlecarousel(val) {
      this.currentIndex = val;
    },
    // 点击查看 图片
    imageSee(data) {
      console.log(data, 'imageSee');
      this.imageList = data;
      this.dialogVisible = true;
    },
    // 查询
    getSearch() {
      this.$nextTick(() => {
        trackPushLogPage(this.paramsData).then(res => {
          if (res.code === 200) {
            this.paramsData.total = res.data.total;
            this.tableData = res.data.records;
            this.tableData.forEach(item => {
              if (item.deliveredPhoto !== '--') {
                item.deliveredPhotoList = item.deliveredPhoto.split(',');
              }
              if (item.deliveredNamePhoto !== '--') {
                item.deliveredNamePhotoList = item.deliveredNamePhoto.split(',');
              }
              if (item.pushStatus === true) {
                item.pushStatusStr = this.$t('GlobalSetObj.succeed');
              } else {
                item.pushStatusStr = this.$t('GlobalSetObj.Lose');
              }
            });
          }
        });
      });
    },
    // 点击复制
    copyFun(data) {
      copyData(data);
    },
    // 点击分页
    handleSizeChange(val) {
      this.paramsData.pageSize = val;
      this.paramsData.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.paramsData.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

.omit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.verifier {
  color: #409eff;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
}
</style>

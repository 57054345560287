import Vue from 'vue';
import Router from 'vue-router';
/* Layout */
import Layout from '@/layout';
import layoutThree from '@/layoutThree';

Vue.use(Router);

/**
 * Note: 子菜单仅在路线时出现 children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   如果设置为true，则项目不会显示在边栏中（默认为false）
 * alwaysShow: true               如果设置为true，将始终显示根菜单
 *                                如果未设置alwaysShow，则当项具有多个子路线时，
 *                                它将变为嵌套模式，否则不显示根菜单
 * redirect: noRedirect           如果设置noRedirect，则不会在面包屑中重定向
 * name:'router-name'             该名称由 <keep-alive> (must set!!!)
 * meta : {
 roles: ['admin','editor']    控制页面角色 (您可以设置多个角色)
 title: 'title'               名称显示在侧边栏和面包屑中 (推荐套)
 icon: 'svg-name'/'el-icon-x' 侧栏中的图标显示
 breadcrumb: false            如果设置为false，则该项将隐藏在面包屑中（默认为true）
 activeMenu: '/example/list'  如果设置了路径，则侧边栏将突出显示您设置的路径
 fillterRouter: true,         用来判断过滤菜单判断， 为true就过滤多层菜单栏的一级，只有单层就不会过滤
 }
 */

/**
 * 注意事项
 *
 *
 * 每添加一个页面，都要在每个页面添加一个权限按钮
 *
 */

/**
 * constantRoutes
 * 没有权限要求的基本页面
 * 所有角色都可以访问
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/linkFailure', // 链接不存在
    component: () => import('@/views/LinkFailure'),
    hidden: true
  },

  {
    path: '/lock',
    component: () => import('@/views/lock'),
    hidden: true
  },

  {
    // 外链上传账单
    path: '/finance/payableManagement/UploadContas',
    component: () => import('@/views/finance/payableManage/payableCollection/UploadContas.vue'),
    hidden: true
  }
];

/**
 * asyncRoutes
 * 需要根据encodeURI动态加载的路由
 */
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: {
          // title: '首页',
          title: 'dashboard',
          icon: 'shouye',
          breadcrumb: false,
          roles: ['dashboard']
        }
      }
    ]
  },
  // 揽收中心
  {
    path: '/collectionCenter',
    component: Layout,
    redirect: '/collectionCenter',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      // title: '揽收中心',
      title: 'collectionCenter',
      icon: 'collect',
      roles: ['admin', 'menu:collection-center']
    },
    children: [
      {
        path: 'CollectKanban',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true, // 用来判断过滤菜单判断， 为true就过滤
        meta: {
          // title: '揽收看板',
          title: 'CollectKanban',
          roles: ['admin', 'menu:collection-center:collect-kanban']
        },
        children: [
          {
            path: 'daily',
            name: 'Daily',
            component: () => import('@/views/collectionCenter/CollectKanban/daily'),
            meta: {
              // title: '揽收日报',
              title: 'daily',
              roles: ['admin', 'menu:collection-center:collect-kanban:daily']
            }
          },
          {
            path: 'collectOperationalData',
            name: 'CollectOperationalData',
            component: () =>
              import('@/views/collectionCenter/CollectKanban/collectOperationalData'),
            meta: {
              // title: '揽收营运数据',
              title: 'collectOperationalData',
              roles: ['admin', 'menu:collection-center:collect-kanban:collectOperationalData']
            }
          },
          {
            path: 'mdHoldupTable',
            name: 'mdHoldupTable',
            component: () => import('@/views/collectionCenter/CollectKanban/mdHoldupTable'),
            meta: {
              // title: 'md滞留表',
              title: 'mdHoldupTable',
              roles: ['admin', 'menu:collection-center:collect-kanban:mdHoldupTable']
            }
          }
        ]
      },
      {
        path: 'order',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '订单'
          title: 'order',
          roles: ['admin', 'menu:collection-center:order']
        },
        children: [
          {
            path: 'lanShouOrders',
            name: 'LanShouOrders',
            component: () => import('@/views/collectionCenter/Order/lanShouOrders'),
            meta: {
              // title: '揽收订单'
              title: 'lanshouOrder',
              roles: ['admin', 'menu:collection-center:lanshouOrder']
            }
          },
          {
            // 揽收码查询
            path: 'receivingCodeQuery',
            name: 'receivingCodeQuery',
            component: () =>
              import('@/views/collectionCenter/Order/collectOrder/receivingCodeQuery/index'),
            meta: {
              // title: '揽收码查询'
              title: 'receivingCodeQuery',
              roles: ['admin', 'menu:collection-center:lanshou-order:receiving-code-query']
            }
          },
          {
            path: 'warehouseToWarehouse',
            name: 'warehouseToWarehouse',
            component: () => import('@/views/collectionCenter/Order/warehouseToWarehouse'),
            // hidden: true,
            meta: {
              // title: '仓对仓揽收订单',
              title: 'warehouseToWarehouse',
              roles: ['admin', 'menu:collection-center:warehouseToWarehouse']
            }
          },
          {
            path: 'pathToPush',
            name: 'PathToPush',
            component: () => import('@/views/collectionCenter/Order/pathToPush'),
            meta: {
              // title: '轨迹推送',
              title: 'PushTrack',
              roles: ['admin', 'menu:collection-center:pathToPush']
            }
          },
          {
            path: 'trackQuery',
            name: 'TrackQuery',
            component: () => import('@/views/collectionCenter/Order/trackQuery'),
            // hidden: true,
            meta: {
              // title: '轨迹查询'
              title: 'trackQuery',
              roles: ['admin', 'menu:collection-center:trackQuery']
            }
          },
          {
            path: 'receiveDataProcessing',
            name: 'ReceiveDataProcessing',
            component: () => import('@/views/collectionCenter/Order/receiveDataProcessing'),
            // hidden: true,
            meta: {
              // title: '揽收数据处理',
              title: 'receiveDataProcessing',
              roles: ['admin', 'menu:collection-center:receiveDataProcessing']
            }
          },
          {
            path: 'stockUpReceiveOrders',
            name: 'StockUpReceiveOrders',
            component: () => import('@/views/collectionCenter/Order/stockUpReceiveOrders'),
            meta: {
              // title: '备货揽收订单',
              title: 'stockUpReceiveOrders',
              roles: ['admin', 'menu:collection-center:stockUpReceiveOrders']
            }
          },
          {
            path: 'noScheduledPickUpOrders',
            name: 'noScheduledPickUpOrders',
            component: () => import('@/views/collectionCenter/Order/noScheduledPickUpOrders'),
            meta: {
              // title: '无预报揽收订单',
              title: 'noScheduledPickUpOrders',
              roles: ['admin', 'menu:collection-center:noScheduledPickUpOrders']
            }
          },
          {
            path: 'collectorInquire',
            name: 'collectorInquire',
            component: () => import('@/views/collectionCenter/Order/collectorInquire'),
            meta: {
              // title: '揽收商家查询',
              title: 'collectorInquire',
              roles: ['admin', 'menu:collection-center:collectorInquire']
            }
          },
          {
            path: 'orderLogQuery',
            name: 'OrderLogQuery',
            component: () => import('@/views/collectionCenter/Order/orderLogQuery'),
            meta: {
              // title: '下单日志查询',
              title: 'orderLogQuery',
              roles: ['admin', 'menu:collection-center:Order:orderLogQuery']
            }
          }
        ]
      },
      {
        path: 'handle',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '操作'
          title: 'operationCZ',
          roles: ['admin', 'menu:collection-center:operationCZ']
        },
        children: [
          // {
          //   path: 'SingleScan',
          //   name: 'SingleScan',
          //   component: () => import('@/views/collectionCenter/Operation/SingleScan'),
          //   meta: {
          //     title: 'singleScan',
          //     roles: ['admin', 'menu:collection-center:singleScan']
          //   }
          // },
          {
            path: 'largeParcelCollection',
            name: 'largeParcelCollection',
            component: () => import('@/views/collectionCenter/Operation/largeParcelCollection'),
            meta: {
              // 大包裹揽收
              title: 'largeParcelCollection',
              roles: ['admin', 'menu:collection-center:largeParcelCollection']
            }
          },
          {
            path: 'readyReceiveScanning',
            name: 'ReadyReceiveScanning',
            component: () => import('@/views/collectionCenter/Operation/ReadyReceiveScanning'),
            meta: {
              // 备货揽收扫描
              title: 'ReadyReceiveScanning',
              roles: ['admin', 'menu:collection-center:ReadyReceiveScanning']
            }
          },
          // 扫描封袋
          {
            path: 'bagScanning',
            name: 'bagScanning',
            component: () => import('@/views/collectionCenter/Operation/bagScanning'),
            meta: {
              // 封袋
              title: 'bagScanning',
              roles: ['admin', 'menu:collection-center:bagScanning']
            }
          },
          // {
          //   path: 'ReplaceBag',
          //   name: 'ReplaceBag',
          //   component: () => import('@/views/collectionCenter/Operation/replaceBag'),
          //   meta: {
          //     title: 'replaceBag',
          //     roles: ['admin', 'menu:collection-center:replaceBag']
          //   }
          // },
          // 新换袋
          {
            path: 'newReplaceBag',
            name: 'newReplaceBag',
            component: () => import('@/views/collectionCenter/Operation/newReplaceBag'),
            meta: {
              title: 'newReplaceBag',
              roles: ['admin', 'menu:collection-center:newReplaceBag']
            }
          },
          {
            path: 'bagNumQuery',
            name: 'bagNumQuery',
            component: () => import('@/views/collectionCenter/Operation/bagNumQuery'),
            meta: {
              // title: '袋号查询',
              title: 'bagNumQuery',
              roles: ['admin', 'menu:collection-center:bagNumQuery']
            }
          },
          {
            path: 'onceScan',
            name: 'onceScan',
            component: () => import('@/views/collectionCenter/Operation/singScanDomesticVersion'),
            meta: {
              // 国内版——单次扫描
              title: 'singleScanZH',
              roles: ['admin', 'menu:collection-center:onceScan']
            }
          },
          {
            path: 'ScanCourier',
            name: 'ScanCourier',
            component: () => import('@/views/collectionCenter/Operation/ScanCourier'),
            meta: {
              // title: '客户上门交件'
              title: 'customerHandDoor',
              roles: ['admin', 'menu:collection-center:ScanCourier']
            }
          },
          {
            path: 'receiveSign',
            name: 'receiveSign',
            component: () => import('@/views/collectionCenter/Operation/receiveSign'),
            meta: {
              // title: '揽收签收',
              title: 'receiveSign',
              roles: ['admin', 'menu:collection-center:receiveSign']
            }
          },
          {
            path: 'loadingConfirm',
            name: 'LoadingConfirm',
            component: () => import('@/views/collectionCenter/Operation/Confirm/loadingConfirm'),
            meta: {
              // title: '装车确认',
              title: 'loadingConfirm',
              roles: ['admin', 'menu:collection-center:loadingConfirm']
            }
          },
          {
            path: 'transferCenterVehicleConfirm',
            name: 'TransferCenterVehicleConfirm',
            component: () =>
              import(
                '@/views/collectionCenter/Operation/transferCenterConfirm/transferCenterVehicleConfirm'
              ),
            meta: {
              // title: '转运中心车辆确认',
              title: 'transferCenterVehicleConfirm',
              roles: ['admin', 'menu:collection-center:transferCenterVehicleConfirm']
            }
          },
          {
            path: 'receiveInfoVerifyQuery',
            name: 'ReceiveInfoVerifyQuery',
            component: () =>
              import('@/views/collectionCenter/Operation/Confirm/receiveInfoVerifyQuery'),
            meta: {
              // title: '揽收信息确认查询',
              title: 'receiveInfoVerifyQuery',
              roles: ['admin', 'menu:collection-center:receiveInfoVerifyQuery']
            }
          },
          {
            path: 'transferCenterVehicleConfirmQuery',
            name: 'TransferCenterVehicleConfirmQuery',
            component: () =>
              import(
                '@/views/collectionCenter/Operation/transferCenterConfirm/transferCenterVehicleConfirmQuery'
              ),
            meta: {
              // title: '转运中心车辆确认查询',
              title: 'transferCenterVehicleConfirmQuery',
              roles: ['admin', 'menu:collection-center:transferCenterVehicleConfirmQuery']
            }
          },
          {
            path: 'receiveScan',
            name: 'receiveScan',
            component: () => import('@/views/collectionCenter/Operation/receiveScan'),
            meta: {
              // title: '非预报揽收',
              title: 'receiveScan',
              roles: ['admin', 'menu:collection-center:receiveScan']
            }
          },
          {
            path: 'loadingScan',
            name: 'LoadingScan',
            component: () => import('@/views/collectionCenter/Operation/carScan/loadingScan'),
            meta: {
              // title: '装车扫描',
              title: 'loadingScan',
              roles: ['admin', 'menu:collection-center:loadingScan']
            }
          },
          {
            path: 'unloadingScan',
            name: 'UnloadingScan',
            component: () => import('@/views/collectionCenter/Operation/carScan/unloadingScan'),
            meta: {
              // title: '卸车扫描',
              title: 'unloadingScan',
              roles: ['admin', 'menu:collection-center:unloadingScan']
            }
          },
          {
            path: 'packetScan',
            name: 'packetScan',
            component: () => import('@/views/collectionCenter/Operation/packetScan'),
            meta: {
              // title: '稽包扫描',
              title: 'packetScan',
              roles: ['admin', 'menu:collection-center:packetScan']
            }
          }
        ]
      },
      {
        path: 'monitorStatisticsStatement',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          title: 'monitorStatisticsStatement',
          // title: '监控/统计报表'
          roles: ['admin', 'menu:collection-center:monitorStatisticsStatement']
        },
        children: [
          {
            path: 'collectOrder',
            name: 'collectOrder',
            component: () => import('@/views/collectionCenter/Report/collectOrder'),
            // hidden: true,
            meta: {
              // title: '揽收订单报表',
              title: 'collectOrder',
              roles: ['admin', 'menu:collection-center:collectOrder']
            }
          },
          {
            path: 'deliveryData',
            name: 'DeliveryData',
            component: () => import('@/views/collectionCenter/Report/deliveryData/index'),
            meta: {
              // title: '揽收送仓数据监控',
              title: 'deliveryData',
              roles: ['admin', 'menu:collection-center:deliveryData']
            }
          },
          {
            path: 'collectTimeReport',
            name: 'collectTimeReport',
            component: () => import('@/views/collectionCenter/Report/collectTimeReport'),
            // hidden: true,
            meta: {
              // title: '揽收时效报表',
              title: 'collectTimeReport',
              roles: ['admin', 'menu:collection-center:collectTimeReport']
            }
          },
          {
            path: 'collectNodeErrDataReport',
            name: 'CollectNodeErrDataReport',
            component: () => import('@/views/collectionCenter/Report/collectNodeErrDataReport'),
            // hidden: true,
            meta: {
              // title: '揽收节点异常数据监控报表',
              title: 'collectNodeErrDataReport',
              roles: ['admin', 'menu:collection-center:collectNodeErrDataReport']
            }
          },
          {
            path: 'KPIMonitoringReports',
            name: 'KPIMonitoringReports',
            component: () => import('@/views/collectionCenter/Report/KPI/KPIMonitoringReports'),
            meta: {
              // title: '揽收KPI监控报表',
              title: 'KPIMonitoringReports',
              roles: ['admin', 'menu:collection-center:KPI-monitoring-reports']
            }
          },
          {
            path: 'MonitoringReport',
            name: 'MonitoringReport',
            component: () => import('@/views/collectionCenter/Report/MonitoringReport'),
            meta: {
              // title: '订单揽收监控报表'
              title: 'MonitoringReport',
              roles: ['admin', 'menu:collection-center:MonitoringReport']
            }
          },
          {
            path: 'TimeLimitForSigning',
            name: 'TimeLimitForSigning',
            component: () => import('@/views/collectionCenter/Report/TimeLimitForSigning/index'),
            // hidden: true,
            meta: {
              // title: '签收时效报表统计',
              title: 'TimeLimitForSigning',
              roles: ['admin', 'menu:collection-center:TimeLimitForSigning']
            }
          },
          {
            path: 'receiveDailyReport',
            name: 'ReceiveDailyReport',
            component: () => import('@/views/collectionCenter/Report/receiveDailyReport/index'),
            // hidden: true,
            meta: {
              // title: '网点揽收日报表',
              title: 'DotReceiveDailyReport',
              roles: ['admin', 'menu:collection-center:receiveDailyReport']
            }
          },
          {
            path: 'SignForTheQuery',
            name: 'SignForTheQuery',
            component: () => import('@/views/collectionCenter/Report/SignForTheQuery'),
            meta: {
              // title: '签收查询'
              title: 'signForQuery',
              roles: ['admin', 'menu:collection-center:signForQuery']
            }
          },
          {
            path: 'SMSManagement',
            name: 'SMSManagement',
            component: () => import('@/views/collectionCenter/Report/SMSManagement'),
            // hidden: true,
            meta: {
              // title: '短信管理',
              title: 'SMSManagement',
              roles: ['admin', 'menu:collection-center:SMSManagement']
            }
          },
          {
            path: 'carMonitoring',
            name: 'CarMonitoring',
            component: () => import('@/views/collectionCenter/Report/carMonitoring'),
            // hidden: true,
            meta: {
              // title: '装车卸车数据监控',
              title: 'carMonitoring',
              roles: ['admin', 'menu:collection-center:carMonitoring']
            }
          },
          {
            path: 'vehicleUsageStatistics',
            name: 'vehicleUsageStatistics',
            component: () => import('@/views/collectionCenter/Report/vehicleUsageStatistics/index'),
            // hidden: true,
            meta: {
              // title: '车辆使用统计',
              title: 'vehicleUsageStatistics',
              roles: ['admin', 'menu:collection-center:vehicleUsageStatistics']
            }
          }
        ]
      },
      {
        path: 'basicDate',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          title: 'basicDate',
          // title: '基础资料'
          roles: ['admin', 'menu:collection-center:basicDate']
        },
        children: [
          {
            path: 'collectionTimeConfiguration',
            name: 'CollectionTimeConfiguration',
            component: () =>
              import('@/views/collectionCenter/BasicData/collectionTimeConfiguration'),
            meta: {
              // title: '揽收时效配置'
              title: 'collectionTimeConfiguration',
              roles: ['admin', 'menu:collection-center:collectionTimeConfiguration']
            }
          },
          {
            path: 'priceTable',
            name: 'priceTable',
            component: () => import('@/views/collectionCenter/tablePrice'),
            meta: {
              title: 'tablePrice',
              roles: ['admin', 'menu:collection-center:tablePrice']
            }
          },
          {
            path: 'MerchantManagement',
            name: 'MerchantManagement',
            component: () => import('@/views/collectionCenter/BasicData/MerchantManagement'),
            meta: {
              // title: '商户管理',
              title: 'MerchantManagement',
              roles: ['admin', 'menu:collection-center:MerchantManagement']
            }
          },
          {
            path: 'zipZone',
            name: 'ZipZone',
            component: () => import('@/views/collectionCenter/BasicData/zipZone'),
            meta: {
              // title: '邮编管理'
              title: 'zipZoneManagement',
              roles: ['admin', 'menu:collection-center:zipZone']
            }
          }
        ]
      },
      {
        path: 'finance',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          title: 'finance',
          // title: '财务',
          roles: ['admin', 'menu:collection-center:finance']
        },
        children: [
          {
            path: 'bill',
            name: 'Bill',
            component: () => import('@/views/collectionCenter/Finance/bill'),
            meta: {
              // title: '账单推送'
              title: 'bill',
              roles: ['admin', 'menu:collection-center:bill']
            }
          },
          {
            path: 'quotationMaintenance',
            name: 'QuotationMaintenance',
            component: () => import('@/views/collectionCenter/Finance/quotationMaintenance'),
            meta: {
              // title: '报价维护',
              title: 'quotationMaintenance',
              roles: ['admin', 'menu:collection-center:quotationMaintenance']
            }
          },
          {
            path: 'mailingArea',
            name: 'MailingArea',
            component: () => import('@/views/collectionCenter/Finance/mailingArea'),
            meta: {
              // title: '寄件区域',
              title: 'mailingArea',
              roles: ['admin', 'menu:collection-center:mailingArea']
            }
          }
        ]
      }
    ]
  },
  // 快递中心
  {
    path: '/expressDataCenter',
    component: Layout,
    redirect: '/expressDataCenter',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      // title: '快递中心',
      title: 'expressDataCenter',
      icon: 'dingdan',
      roles: ['admin', 'menu:order-center']
    },
    children: [
      {
        path: 'OrderCenter',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '快递订单',
          title: 'OrderCenter',
          roles: ['admin', 'menu:order-center']
        },
        children: [
          {
            path: 'logisticsOrders',
            name: 'LogisticsOrder',
            component: () => import('@/views/orderCenter/logisticsOrders/index'),
            meta: {
              // 订单查询
              title: 'orderSearch',
              roles: ['admin', 'menu:order-center:logisticsOrders']
            }
          },
          {
            path: 'serviceOroviderOrder',
            name: 'serviceOroviderOrder',
            component: () => import('@/views/orderCenter/serviceOroviderOrder/index'),
            meta: {
              // 服务商订单
              title: 'serviceOroviderOrder',
              roles: ['admin', 'menu:order-center:serviceOroviderOrder']
            }
          },
          {
            path: 'addressBaseManagement',
            name: 'addressBaseManagement',
            component: () => import('@/views/orderCenter/addressBaseManagement/index'),
            meta: {
              // 地址库管理
              title: 'addressBaseManagement',
              roles: ['admin', 'menu:order-center:addressBaseManagement']
            }
          },
          // 多次签收订单
          {
            path: 'multipleSignRecords',
            name: 'multipleSignRecords',
            component: () => import('@/views/orderCenter/multipleSignRecords/index'),
            meta: {
              title: 'signMultipleOrders',
              roles: ['admin', 'menu:orderCenter:multipleSignRecords']
            }
          },
          {
            path: 'placeOrder',
            name: 'Order',
            component: () => import('@/views/orderCenter/orderList/placeOrder'),
            meta: {
              // title: '快递下单'
              title: 'placeOrder',
              roles: ['admin', 'menu:order-center:expressOrderPlacement']
            }
          },
          {
            path: 'orderLogs',
            name: 'OrderLogs',
            component: () => import('@/views/orderCenter/orderLogs/index'),
            meta: {
              // 下单日志
              title: 'OrderLogs',
              roles: ['admin', 'menu:order-center:orderLogs']
            }
          }
        ]
      },
      {
        path: 'dataReport',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '数据报表',
          title: 'ReportManagement',
          roles: ['admin', 'menu:finance:ReportManagement']
        },
        children: [
          // 运单时效监控
          {
            path: 'WaybillTimelinessStatistics',
            name: 'WaybillTimelinessStatistics',
            component: () => import('@/views/ReportManagement/waybillTimelinessStatistics/index'),
            meta: {
              title: 'WaybillTimeliness',
              roles: ['admin', 'menu:ReportManagement:WaybillTimelinessStatistics']
            }
          },
          {
            path: 'outGoRecord',
            component: () => import('@/views/automaticSortings/outGoRecord/index'),
            name: 'OutGoRecord',
            meta: {
              // title: '分拨扫描记录表',
              title: 'outGoRecord',
              roles: ['admin', 'menu:ReportManagement:outGoRecord']
            }
          },
          // 每日派送数据监控
          {
            path: 'DailyDeliveryData',
            name: 'DailyDeliveryData',
            component: () => import('@/views/ReportManagement/SHEINDailyDeliveryData/index'),
            meta: {
              title: 'SHEINDailyDeliveryDataMonitoring', // 每日派送数据监控
              roles: ['admin', 'menu:ReportManagement:SHEINDailyDeliveryData']
            }
          },
          {
            path: 'singleVolumeDailyReport',
            name: 'SingleVolumeDailyReport',
            component: () => import('@/views/ReportManagement/singleVolumeDailyReport/index'),
            meta: {
              title: 'DailyReportSingleVolumeBusinessModules', // 业务模块单量日报
              roles: ['admin', 'menu:ReportManagement:singleVolumeDailyReport']
            }
          },
          // 末端揽收数据监控
          {
            path: 'endCollectionMonitoring',
            name: 'endCollectionMonitoring',
            component: () => import('@/views/ReportManagement/endCollectionMonitoring/index'),
            meta: {
              title: 'endCollectionMonitoring',
              roles: ['admin', 'menu:ReportManagement:endCollectionMonitoring']
            }
          },
          // 派送装车监控
          {
            path: 'DeliveryLoading',
            name: 'DeliveryLoading',
            component: () => import('@/views/ReportManagement/DeliveryLoading/index'),
            meta: {
              title: 'DeliveryLoading', // 派送装车监控
              roles: ['admin', 'menu:ReportManagement:DeliveryLoading']
            }
          },
          {
            path: 'misallocationRate',
            component: () => import('@/views/ReportManagement/misallocationRate/index'),
            name: 'MisallocationRate',
            meta: {
              // title: '分拨错发率',
              title: 'misallocationRateName',
              roles: ['admin', 'menu:ReportManagement:misallocationRate']
            }
          },
          // 静态时效监控
          {
            path: 'staticAgingMonitoring',
            name: 'staticAgingMonitoring',
            component: () => import('@/views/ReportManagement/staticAgingMonitoring/index'),
            meta: {
              title: 'staticAgingMonitoring',
              roles: ['admin', 'menu:ReportManagement:staticAgingMonitoring']
            }
          },
          // 网点时效统计
          {
            path: 'franchiseeTimelinessStatistics',
            name: 'FranchiseeTimelinessStatistics',
            component: () =>
              import('@/views/ReportManagement/franchiseeTimelinessStatistics/index'),
            meta: {
              title: 'FranchiseeTimelinessStatistics',
              roles: ['admin', 'menu:ReportManagement:FranchiseeTimelinessStatistics']
            }
          },
          // 轨迹停更监控
          {
            path: 'trackStopMonitoring',
            name: 'trackStopMonitoring',
            component: () => import('@/views/ReportManagement/trackStopMonitoring/index'),
            meta: {
              title: 'trackStopMonitoring', // 轨迹停更数据监控
              roles: ['admin', 'menu:ReportManagement:trackStopMonitoring']
            }
          },
          // 分拨滞留监控
          {
            path: 'AllocateDetentionMonitoring',
            name: 'AllocateDetentionMonitoring',
            component: () => import('@/views/ReportManagement/AllocateDetentionMonitoring/index'),
            meta: {
              title: 'allocateDetentionMonitoring',
              roles: ['admin', 'menu:ReportManagement:AllocateDetentionMonitoring']
            }
          },
          // 退件数据监控
          {
            path: 'ReturnDataMonitoring',
            name: 'ReturnDataMonitoring',
            component: () => import('@/views/ReportManagement/ReturnDataMonitoring/index'),
            meta: {
              title: 'returnDataMonitoring',
              roles: ['admin', 'menu:ReportManagement:returnDataMonitoring']
            }
          },
          // 退件操作记录
          {
            path: 'returnOperationRecord',
            name: 'ReturnOperationRecord',
            component: () => import('@/views/ReportManagement/returnOperationRecord/index'),
            meta: {
              title: 'ReturnOperationRecord',
              roles: ['admin', 'menu:ReportManagement:returnDataMonitoring']
            }
          },
          // 签收经纬度监控
          {
            path: 'signLatLonMonitoring',
            name: 'signLatLonMonitoring',
            component: () => import('@/views/ReportManagement/signLatLonMonitoring/index'),
            meta: {
              title: 'signLatLonMonitoring',
              roles: ['admin', 'menu:ReportManagement:signLatLonMonitoring']
            }
          },
          // 全链路时效统计
          {
            path: 'allLinkTimeStatistics',
            name: 'AllLinkTimeStatistics',
            component: () => import('@/views/ReportManagement/allLinkTimeStatistics/index'),
            meta: {
              title: 'allLinkTimeStatistics',
              roles: ['admin', 'menu:ReportManagement:allLinkTimeStatistics']
            }
          }
        ]
      },
      {
        path: 'expressSignage',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '快递看板',
          title: 'expressSignage',
          roles: ['admin', 'menu:order-center:waybillKanbanBoard']
        },
        children: [
          {
            path: 'WaybillSignboard',
            name: 'Kanban',
            component: () => import('@/views/orderCenter/waybillKanbanBoard/index'),
            meta: {
              // 运单看板
              title: 'kanbanBoard',
              roles: ['admin', 'menu:order-center:waybillKanbanBoard']
            }
          },
          {
            path: 'TransferKanbanBoard',
            name: 'TransferKanbanBoard',
            component: () => import('@/views/orderCenter/TransferKanbanBoard/index'),
            meta: {
              // 转运看板
              title: 'TransferKanbanBoard',
              roles: ['admin', 'menu:order-center:TransferKanbanBoard']
            }
          },
          {
            path: 'expressOperationKanbanBoard',
            name: 'expressOperationKanbanBoard',
            component: () => import('@/views/orderCenter/expressOperationKanbanBoard/index'),
            meta: {
              // 快递运营看板
              title: 'expressOperationKanbanBoard',
              roles: ['admin', 'menu:order-center:expressOperationKanbanBoard']
            }
          },
          {
            path: 'sortingMachineSignboard',
            name: 'SortingMachineSignboard',
            component: () => import('@/views/orderCenter/sortingMachineSignboard/index'),
            meta: {
              // 分拣机看板
              title: 'SortingMachineSignboard',
              roles: ['admin', 'menu:order-center:sortingMachineSignboard']
            }
          },
          {
            path: 'completeLink',
            name: 'CompleteLink',
            component: () => import('@/views/orderCenter/completeLink'),
            meta: {
              // 全环节看板
              title: 'completeLink',
              roles: ['admin', 'menu:order-center:completeLink']
            }
          }
        ]
      },
      {
        path: 'operatingPlatform', // 操作平台
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '操作平台',
          title: 'operatingPlatform',
          roles: ['admin', 'menu:operating-platform']
        },
        children: [
          {
            path: 'arrivedBox',
            component: () => import('@/views/automaticSortings/arrivedBox/index'),
            name: 'ArrivedBox',
            meta: {
              // title: '到件',
              title: 'arrive',
              roles: ['admin', 'menu:automatic-sorting:arrivedBox']
            }
          },
          {
            path: 'senderBox',
            component: () => import('@/views/automaticSortings/senderBox/index'),
            name: 'SenderBox',
            meta: {
              // title: '发件',
              title: 'sender',
              roles: ['admin', 'menu:automatic-sorting:senderBox']
            }
          },
          {
            path: 'quickOrderChange',
            name: 'quickOrderChange',
            component: () => import('@/views/operatingPlatform/quickOrderChange'),
            meta: {
              // title: '快速换单'
              title: 'quickOrderChange',
              roles: ['admin', 'menu:operating-platform:quickOrderChange']
            }
          },
          {
            path: 'packagePrinting',
            name: 'PackagePrinting',
            component: () => import('@/views/automaticSortings/PackagePrinting/index'),
            meta: {
              // title: '包牌打印'
              title: 'PackagePrinting1',
              roles: ['admin', 'menu:automatic-sorting:packagePrinting']
            }
          },
          {
            path: 'replaceScanner',
            component: () => import('@/views/automaticSortings/replaceScanner/index'),
            name: 'ReplaceScanner',
            meta: {
              // title: '换单扫描',
              title: 'replaceScanner',
              roles: ['admin', 'menu:automatic-sorting:replaceScanner']
            }
          },
          {
            path: 'loadingDocument',
            name: 'loadingDocument',
            component: () => import('@/views/automaticSortings/loadingDocument/index'),
            meta: {
              // title: '装车文件'
              title: 'LoadingDocument',
              roles: ['admin', 'menu:automatic-sorting:loadingDocument']
            }
          },
          {
            path: 'manualSorting',
            name: 'manualSorting',
            component: () => import('@/views/automaticSortings/manualSorting/index'),
            meta: {
              // title: '手动分拣',
              title: 'ManualSortingRoute',
              roles: ['admin', 'menu:automatic-sorting:ManualSortingRoute']
            }
          },
          {
            path: 'WarehousingWeight',
            name: 'WarehousingWeight',
            component: () => import('@/views/automaticSortings/WarehousingWeight/WarehousWeight'),
            meta: {
              // 入库称重
              title: 'WarehousingWeight',
              roles: ['admin', 'menu:automatic-sorting:WarehousingWeight']
            }
          },
          {
            path: 'scanBagging',
            name: 'ScanBagging',
            component: () => import('@/views/automaticSortings/ScanBagging/index'),
            meta: {
              // title: '扫描装袋'
              title: 'ScanBagging',
              roles: ['admin', 'menu:automatic-sorting:ScanBagging']
            }
          },
          {
            path: 'removeTheBag',
            name: 'RemoveTheBag',
            component: () => import('@/views/automaticSortings/RemoveTheBag/index'),
            meta: {
              // title: '移除出袋'
              title: 'RemoveTheBag',
              roles: ['admin', 'menu:automatic-sorting:removeTheBag']
            }
          }
        ]
      },
      {
        path: 'sortingManagement',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '分拣管理',
          title: 'sortingManagement',
          roles: ['admin', 'menu:automatic-sorting']
        },
        children: [
          {
            path: 'newSortingRules',
            component: () => import('@/views/automaticSortings/newSortingRules/index'),
            name: 'NewSortingRules',
            meta: {
              // title: '新分拣规则',
              title: 'NewSortingRules',
              roles: ['admin', 'menu:automatic-sorting:sorting-newSortingRules']
            }
          },
          {
            path: 'deviceManagement',
            component: () => import('@/views/automaticSortings/deviceManagement/index'),
            name: 'DeviceManagement',
            meta: {
              // title: '设备管理',
              title: 'DeviceManagement',
              roles: ['admin', 'menu:automatic-sorting:deviceManagement']
            }
          },
          {
            path: 'gridManagement',
            component: () => import('@/views/automaticSortings/GridManagement/index'),
            name: 'gridManagement',
            meta: {
              // title: '格口管理',
              title: 'GridManagement',
              roles: ['admin', 'menu:automatic-sorting:grid-management']
            }
          },
          {
            path: 'bagworkList',
            name: 'BagWork',
            component: () => import('@/views/automaticSortings/newBagworkList/index'),
            meta: {
              // title: '装袋列表'
              title: 'bagworkList',
              roles: ['admin', 'menu:automatic-sorting:bagworkList']
            }
          },
          {
            path: 'getSortCode',
            name: 'getSortCode',
            component: () => import('@/views/automaticSortings/getSortCode/index'),
            meta: {
              // title: '装袋列表'
              title: 'getSortCode'
            }
          }
        ]
      },
      {
        path: 'serviceProviderManagement',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '服务商系统管理',
          title: 'SupplierManagement',
          roles: ['admin', 'menu:supplierManagement']
        },
        children: [
          {
            path: 'AccountOpening',
            component: () => import('@/views/supplierManagement/vendorAccountNumber/index'),
            name: 'VendorAccountNumber',
            meta: {
              // title: '账号开通',
              title: 'AccountOpening',
              roles: ['admin', 'menu:supplierManagement:vendorAccountNumber']
            }
          },
          {
            path: 'vendorRole',
            component: () => import('@/views/supplierManagement/vendorRole/index'),
            name: 'VendorRole',
            meta: {
              // title: '供应商角色',
              title: 'VendorRole',
              roles: ['admin', 'menu:supplierManagement:VendorRole']
            }
          },
          {
            path: 'vendorPermissions',
            component: () => import('@/views/supplierManagement/vendorPermissions/index'),
            name: 'VendorPermissions',
            meta: {
              // title: '供应商权限',
              title: 'VendorPermissions',
              roles: ['admin', 'menu:supplierManagement:VendorPermissions']
            }
          },
          {
            path: 'operatorsManual',
            component: () => import('@/views/supplierManagement/operatorsManual/index'),
            name: 'OperatorsManual',
            meta: {
              // title: '操作手册',
              title: 'OperatorsManual',
              roles: ['admin', 'menu:supplierManagement:operatorsManual']
            }
          },
          {
            path: 'channelManage',
            name: 'ChannelManage',
            component: () => import('@/views/automaticSortings/routerView'),
            alwaysShow: true, // 只有一个子菜单时显示父菜单
            fillterRouter: true,
            meta: {
              // title: '渠道'
              title: 'channel',
              roles: ['admin', 'menu:channel-manage']
            },
            children: [
              {
                path: 'serviceProvider',
                name: 'ServiceProvider',
                component: () => import('@/views/channelManage/serviceProvider/serviceProvider'),
                meta: {
                  // title: '系统服务商',
                  title: 'SystemServiceProvider',
                  roles: ['admin', 'menu:channel-manage:serviceProvider']
                }
              },
              {
                path: 'serviceProviderChannel',
                name: 'ServiceProviderChannel',
                component: () =>
                  import('@/views/channelManage/serviceProviderChannel/serviceChannel'),
                meta: {
                  // title: '服务商渠道',
                  title: 'ServiceProviderChannels',
                  roles: ['admin', 'menu:channel-manage:serviceProviderChannel']
                }
              },
              {
                path: 'ServiceErrorMessage',
                component: () =>
                  import('@/views/channelManage/ServiceErrorMessage/ServiceErrorMessage'),
                name: 'ServiceErrorMessage',
                meta: {
                  title: 'ServiceProviderErrorMessageComparison',
                  // title: 'ServiceProviderErrorMessageComparison',
                  roles: ['admin', 'menu:channelManage:ServiceErrorMessage']
                }
              }
            ]
          }
        ]
      },
      {
        path: 'ITResourceManagement',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: 'IT资源管理',
          title: 'ITResourceManagement',
          roles: ['admin', 'menu:system-manage:trackSendingManagement']
        },
        children: [
          {
            path: 'trackSendingManagement',
            component: () => import('@/views/resource/trackSendingManagement'),
            name: 'trackSendingManagement',
            meta: {
              // title: '轨迹推送管理',
              title: 'TrackPushManagement',
              roles: ['admin', 'menu:system-manage:trackSendingManagement']
            }
          },
          {
            path: 'trackReceptionManagement',
            component: () => import('@/views/resource/trackReceptionManagement'),
            name: 'trackReceptionManagement',
            meta: {
              // title: '轨迹接收管理',
              title: 'TrackReceptionManagement',
              roles: ['admin', 'menu:system-manage:trackReceptionManagement']
            }
          },
          {
            path: 'trajectoryStatusMonitoring',
            component: () => import('@/views/resource/trajectoryStatusMonitoring/index'),
            name: 'TrajectoryStatusMonitoring',
            meta: {
              // title: '轨迹与状态同步监控',
              title: 'TrajectoryStatusMonitoring',
              roles: ['admin', 'menu:system-manage:trajectoryStatusMonitoring']
            }
          }
        ]
      }
    ]
  },
  // 大件快递
  {
    path: '/bulkyExpress',
    component: Layout,
    redirect: '/bulkyExpress',
    name: 'bulkyExpress',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      // title: '大件快递',
      title: 'bulkyExpress',
      icon: 'bulkyExpress',
      roles: ['admin', 'menu:bulky-express']
    },
    children: [
      {
        path: 'largeOrderManagement',
        name: 'largeOrderManagement',
        component: () => import('@/views/bulkyExpress/largeOrderManagement/index'),
        meta: {
          // title: '大件订单管理'
          title: 'largeOrderManagement',
          roles: ['admin', 'menu:collection-center:largeOrderManagement']
        }
      },
      {
        path: 'largeJobManagement',
        component: layoutThree,
        name: 'LayoutThree',
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        meta: {
          // title: '大件作业管理',
          title: 'largeJobManagement',
          roles: ['admin', 'menu:bulky-express:large-job-management']
        },
        children: [
          {
            path: 'collectingLoadingRecords',
            name: 'CollectingLoadingRecords',
            component: () =>
              import('@/views/bulkyExpress/largeJobManagement/collectingLoadingRecords/index'),
            meta: {
              // title: '揽收装车记录',
              title: 'collectingLoadingRecords',
              roles: ['admin', 'menu:bulky-express:large-job-management:collecting-loading-records']
            }
          }
        ]
      }
    ]
  },
  // 财务
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance',
    name: 'finance',
    meta: {
      // title: '财务中心',
      title: 'FinancialCenter',
      icon: 'FinancialCenter',
      roles: ['admin', 'menu:finance']
    },
    children: [
      {
        path: 'financialManage',
        component: layoutThree,
        name: 'LayoutThree',
        redirect: '/financialManage',
        fillterRouter: true,
        meta: {
          // title: '基础资料',
          title: 'BasicInformation',
          roles: ['admin', 'menu:finance:basicData']
        },
        children: [
          {
            path: 'receivable',
            component: layoutThree,
            fillterRouter: true,
            name: 'Receivable',
            meta: {
              title: 'receivable',
              // title: '应收'
              roles: ['admin', 'menu:finance:basicData:receivable']
            },

            children: [
              {
                path: 'partition',
                component: () => import('@/views/finance/basicData/partition/index'),
                name: 'Partition',
                meta: {
                  // title: '分区管理',
                  title: 'PartitionManagement',
                  roles: ['admin', 'menu:finance:basicData:partition']
                }
              },
              {
                path: 'accountManagement',
                component: () => import('@/views/finance/basicData/accountManagement/index'),
                name: 'AccountManagement',
                meta: {
                  // title: '账户管理',
                  title: 'AccountManagement',
                  roles: ['admin', 'menu:finance:basicData:accountManagement']
                }
              },
              {
                path: 'feeType',
                component: () => import('@/views/finance/basicData/feeType/index'),
                name: 'FeeType',
                meta: {
                  // title: '费用类型',
                  title: 'feesType',
                  roles: ['admin', 'menu:finance:basicData:feesType']
                }
              },
              {
                path: 'quotationManagement',
                component: () => import('@/views/finance/basicData/quotationManagement/index'),
                name: 'QuotationManagement',
                meta: {
                  // title: '报价管理',
                  title: 'quoteManagement',
                  roles: ['admin', 'menu:finance:basicData:quotationManagement']
                }
              },
              {
                path: 'customerAccount',
                component: () => import('@/views/finance/basicData/customerAccount/index'),
                name: 'CustomerAccount',
                meta: {
                  // title: '客户账户',
                  title: 'CustomerAccount',
                  roles: ['admin', 'menu:finance:basicData:customerAccount']
                }
              },
              {
                path: 'branchAccounts',
                component: () => import('@/views/finance/basicData/branchAccounts/index'),
                name: 'BranchAccounts',
                meta: {
                  // title: '网点账户',
                  title: 'BranchAccounts',
                  roles: ['admin', 'menu:finance:basicData:branchAccounts']
                }
              },
              {
                path: 'PostalCodeCorrection',
                component: () => import('@/views/finance/basicData/postalCodeCorrection/index'),
                name: 'PostalCodeCorrection',
                meta: {
                  // title: '邮编纠正',
                  title: 'PostalCodeCorrection',
                  roles: ['admin', 'menu:finance:basicData:PostalCodeCorrection']
                }
              }
            ]
          },

          {
            path: 'cope',
            component: layoutThree,
            name: 'Cope',
            alwaysShow: true, // 只有一个子菜单时显示父菜单
            fillterRouter: true,
            meta: {
              title: 'payable',
              // title: '应付'
              roles: ['admin', 'menu:finance:basicData:payable']
            },
            children: [
              {
                path: 'accountsPayable',
                name: 'AccountsPayable',
                component: () => import('@/views/finance/basicData/cope/accountsPayable/index'),
                meta: {
                  // title: '应付账户'
                  title: 'AccountsPayable',
                  roles: ['admin', 'menu:finance:basicData:payable:accountsPayable']
                }
              },
              {
                path: 'payablePartition',
                name: 'PayablePartition',
                component: () => import('@/views/finance/basicData/cope/partition/index'),
                meta: {
                  // title: '分区管理'
                  title: 'PartitionManagement',
                  roles: ['admin', 'menu:finance:basicData:payable:partitionManagement']
                }
              },
              {
                path: 'feesTypeCope',
                name: 'FeesTypeCope',
                component: () => import('@/views/finance/basicData/cope/feesTypeCope/index'),
                meta: {
                  // title: '费用类型应付'
                  title: 'feesType',
                  roles: ['admin', 'menu:finance:basicData:payable:feesType']
                }
              },
              {
                path: 'quoteManagement',
                name: 'QuoteManagement',
                component: () => import('@/views/finance/basicData/cope/quotationManagement/index'),
                meta: {
                  // title: '报价管理'
                  title: 'quoteManagement',
                  roles: ['admin', 'menu:finance:basicData:payable:quoteManagement']
                }
              },
              {
                path: 'dispatchReferenceTable',
                name: 'DispatchReferenceTable',
                component: () =>
                  import('@/views/finance/basicData/cope/dispatchReferenceTable/index'),
                meta: {
                  // title: '派送参照表'
                  title: 'DispatchReferenceTable',
                  roles: ['admin', 'menu:finance:basicData:payable:dispatchReferenceTable']
                }
              },
              {
                path: 'routeDistanceReference',
                component: () =>
                  import('@/views/finance/basicData/cope/routeDistanceReference/index'),
                name: 'RouteDistanceReference',
                meta: {
                  // title: '路线距离参照表',
                  title: 'RouteDistanceReference',
                  roles: ['admin', 'menu:finance:payableManagement:routeDistanceReference']
                }
              },
              {
                path: 'hutdispatchAccountsPayable',
                name: 'HutDispatchAccountsPayable',
                component: () =>
                  import('@/views/finance/basicData/cope/hutdispatchAccountsPayable/index'),
                meta: {
                  // title: '安骏派送应付账户'
                  title: 'HutDispatchAccountsPayable',
                  roles: ['admin', 'menu:finance:basicData:payable:hutdispatchAccountsPayable']
                }
              },
              {
                path: 'dispatchAccountsPayable',
                name: 'DispatchAccountsPayable',
                component: () =>
                  import('@/views/finance/basicData/cope/dispatchAccountsPayable/index'),
                meta: {
                  // title: 'AJD派送应付账户'
                  title: 'DispatchAccountsPayable',
                  roles: ['admin', 'menu:finance:basicData:payable:dispatchAccountsPayable']
                }
              },
              {
                path: 'dspdispatchAccountsPayable',
                name: 'DspDispatchAccountsPayable',
                component: () =>
                  import('@/views/finance/basicData/cope/dspdispatchAccountsPayable/index'),
                meta: {
                  // title: 'DSP派送应付账户'
                  title: 'DspDispatchAccountsPayable',
                  roles: ['admin', 'menu:finance:basicData:payable:dspdispatchAccountsPayable']
                }
              },
              {
                path: 'accountsReceivablePayable',
                name: 'AccountsReceivablePayable',
                component: () =>
                  import('@/views/finance/basicData/cope/accountsReceivablePayable/index'),
                meta: {
                  // title: '揽收应付账户'
                  title: 'AccountsReceivablePayable',
                  roles: ['admin', 'menu:finance:basicData:payable:accountsReceivablePayable']
                }
              },
              {
                path: 'costsPayableAccount',
                component: () =>
                  import('@/views/finance/basicData/cope/costsPayableAccount/index.vue'),
                name: 'CostsPayableAccount',
                meta: {
                  // title: '成本应付账户',
                  title: 'CostsPayableAccount',
                  roles: ['admin', 'menu:finance:basicData:CostsPayableAccount']
                }
              }
            ]
          },
          {
            path: 'currencyManagement',
            component: () => import('@/views/finance/basicData/currencyManagement/index'),
            name: 'CurrencyManagement',
            meta: {
              // title: '币种管理',
              title: 'CurrencyManagement',
              roles: ['admin', 'menu:finance:basicData:currencyManagement']
            }
          },
          {
            path: 'districtManagement',
            component: () => import('@/views/finance/basicData/districtManagement/index'),
            name: 'DistrictManagement',
            meta: {
              // title: '地区与税费',
              title: 'DistrictManagement',
              roles: ['admin', 'menu:finance:basicData:DistrictManagement']
            }
          },
          {
            path: 'dataPushLogs',
            component: () => import('@/views/finance/basicData/dataPushLogs/index'),
            name: 'DataPushLogs',
            meta: {
              // title: '数据推送日志',
              title: 'DataPushLogs',
              roles: ['admin', 'menu:finance:basicData:dataPushLogs']
            }
          },
          {
            path: 'priceInquiryLog',
            component: () => import('@/views/finance/basicData/PriceInquiryLog/index'),
            name: 'PriceInquiryLog',
            meta: {
              // title: '价格查询日志',
              title: 'PriceInquiryLog',
              roles: ['admin', 'menu:finance:basicData:priceInquiryLog']
            }
          }
        ]
      },
      {
        path: 'accountsReceivable',
        component: layoutThree,
        fillterRouter: true,
        name: 'LayoutThree',
        meta: {
          // title: '应收管理(公司对网点)',
          title: 'ReceivableManagementSite',
          roles: ['admin', 'menu:finance:receivableManagementSite']
        },
        children: [
          {
            path: 'aRGenerationNew',
            component: () =>
              import('@/views/finance/receivableManagementSite/aRGenerationNew/index'),
            name: 'ARGenerationNew',
            meta: {
              // title: '应收费用',
              title: 'FeesApply',
              roles: ['admin', 'menu:finance:receivableManagementSite:aRGenerationNew']
            }
          },
          {
            path: 'toExamine',
            component: () => import('@/views/finance/receivableManagementSite/toExamine/index'),
            name: 'ToExamine',
            meta: {
              // title: '账单审核',
              title: 'BillingReview',
              roles: ['admin', 'menu:finance:receivableManagementSite/toExamine']
            }
          },
          {
            path: 'billCollection',
            component: () =>
              import('@/views/finance/receivableManagementSite/billCollection/index'),
            name: 'BillCollection',
            meta: {
              // title: '账单核收',
              title: 'BillReconciliation',
              roles: ['admin', 'menu:finance:receivableManagementSite:billCollection']
            }
          },
          {
            // 暂时放到该菜单下
            path: 'invoiceManagementCN',
            component: () =>
              import('@/views/finance/receivableManagementSite/invoiceManagementCN/index'),
            name: 'InvoiceManagementCN',
            meta: {
              // title: '发票管理',
              title: 'InvoiceManagement',
              roles: ['admin', 'menu:finance:receivableManagementSite:invoiceManagementCN']
            }
          }
        ]
      },
      // {
      //   path: 'siteToCustomer',
      //   component: layoutThree,
      //   fillterRouter: true,
      //   name: 'LayoutThree',
      //   meta: {
      //     // title: '应收管理(网点对客户)',
      //     title: 'ReceivableManagementCompany',
      //     roles: ['admin', 'menu:finance:receivableManagementCompany']
      //   },
      //   children: [
      //     {
      //       path: 'FeesApply',
      //       component: () =>
      //         import('@/views/finance/receivableManagementCompany/aRGenerationNew/index'),
      //       name: 'FeesApply',
      //       meta: {
      //         // title: '应收费用',
      //         title: 'FeesApply',
      //         roles: ['admin', 'menu:finance:receivableManagementCompany:aRGenerationNew']
      //       }
      //     },
      //     {
      //       path: 'toExamineSiteToCustomer',
      //       component: () => import('@/views/finance/receivableManagementCompany/toExamine/index'),
      //       name: 'ToExamineSiteToCustomer',
      //       meta: {
      //         // title: '账单审核',
      //         title: 'BillingReview',
      //         roles: ['admin', 'menu:finance:receivableManagementCompany/toExamine']
      //       }
      //     },
      //     {
      //       path: 'billCollectionSiteToCustomer',
      //       component: () =>
      //         import('@/views/finance/receivableManagementCompany/billCollection/index'),
      //       name: 'BillCollectionSiteToCustomer',
      //       meta: {
      //         // title: '账单核收',
      //         title: 'BillReconciliation',
      //         roles: ['admin', 'menu:finance:receivableManagementCompany:billCollection']
      //       }
      //     }
      //   ]
      // },
      {
        path: 'payableManagement',
        component: layoutThree,
        fillterRouter: true,
        name: 'LayoutThree',
        redirect: '/payableManagement',
        meta: {
          // title: '应付管理',
          title: 'PayableManagement',
          roles: ['admin', 'menu:finance:payableManagement']
        },
        children: [
          {
            path: 'feesPayable',
            component: layoutThree,
            // component: () => import('@/views/finance/payableManage/feesPayable/index'),
            name: 'FeesPayable',
            alwaysShow: true, // 只有一个子菜单时显示父菜单
            meta: {
              // title: '应付费用',
              title: 'FeesPayable',
              roles: ['admin', 'menu:finance:payableManagement:feesPayable']
            },
            children: [
              // {
              //   path: 'synthesis',
              //   name: 'Synthesis',
              //   component: () => import('@/views/finance/payableManage/feesPayable/Synthesis/index.vue'),
              //   meta: {
              //     // title: '应付综合费用'
              //     title: 'ComprehensiveCost',
              //     roles: ['admin', 'menu:finance:basicData:payable:Synthesis']
              //   }
              // },
              {
                path: 'threeCollect',
                name: 'ThreeCollect',
                component: () =>
                  import('@/views/finance/payableManage/feesPayable/ThirdParty/index.vue'),
                meta: {
                  // title: '第三方揽收'
                  title: 'ThirdPartyCollection',
                  roles: ['admin', 'menu:finance:basicData:payable:threeCollect']
                }
              },
              {
                path: 'hut',
                name: 'Hut',
                component: () =>
                  import('@/views/finance/payableManage/feesPayable/AnJun/index.vue'),
                meta: {
                  // title: '安俊小屋'
                  title: 'AnjunCottage',
                  roles: ['admin', 'menu:finance:basicData:payable:hut']
                }
              },
              {
                path: 'AJDDispatch',
                name: 'AJDDispatch',
                component: () =>
                  import('@/views/finance/payableManage/feesPayable/AJDDispatch/index'),
                meta: {
                  // title: 'AJD派送'
                  title: 'AJDDispatch',
                  roles: ['admin', 'menu:finance:basicData:payable:AJDDispatch']
                }
              },
              {
                path: 'DSPDispatch',
                name: 'DSPDispatch',
                component: () =>
                  import('@/views/finance/payableManage/feesPayable/DSPDispatch/index'),
                meta: {
                  // title: 'DSP派送'
                  title: 'DSPDispatch',
                  roles: ['admin', 'menu:finance:basicData:payable:DSPDispatch']
                }
              },
              {
                path: 'transportationTrunkFeeder',
                name: 'TransportationTrunkFeeder',
                component: () =>
                  import(
                    '@/views/finance/payableManage/feesPayable/transportationTrunkFeeder/index'
                  ),
                meta: {
                  // title: '运输（干/支线）'
                  title: 'TransportationTrunkFeeder',
                  roles: ['admin', 'menu:finance:basicData:payable:transportationTrunkFeeder']
                }
              }
            ]
          },
          {
            path: 'accountsPayableReview',
            component: () => import('@/views/finance/payableManage/accountsPayableReview/index'),
            name: 'AccountsPayableReview',
            meta: {
              // title: '账单审核',
              title: 'BillingReview',
              roles: ['admin', 'menu:finance:payableManagement:accountsPayableReview']
            }
          },
          {
            path: 'payableCollection',
            component: () => import('@/views/finance/payableManage/payableCollection/index'),
            name: 'PayableCollection',
            meta: {
              // title: '账单核付',
              title: 'BillVerification',
              roles: ['admin', 'menu:finance:payableManagement:payableCollection']
            }
          },
          {
            path: 'bankPaymentManagement',
            component: () => import('@/views/finance/payableManage/bankPaymentManagement/index'),
            name: 'BankPaymentManagement',
            meta: {
              // title: 'Bradesco银行付款管理',
              title: 'BankPaymentManagement',
              roles: ['admin', 'menu:finance:payableManagement:bankPaymentManagement']
            }
          }
        ]
      },
      {
        path: 'costManagement',
        component: layoutThree,
        name: 'LayoutThree',
        meta: {
          // title: '成本管理',
          title: 'costManagement',
          roles: ['admin', 'menu:finance:costManagement']
        },
        children: [
          {
            path: 'costsFee',
            component: () => import('@/views/finance/costManagement/costsFee/index'),
            name: 'CostsFee',
            meta: {
              // title: '成本费用',
              title: 'costsFee',
              roles: ['admin', 'menu:finance:costManagement:costsFee']
            }
          },
          {
            path: 'costAudit',
            component: () => import('@/views/finance/costManagement/costAudit/index'),
            name: 'CostAudit',
            meta: {
              // title: '成本审核',
              title: 'costAudit',
              roles: ['admin', 'menu:finance:costManagement:costAudit']
            }
          },
          {
            path: 'costApproval',
            component: () => import('@/views/finance/costManagement/costApproval/index'),
            name: 'CostApproval',
            meta: {
              // title: '成本核付',
              title: 'costApproval',
              roles: ['admin', 'menu:finance:costManagement:costApproval']
            }
          }
        ]
      },
      {
        path: 'invoiceManagement',
        component: () => import('@/views/finance/invoiceManagement/index'),
        name: 'InvoiceManagement',
        meta: {
          // title: '发票管理',
          title: 'InvoiceManagement',
          roles: ['admin', 'menu:finance:InvoiceManagement']
        }
      },
      {
        path: 'fileUploadManagement',
        component: () => import('@/views/finance/FileUploadManagement/index'),
        name: 'FileUploadManagement',
        meta: {
          // title: '文件上传管理',
          title: 'FileUploadManagement',
          roles: ['admin', 'menu:finance:FileUploadManagement']
        }
      },
      {
        path: 'collectionDailyReceivableStatistics',
        component: () =>
          import('@/views/finance/DataDheet/CollectionDailyReceivableStatistics/index'),
        name: 'CollectionDailyReceivableStatistics',
        meta: {
          // title: '揽收每日应收统计',
          title: 'CollectionDailyReceivableStatistics',
          roles: ['admin', 'menu:finance:DataDheet:CollectionDailyReceivableStatistics']
        }
      },
      {
        path: 'dataDheet',
        component: layoutThree,
        alwaysShow: true, // 只有一个子菜单时显示父菜单
        fillterRouter: true,
        name: 'LayoutThree',
        meta: {
          // title: '数据报表',
          title: 'DataDheet',
          roles: ['admin', 'menu:finance:DataDhe1212et221'] // 暂时隐藏
        },
        children: [
          {
            path: 'collectionDailyReceivableStatistics',
            component: () =>
              import('@/views/finance/DataDheet/CollectionDailyReceivableStatistics/index'),
            name: 'CollectionDailyReceivableStatistics',
            meta: {
              // title: '揽收每日应收统计',
              title: 'CollectionDailyReceivableStatistics',
              roles: ['admin', 'menu:finance:DataDheet:CollectionDailyReceivableStatistics']
            }
          }
        ]
      }
    ]
  },
  // 客户管理
  {
    path: '/customerManagements',
    component: Layout,
    redirect: '/customerManagements',
    name: 'customerManagements',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      // title: '客户管理',
      title: 'CustomerManagement',
      icon: 'kehu',
      roles: ['admin', 'menu:customer-managements']
    },
    children: [
      {
        path: 'customerList',
        name: 'CustomerList',
        component: () => import('@/views/customerManagements/CustomerList/index'),
        meta: {
          // title: '客户列表',
          title: 'CustomerList',
          roles: ['admin', 'menu:customer-managements:list']
        }
      },
      {
        path: 'customerTimelinessManagement',
        name: 'customerTimelinessManagement',
        component: () => import('@/views/customerManagements/customerTimelinessManagement/index'),
        meta: {
          // title: '客户列表',customerTimelinessManagement
          title: 'customerTimelinessManagement', // 客户时效管理
          roles: ['admin', 'menu:customer-managements:customerTimelinessManagement']
        }
      }
    ]
  },
  // transportn
  {
    path: '/transport',
    component: Layout,
    redirect: '/collectionCenter',
    alwaysShow: true,
    meta: {
      title: 'Transport',
      icon: 'transport',
      roles: ['admin', 'menu:transport']
    },
    children: [
      {
        path: 'createDriver',
        name: 'createDriver',
        component: () => import('@/views/transport/driver'),
        meta: {
          title: 'driver',
          roles: ['admin', 'menu:transport']
        }
      },
      {
        path: 'createVehicle',
        name: 'createVehicle',
        component: () => import('@/views/transport/vehicle'),
        meta: {
          title: 'vehicle',
          roles: ['admin', 'menu:transport']
        }
      },
      {
        path: 'createRouter',
        name: 'createRouter',
        component: () => import('@/views/transport/router'),
        meta: {
          title: 'route',
          roles: ['admin', 'menu:transport']
        }
      },
      {
        path: 'createOrder',
        name: 'createOrder',
        component: () => import('@/views/transport/order'),
        meta: {
          title: 'order',
          roles: ['admin', 'menu:transport']
        }
      },
      {
        path: 'createSeller',
        name: 'createSeller',
        component: () => import('@/views/transport/sellers'),
        meta: {
          title: 'seller',
          roles: ['admin', 'menu:transport']
        }
      },
      {
        path: 'createTrailer',
        name: 'createTrailer',
        component: () => import('@/views/transport/trailer'),
        meta: {
          title: 'trailer',
          roles: ['admin', 'menu:transport']
        }
      }
    ]
  },
  // 服务质量
  {
    path: '/serviceQuality',
    component: Layout,
    redirect: '/serviceQuality',
    name: 'serviceQuality',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      // title: '服务质量'
      title: 'serviceQuality',
      roles: ['admin', 'menu:qualityService'],
      icon: 'problem'
    },
    children: [
      {
        path: 'complaintWorkOrder',
        name: 'ComplaintWorkOrder',
        component: () => import('@/views/Problem/complaintWorkOrder/index.vue'),
        meta: {
          // title: '投诉工单'
          title: 'ComplaintWorkOrder',
          roles: ['admin', 'menu:collection-center:complaintWorkOrder']
        }
      },
      {
        path: 'ProblemDelivery',
        name: 'ProblemDelivery',
        component: () => import('@/views/Problem/ProblemDelivery'),
        meta: {
          // title: '异常件'
          title: 'ProblemPiece',
          roles: ['admin', 'menu:collection-center:ProblemPiece']
        }
      },
      {
        path: 'PacketQuery',
        name: 'PacketQuery',
        component: () => import('@/views/Problem/packetQuery'),
        meta: {
          // title: '稽包查询',
          title: 'packetQuery',
          roles: ['admin', 'menu:collection-center:problem:packetQuery']
        }
      },
      {
        path: 'customerIssuemanagement',
        name: 'customerIssuemanagement',
        component: () => import('@/views/Problem/customerIssuemanagement/index'),
        meta: {
          // title: '本地客户问题件管理',
          title: 'customerIssuemanagement',
          roles: ['admin', 'menu:collection-center:customerIssuemanagement']
        }
      },
      // 强制签收审核
      {
        path: 'mandatorySignatureVerification',
        name: 'mandatorySignatureVerification',
        component: () => import('@/views/qualityService/signatureVerification/index'),
        meta: {
          title: 'MandatorySignatureVerification', // 强制签收审核
          roles: ['admin', 'menu:qualityService:mandatorySignatureVerification']
        }
      },
      {
        path: 'signLimitConfiguration',
        component: () => import('@/views/qualityService/signLimitConfiguration/index'),
        name: 'SignLimitConfiguration',
        meta: {
          title: 'SignLimitConfiguration', // 签收限制配置
          roles: ['admin', 'menu:system-manage:SignLimitConfiguration']
        }
      },
      {
        path: 'sMSConfiguration',
        component: () => import('@/views/qualityService/smsConfiguration/index'),
        name: 'SMSConfiguration',
        meta: {
          // title: '短信配置',
          title: 'SMSConfiguration',
          roles: ['admin', 'menu:system-manage:SMSConfiguration']
        }
      },
      {
        path: 'systemMessages',
        component: () => import('@/views/supplierManagement/systemMessages/index'),
        name: 'systemMessages',
        meta: {
          // title: '系统消息',
          title: 'sysInfo',
          roles: ['admin', 'menu:supplierManagement:systemMessages']
        }
      },
      {
        path: 'requestMessageList',
        component: () => import('@/views/supplierManagement/requestMessageList/index'),
        name: 'requestMessageList',
        meta: {
          // title: '需求留言列表',
          title: 'requestMessageList',
          roles: ['admin', 'menu:supplierManagement:messageList']
        }
      }
    ]
  },
  // 基础数据
  {
    path: '/basicData',
    component: Layout,
    name: 'BasicData',
    // hidden: true,
    alwaysShow: true,
    meta: {
      // title: '基础数据',
      title: 'basicData',
      icon: 'basicData',
      roles: ['admin', 'menu:BasicData']
    },
    children: [
      {
        path: 'site',
        name: 'Site',
        component: () => import('@/views/collectionCenter/BasicData/site'),
        meta: {
          // title: '网点管理'
          title: 'siteManagement',
          roles: ['admin', 'menu:collection-center:siteManagement']
        }
      },
      {
        path: 'vehicleManagement',
        name: 'VehicleManagement',
        component: () => import('@/views/collectionCenter/BasicData/vehicleManagement'),
        // hidden: true,
        meta: {
          // title: '车辆管理',
          title: 'vehicleManagement',
          roles: ['admin', 'menu:collection-center:vehicleManagement']
        }
      },
      {
        path: 'carNumber',
        name: 'CarNumber',
        component: () => import('@/views/collectionCenter/BasicData/carNumber/index'),
        // hidden: true,
        meta: {
          // title: '车签号',
          title: 'carNumber',
          roles: ['admin', 'menu:collection-center:carNumber']
        }
      },
      {
        path: 'SupplierMain',
        name: 'supplierMain',
        component: () => import('@/views/supplier/SupplierMain'),
        meta: {
          // 供应商
          title: 'supplier',
          roles: ['admin', 'menu:supplier:SupplierMainList']
        }
      },
      {
        path: 'shiftManagement',
        name: 'ShiftManagement',
        component: () => import('@/views/route/shiftManagement/index'),
        meta: {
          // title: '班次管理'
          title: 'ShiftManagement',
          roles: ['admin', 'menu:BasicData:shiftManagement']
        }
      },
      {
        path: 'dryBranchFlowManagement',
        name: 'DryBranchFlowManagement',
        component: () => import('@/views/route/dryBranchFlowManagement/index'),
        meta: {
          // title: '干支线流向管理'
          title: 'DryBranchFlowManagement',
          roles: ['admin', 'menu:basicData:dryBranchFlowManagement']
        }
      },
      // 时效管理
      {
        path: 'agingManagement',
        name: 'agingManagement',
        component: () => import('@/views/route/agingManagement/index'),
        meta: {
          title: 'agingManagement',
          roles: ['admin', 'menu:ReportManagement:agingManagement']
        }
      }
    ]
  },
  // 用户管理
  {
    path: '/user',
    component: Layout,
    redirect: '/user/userManage',
    name: 'User',
    meta: {
      // title: '用户中心',
      title: 'UserCenter',
      icon: 'yonghu',
      roles: ['admin', 'menu:user-center']
    },
    children: [
      {
        path: 'userManage',
        component: () => import('@/views/user/userManage/userManage'),
        name: 'UserManage',
        meta: {
          // // title: '用户管理',
          // title: 'UserManagement',
          // title: '员工管理'
          title: 'driverManagement',
          roles: ['admin', 'menu:user-center:user']
        }
      },
      {
        path: 'roleManage',
        component: () => import('@/views/user/roleManage/roleManage'),
        name: 'RoleManage',
        meta: {
          // title: '角色管理',
          title: 'RoleManagement',
          roles: ['admin', 'menu:user-center:role']
        }
      },
      {
        path: 'permissionManage',
        component: () => import('@/views/user/positionManage/index'),
        name: 'permissionManage',
        meta: {
          // title: '权限管理',
          title: 'PermissionManagement',
          roles: ['admin', 'menu:user-center:permission']
        }
      },
      {
        path: 'organizationManage',
        component: () => import('@/views/user/organizationManage/index'),
        name: 'OrganizationManage',
        meta: {
          // title: '组织架构',
          title: 'OrganizationalStructure',
          roles: ['admin', 'menu:user-center:organization']
        }
      },
      {
        path: 'jobManagement',
        component: () => import('@/views/user/JobManagement'),
        name: 'jobManagement',
        meta: {
          // title: '职务管理',
          title: 'JobManagement',
          roles: ['admin', 'menu:user-center:job-management']
        }
      },
      {
        path: 'personnelRegistration',
        component: () => import('@/views/user/personnelRegistration'),
        name: 'personnelRegistration',
        meta: {
          title: 'personnelRegistration',
          // title: 'JobManagement',
          roles: ['admin', 'menu:user-center:personnel-registration']
        }
      }
    ]
  },
  // 系统管理
  {
    path: '/resource',
    component: Layout,
    redirect: '/resource',
    name: 'resource',
    alwaysShow: true,
    meta: {
      // title: '系统管理',
      title: 'SystemAdministration',
      icon: 'xitong',
      roles: ['admin', 'menu:system-manage']
    },
    children: [
      {
        path: 'sharedResource',
        name: 'sharedResource',
        component: () => import('@/views/resource/sharedResource'),
        // hidden: true,
        meta: {
          // title: '资源管理'
          title: 'resourceManagement'
        }
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/resource/dictionary/index'),
        meta: {
          // title: '字典'
          title: 'dictionary'
        }
      },
      {
        path: 'exportList',
        component: () => import('@/views/resource/ExportList'),
        name: 'exportList',
        meta: {
          // title: '资源导出列表',
          title: 'TheResourceExportList',
          roles: ['admin', 'menu:system-manage:exportList']
        }
      },
      {
        path: 'exportTaskList',
        component: () => import('@/views/resource/ExportTaskList'),
        name: 'exportTaskList',
        meta: {
          // title: '资源任务列表',
          title: 'TheResourceTaskList'
          // roles: ['admin', 'menu:system-manage:exportList']
        }
      },
      // {
      //   path: 'trackSendingManagement',
      //   component: () => import('@/views/resource/trackSendingManagement'),
      //   name: 'trackSendingManagement',
      //   meta: {
      //     // title: '轨迹推送管理',
      //     title: 'TrackPushManagement',
      //     roles: ['admin', 'menu:system-manage:trackSendingManagement']
      //   }
      // },
      // {
      //   path: 'trackReceptionManagement',
      //   component: () => import('@/views/resource/trackReceptionManagement'),
      //   name: 'trackReceptionManagement',
      //   meta: {
      //     // title: '轨迹接收管理',
      //     title: 'TrackReceptionManagement',
      //     roles: ['admin', 'menu:system-manage:trackReceptionManagement']
      //   }
      // },
      {
        path: 'operationGuide',
        component: () => import('@/views/resource/operationGuide'),
        name: 'operationGuide',
        meta: {
          // title: '操作指南',
          title: 'operationGuide',
          roles: ['admin', 'menu:system-manage:operationGuide']
        }
      }
    ]
  },
  // document fiscal
  {
    path: '/fiscalDocuments',
    component: Layout,
    redirect: '/collectionCenter',
    alwaysShow: true,
    meta: {
      title: 'Processos',
      icon: 'document-issue',
      roles: ['admin', 'menu:fiscalDocuments']
    },
    children: [
      {
        path: 'createMDFE',
        name: 'createMDFE',
        component: () => import('@/views/fiscalDocuments/mdfe'),
        meta: {
          title: 'MDFE',
          roles: ['admin', 'menu:fiscalDocuments:mdfe']
        }
      }
    ]
  },

  // 退件中心
  {
    path: '/returnCenter',
    component: Layout,
    redirect: '/returnCenter',
    alwaysShow: true,
    meta: {
      title: 'returnCenter',
      // title: '退件中心',
      icon: 'returnCenter',
      roles: ['admin', 'menu:returnCenter']
    },
    children: [
      {
        path: 'returnQuery',
        name: 'returnQuery',
        component: () => import('@/views/returnCenter/returnQuery'),
        meta: {
          title: 'returnQuery',
          // title: '退件查询',
          roles: ['admin', 'menu:return-center:return-query']
        }
      },
      {
        path: 'expressReturn',
        name: 'expressReturn',
        component: () => import('@/views/returnCenter/expressReturn'),
        meta: {
          // title: '快递退件',
          title: 'expressReturn',
          roles: ['admin', 'menu:return-center:expressReturn']
        }
      }
    ]
  },
  // transfer
  {
    path: '/transfer',
    component: Layout,
    redirect: '/collectionCenter',
    alwaysShow: true,
    meta: {
      title: 'transfer',
      icon: 'transfer',
      roles: ['admin', 'menu:transfer']
    },
    children: [
      {
        path: 'createBank',
        name: 'createBank',
        component: () => import('@/views/transfer/bank'),
        meta: {
          title: 'bank',
          roles: ['admin', 'menu:transfer']
        }
      },
      {
        path: 'createBase',
        name: 'createBase',
        component: () => import('@/views/transfer/base'),
        meta: {
          title: 'base',
          roles: ['admin', 'menu:transfer']
        }
      },
      {
        path: 'createCompany',
        name: 'createCompany',
        component: () => import('@/views/transfer/companies'),
        meta: {
          title: 'company',
          roles: ['admin', 'menu:transfer']
        }
      },
      {
        path: 'createTransferRoute',
        name: 'createTransferRoute',
        component: () => import('@/views/transfer/transferRoute'),
        meta: {
          title: 'transferRoutes',
          roles: ['admin', 'menu:transfer']
        }
      }
    ]
  },
  {
    path: '/reverse',
    component: Layout,
    redirect: '/collectionCenter',
    alwaysShow: true,
    meta: {
      title: 'reverse',
      icon: 'reverse',
      roles: ['admin', 'menu:reverse']
    },
    children: [
      {
        path: 'createEdition',
        name: 'createEdition',
        component: () => import('@/views/reverse/edition'),
        meta: {
          title: 'edition',
          roles: ['admin', 'menu:reverse']
        }
      },
      {
        path: 'uploadFile',
        name: 'uploadFile',
        component: () => import('@/views/reverse/upload'),
        meta: {
          title: 'editionUpload',
          roles: ['admin', 'menu:reverse']
        }
      }
    ]
  },
  // tower control
  {
    path: '/towerControl',
    component: Layout,
    redirect: '/collectionCenter',
    alwaysShow: true,
    meta: {
      title: 'controlTower',
      icon: 'chart',
      roles: ['admin', 'menu:towerControl']
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/towerControl/dashboard'),
        meta: {
          title: 'dashboard',
          roles: ['admin', 'menu:towerControl']
        }
      }
    ]
  },
  // 修改密码
  {
    path: '/profile',
    component: Layout,
    hidden: true,
    redirect: '/profile/changepwd',
    name: 'profile',
    children: [
      {
        path: 'changepwd',
        name: 'changepwd',
        component: () => import('@/views/login/ChangePwd'),
        // meta: { title: '修改密码' }
        meta: { title: 'changePwd' }
      }
    ]
  },

  // 新 操作中心
  // {
  //   path: '/views/operationCenter',
  //   component: Layout,
  //   name: 'operationCenter',
  //   alwaysShow: true, // 只有一个子菜单时显示父菜单
  //   hidden: true,
  //   meta: {
  //     // title: '新操作中心',
  //     title: 'operationCenter',
  //     icon: 'chengzhong',
  //     roles: ['admin', 'menu:operation-center']
  //   },
  //   children: [
  //     {
  //       path: 'bagLabel',
  //       component: () => import('@/views/operationCenter/BagLabel/index'),
  //       name: 'bagLabel',
  //       meta: {
  //         // title: '大包列表(CN35)',
  //         title: 'bagLabel',
  //         oles: ['admin', 'menu:operation-center:bag-label']
  //       }
  //     }
  //   ]
  // },
  // 理赔管理
  {
    path: '/ClaimsManagement',
    component: Layout,
    redirect: '/ClaimList',
    name: 'ClaimsManagement',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      title: 'ClaimsManagement',
      icon: 'ReportManagement',
      roles: ['admin', 'menu:claimsManagement']
    },
    children: [
      {
        path: 'claimList',
        name: 'ClaimList',
        component: () => import('@/views/claimsManagement/claimList/index'),
        meta: {
          // 理赔列表
          title: 'ClaimList',
          roles: ['admin', 'menu:claimsManagement:claimList']
        }
      },
      {
        path: 'claimStatement',
        name: 'ClaimStatement',
        component: () => import('@/views/claimsManagement/claimStatement/index'),
        meta: {
          // 理赔账单
          title: 'ClaimStatement',
          roles: ['admin', 'menu:claimsManagement:claimStatement']
        }
      },
      {
        path: 'claimsReviewRules',
        name: 'ClaimsReviewRules',
        component: () => import('@/views/claimsManagement/claimsReviewRules/index'),
        meta: {
          // 理赔审核规则
          title: 'ClaimsReviewRules',
          roles: ['admin', 'menu:claimsManagement:claimsReviewRules']
        }
      }
    ]
  },

  // 索赔管理
  {
    path: '/compensationManagement',
    component: Layout,
    redirect: '/compensationManagement',
    name: 'CompensationManagement',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      title: 'CompensationManagement',
      icon: 'ReportManagement',
      roles: ['admin', 'menu:compensationManagement']
    },
    children: [
      {
        path: 'compensationList',
        name: 'CompensationList',
        component: () => import('@/views/compensationManagement/compensationList/index.vue'),
        meta: {
          // 索赔列表
          title: 'CompensationList',
          roles: ['admin', 'menu:compensationManagement:compensationList']
        }
      },
      {
        path: 'compensationStatement',
        name: 'CompensationStatement',
        component: () => import('@/views/compensationManagement/compensationStatement/index.vue'),
        meta: {
          // 索赔账单
          title: 'CompensationStatement',
          roles: ['admin', 'menu:compensationManagement:compensationStatement']
        }
      },
      {
        path: 'compensationRuleList',
        name: 'CompensationRuleList',
        component: () => import('@/views/compensationManagement/compensationRuleList/index.vue'),
        meta: {
          // 索赔规则
          title: 'CompensationRuleList',
          roles: ['admin', 'menu:compensationManagement:compensationRuleList']
        }
      }
    ]
  },
  // 编织袋回收
  {
    path: '/wovenBagManagement',
    component: Layout,
    redirect: '/wovenBagManagement',
    name: 'wovenBagManagement',
    alwaysShow: true, // 只有一个子菜单时显示父菜单
    meta: {
      // title: '编织袋管理',
      title: 'wovenBagManagement',
      icon: 'woven',
      roles: ['admin', 'menu:wovenBagManagement']
    },
    children: [
      {
        path: 'usageConfiguration',
        name: 'usageConfiguration',
        component: () => import('@/views/wovenBagManagement/usageConfiguration/index'),
        meta: {
          // title: '使用量配置',
          title: 'usageConfiguration',
          roles: ['admin', 'menu:wovenBagManagement:usageConfiguration']
        }
      },
      {
        path: 'recyclingManagement',
        name: 'recyclingManagement',
        component: () => import('@/views/wovenBagManagement/recyclingManagement/index'),
        meta: {
          // title: '回收管理',
          title: 'recyclingManagement',
          roles: ['admin', 'menu:wovenBagManagement:recyclingManagement']
        }
      },
      {
        path: 'detailManagement',
        name: 'DetailManagement',
        component: () => import('@/views/wovenBagManagement/detailManagement/index'),
        meta: {
          // title: '明细管理',
          title: 'detailManagement',
          roles: ['admin', 'menu:wovenBagManagement:detailManagement']
        }
      },
      {
        path: 'recyclingSummaryQuery',
        name: 'RecyclingSummaryQuery',
        component: () => import('@/views/wovenBagManagement/recyclingSummaryQuery/index'),
        meta: {
          // title: '回归汇总查询',
          title: 'recyclingSummaryQuery',
          roles: ['admin', 'menu:wovenBagManagement:recyclingSummaryQuery']
        }
      },
      {
        path: 'monthlySummary',
        name: 'MonthlySummary',
        component: () => import('@/views/wovenBagManagement/monthlySummary/index'),
        meta: {
          // title: '按月汇总',
          title: 'MonthlySummary',
          roles: ['admin', 'menu:wovenBagManagement:monthlySummary']
        }
      }
    ]
  },
  // 轨迹管理
  {
    path: '/trajectoryManagement',
    component: Layout,
    redirect: '/trajectoryQuery',
    name: 'TrajectoryManagement',
    alwaysShow: true,
    meta: {
      // title: '轨迹管理',
      title: 'TrajectoryManagement',
      icon: 'trajectory',
      roles: ['admin', 'menu:trajectory-management']
    },
    children: [
      {
        path: 'trajectoryQuery',
        name: 'TrackQuery',
        component: () => import('@/views/trajectoryManagement/index'),
        // hidden: true,
        meta: {
          // title: '轨迹查询'
          title: 'TrajectoryQuery',
          roles: ['admin', 'menu:trajectory-management:trajectory-query']
        }
      },
      {
        path: 'serviceTrajectoryMatching',
        name: 'ServiceTrajectoryMatching',
        component: () => import('@/views/trajectoryManagement/serviceTrajectoryMatching/index'),
        // hidden: true,
        meta: {
          // title: '服务商轨迹匹配'
          title: 'serviceTrajectoryMatching',
          roles: ['admin', 'menu:trajectory-management:service-trajectory-matching']
        }
      }
      // {
      //   path: 'serviceTrajectoryMatching',
      //   name: 'ServiceTrajectoryMatching',
      //   component: () => import('@/views/trajectoryManagement/serviceTrajectoryMatching/index'),
      //   // hidden: true,
      //   meta: {
      //     // title: '服务商轨迹匹配'
      //     title: 'serviceTrajectoryMatching',
      //     roles: ['admin', 'menu:trajectory-management:service-trajectory-matching']
      //   }
      // }
    ]
  },
  {
    path: '/vehicleManagement',
    component: Layout,
    redirect: '/vehicleManagement',
    children: [
      {
        path: 'vehicleManagement',
        name: 'vehicleManagement',
        component: () => import('@/views/resource/vehicleManagement'),
        meta: {
          // title: '车辆管理',
          title: 'vehicleManagement1',
          icon: 'transport',
          roles: ['admin', 'menu:system-manage:vehicle-management']
        }
      }
    ]
  },
  // 智能柜
  {
    path: '/smartCabinet',
    component: Layout,
    name: 'smartCabinet',
    alwaysShow: true,
    meta: {
      // title: '智能柜',
      title: 'smartCabinet',
      icon: 'intelligentCabinet',
      roles: ['admin', 'menu:smart-Cabinet']
    },
    children: [
      {
        path: 'cabinetInformation',
        name: 'cabinetInformation',
        component: () => import('@/views/smartCabinet/cabinetInformation'),
        // hidden: true,
        meta: {
          // title: '柜子信息',
          title: 'cabinetInformation',
          roles: ['admin', 'menu:smart-Cabinet:cabinet-information']
        }
      },
      {
        path: 'latticeMaintenance',
        name: 'latticeMaintenance',
        component: () => import('@/views/smartCabinet/latticeMaintenance'),
        // hidden: true,
        meta: {
          // title: '格子维护',
          title: 'latticeMaintenance',
          roles: ['admin', 'menu:smart-Cabinet:lattice-maintenance']
        }
      },
      {
        path: 'cabinetMaintenance',
        name: 'cabinetMaintenance',
        component: () => import('@/views/smartCabinet/cabinetMaintenance'),
        // hidden: true,
        meta: {
          // title: '柜子维护',
          title: 'cabinetMaintenance',
          roles: ['admin', 'menu:smart-Cabinet:cabinet-maintenance']
        }
      }
    ]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
];

const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    // mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const { matcher } = createRouter();
  router.matcher = matcher; // reset router
}

// 路由白名单
export const WHITE_LIST = (() => {
  return constantRoutes.map((item) => {
    return item.path;
  });
})();

export default router;

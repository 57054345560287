<template>
  <div class="app-container">
    <el-card class="mb5">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="mini"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            <!--报价方案名称-->
            <el-form-item :label="$t('basicData.TheNameOfTheQuoteProposal')" prop="name">
              <el-input v-model="queryForm.params.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--应收主体-->
            <el-form-item :label="$t('basicData.businessPrincipal')" prop="providerInfoId">
              <el-select
                v-model="queryForm.params.providerInfoId"
                filterable
                clearable
                remote
                reserve-keyword
                :remote-method="getProviderPage"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
                @focus="getProviderPage()"
                @change="providerInfoChange()"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerName + '（' + item.code + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--网点-->
            <el-form-item :label="$t('basicData.Outlets')" prop="siteId">
              <QuerySiteCmp
                :val="queryForm.params.siteId"
                :provider-id="queryForm.params.providerInfoId"
                @change="val => queryForm.params.siteId = val"
              />
              <!-- <el-select
                v-model="queryForm.params.siteId"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getSitePageList"
                @focus="getSitePageList()"
              >
                请先选择应收主体
                <el-option
                  v-for="item in outletsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--快递-->
                <el-option key="1" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                <!--揽收-->
                <el-option key="2" :label="$t('collectionCenter.lanshou')" :value="1" />
                <!--派件-->
                <el-option key="3" :label="$t('collectionCenter.delivery')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--使用状态-->
            <el-form-item :label="$t('basicData.UsageStatus')" prop="offerPriceStatus">
              <el-select
                v-model="queryForm.params.offerPriceStatus" clearable :placeholder="$t('basicData.pleaseChoose')"
                style="width: 100%;"
              >
                <!--全部-->
                <el-option :key="0" :label="$t('basicData.all')" value="" />
                <!--未使用-->
                <el-option :key="1" :label="$t('basicData.NotUsed')" value="2" />
                <!--已使用-->
                <el-option :key="2" :label="$t('basicData.inUse')" value="1" />
                <!--已过期-->
                <el-option :key="3" :label="$t('basicData.Expired')" value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--报价类型-->
            <el-form-item :label="$t('basicData.QuoteType')" prop="offerType">
              <el-select
                v-model="queryForm.params.offerType"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                clearable
              >
                <!--全部-->
                <el-option :key="0" :label="$t('basicData.all')" value="" />
                <el-option
                  v-for="item in quoteTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--客户-->
            <el-form-item :label="$t('GlobalSetObj.client')">
              <el-select
                v-model="selectedCustomer"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                filterable
                clearable
                remote
                reserve-keyword
                :remote-method="getCustomerOption"
                @clear="getCustomerOption('')"
              >
                <el-option
                  v-for="item in customerOption"
                  :key="item.id"
                  :label="item.customerAlias + '（' + item.username + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div style="margin-top: 10px;">
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="mb10">
        <!--添加-->
        <el-button
          v-permit:remove="'btn:finance:basicData:quotationManagement:add'"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handlerOperation(1)"
        >{{ $t('basicData.Add') }}
        </el-button>
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--报价方案名称-->
        <el-table-column
          prop="name"
          :label="$t('basicData.TheNameOfTheQuoteProposal')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-end"
              popper-class="copy"
              trigger="hover"
            >
              <el-button style="color: black;font-size: 12px" type="text" @click="copy(scope.row.name)">
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="WaybillNumber" @click="handlerOperation(2, scope.row)">
                {{ scope.row.name }}
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <!--分区方案名称-->
        <el-table-column
          prop="partitionName"
          :label="$t('basicData.PartitionSchemeName')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div class="WaybillNumber" @click="handlerOperation(3, scope.row)">
              {{ scope.row.partitionName }}
            </div>
          </template>
        </el-table-column>
        <!--应收主体-->
        <el-table-column
          prop="providerInfoName"
          :label="$t('basicData.businessPrincipal')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        />
        <!--网点-->
        <el-table-column
          prop="siteName"
          :label="$t('basicData.Outlets')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        />
        <!--客户-->
        <el-table-column
          prop="customerName"
          :label="$t('GlobalSetObj.client')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        />
        <!--报价类型-->
        <el-table-column
          prop="offerType"
          :label="$t('basicData.QuoteType')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--1:标准价 2:应收主体价 3:网点价 4:指定客户价-->
            <span v-if="scope.row.offerType === 1">{{ $t('basicData.StandardPrice') }}</span>
            <!--应收主体价-->
            <span v-else-if="scope.row.offerType === 2">{{ $t('basicData.BusinessEntityPrice') }}</span>
            <!--网点价-->
            <span v-else-if="scope.row.offerType === 3">{{ $t('basicData.SitePrice') }}</span>
            <!--指定客户价-->
            <span v-else-if="scope.row.offerType === 4">{{ $t('basicData.SpecifyCustomerPrice') }}</span>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column
          prop="code"
          :label="$t('collectionCenter.businessT')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <!--快递-->
              <span v-if="scope.row.businessType === 0">{{ $t('collectionCenter.expressDelivery') }}</span>
              <!--揽收-->
              <span v-else-if="scope.row.businessType === 1">{{ $t('collectionCenter.lanshou') }}</span>
              <!--派件-->
              <span v-else-if="scope.row.businessType === 2">{{ $t('collectionCenter.delivery') }}</span>
              <!--快递\揽收-->
              <span v-else-if="scope.row.businessType === 3">{{ $t('collectionCenter.expressDelivery') }}、{{ $t('collectionCenter.lanshou') }}</span>
              <!--快递\派件-->
              <span v-else-if="scope.row.businessType === 4">{{ $t('collectionCenter.expressDelivery') }}、{{ $t('collectionCenter.delivery') }}</span>
              <!--揽收\派件-->
              <span v-else-if="scope.row.businessType === 5">{{ $t('collectionCenter.lanshou') }}、{{ $t('collectionCenter.delivery') }}</span>
              <!--快递\揽收\派件-->
              <span v-else-if="scope.row.businessType === 6">{{ $t('collectionCenter.expressDelivery') }}、{{ $t('collectionCenter.lanshou') }}、{{ $t('collectionCenter.delivery') }}</span>
            </div>
          </template>
        </el-table-column>
        <!--使用类型-->
        <!--        <el-table-column-->
        <!--          prop="offerAttr"-->
        <!--          :label="$t('basicData.UsageType')"-->
        <!--          align="center"-->
        <!--          width="140"-->
        <!--          :show-overflow-tooltip="true"-->
        <!--        >-->
        <!--          <template slot-scope="scope">-->
        <!--            &lt;!&ndash;1:应收 2:应付&ndash;&gt;-->
        <!--            <span v-if="scope.row.offerAttr === 1">{{ $t('basicData.Receivables') }}</span>-->
        <!--            &lt;!&ndash;应付&ndash;&gt;-->
        <!--            <span v-else-if="scope.row.offerAttr === 2">{{ $t('basicData.payable') }}</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--使用状态-->
        <el-table-column
          prop="offerPriceStatus"
          :label="$t('basicData.UsageStatus')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>
              <!--禁用-->
              <span v-if="scope.row.offerPriceStatus === 3">{{ $t('basicData.disable') }}</span>
              <!--未使用-->
              <span v-if="scope.row.offerPriceStatus === 2">{{ $t('basicData.NotUsed') }}</span>
              <!--已使用-->
              <span v-if="scope.row.offerPriceStatus === 1">{{ $t('basicData.inUse') }}</span>
              <!--已过期-->
              <span v-if="scope.row.offerPriceStatus === 0">{{ $t('basicData.Expired') }}</span>
            </span>
          </template>
        </el-table-column>
        <!--有效期开始-->
        <el-table-column
          prop="startTime"
          :label="$t('basicData.TheValidityPeriodBegins')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--有效期结束-->
        <el-table-column
          prop="endTime"
          :label="$t('basicData.EndOfValidityPeriod')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--创建人-->
        <el-table-column
          prop="createUserStr"
          :label="$t('basicData.CreatePeople')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          :label="$t('basicData.CreationTime')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--最后修改人-->
        <el-table-column
          prop="updateUserStr"
          :label="$t('basicData.LastModifier')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--最后修改时间-->
        <el-table-column
          prop="updateTime"
          :label="$t('basicData.LastModified')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--备注-->
        <!--        <el-table-column-->
        <!--          prop="remark"-->
        <!--          :label="$t('basicData.remark')"-->
        <!--          align="left"-->
        <!--          :show-overflow-tooltip="true"-->
        <!--        />-->
        <el-table-column
          :label="$t('basicData.operate')" fixed="right" align="center"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.status === true"
                v-permit="'but:finance:basicData:accountManagement:edit'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="asyncPartitionEdit(scope.row, 0)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini"
                >{{ $t('basicData.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                v-if="scope.row.status === false"
                v-permit="'btn:finance:basicData:quotationManagement:edit'"
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="asyncPartitionEdit(scope.row, 1)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" type="success"
                  size="mini"
                >{{ $t('basicData.enable') }}</el-button>
              </el-popconfirm>
              <!--删除-->
              <!--              <el-popconfirm-->
              <!--                v-permit="'but:finance:basicData:quotationManagement:delete'"-->
              <!--                :title="$t('basicData.AreYouSureYouWantToDelete')"-->
              <!--                @confirm="deleteAccount(scope.row)"-->
              <!--              >-->
              <!--                &lt;!&ndash;删除&ndash;&gt;-->
              <!--                <el-button slot="reference" class="ml" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>-->
              <!--              </el-popconfirm>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 添加、详情 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      :with-header="false"
      direction="ltr"
      size="90%"
    >
      <detail-log
        :key="editKey"
        :edit-data="drawerObj.editData"
        :operation-type="drawerObj.typeIndex"
        @saveChange="updatePage"
      />
    </el-drawer>
    <!--分区详情-->
    <el-drawer
      :visible.sync="showPartitionDetail"
      :with-header="false"
      direction="ltr"
      size="80%"
    >
      <details-cmp
        :key="editKeyPartition"
        :type-index="2"
        :edit-data="drawerObj.editData"
        :state-list="stateList"
        style="height: 100%;"
        @updatePage="updatePage"
      />
    </el-drawer>
  </div>
</template>

<script>
import { apiOfferPricePage, apiOfferPriceSwitch, apiProviderPage, apiAreaPage } from '@/api/finance/basicData';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { copyData } from '@/utils/copyData';
import { apiGetCustomerPage } from '@/api/finance/contasDeClientes';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp';

// 州请求参数
const stateQuery = {
  pageNumber: 1,
  pageSize: 9999999,
  params: {
    level: 1,
    parentId: ''
  }
};

export default {
  name: 'QuotationManagement',
  components: {
    'detail-log': () => import('./cmp/detailLog'),
    'details-cmp': () => import('./cmp/partitionDetails'),
    QuerySiteCmp
  },
  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        orderDirection: 'DESC',
        params: {
          name: '', // 报价名称
          providerInfoId: '', // 应收主体id
          businessType: null,
          siteId: '', // 网点id
          offerAttr: '', // 使用类型 1:应收 2:应付
          offerPriceStatus: '', // 报价状态 已过期:0 使用中:1 未启用:2
          offerType: '', // 1:标准价 2:应收主体价 3:网点价
          customerIds: [] // 客户
        }
      },
      tableData: [],
      total: 0,
      drawerObj: {
        visible: false,
        typeIndex: 1,
        editData: {}
      },
      editKey: new Date().getTime(),
      quoteTypeList: [
        { name: this.$t('basicData.StandardPrice'), id: 1 }, // '标准价'
        { name: this.$t('basicData.BusinessEntityPrice'), id: 2 }, // '应收主体价'
        { name: this.$t('basicData.SitePrice'), id: 3 } // '网点价'
      ], // 报价类型
      userIdAndNameObj: null,
      providerList: [], // 应收主体选项
      outletsList: [], // 站点选项
      showPartitionDetail: false,
      editKeyPartition: new Date().getTime(),
      customerOption: [],
      selectedCustomer: '',
      stateList: [] // 所有州
    };
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();
    this.providerInfoChange();
    // 获取客户选项
    this.getCustomerOption('');

    // 获取所有一级州
    setTimeout(() => {
      this.asyncApiAreaPage();
    }, 1000);
  },
  methods: {
    // 获取客户选项
    getCustomerOption(name) {
      const param = {
        pageSize: 99999999,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiGetCustomerPage(param).then(res => {
        if (res.code === 200) {
          this.customerOption = res.data.records;
        }
      });
    },
    // 分页获取站点
    // getSitePageList(name = '') {
    //   const param = {
    //     pageNumber: 1,
    //     pageSize: 10,
    //     params: {
    //       siteStatus: 1,
    //       name,
    //       providerId: this.queryForm.params.providerInfoId
    //     }
    //   };
    //   getDistributionDotList(param).then(res => {
    //     if (res.status === 'OK') {
    //       this.outletsList = res.data.records;
    //     }
    //   });
    // },
    // 应收主体变化
    providerInfoChange() {
      this.queryForm.params.siteId = '';
      // this.outletsList = [];
      // this.getSitePageList();
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 获取应收主体选项
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name,
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm.params = {
        name: '', // 报价名称
        providerInfoId: '', // 应收主体id
        siteId: '', // 网点id
        offerAttr: '', // 使用类型 1:应收 2:应付
        offerPriceStatus: '', // 报价状态 已过期:0 使用中:1 未启用:2
        offerType: '', // 1:标准价 2:应收主体价 3:网点价
        customerIds: []
      };
      this.selectedCustomer = '';
    },
    // 添加\详情
    handlerOperation(type, row) {
      if (row) {
        this.drawerObj.editData = row;
      } else {
        this.drawerObj.editData = {};
      }
      if (type === 3) {
        this.showPartitionDetail = true;
        this.editKeyPartition = new Date().getTime();
        // this.$router.push({ name: 'Partition', params: row });
        return;
      }
      this.editKey = new Date().getTime();
      this.drawerObj.typeIndex = type;
      this.drawerObj.visible = true;
    },
    // 获取列表数据
    getData() {
      this.queryForm.params.name = this.queryForm.params.name ? this.queryForm.params.name.trim() : '';
      if (this.selectedCustomer) {
        this.queryForm.params.customerIds = [this.selectedCustomer];
      } else {
        this.queryForm.params.customerIds = [];
      }
      apiOfferPricePage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
          // this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 启用、禁用
    asyncPartitionEdit(row, type) {
      const param = {
        id: row.id,
        sw: type
      };
      apiOfferPriceSwitch(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('basicData.TheOperationSucceeded')); // 操作成功
          this.getDataSearch();
        }
      });
    },
    // 删除
    deleteAccount(row) {},
    // 数据更新
    updatePage(type) {
      if (type) {
        // this.getDataSearch();
        this.getData();
      }
      this.drawerObj.visible = false;
      this.showPartitionDetail = false;
    },

    // 获取所有州
    asyncApiAreaPage() {
      apiAreaPage(stateQuery).then(res => {
        const { records } = res.data;
        this.stateList = (records || []).map(item => {
          const { id, name, twoCode } = item;
          const obj = {
            label: twoCode,
            value: id,
            name
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.WaybillNumber {
  color: #409eff;
  cursor: pointer;
}
.queryFormClass .el-form-item--mini.el-form-item,
.queryFormClass .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
</style>

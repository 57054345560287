<template>
  <div class="app-container">
    <div ref="searchForm">
      <el-card class="mb5">
        <el-form
          ref="form" :model="queryForm"
          label-position="top" size="small"
          class="searchContainer"
        >
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--账单时间 :range-separator="$t('operationCenter.to')"-->
                <el-form-item :label="$t('receivablePayable.BillingDate')">
                  <el-date-picker
                    v-model="queryForm.params.payableTimes"
                    style="width: 100%"
                    range-separator="-"
                    type="daterange"
                    :start-placeholder="$t('operationCenter.startDate')"
                    :end-placeholder="$t('operationCenter.endDate')"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--对账状态-->
                <el-form-item :label="$t('receivablePayable.ReconciliationStatus')">
                  <el-select
                    v-model="queryForm.params.auditStatus"
                    filterable
                    multiple
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <!--全部-->
                    <!--                    <el-option key="0" :label="$t('basicData.all')" :value="null" />-->
                    <!--待审核-->
                    <el-option key="1" :label="$t('receivablePayable.ToBeReviewed')" :value="0" />
                    <!--已通过-->
                    <el-option key="2" :label="$t('receivablePayable.Passed')" :value="1" />
                    <!--未通过-->
                    <el-option key="3" :label="$t('receivablePayable.DoesNotPass')" :value="2" />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--应收主体-->
                <el-form-item :label="$t('basicData.businessPrincipal')">
                  <el-select
                    v-model="queryForm.params.providerId"
                    filterable
                    :placeholder="$t('operationCenter.Only10AreDisplayed')"
                    :remote-method="getProviderPage"
                    remote
                    clearable
                    style="width: 100%;"
                    @focus="getProviderPage('')"
                  >
                    <el-option
                      v-for="item in providerList"
                      :key="item.id"
                      :label="item.providerName + '（' + item.code + '）'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--客户-->
                <el-form-item :label="$t('GlobalSetObj.client')">
                  <CustomerSelectCmp style="width: 100%" :is-all="false" @change=" val => queryForm.params.customerId = val" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--生成时间-->
                <el-form-item :label="$t('receivablePayable.BuildTime')">
                  <el-date-picker
                    v-model="queryForm.params.time"
                    type="datetimerange"
                    style="width: 100%"
                    :start-placeholder="$t('operationCenter.startDate')"
                    :end-placeholder="$t('operationCenter.endDate')"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--生成人-->
                <el-form-item :label="$t('receivablePayable.BuildThePerson')">
                  <QueryUserV2Cmp :val="queryForm.params.handlerId" style="width: 100%" @change=" val => queryForm.params.handlerId = val" />
                </el-form-item>
                <!-- <el-form-item :label="$t('GlobalSetObj.client')">
                  <CustomerSelectCmp style="width: 100%" :is-all="false" @change=" val => queryForm.params.customerId = val" />
                </el-form-item> -->
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--账单批次号-->
                <el-form-item :label="$t('receivablePayable.BillingBatchNumber')">
                  <!-- <el-input v-model="queryForm.params.billBatchNumber" clearable /> -->
                  <InputTextareaCmp
                    ref="inputTextareaRef"
                    :val="queryForm.params.billBatchNumber"
                    :max-length="200"
                    @input="(_, val) => queryForm.params.billBatchNumber = val"
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>

        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh"
          size="small"
          @click="onReset"
        >{{ $t('basicData.reset') }}
        </el-button>
      </el-card>
    </div>

    <el-card>
      <div style="margin-bottom: 10px;" class="row">
        <div class="flex">
          <!--审核-->
          <el-button
            v-permit:remove="'btn:receivableManagementSite:toExamine:approved'"
            size="small"
            type="primary"
            @click="operationBtn(1)"
          >{{ $t('receivablePayable.examine') }}</el-button>
          <!--自定义栏目-->
          <CustomStatusBar
            :columns-data="TABLE_HEADER"
            :params="searchParams"
            :save-params="customParams"
            @updateColumns="updateColumns"
          />
        </div>
        <div>
          <el-button
            v-permit:remove="'btn:receivableManagementSite:toExamine:exportDetail'"
            icon="el-icon-download"
            size="small"
            @click="onExport"
          >{{ $t('basicData.账单导出') }}</el-button>
          <el-button
            v-permit:remove="'btn:receivableManagementSite:toExamine:export'"
            icon="el-icon-download"
            size="small"
            @click="onExportDetail"
          >{{ $t('basicData.账单明细导出') }}</el-button>
        </div>
      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template v-slot="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in currentColumns"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <!--账单批次号-->
              <div v-if="item.prop === 'billBatchNumber'" class="batchNumber" @click="showBillBatchNumber(scope.row)">
                {{ scope.row.billBatchNumber }}
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <div
                v-if="((scope.row.auditStatus === 0 || scope.row.auditStatus === 2) && $store.state.user.id === scope.row.createUser) || $store.getters.button_permissions.includes('btn:receivableManagementSite:toExamine:recall')"
              >
                <el-button
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button><!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getExaminePage"
            @size-change="getExaminePage"
          />
        </div>
      </div>
    </el-card>

    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <div>
        <batch-number-detail
          :key="batchNumber"
          :batch-number="batchNumber"
          :bill-batch-data="billBatchData"
          @closeDetail="closeDetail"
        />
      </div>
    </el-drawer>
    <!--    审核-->
    <el-dialog
      :title="$t('receivablePayable.examine')"
      :visible.sync="showExamine"
      width="600px"
    >
      <div>
        <div>
          <el-form
            :key="showExamine" ref="examineForm" :model="examineForm"
            label-width="auto"
          >
            <!--审核-->
            <el-form-item
              :label="$t('receivablePayable.examine') + '：'"
              prop="examine"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-radio-group v-model="examineForm.examine">
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据合并生成一个收款单）-->
                  <el-radio :label="0">{{ $t('receivablePayable.PassMultipleDataMergingToGenerateOneReceipt') }}</el-radio>
                </div>
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据生成多个收款单）-->
                  <el-radio :label="1">{{ $t('receivablePayable.GenerateMultipleReceiptsThrough') }}</el-radio>
                </div>
                <div>
                  <!--不通过-->
                  <el-radio :label="2">{{ $t('receivablePayable.DoesNotPass') }}</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('receivablePayable.remark') + '：'"
              prop="remark"
              :rules="{ required: examineForm.examine === 2, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model="examineForm.remark" type="textarea" :rows="6" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examine(1)">{{ $t('basicData.Cancel') }}</el-button>
        <el-button type="primary" @click="examine(2)">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiSiteBillAudit, apiSiteBillGetPage, apiSiteWithdraw } from '@/api/finance/receivables';
import { apiProviderPage, apiSiteBillExport, apiSiteBillExportDetailed } from '@/api/finance/basicData';
import { cloneDeep } from 'lodash-es';
import { goResourceTaskList } from '@/utils/goExportList.js';
import { exportDetailCheck, TABLE_HEADER } from './utils';
import batchNumberDetail from './cmp/batchNumberDetail';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import CustomerSelectCmp from '@/components/CustomerSelectCmp/CustomerSelectCmp.vue';
import QueryUserV2Cmp from '@/views/finance/cmp/Query/QueryUserV2Cmp.vue';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';

const PARAM = {
  billBatchNumber: '', // 对账单批次号
  receiptNumber: '',
  auditStatus: [0, 2], // 对账状态
  siteId: '',
  providerId: '', // 供应商应收主体
  customerId: '', // 客户
  handlerId: '', // 生成人id
  time: '', // 生成时间
  payableTimes: [] // 账单日期
};

export default {
  name: 'ToExamine',
  components: {
    batchNumberDetail,
    CustomerSelectCmp,
    QueryUserV2Cmp,
    InputTextareaCmp,
    CustomStatusBar: () => import('@/components/customStatusBar/index.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      TABLE_HEADER,
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...PARAM
        }
      },
      createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      tableKey: new Date().getTime(),
      batchNumber: '', // 对账单批次号
      billBatchData: {},
      providerList: [],
      selectData: [],
      rowSelectFlag: false,
      showExamine: false,
      examineForm: {
        examine: 2,
        remark: ''
      },
      currentColumns: [],
      searchParams: {
        type: 0,
        menuEnum: 'companyToSiteBillReview'
      },
      customParams: {
        templateField: '',
        templateName: '应收账单审核',
        userId: '',
        ...this.searchParams
      }
    };
  },

  created() {
    this.createUser = getUserIdAndNameObj();
    this.getExaminePage();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 更新自定义栏目columns
    updateColumns(list) {
      this.$nextTick(() => {
        this.currentColumns = cloneDeep(list);
        this.tableKey = new Date().getTime();
      });

      // console.log(list, '列表呈现的columns');
    },
    // 撤回
    recall(row) {
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        // const param = {
        //   id: row.id
        // };
        apiSiteWithdraw(row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 获取业务主体
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },

    // 请求数据处理
    getQueryData() {
      const query = cloneDeep(this.queryForm);

      query.params.billBatchNumbers = [];

      const { time } = query.params;

      // 生成时间处理
      if (Array.isArray(time) && time.length === 2) {
        query.params.startTime = time[0];
        query.params.endTime = time[1];
      }
      query.params.time = undefined;

      // 账单批次号
      if (query.params.billBatchNumber.length >= 1) {
        query.params.billBatchNumbers = this.$refs.inputTextareaRef.getValue();
      }
      // 替换字段
      query.params.billBatchNumber = undefined;

      return query;
    },

    // 获取列表数据
    getExaminePage() {
      const row = this.getQueryData();
      apiSiteBillGetPage(row).then(res => {
        if (res.code === 200) {
          // this.tableData = res.data.records;
          this.tableData = (res.data.records || []).map(item => {
            return Object.freeze(item);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      if (this.rowSelectFlag) return;
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.billBatchNumber;
      this.billBatchData = data;
      this.showDetail = true;
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    // 重置
    onReset() {
      this.queryForm.params = {
        ...PARAM
      };

      this.searchResources();
    },
    // 操作
    operationBtn(type) {
      if (this.selectData.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      if (type === 1) {
        this.showExamine = true;
        return false;
      }
    },
    // 审核保存
    examine(type) {
      if (type === 2) {
        const ids = [];
        this.selectData.map(item => {
          ids.push(item.billBatchNumber);
        });
        const param = {
          billBatchNumbers: ids,
          operationType: this.examineForm.examine,
          auditReason: this.examineForm.remark
        };
        this.$refs['examineForm'].validate((valid) => {
          if (valid) {
            apiSiteBillAudit(param).then(res => {
              if (res.code === 200) {
                this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                this.getExaminePage();
                this.showExamine = false;
                this.examineForm.examine = 2;
                this.examineForm.remark = '';
              }
            }).catch(err => {
              console.error(err);
            });
          }
        });
      } else {
        this.showExamine = false;
        this.examineForm.examine = 2;
        this.examineForm.remark = '';
      }
    },

    // 账单导出
    onExport() {
      const { params } = this.getQueryData();
      params.ids = this.selectData.map(item => {
        return item.id;
      });

      apiSiteBillExport(params).then(() => {
        goResourceTaskList();
      }).catch(err => {
        console.error(err);
      });
    },

    // 账单明细导出
    onExportDetail() {
      const { params } = this.getQueryData();
      // 校验
      if (!exportDetailCheck(params)) {
        return;
      }

      params.ids = this.selectData.map(item => {
        return item.id;
      });

      apiSiteBillExportDetailed(params).then(() => {
        goResourceTaskList();
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<template>
  <div class="app-container">
    <el-card class="mb5">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--客户名称-->
            <el-form-item :label="$t('basicData.CustomerName')" prop="customerAlias">
              <el-input v-model="queryForm.params.customerAlias" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--客户编号-->
            <el-form-item :label="$t('basicData.CustomerCode')" prop="id">
              <el-input v-model="queryForm.params.id" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--账户名称-->
            <el-form-item :label="$t('basicData.AccountName')" prop="username">
              <el-input v-model="queryForm.params.username" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="small"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div style="margin-bottom: 10px;clear: both;">
        <div style="float: right;">
          <!--导出账户信息-->
          <el-button
            v-permit:remove="'but:finance:basicData:accountManagement:ExportAccountInformation'"
            size="small"
            type="primary"
            @click="exportAccount(1)"
          >{{ $t('basicData.ExportAccountInformation') }}
          </el-button>
          <!--导出账户流水-->
          <el-button
            v-permit:remove="'but:finance:basicData:accountManagement:ExportAccountRollover'"
            size="small"
            type="primary"
            @click="exportAccount(2)"
          >{{ $t('basicData.ExportAccountRollover') }}
          </el-button>
        </div>
        <div style="clear: both;" />
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        />
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--客户名称-->
        <el-table-column
          prop="customerAlias"
          :label="$t('basicData.CustomerName')"
          align="left"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-end"
              popper-class="copy"
              trigger="hover"
            >
              <el-button type="text" @click="copy(scope.row.customerAlias)">{{ $t('orderDetails.copy') }}</el-button>
              <span slot="reference" style="color: #409eff; cursor: pointer;" @click="accountDetails(scope.row)">{{ scope.row.customerAlias }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 客户编码 -->
        <el-table-column
          prop="id"
          :label="$t('basicData.CustomerCode')"
          align="left"
          min-width="160"
          :show-overflow-tooltip="true"
        />
        <!--账户名称-->
        <el-table-column
          prop="username"
          :label="$t('basicData.AccountName')"
          align="left"
          min-width="160"
          :show-overflow-tooltip="true"
        />
        <!--账户余额-->
        <el-table-column
          prop="balance"
          :label="$t('basicData.AccountBalance')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        />
        <!--信用额度-->
        <el-table-column
          prop="initialCreditLimit"
          :label="$t('basicData.creditLimit')"
          align="center"
          min-width="140"
          :show-overflow-tooltip="true"
        />
        <!--累计未结算-->
        <el-table-column
          prop="unsettledAmount"
          :label="$t('basicData.AccumulatedUnsettled')"
          align="center"
          min-width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.unsettledAmount }}</div>
          </template>
        </el-table-column>
        <!--总计余额-->
        <el-table-column
          prop="totalBalance"
          :label="$t('basicData.TotalBalance')"
          align="center"
          min-width="140"
          :show-overflow-tooltip="true"
        />
        <!--账单账期-->
        <el-table-column
          prop="paymentCycle"
          :label="$t('basicData.billingPeriod')"
          align="center"
          min-width="140"
          :show-overflow-tooltip="true"
        />
        <!--操作-->
        <el-table-column
          :label="$t('basicData.operate')"
          align="left"
          width="160"
          fixed="right"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-permit:remove="'but:finance:basicData:accountManagement:setUpABillingPeriod'"
                size="mini"
                type="primary"
                @click="setUpABillingPeriod(scope.row)"
              >{{ $t('basicData.SetUpABillingPeriod') }} <!--设置账期-->
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>
    <!--设置账期-->
    <el-dialog
      :title="$t('basicData.SetUpABillingPeriod')"
      :visible.sync="showCollectionCycle"
      width="60%"
      :append-to-body="true"
    >
      <div>
        <el-form
          :key="showCollectionCycle"
          ref="paymentCycle"
          :model="collectionCycleData"
          :rules="rules"
          label-width="auto"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <!--客户名称-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.CustomerName') + '：'" prop="customerAlias">
                  <span>{{ collectionCycleData.customerAlias }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <!--当前账期-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.CurrentBillingPeriod') + '：'" prop="paymentCycle">
                  <span>{{ collectionCycleData.paymentCycle }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <!--调整后账期-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.AdjustTheBillingPeriod') + '：'" prop="days">
                  <el-input v-model="collectionCycleData.days" />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="showCollectionCycle = false">{{ $t('basicData.Cancel') }}</el-button>
        <!--确 定-->
        <el-button type="primary" @click="saveCollectionCycle()">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
    <!--账户详情-->
    <el-drawer
      :title="$t('basicData.AccountDetails')"
      :append-to-body="true"
      :visible.sync="showAccountDetail"
      direction="ltr"
      size="90%"
    >
      <div>
        <account-details :key="detailKey" :query-form="rowDetail" @closeDetail="closeDetail" />
      </div>
    </el-drawer>
    <!--选择导出时间-->
    <el-dialog
      :title="$t('basicData.SelectTheExportTime') + '：'"
      :visible.sync="showExportTime"
      width="600px"
      :append-to-body="true"
    >
      <div>
        <el-form
          ref="exportTime"
          :model="exportTimeForm"
          label-width="auto"
          size="small"
        >
          <el-form-item
            :label="$t('basicData.SelectTheExportTime')"
            prop="exportTime"
            :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
          >
            <el-date-picker
              v-model="exportTimeForm.exportTime"
              style="width: 100%"
              type="datetimerange"
              :range-separator="$t('operationCenter.to')"
              :start-placeholder="$t('operationCenter.startDate')"
              :end-placeholder="$t('operationCenter.endDate')"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="showExportTime = false;exportTimeForm.exportTime = []">{{ $t('basicData.Cancel') }}</el-button>
        <!--确 定-->
        <el-button type="primary" @click="exportAccountFlow()">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiGetCustomerPage, apiUpdatePaymentCycle } from '@/api/finance/contasDeClientes';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { copyData } from '@/utils/copyData';
import { apiCustomerExportAccountInfo, apiCustomerExportAccountStatement } from '@/api/finance/basicData';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import Big from 'big.js';
import { goExportList } from '@/utils/goExportList.js';

const PARAMS = {
  customerAlias: '',
  id: '',
  username: ''
};

export default {
  name: 'CustomerAccount',

  components: {
    'account-details': () => import('./cmp/accountDetails')
  },
  mixins: [estimateTableHeight],
  data() {
    // 正整数
    const positiveInteger = (rule, value, callback) => {
      const reg = /^(0|[1-9][0-9]*)$/;
      if (value.length > rule.length) {
        return callback(new Error(this.$i18n.t('basicData.TheLengthCannotBeGreaterThan') + rule.length)); // 长度不能大于
      }
      if (rule.required) {
        if (value) {
          if (reg.test(value) === false) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            return callback();
          }
        } else {
          return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
        }
      } else {
        if (value) {
          if (reg.test(value) === false) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            return callback();
          }
        } else {
          return callback();
        }
      }
    };
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...PARAMS
        }
      },
      tableData: [],
      total: 0,
      drawerObj: {
        visible: false,
        editData: {},
        typeIndex: 1
      },
      editKey: new Date().getTime(),
      userIdAndNameObj: null,
      showCollectionCycle: false,
      collectionCycleData: {
        customerId: '',
        customerAlias: '',
        paymentCycle: '',
        days: ''
      },
      rules: {
        days: { required: true, validator: positiveInteger, length: 3, trigger: ['blur', 'change'] }
      },
      showAccountDetail: false,
      detailKey: new Date().getTime(),
      rowDetail: {},
      showExportTime: false,
      exportTimeForm: {
        exportTime: []
      },
      selectData: []
    };
  },

  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();
  },
  methods: {
    // 列表数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 导出账户流水
    exportAccountFlow() {
      const arr = this.selectData.map(item => {
        return item.id;
      });
      const param = {
        customerAlias: this.queryForm.params.customerAlias,
        customerIds: arr,
        receiptType: null,
        startTime: '',
        endTime: ''
      };
      if (this.exportTimeForm.exportTime && this.exportTimeForm.exportTime.length > 0) {
        param.startTime = this.exportTimeForm.exportTime[0];
        param.endTime = this.exportTimeForm.exportTime[1];
      } else {
        this.$message.warning(this.$i18n.t('basicData.SelectADatetime')); // '请选择时间'
        return;
      }
      apiCustomerExportAccountStatement(param).then(res => {
        if (res.code === 200) {
          // exportTips();
          return goExportList(this);
        }
      }).then(res => {
        if (res) {
          this.showExportTime = false;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 更新数据关闭详情弹窗
    closeDetail(type) {
      if (type) {
        this.getData();
      } else {
        this.showAccountDetail = false;
      }
    },
    // 查询详情
    accountDetails(row) {
      this.rowDetail = row;
      this.showAccountDetail = true;
      this.detailKey = new Date().getTime();
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm.params = { ...PARAMS };
    },
    // 获取列表数据
    getData() {
      apiGetCustomerPage(this.queryForm).then(res => {
        const { status, data } = res;
        if (status === 'OK') {
          this.tableData = data.records.map(item => {
            const { balance, unsettledAmount, updateUser, createUser } = item;
            const updateUserStr = getUserName(updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(createUser, this.userIdAndNameObj);
            const totalBalance = new Big(balance).minus(unsettledAmount).round(2).toNumber();
            return {
              ...item,
              updateUserStr,
              createUserStr,
              totalBalance
            };
          });

          this.total = data.total;
        }
      });
    },
    // 设置账期
    setUpABillingPeriod(row) {
      const { id, paymentCycle, customerAlias } = row;
      this.collectionCycleData = {
        id,
        paymentCycle,
        customerAlias,
        days: ''
      };
      this.showCollectionCycle = true;
    },
    // 保存修改账期
    saveCollectionCycle() {
      this.$refs.paymentCycle.validate((valid) => {
        if (valid) {
          const param = {
            customerId: this.collectionCycleData.id,
            paymentCycle: this.collectionCycleData.days
          };
          apiUpdatePaymentCycle(param).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // TheOperationSucceeded 操作成功
              this.getData();
              this.showCollectionCycle = false;
            }
          });
        }
      });
    },
    // 导出账户信息、导出账户流水
    exportAccount(type) {
      if (type === 2) {
        this.showExportTime = true;
      } else {
        const param = {
          customerIds: [],
          customerAlias: this.queryForm.params.customerAlias
        };
        param.customerIds = this.selectData.map(item => {
          return item.id;
        });
        apiCustomerExportAccountInfo(param).then(res => {
          if (res.code === 200) {
            goExportList(this);
          }
        });
      }
    },
    // 更新列表数据
    updatePage() {}
  }
};
</script>

<style scoped>

</style>

<template>
  <div class="dailyBox">
    <div class="leftTopClass">{{ data.createTime }}</div>
    <div class="top-title">
      <img src="/static/daily/titleBg.png" alt="">
      <!-- 揽收看板可视化大屏 -->
      <span>{{ $t('collectionCenter.lanshouLargeScreen') }}</span>
    </div>
    <div style="padding: 0 20px;z-index: 100;top: -10px;position:relative;" class="searchConst">
      <el-form size="small" label-position="left" label-width="auto">
        <!-- 揽收时间： -->
        <el-form-item :label="$t('collectionCenter.lanshouT')" style="display: inline-block;margin-right: 20px;">
          <el-date-picker
            v-model="timeValue"
            :clearable="false"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('collectionCenter.pleaseChoose')"
            style="width: 160px;"
          />
        </el-form-item>
        <!-- 业务类型 -->
        <el-form-item :label="$t('navbar.businessType')" style="display: inline-block;margin-right: 20px;">
          <el-select v-model="queryForm.businessType" style="width: 160px;">
            <!--全部-->
            <el-option key="0" :label="$t('customerManagements.all')" :value="0" />
            <!--揽收-->
            <el-option key="1" :label="$t('collectionCenter.lanshou')" :value="1" />
            <!--快递-->
            <el-option key="2" :label="$t('collectionCenter.expressDelivery')" :value="2" />
          </el-select>
        </el-form-item>
        <!-- 客户名称 -->
        <el-form-item
          :label="$t('collectionCenter.CustomerName')"
          :placeholder="$t('collectionCenter.pleaseChoose')"
          style="display: inline-block"
        >
          <el-select v-model="queryForm.customerId" clearable style="width: 180px;">
            <el-option
              v-for="(item, index) in customerArr"
              :key="index"
              :label="item.username"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- 查询 -->
        <el-button
          type="primary"
          size="small"
          style="margin-left: 20px"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button>
        <!-- 重置 -->
        <el-button
          size="small"
          type="primary"
          plain
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button>
      </el-form>
    </div>
    <div class="screen">
      <div class="screenL">
        <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
        <div class="screenLC">
          <div>
            <!-- 商家数据展示 -->
            <span class="screen-title">{{ $t('collectionCenter.merchantData') }}</span>
            <!-- 商家总数 -->
            <div class="screenLC-font">{{ $t('collectionCenter.merchantsTotalNumber') }}<span class="screenLC-font-span red">{{ data.totalMerchantNum || 0 }}</span></div>
            <!-- 完成商家 -->
            <div class="screenLC-font">{{ $t('collectionCenter.completeMerchant') }}<span class="screenLC-font-span yellow">{{ data.finishedMerchantNum || 0 }}</span></div>
            <!-- 剩余商家 -->
            <div class="screenLC-font">{{ $t('collectionCenter.residualMerchant') }}<span class="screenLC-font-span green">{{ data.remainingMerchantNum || 0 }}</span>
              <!-- 导出 -->
              <span v-if="merchantExportBtn" class="export-green" @click="residualMerchantExport">{{ $t('collectionCenter.export') }}</span>
            </div>
          </div>
          <div class="round">
            <span class="round-percent">{{ data.completion || 0 }}</span>
            <!-- 完成度 -->
            <span class="round-completion">{{ $t('collectionCenter.degreeCompletion') }}</span>
          </div>
        </div>
      </div>
      <div class="screenR">
        <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
        <div class="screenRC">
          <!-- 单量数据展示 -->
          <span class="screen-title">{{ $t('collectionCenter.singleVolumeData') }}</span>
          <div style="margin-top: 20px;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;">
            <div class="screenRC-content">
              <span class="screenRC-font sum red">{{ data.totalOrderNum || 0 }}</span>
              <!-- 总计单量 -->
              <span class="screenRC-font description">{{ $t('collectionCenter.totalUnit') }}</span>
              <span style="height: 80px; width: 100%;background: url(&quot;/static/daily/redRing.png&quot;) no-repeat 100% 50%; background-size: cover;" />
            </div>
            <div class="screenRC-content">
              <span class="screenRC-font sum blue">{{ data.finishedOrderNum || 0 }}</span>
              <!-- 完成单量 -->
              <span class="screenRC-font description">{{ $t('collectionCenter.completionUnit') }}</span>
              <span style="height: 80px; width: 100%;background: url(&quot;/static/daily/blueRing.png&quot;) no-repeat 100% 50%; background-size: cover;" />
            </div>
            <div class="screenRC-content">
              <span class="screenRC-font sum yellow">{{ data.remainingOrderNum || 0 }}</span>
              <!-- 剩余单量 -->
              <span class="screenRC-font description">{{ $t('collectionCenter.residualUnit') }}</span>
              <span style="height: 80px; width: 100%;background: url(&quot;/static/daily/ylwRing.png&quot;) no-repeat 100% 50%; background-size: cover;" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="screenB">
      <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
      <div class="screenB-box">
        <div class="screenB-content">
          <span class="screenB-img green" />
          <span class="screenB-font sum green">{{ data.trackPushNum || 0 }}</span>
          <!-- 轨迹推送 -->
          <span class="screenB-font description">{{ $t('collectionCenter.PushTrack') }}</span>
        </div>
        <!--        <div class="screenB-content">-->
        <!--          <span class="screenB-img blue" />-->
        <!--          <span class="screenB-font sum blue">128</span>-->
        <!--          &lt;!&ndash; 出车数量 &ndash;&gt;-->
        <!--          <span class="screenB-font description">{{ $t('collectionCenter.outgoingVehiclesNumber') }}</span>-->
        <!--        </div>-->
<!--        <div class="screenB-content">-->
<!--          <span class="screenB-img yellow" />-->
<!--          <span class="screenB-font sum yellow">{{ data.helperNum }}</span>-->
<!--          &lt;!&ndash; 助理数量 &ndash;&gt;-->
<!--          <span class="screenB-font description">{{ $t('collectionCenter.assistantsNumber') }}</span>-->
<!--        </div>-->
        <div class="screenB-content">
          <span class="screenB-img orange" />
          <span class="screenB-font sum orange">{{ data.closedOrderNum || 0 }}</span>
          <!-- 闭环单量 -->
          <span class="screenB-font description">{{ $t('collectionCenter.closedLoopUnit') }}</span>
        </div>
        <!--        <div class="screenB-content">-->
        <!--          <span class="screenB-img pink" />-->
        <!--          <span class="screenB-font sum pink">158</span>-->
        <!--          &lt;!&ndash; 投诉数量 &ndash;&gt;-->
        <!--          <span class="screenB-font description">{{ $t('collectionCenter.complaintsNumber') }}</span>-->
        <!--        </div>-->
        <div class="screenB-content">
          <span class="screenB-img purple" />
          <span class="screenB-font sum purple">{{ data.abnormalOrderNum || 0 }}</span>
          <!-- 异常单量 -->
          <span class="screenB-font description">{{ $t('collectionCenter.AbnormalUnivariate') }}
            <!-- 导出 -->
            <span v-if="orderExportBtn" class="export-white" @click="exceptionNumExport">{{ $t('collectionCenter.export') }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFormatServerTime,
  queryKanban,
  kanbanMerchantExportAll,
  kanbanOrderExportAll,
  queryAllCustomer
} from '@/api/lanshou';
export default {
  name: 'Daily',
  data() {
    return {
      merchantExportBtn: this.$store.getters.button_permissions.includes('btn:collection-center:collect-kanban:daily:merchant-export'),
      orderExportBtn: this.$store.getters.button_permissions.includes('btn:collection-center:collect-kanban:daily:order-export'),
      timeValue: '',
      data: {},
      queryForm: {
        businessType: 0,
        customerId: ''
      },
      customerArr: [],
      showSearch: false
    };
  },
  activated() {
    this.queryForm = {
      businessType: 0,
      customerId: ''
    };
    this.repush();
  },
  created() {
    if (!this.timeValue) {
      this.repush();
    }
    this.asyncCustomerList();
  },
  methods: {
    // 重置
    resetClick() {
      this.queryForm = {
        businessType: 0,
        customerId: ''
      };
      getFormatServerTime().then(res => {
        if (res.status === 'OK' && res.data) {
          this.timeValue = res.data;
        }
      });
    },
    // 获取客户列表
    asyncCustomerList() {
      // 获取客户选项
      queryAllCustomer().then((res) => {
        if (res.code === 200) {
          this.customerArr = res.data;
        }
      });
    },
    repush() {
      getFormatServerTime().then(res => {
        if (res.status === 'OK' && res.data) {
          this.timeValue = res.data;
          const form = {
            businessType: this.queryForm.businessType,
            customerId: this.queryForm.customerId,
            createTime: this.timeValue.split(' ')[0]
          };
          queryKanban(form).then(res => {
            if (res.status === 'OK') {
              this.data = res.data;
              const completion = parseInt(this.data.finishedMerchantNum / this.data.totalMerchantNum * 100);
              this.data.completion = this.data.finishedMerchantNum !== 0 && this.data.totalMerchantNum !== 0 ? completion + '%' : '0%';
            }
          }).catch({});
        }
      }).catch({});
    },
    exceptionNumExport() {
      const exportForm = {
        businessType: this.queryForm.businessType,
        customerId: this.queryForm.customerId,
        createTime: this.timeValue.split(' ')[0]
      };
      kanbanOrderExportAll(exportForm).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      }).catch({});
    },
    residualMerchantExport() {
      const exportForm = {
        businessType: this.queryForm.businessType,
        customerId: this.queryForm.customerId,
        createTime: this.timeValue.split(' ')[0]
      };
      kanbanMerchantExportAll(exportForm).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      }).catch({});
    },
    searchClick() {
      const form = {
        businessType: this.queryForm.businessType,
        customerId: this.queryForm.customerId,
        createTime: this.timeValue.split(' ')[0]
      };
      queryKanban(form).then(res => {
        if (res.status === 'OK') {
          this.data = res.data;
          const completion = parseInt(this.data.finishedMerchantNum / this.data.totalMerchantNum * 100);
          this.data.completion = this.data.finishedMerchantNum !== 0 && this.data.totalMerchantNum !== 0 ? completion + '%' : '0%';
          this.showSearch = false;
        }
      }).catch({});
    }
  }
};
</script>
<style>
.searchConst .el-input__inner {
  background-color: transparent !important;
  border: 1px solid #33BDFB;
  color: #33BDFB !important;
}
.searchConst .el-input__inner:hover {
  border-color: #33BDFB;
}
.searchConst .el-input__inner::placeholder {
  color: #33BDFB;
  opacity: 1;
}
.searchConst .el-input__prefix,
.searchConst .el-input__suffix,
.searchConst .el-button--primary.is-plain:hover,
.searchConst .el-select .el-input .el-select__caret,
.searchConst .el-button--primary.is-plain:active,
.searchConst .el-button--primary.is-plain:focus,
.searchConst .el-form-item__label {
  color: #33BDFB;
}
.searchConst .el-button--primary.is-plain {
  background-color: transparent;
  border-color: #33BDFB;
}
</style>
<style lang="scss" scoped>
  .leftTopClass{
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1;
    color: #33BDFB;
  }
  //@keyframes change-bg{
  //  0%{
  //    background: url("/static/daily/complete.png") no-repeat;background-size: cover;
  //  }
  //  100%{
  //    background: url("/static/daily/complete2.png") no-repeat;background-size: cover;
  //  }
  //}
  .top-title{width: 100%;height: auto;position: relative;
    img{width: 100%;height: 144px;margin-top: -13px;margin-bottom: -45px;display: block;}
    span{position: absolute; top: 20px;left: 50%;transform: translateX(-50%); color: #64CDFF;font-size: 20px;font-weight: 500;letter-spacing: 5px;}
  }
  .screenB{height: auto; margin-top: 20px; margin-left: 20px; margin-right: 20px; position: relative;
    .screenB-box{display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap; height: 100%; width: 100%;padding: 50px 20px; box-sizing: border-box;}
    .angle{width: 25px;height: 25px;position: absolute;}
    .angle.lt{top: 0; left: 0; border-left: 3px solid #00b3f4; border-top: 3px solid #00b3f4;}
    .angle.rt{top: 0; right: 0; border-right: 3px solid #00b3f4; border-top: 3px solid #00b3f4;}
    .angle.lb{bottom: 0; left: 0; border-left: 3px solid #00b3f4; border-bottom: 3px solid #00b3f4;}
    .angle.rb{bottom: 0; right: 0; border-right: 3px solid #00b3f4; border-bottom: 3px solid #00b3f4;}
    .screenB-content:nth-child(1){margin-left: 0;}
    .screenB-content{display: flex;flex-flow: column;width: 145px; box-sizing: border-box;margin: 0 20px;
      span{display: inline-block;text-align: center;margin: 0 auto;word-break: break-all;}
      .screenB-font.sum{margin-top: 10px;font-size: 25px;letter-spacing: 3px;font-weight: bold;}
      .screenB-font.description{color: #fff;font-size: 15px;letter-spacing: 3px;position: relative;}
      .screenB-font.sum.green{color: #00C94C;}
      .screenB-font.sum.blue{color: #00C6F5;}
      .screenB-font.sum.yellow{color: #CDD900;}
      .screenB-font.sum.orange{color: #FF5600;}
      .screenB-font.sum.pink{color: #FF3D47;}
      .screenB-font.sum.purple{color: #D501BE;}
      .export-white{font-size: 10px;color: #fff;text-align: left; position: absolute; right: -40px;bottom: 0;cursor: pointer;}
      .screenB-img{height: 60px; width: 60px;border-radius: 50%;box-sizing: border-box;position: relative;}
      .screenB-img.green{background: url("/static/daily/greenBg.png") no-repeat 100% 100%;background-size: 78px;background-position: center;
        &:before{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";width: 100%;height: 100%;
          background: url("/static/daily/greenGraphical.png") no-repeat 100% 100%;background-size: 35px;background-position: center
        }
      }
      .screenB-img.blue{background: url("/static/daily/blueBg.png") no-repeat 100% 100%;background-size: 78px;background-position: center;
        &:before{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";width: 100%;height: 100%;
          background: url("/static/daily/blueGraphical.png") no-repeat 100% 100%;background-size: 35px;background-position: center
        }
      }
      .screenB-img.yellow{background: url("/static/daily/ylwBg.png") no-repeat 100% 100%;background-size: 78px;background-position: center;
        &:before{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";width: 100%;height: 100%;
          background: url("/static/daily/ylwGraphical.png") no-repeat 100% 100%;background-size: 35px;background-position: center
        }
      }
      .screenB-img.orange{background: url("/static/daily/orangeBg.png") no-repeat 100% 100%;background-size: 78px;background-position: center;
        &:before{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";width: 100%;height: 100%;
          background: url("/static/daily/orangeGraphical.png") no-repeat 100% 100%;background-size: 35px;background-position: center
        }
      }
      .screenB-img.pink{background: url("/static/daily/orangeBg.png") no-repeat 100% 100%;background-size: 78px;background-position: center;
        &:before{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";width: 100%;height: 100%;
          background: url("/static/daily/redGraphical.png") no-repeat 100% 100%;background-size: 35px;background-position: center
        }
      }
      .screenB-img.purple{background: url("/static/daily/purpleBg.png") no-repeat 100% 100%;background-size: 78px;background-position: center;
        &:before{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";width: 100%;height: 100%;
          background: url("/static/daily/purpleGraphical.png") no-repeat 100% 100%;background-size: 35px;background-position: center
        }
      }
    }
  }
  .screenB-box, .screenL, .screenR{ background: rgba(255, 255, 255, 0.05);}
  .screen{overflow: hidden; display: flex; justify-content: space-between; align-items: center;flex-wrap: wrap;align-items:stretch; margin-left: 20px; margin-right: 20px;
    .screenL, .screenR{width: 49%;min-height: 245px;height:inherit; position: relative;padding: 20px; box-sizing: border-box;
      .angle{width: 25px;height: 25px;position: absolute;}
      .angle.lt{top: 0; left: 0; border-left: 3px solid #00b3f4; border-top: 3px solid #00b3f4;}
      .angle.rt{top: 0; right: 0; border-right: 3px solid #00b3f4; border-top: 3px solid #00b3f4;}
      .angle.lb{bottom: 0; left: 0; border-left: 3px solid #00b3f4; border-bottom: 3px solid #00b3f4;}
      .angle.rb{bottom: 0; right: 0; border-right: 3px solid #00b3f4; border-bottom: 3px solid #00b3f4;}
    }

    .screen-title{display: inline-block; padding: 7px 20px; box-sizing: border-box;
      font-size: 16px;font-weight: bold;color: #51CDFA;word-spacing: 3px;letter-spacing: 3px;position: relative;
      background: url("/static/daily/btn.png") no-repeat 100% 100%;background-size: contain;background-position: center;
    }
    .screenLC{width: 100%; position: relative;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;
      .round{width: 140px; height: 140px; border-radius: 50%;box-sizing: border-box;position: relative;
        //animation: change-bg 1s infinite;
        background: url("/static/daily/complete.png") no-repeat;background-size: cover;
        .round-percent{position: absolute;top: 47%;left: 50%;transform: translate(-50%, -50%);color: #fff;font-size: 30px;font-weight: bold;letter-spacing: 3px;}
        .round-completion{position: absolute;top: 62%;left: 50%;transform: translateX(-50%);color: #fff;font-size: 15px;word-break: break-all;width: 78%;text-align: center;}
      }
      .screenLC-font{color: #51CDFA;font-size: 16px;font-weight: bold;letter-spacing: 4px;margin-top: 23px;}
      .screenLC-font-span{margin-left: 5px; font-size: 25px;}
      .screenLC-font-span.red{color: #bb3a42;}
      .screenLC-font-span.yellow{color: #e8e20a;}
      .screenLC-font-span.green{color: #03ea09;}
      .export-green{font-size: 10px;color: #03ea09;cursor: pointer;}
    }
    .screenRC{width: 100%;
      .screenRC-content{display: flex;flex-flow: column;width: 145px; margin-bottom: 10px;
        span{display: inline-block;text-align: center;word-break: break-all;}
        .screenRC-font.description{color: #fff;font-size: 15px;letter-spacing: 3px;margin-top: 10px;}
        .screenRC-font.sum.red{color: #bb3a42;font-size: 25px;font-weight: bold;letter-spacing: 3px;}
        .screenRC-font.sum.blue{color: #00C7EB;font-size: 25px;font-weight: bold;letter-spacing: 3px;}
        .screenRC-font.sum.yellow{color: #E2C600;font-size: 25px;font-weight: bold;letter-spacing: 3px;}
      }
    }
  }

  .selectClass ::v-deep .el-form-item__content{border: none;line-height: 16px;}
  .selectClass ::v-deep .el-input__inner{background: none;border: none;color: #33BDFB;padding: 0;width: 90px;height: auto;font-size: 16px;line-height: 16px;}
  .selectClass ::v-deep .el-input{width: auto;position: relative;line-height: 16px;}
  .selectClass ::v-deep .el-input__icon.el-icon-date:before{content: "";}
  .selectClass ::v-deep .el-input__prefix, .el-input__suffix{right: 47px;width: 25px;left: auto;}

  .selectClass{position: absolute;right: 30px;top: 16px;z-index: 5;}
  .selectClass, .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item{
    margin-bottom: 0;
  }
  .selBtn{
    margin-left: 15px;
    color: #33BDFB;
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid #33BDFB;
    cursor: pointer;
  }
  .dailyBox{
    position: relative;
    width: 100%;
    min-height: calc(100vh - 72px);
    height: 100%;
    box-sizing: border-box;
    background: linear-gradient(0deg, #020202, #031034);
  }
</style>

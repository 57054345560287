<template>
  <div class="boxInfo">
    <el-tabs
      type="border-card" style="height: 100%;" :value="'first'"
      @tab-click="handleTabClick"
    >
      <!--'新增分区'\'分区详情'-->
      <el-tab-pane :label="typeIndex === 1 ? $t('basicData.AddPartition') : $t('basicData.PartitionDetails')" name="first">
        <div class="addFormBox">
          <el-form
            ref="queryForm"
            class="queryFormClass"
            :model="formData"
            :rules="rules"
            size="small"
            :label-width="$i18n.locale==='zh'?'120px':'auto'"
            :label-position="$i18n.locale!=='zh'?'top':'right'"
          >
            <el-row>
              <el-col :span="20">
                <!--分区方案名称-->
                <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="name">
                  <el-input
                    v-model.trim="formData.name"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    clearable
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--备注内容-->
                <el-form-item :label="$t('basicData.RemarkContent')">
                  <el-input
                    v-model="formData.remark"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    type="textarea"
                    :rows="3"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--业务类型-->
                <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
                  <el-select v-model="formData.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                    <!--快递-->
                    <el-option key="0" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                    <!--揽收-->
                    <el-option key="1" :label="$t('collectionCenter.lanshou')" :value="1" />
                    <!--派件-->
                    <el-option key="2" :label="$t('collectionCenter.delivery')" :value="2" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--有始发州-->
                <el-form-item :label="$t('orderDetails.desRem') + $t('GlobalSetObj.Estado')">
                  <el-checkbox v-model="formData.stateOfOrigin" :true-label="1" :false-label="0" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="mb10">
            <!--新增分区 v-permit:remove="'but:finance:basicData:partition:edit'"-->
            <el-button
              size="small"
              type="primary"
              @click="handleAdd"
            >{{ $t('basicData.AddPartition') }}
            </el-button>
            <el-popover
              placement="bottom-start"
              trigger="hover"
            >
              <div>
                <import-download :table-data="formData" :state-list="stateList" @importPartition="importPartition" />
              </div>
              <!--导入/下载-->
              <el-button
                slot="reference" size="small" plain
                style="margin-left: 10px"
              >
                {{ $t('basicData.ImportDownload') }}
              </el-button>
            </el-popover>
          </div>
          <div>
            <el-form
              ref="tableForm"
              :model="formData"
              label-width="0px"
              class="error-form"
              :rules="rules"
              size="small"
            >
              <virtual-scroll
                ref="virtualScroll"
                :key="key"
                :data="formData.partitionAffiliateList"
                :item-size="62"
                key-prop="key"
                @change="(virtualList) => list = virtualList"
              >
                <el-table :data="list" row-key="key" border>
                  <virtual-column width="50" type="index" />
                  <el-table-column :label="$t('basicData.PartitionName')" width="160">
                    <template slot="header">
                      <div>
                        <!--分区名称-->
                        <span style="color: red;">*</span>
                        <span>{{ $t('basicData.PartitionName') }}</span>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <div>
                        <el-form-item
                          :prop="`partitionAffiliateList.${scope.$index}.partitionIdentifier`"
                          :rules="rules.partitionIdentifier"
                        >
                          <el-input
                            v-model="scope.row.partitionIdentifier"
                            maxlength="50"
                            show-word-limit
                            :placeholder="$t('basicData.PartitionName')"
                          />
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>

                  <!-- 始发州  -->
                  <el-table-column :label="$t('orderDetails.desRem') + $t('GlobalSetObj.Estado')" width="220">
                    <template slot-scope="scope">
                      <div>
                        <el-form-item
                          :prop="`partitionAffiliateList.${scope.$index}.state`"
                          :rules="rules.state"
                        >
                          <StateSelectCmp
                            :disabled="formData.stateOfOrigin === 0" :list="stateList" :value="scope.row.state"
                            @input="val => scope.row.state = val"
                          />
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>

                  <!--邮编-->
                  <el-table-column>
                    <template slot="header">
                      <div>
                        <span style="color: red;">*</span>
                        <span>{{ $t('orderDetails.desRem') + $t('basicData.zipCode') }}</span>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <div>
                        <el-form-item
                          :prop="'partitionAffiliateList.' + scope.$index + '.postalCode'"
                          :rules="rules.postalCode"
                        >
                          <el-input
                            v-model.trim="scope.row.postalCode"
                            :placeholder="$t('basicData.ZipCodeExample')"
                          />
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('basicData.operate')" width="120">
                    <template slot-scope="scope">
                      <!--确定删除吗？-->
                      <el-popconfirm
                        :title="$t('basicData.AreYouSureYouWantToDelete')"
                        @confirm="handleDel(scope)"
                      >
                        <!--删除-->
                        <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </virtual-scroll>
            </el-form>
          </div>
        </div>
        <div class="addFooter">
          <!--取消-->
          <el-button @click="() => handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
          <!--保存-->
          <el-button v-permit:remove="'but:finance:basicData:partition:edit'" type="primary" @click="submitForm">{{ $t('basicData.Save') }}</el-button>
        </div>
      </el-tab-pane>
      <!--操作日志-->
      <el-tab-pane v-if="typeIndex !== 1" :label="$t('basicData.OperationalLogs')" name="second">
        <operation-log v-if="isSecondActive" :edit-data="editData" />
      </el-tab-pane>
    </el-tabs>
    <!--保存提示-->
    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="30%"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 10px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiAffiliateAdd, apiAffiliateDelete, apiAffiliateEdit, apiGetAffiliateById } from '@/api/finance/partition';
import { compressList } from '@/utils/zipcode';
import { Loading } from 'element-ui';
import StateSelectCmp from './StateSelectCmp.vue';
import i18n from '@/lang';
import { cloneDeep } from 'lodash-es';
import { checkZipCode, rowComparison, postalCodeFormat, checkOverlap } from './utils';
import VirtualScroll, { VirtualColumn } from 'el-table-virtual-scroll';

const options = {
  lock: true,
  spinner: 'el-icon-loading',
  text: 'loading...',
  background: 'rgba(250, 250, 250, 0.6)' // 加载动画的背景
};

// 添加模板
const addTmp = {
  partitionIdentifier: '',
  postalCode: '',
  id: undefined,
  state: ''
};

export default {
  components: {
    VirtualColumn,
    'import-download': () => import('./ImportDownload'),
    'operation-log': () => import('./operationLog'),
    StateSelectCmp,
    VirtualScroll
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    typeIndex: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 所有一级州
    stateList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    // 州校验
    const stateCheck = (rule, value, callback) => {
      const { stateOfOrigin } = this.formData;
      // const list = this.formData.partitionAffiliateList;
      // 没有勾选
      if (stateOfOrigin !== 1) {
        return callback();
      }

      if (!value) {
        return callback(new Error(i18n.t('GlobalSetObj.pleaseChoose'))); // '请选择州'
      }

      // 是否存在相同州
      // if (list.length >= 2) {
      //   console.log(value, list);
      //   let n = 0;
      //   list.forEach(item => {
      //     if (item.state === value) {
      //       n++;
      //     }
      //   });
      //   if (n >= 2) {
      //     const str1 = this.$t('basicData.StateDuplication');
      //     callback(new Error(`${str1}`));
      //     return;
      //   }
      // }

      return callback();
    };

    // 邮编校验
    const postalCodeCheck = (rule, value, callback) => {
      // 格式校验
      {
        const [isBool, text] = postalCodeFormat(value.trim());
        if (!isBool) {
          return callback(new Error(text));
        }
      }

      // 同行比较重叠的情况
      {
        const [isBool, text] = rowComparison(value);
        if (!isBool) {
          return callback(new Error(text));
        }
      }

      return callback();
    };

    return {
      list: [],
      key: new Date().getTime(),
      isSecondActive: false,
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          // '长度在 2 到 20 个字符'
          {
            min: 2,
            max: 20,
            message: this.$i18n.t('basicData.TheLengthBetween2And20Characters'),
            trigger: ['blur', 'change']
          }
        ],
        businessType: { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },

        // 分区名称
        partitionIdentifier: [
          {
            required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: 'blur'
          }
        ],

        state: [
          {
            validator: stateCheck,
            trigger: 'change'
          }
        ],

        // 邮编
        postalCode: [
          {
            // required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: 'blur'
            required: true, validator: postalCodeCheck, trigger: 'blur'
          }
        ]
      },
      errData: [],
      dialogVisible: false,
      formData: {
        name: '',
        remark: '',
        businessType: '',
        stateOfOrigin: 0,
        partitionAffiliateList: []
      }

    };
  },
  created() {
    if (this.editData.id) {
      this.asyncGetAffiliateById(this.editData.id);
    }
  },

  methods: {
    handleTabClick(tab) {
      if (tab.name === 'second' && !this.isSecondActive) {
        this.isSecondActive = true; // 触发 ConfigManagement 组件的加载和初始化请求
      }
    },
    // 增加分区
    handleAdd() {
      this.formData.partitionAffiliateList = this.formData.partitionAffiliateList || [];
      const obj = {
        ...addTmp,
        key: `item-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
      };
      this.formData.partitionAffiliateList.push(obj);
    },
    // 导入分区
    importPartition(arr) {
      // 校验是否需要必填始发州
      {
        const { stateOfOrigin } = this.formData;
        if (stateOfOrigin === 1) {
          const isBool = arr.every(item => {
            return item.state > 0;
          });
          if (!isBool) {
            const str = this.$t('GlobalSetObj.pleaseChoose');// 请选择
            const str2 = this.$t('receivablePayable.OriginatingState'); // 始发州
            const str3 = this.$t('CenterForSorting.Import'); // 导入
            this.$message.warning(str3 + str + str2); // 请选择始发州
            return;
          }
        }
      }

      const partitionAffiliateList = cloneDeep(this.formData.partitionAffiliateList);

      // 处理逻辑
      arr.forEach(originalItem => {
        const processedItem = {
          postalCode: compressList(originalItem.zipcode || '').join(','),
          state: originalItem.state,
          partitionIdentifier: originalItem.name,
          id: originalItem.id || ''
        };

        const existingIndex = processedItem.id
          ? partitionAffiliateList.findIndex(i => i.id === processedItem.id)
          : -1;

        if (existingIndex !== -1) {
          partitionAffiliateList[existingIndex] = processedItem;
        } else if (!processedItem.id) {
          partitionAffiliateList.push(processedItem);
        }
      });
      const ArrList = partitionAffiliateList.map((item, index) => {
        return {
          ...item,
          id: item.id || '',
          key: this.generateUniqueKey(index)
        };
      });
      console.log(ArrList);
      console.log(partitionAffiliateList);
      this.formData.partitionAffiliateList = ArrList;
      this.key = new Date().getTime();
    },
    generateUniqueKey(index) {
      return `item-${Date.now()}-${index}-${Math.random().toString(36).substr(2, 9)}`;
    },
    // 删除分区
    handleDel(scope) {
      if (scope.row.id) {
        apiAffiliateDelete(scope.row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          }
        });
      }
      this.formData.partitionAffiliateList.splice(scope.$index, 1);
    },
    // 取消
    handleUpdatePage(type) {
      this.$emit('updatePage', type);
    },
    // 同一个始发州下的邮编区间校验
    validatePostalCode() {
      const { stateOfOrigin, partitionAffiliateList } = this.formData;
      const list = cloneDeep(partitionAffiliateList);

      if (stateOfOrigin === 0) {
        // 所有的一起校验
        const errArr = [];
        checkOverlap(list, errArr);

        return errArr;
      }

      return checkZipCode(list);
    },

    // 处理提交的数据格式
    setSubmitFormDataType() {
      const { name, remark, businessType, stateOfOrigin, partitionAffiliateList } = this.formData;
      // const tableData = this.formData.partitionAffiliateList;

      const row = {
        id: undefined,
        name,
        businessType,
        remark,
        attribute: 1,
        stateOfOrigin,
        partitionAffiliateList: []
      };

      row.partitionAffiliateList = partitionAffiliateList.map(item => {
        const { postalCode, partitionIdentifier, id, state } = item;

        const obj = {
          partitionIdentifier,
          postalCode, // 邮编
          id,
          state, // 州
          partitionId: this.editData.id
        };

        // 没有勾选
        if (!stateOfOrigin) {
          obj.state = undefined;
        }

        return obj;
      });

      return row;
    },

    // 新增
    asyncAffiliateAdd(obj) {
      apiAffiliateAdd(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 查看订单详情
    asyncGetAffiliateById(ids) {
      apiGetAffiliateById(ids).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        const {
          businessType,
          name,
          remark,
          stateOfOrigin,
          partitionAffiliateList
        } = data;

        const arr = (partitionAffiliateList || []).map(item => {
          const { partitionIdentifier, postalCode, id, state } = item;
          return {
            ...addTmp,
            id,
            partitionIdentifier,
            postalCode,
            state: Number(state) || '',
            key: this.generateUniqueKey(id)
          };
        });

        this.formData = {
          businessType,
          name,
          remark,
          stateOfOrigin: stateOfOrigin || 0,
          partitionAffiliateList: arr
        };
      }).catch(err => {
        console.error(err);
      });
    },
    // 编辑
    asyncAffiliateEdit(obj) {
      apiAffiliateEdit(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    /**
     * 提交表单
     */
    async submitForm() {
      try {
        // 表单校验
        const valid = await this.$refs.queryForm.validate();
        if (!valid) return;
        // 分区校验
        if (this.formData.partitionAffiliateList.length === 0) {
          this.$message.warning(this.$i18n.t('basicData.PleaseAddPartitions'));
          return;
        }
        // 表格校验
        const tabValid = await this.$refs.tableForm.validate();
        if (!tabValid) return;

        const loading = Loading.service(options);
        try {
          this.errData = this.validatePostalCode();

          // 重复项检测
          const findByItems = (eq) => (arr) => arr.filter(
            (x, i) => arr.find((y, j) => i !== j && eq(x, y))
          );
          const duplicatedItems = findByItems((a, b) => a.partitionIdentifier.toString() === b.partitionIdentifier.toString());
          const duplicatedZipCode = findByItems((a, b) => a.postalCode.toString() === b.postalCode.toString());
          const arrDuplicated = duplicatedItems(this.formData.partitionAffiliateList);
          const arrZipCode = duplicatedZipCode(this.formData.partitionAffiliateList);
          const nameArr = [];
          const zipCode = [];
          this.formData.partitionAffiliateList.map((item, ind) => {
            arrDuplicated.map(list => {
              if (list.partitionIdentifier === item.partitionIdentifier) {
                if (!nameArr.includes(ind + 1)) {
                  nameArr.push(ind + 1);
                }
              }
            });
            arrZipCode.map(list => {
              if (list.postalCode === item.postalCode) {
                if (!zipCode.includes(ind + 1)) {
                  zipCode.push(ind + 1);
                }
              }
            });
          });
          if (nameArr.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${nameArr.join(',')}` + this.$i18n.t('basicData.repeat'); // 第*和*行重复
            this.errData.push(tip);
          }
          console.log(this.errData, '==================');
          if (this.errData.length) {
            this.dialogVisible = true;
            return;
          }

          const submitData = this.setSubmitFormDataType();
          if (this.typeIndex === 1) {
            await this.asyncAffiliateAdd(submitData);
          } else if (this.typeIndex === 2) {
            submitData.id = this.editData.id;
            await this.asyncAffiliateEdit(submitData);
          }

          this.handleUpdatePage(true);
        } finally {
          loading.close();
        }
      } catch (error) {
        console.error('Submission Error:', error);
        this.$message.error(this.$i18n.t('common.submitError'));
      }
    }

  //   submitForm() {
  //     this.$refs.queryForm.validate((valid) => {
  //       if (!valid) {
  //         return false;
  //       }
  //       if (this.formData.partitionAffiliateList.length === 0) {
  //         this.$message.warning(this.$i18n.t('basicData.PleaseAddPartitions')); // `请添加分区`,);
  //         return false;
  //       }
  //       this.$refs.tableForm.validate((tabRules) => {
  //         if (!tabRules) {
  //           return false;
  //         }
  //         Loading.service(options);
  //         const loadingInstance = Loading.service(options);
  //         this.errData = [];
  //         // 同分区邮编校验
  //         {
  //           const errArr = this.validatePostalCode();
  //           if (errArr.length > 0) {
  //             this.errData.push(...errArr);
  //           }
  //         }
  //
  //         const findByItems = (eq) => (arr) => arr.filter(
  //           (x, i) => arr.find((y, j) => i !== j && eq(x, y))
  //         );
  //         const duplicatedItems = findByItems((a, b) => a.partitionIdentifier.toString() === b.partitionIdentifier.toString());
  //         const duplicatedZipCode = findByItems((a, b) => a.postalCode.toString() === b.postalCode.toString());
  //         const arrDuplicated = duplicatedItems(this.formData.partitionAffiliateList);
  //         const arrZipCode = duplicatedZipCode(this.formData.partitionAffiliateList);
  //         const nameArr = [];
  //         const zipCode = [];
  //         this.formData.partitionAffiliateList.map((item, ind) => {
  //           arrDuplicated.map(list => {
  //             if (list.partitionIdentifier === item.partitionIdentifier) {
  //               if (!nameArr.includes(ind + 1)) {
  //                 nameArr.push(ind + 1);
  //               }
  //             }
  //           });
  //           arrZipCode.map(list => {
  //             if (list.postalCode === item.postalCode) {
  //               if (!zipCode.includes(ind + 1)) {
  //                 zipCode.push(ind + 1);
  //               }
  //             }
  //           });
  //         });
  //         if (nameArr.length > 1) {
  //           const tip = this.$i18n.t('basicData.Lines') + `${nameArr.join(',')}` + this.$i18n.t('basicData.repeat'); // 第*和*行重复
  //           this.errData.push(tip);
  //         }
  //         if (this.errData.length > 0) {
  //           this.dialogVisible = true;
  //           loadingInstance.close();
  //           return;
  //         }
  //         // 提交数据处理
  //         const row = this.setSubmitFormDataType();
  //         loadingInstance.close();
  //         if (this.typeIndex === 1) {
  //           // 新增
  //           this.asyncAffiliateAdd(row);
  //           return;
  //         }
  //
  //         if (this.typeIndex === 2) {
  //           // 3.编辑
  //           row.id = this.editData.id;
  //           this.asyncAffiliateEdit(row);
  //         }
  //         this.handleUpdatePage(true);
  //       });
  //     });
  //   }
  }
};
</script>

<style scoped>

</style>
<style>
.boxInfo {
  height: 100%;
}

.boxInfo .el-tabs__content {
  height: calc(100vh - 40px);
  padding: 0;
}

.boxInfo .el-tabs__content .el-tab-pane {
  height: 100%;
}

.boxInfo .el-tabs__content .el-tab-pane .addFormBox {
  height: calc(100% - 65px);
  padding: 15px;
  overflow-y: auto;
}

.addFooter {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: right;
}
</style>

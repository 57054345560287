<template>
  <!-- 页面名称 -->
  <div class="msgBox">
    <div style="display: flex;justify-content: space-between;align-items: center">
      <el-button
        icon="el-icon-upload2"
        size="mini"
        @click="download"
      >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
      <div style="margin-right: 70px;display: flex;justify-content: space-between;align-items: center">
        <!-- 更新时间 -->
        <span v-show="updateTime">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</span>
        <div class="dateBox">
          <span :class="{'d1': type == 4}" @click="get2Data(4)">{{ $t('collectionCenter.beforeYesterday') }}</span><!-- 前天 -->
          <span :class="{'d1': type == 0}" @click="get2Data(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
          <span :class="{'d1': type == 1}" @click="get2Data(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
          <span :class="{'d1': type == 2}" @click="get2Data(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
          <span :class="{'d1': type == 3}" @click="get2Data(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
        </div>
      </div>
    </div>
    <el-divider />
    <el-tabs v-model="activeName">
      <el-tab-pane :label="tableName" name="hz">
        <!-- 装车信息统计列表 -->
        <el-table
          ref="loadRef"
          :data="tableDate1"
          :max-height="600"
          border
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            :label="$t('collectionCenter.ind')" align="center" type="index"
            width="60"
          ><!-- 序号 -->
            <template slot-scope="scope">
              <span>{{ (queryForm1.pageNumber - 1) * queryForm1.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.licensePlateNumber')"
            prop="vehicleNumber"
            align="center"
          /><!-- 车牌号 -->
          <el-table-column
            :label="$t('collectionCenter.operationMode')"
            prop="operationModeName"
            align="center"
          /><!-- 运营模式 -->
          <el-table-column
            :label="$t('collectionCenter.totalNumberBagsLoaded')"
            prop="loadBagCount"
            align="center"
          ><!-- 装车总袋数 -->
            <template slot-scope="scope">
              <div class="skipClass" @click="openBagging(scope.row.vehicleNumber, scope.row.loadBagCount)">{{ scope.row.loadBagCount }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.totalBagsUnloaded')"
            prop="unloadBagCount"
            align="center"
          ><!-- 卸车总袋数 -->
            <template slot-scope="scope">
              <div class="skipClass" @click="openBagging(scope.row.vehicleNumber, scope.row.unloadBagCount)">{{ scope.row.unloadBagCount }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.bagCountDiscrepancy')"
            prop="loadUnloadBagDiffCount"
            align="center"
          ><!-- 装卸车总袋数差异 -->
            <template slot-scope="scope">
              <div class="skipClass" @click="openBagging(scope.row.vehicleNumber, scope.row.loadUnloadBagDiffCount)">{{ scope.row.loadUnloadBagDiffCount }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.carLoadingVotes')"
            prop="loadTicketCount"
            align="center"
          ><!-- 装车总票数 -->
            <template slot-scope="scope">
              <div class="skipClass" @click="openBag(scope.row.vehicleNumber, scope.row.loadTicketCount)">{{ scope.row.loadTicketCount }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.carUnloadingVotes')"
            prop="unloadTicketCount"
            align="center"
          ><!-- 卸车总票数 -->
            <template slot-scope="scope">
              <div class="skipClass" @click="openBag(scope.row.vehicleNumber, scope.row.unloadTicketCount)">{{ scope.row.unloadTicketCount }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.votesDiscrepancy')"
            prop="loadUnloadTicketDiffCount"
            align="center"
          ><!-- 装卸车总票数差异 -->
            <template slot-scope="scope">
              <div class="skipClass" @click="openBag(scope.row.vehicleNumber, scope.row.loadUnloadTicketDiffCount)">{{ scope.row.loadUnloadTicketDiffCount }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="right">
          <el-pagination
            :current-page.sync="queryForm1.pageNumber"
            :page-size.sync="queryForm1.pageSize"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :total="total1"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getNumPage1"
            @size-change="getPageSize1"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="activeName == 'mx1'" :label="tableName + $t('collectionCenter.packageDetail')" name="mx1">
        <!-- 装袋信息统计列表 -->
        <el-table
          ref="ref"
          :data="tableDate2"
          :max-height="600"
          border
        >
          <el-table-column
            :label="$t('collectionCenter.ind')" align="center" type="index"
            width="60"
          ><!-- 序号 -->
            <template slot-scope="scope">
              <span>{{ (queryForm2.pageNumber - 1) * queryForm2.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.BagNumber')"
            prop="bagNumber"
            align="center"
          /><!-- 袋号 -->
          <el-table-column
            :label="$t('collectionCenter.loadingScanningTime')"
            prop="loadScanTime"
            align="center"
          /><!-- 装车扫描时间 -->
          <el-table-column
            :label="$t('customerManagements.loadSiteIdList')"
            prop="loadSiteName"
            align="center"
          /><!-- 装车网点 -->
          <el-table-column
            :label="$t('collectionCenter.loader')"
            prop="loadUserName"
            align="center"
          /><!-- 装车人 -->
          <el-table-column
            :label="$t('collectionCenter.unloadingScanningTime')"
            prop="unloadScanTime"
            align="center"
          /><!-- 卸车扫描时间 -->
          <el-table-column
            :label="$t('collectionCenter.unloadDot')"
            prop="unloadSiteName"
            align="center"
          /><!-- 卸车网点 -->
          <el-table-column
            :label="$t('collectionCenter.unloader')"
            prop="unloadUserName"
            align="center"
          /><!-- 卸车人 -->
        </el-table>
        <div class="right">
          <el-pagination
            :current-page.sync="queryForm2.pageNumber"
            :page-size.sync="queryForm2.pageSize"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :total="total2"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getNumPage2"
            @size-change="getPageSize2"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="activeName == 'mx2'" :label="tableName + $t('collectionCenter.packageDetail')" name="mx2">
        <!-- 袋子详细信息列表 -->
        <el-table
          ref="ref"
          :data="tableDate3"
          :max-height="600"
          border
        >
          <el-table-column
            :label="$t('collectionCenter.ind')" align="center" type="index"
            width="60"
          ><!-- 序号 -->
            <template slot-scope="scope">
              <span>{{ (queryForm3.pageNumber - 1) * queryForm3.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('collectionCenter.Barcode')"
            prop="scanNumber"
            align="center"
          /><!-- 条形码 -->
          <el-table-column
            :label="$t('collectionCenter.parcelNum')"
            prop="packageNumber"
            align="center"
          /><!-- 包裹号 -->
          <el-table-column
            :label="$t('collectionCenter.loadingScanningTime')"
            prop="loadScanTime"
            align="center"
          /><!-- 装车扫描时间 -->
          <el-table-column
            :label="$t('customerManagements.loadSiteIdList')"
            prop="loadSiteName"
            align="center"
          /><!-- 装车网点 -->
          <el-table-column
            :label="$t('collectionCenter.loader')"
            prop="loadUserName"
            align="center"
          /><!-- 装车人 -->
          <el-table-column
            :label="$t('collectionCenter.unloadingScanningTime')"
            prop="unloadScanTime"
            align="center"
          /><!-- 卸车扫描时间 -->
          <el-table-column
            :label="$t('collectionCenter.unloadDot')"
            prop="unloadSiteName"
            align="center"
          /><!-- 卸车网点 -->
          <el-table-column
            :label="$t('collectionCenter.unloader')"
            prop="unloadUserName"
            align="center"
          /><!-- 卸车人 -->
        </el-table>
        <div class="right">
          <el-pagination
            :current-page.sync="queryForm3.pageNumber"
            :page-size.sync="queryForm3.pageSize"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :total="total3"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getNumPage3"
            @size-change="getPageSize3"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getTransitDetailPage,
  getTransitBagDetailPage,
  getTransitOrderDetailPage,
  transitDetailExportAll,
  transitBagDetailExportAll,
  transitOrderDetailExportAll
} from '@/api/lanshou';
import dayjs from 'dayjs';
export default {
  props: ['form'],
  data() {
    return {
      activeName: this.form.activeName,
      // 装车信息统计列表
      queryForm1: this.form.queryForm,
      tableName: this.form.tableName,
      tableDate1: [],
      total1: 0,
      // 装袋信息统计列表
      queryForm2: {},
      tableDate2: [],
      total2: 0,
      // 袋子详细信息列表
      queryForm3: {},
      tableDate3: [],
      total3: 0,
      isEmptyTable: '',
      updateTime: this.form.updateTime,
      type: this.form.dateType
    };
  },
  mounted() {
    this.default();
    this.getNumPage1();
  },
  methods: {
    get2Data(type) {
      this.type = type;
      // 设置一周的起始为周一
      dayjs.locale('en', {
        weekStart: 1
      });
      const d = new Date();
      const day4 = dayjs(d).subtract(2, 'day').format('YYYY-MM-DD'); // 前天
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD'); // 昨天
      const day1 = dayjs(d).format('YYYY-MM-DD'); // 当天
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD'); // 近一月
      // 获取当前周的开始日期（周一）
      dayjs.locale('en', {
        weekStart: 1
      });
      const day5 = dayjs(d).startOf('week').format('YYYY-MM-DD');
      let date = {};
      if (type === 4) { // 前天
        date = {
          startTime: day4,
          endTime: day4
        };
      }
      if (type === 0) { // 昨天
        date = {
          startTime: day0,
          endTime: day0
        };
      }
      if (type === 1) { // 今日
        date = {
          startTime: day1,
          endTime: day1
        };
      }
      if (type === 2) { // 近一周
        date = {
          startTime: day5,
          endTime: day1
        };
      }
      if (type === 3) { // 近一月
        date = {
          startTime: day3,
          endTime: day1
        };
      }
      this.queryForm1 = JSON.parse(JSON.stringify(this.form.queryForm));
      this.queryForm1.params.startTime = date.startTime;
      this.queryForm1.params.endTime = date.endTime;
      getTransitDetailPage(this.queryForm1).then(res => {
        if (res.status === 'OK') {
          this.tableDate1 = res.data.records;
          this.total1 = res.data.total;
        }
      }).catch();
      if (this.activeName === 'mx1') {
        if (this.isEmptyTable === 0) {
          this.tableDate2 = [];
          this.total2 = 0;
        } else {
          this.queryForm2 = {
            pageNumber: 1,
            pageSize: 10,
            params: {
              startTime: date.startTime,
              endTime: date.endTime,
              dashboardDetailType: this.queryForm1.params.dashboardDetailType,
              transitType: this.queryForm1.params.transitType,
              vehicleNumber: this.queryForm2.params.vehicleNumber,
              latestBatchTime: this.updateTime
            }
          };
          this.getNumPage2();
        }
      }
      if (this.activeName === 'mx2') {
        if (this.isEmptyTable === 0) {
          this.tableDate3 = [];
          this.total3 = 0;
        } else {
          this.queryForm3 = {
            pageNumber: 1,
            pageSize: 10,
            params: {
              startTime: date.startTime,
              endTime: date.endTime,
              dashboardDetailType: this.queryForm1.params.dashboardDetailType,
              transitType: this.queryForm1.params.transitType,
              vehicleNumber: this.queryForm3.params.vehicleNumber,
              latestBatchTime: this.updateTime
            }
          };
          this.getNumPage3();
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (column.label === this.$t('collectionCenter.totalNumberBagsLoaded') ||
          (column.label === this.$t('collectionCenter.totalBagsUnloaded')) ||
          (column.label === this.$t('collectionCenter.bagCountDiscrepancy')) ||
          (column.label === this.$t('collectionCenter.carLoadingVotes')) ||
          (column.label === this.$t('collectionCenter.carUnloadingVotes')) ||
          (column.label === this.$t('collectionCenter.votesDiscrepancy'))
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    default() {
      this.queryForm1.pageNumber = 1;
      this.queryForm1.pageSize = 10;
      this.queryForm2.pageNumber = 1;
      this.queryForm2.pageSize = 10;
      this.tableDate2 = [];
      this.queryForm3.pageNumber = 1;
      this.queryForm3.pageSize = 10;
      this.tableDate3 = [];
    },
    getNumPage1() {
      getTransitDetailPage(this.queryForm1).then(res => {
        if (res.status === 'OK') {
          this.tableDate1 = res.data.records;
          this.total1 = res.data.total;
        }
      }).catch();
    },
    getPageSize1() {
      this.queryForm1.pageNumber = 1;
      getTransitDetailPage(this.queryForm1).then(res => {
        if (res.status === 'OK') {
          this.tableDate1 = res.data.records;
          this.total1 = res.data.total;
        }
      }).catch();
    },
    openBagging(vehicleNumber, num) {
      this.activeName = 'mx1';
      this.queryForm2 = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.queryForm1.params.startTime,
          endTime: this.queryForm1.params.endTime,
          dashboardDetailType: this.queryForm1.params.dashboardDetailType,
          transitType: this.queryForm1.params.transitType,
          vehicleNumber,
          latestBatchTime: this.updateTime
        }
      };
      this.isEmptyTable = num;
      if (num === 0) {
        this.tableDate2 = [];
        this.total2 = 0;
      } else {
        this.getNumPage2();
      }
    },
    getNumPage2() {
      if (this.isEmptyTable === 0) {
        this.tableDate2 = [];
        this.total2 = 0;
      } else {
        getTransitBagDetailPage(this.queryForm2).then(res => {
          if (res.status === 'OK') {
            this.tableDate2 = res.data.records;
            this.total2 = res.data.total;
          }
        }).catch();
      }
    },
    getPageSize2() {
      this.queryForm2.pageNumber = 1;
      if (this.isEmptyTable === 0) {
        this.tableDate2 = [];
        this.total2 = 0;
      } else {
        getTransitBagDetailPage(this.queryForm2).then(res => {
          if (res.status === 'OK') {
            this.tableDate2 = res.data.records;
            this.total2 = res.data.total;
          }
        }).catch();
      }
    },
    openBag(vehicleNumber, num) {
      this.activeName = 'mx2';
      this.queryForm3 = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.queryForm1.params.startTime,
          endTime: this.queryForm1.params.endTime,
          dashboardDetailType: this.queryForm1.params.dashboardDetailType,
          transitType: this.queryForm1.params.transitType,
          vehicleNumber,
          latestBatchTime: this.updateTime
        }
      };
      this.isEmptyTable = num;
      if (num === 0) {
        this.tableDate3 = [];
        this.total3 = 0;
      } else {
        this.getNumPage3();
      }
    },
    getNumPage3() {
      if (this.isEmptyTable === 0) {
        this.tableDate3 = [];
        this.total3 = 0;
      } else {
        getTransitOrderDetailPage(this.queryForm3).then(res => {
          if (res.status === 'OK') {
            this.tableDate3 = res.data.records;
            this.total3 = res.data.total;
          }
        }).catch();
      }
    },
    getPageSize3() {
      this.queryForm3.pageNumber = 1;
      if (this.isEmptyTable === 0) {
        this.tableDate3 = [];
        this.total3 = 0;
      } else {
        getTransitOrderDetailPage(this.queryForm3).then(res => {
          if (res.status === 'OK') {
            this.tableDate3 = res.data.records;
            this.total3 = res.data.total;
          }
        }).catch();
      }
    },
    download() {
      if (this.activeName === 'hz') {
        transitDetailExportAll(this.queryForm1.params).then(res => {
          if (res.status === 'OK') {
            this.exportThis();
          }
        });
      }
      if (this.activeName === 'mx1') {
        transitBagDetailExportAll(this.queryForm2.params).then(res => {
          if (res.status === 'OK') {
            this.exportThis();
          }
        });
      }
      if (this.activeName === 'mx2') {
        transitOrderDetailExportAll(this.queryForm3.params).then(res => {
          if (res.status === 'OK') {
            this.exportThis();
          }
        });
      }
    },
    exportThis() {
      const h = this.$createElement;
      this.$msgbox({
        // '提示'
        title: this.$t('collectionCenter.tips'),
        message: h('p', null, [
          // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
          h('span', this.$t('collectionCenter.exportChangeTip')),
          // '请勿重复导出！'
          h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
        ]),
        showCancelButton: true,
        // '立即前往'
        confirmButtonText: this.$t('collectionCenter.Immediately'),
        // '关闭'
        cancelButtonText: this.$t('collectionCenter.close')
      }).then(action => {
        if (action === 'confirm') {
          this.$router.push({ path: '/views/resource/exportTaskList' });
        }
      }).catch(() => {
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.msgBox ::v-deep .el-table--border{
  color: #606266 !important;
  border-color: #ebeef5 !important;
  border-bottom-color: #ebeef5 !important;
  border-right-color: #ebeef5 !important;
}
.skipClass{
  border: 1px solid transparent;
  box-sizing: border-box;
}
.skipClass:hover{
  border: 1px solid #00fcf9;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
}

.dateBox{
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 5px;
  span{display: inline-block;border: 1px solid #5EBC7F;padding: 0 15px;box-sizing: border-box;cursor: pointer; text-align: center;color: #5EBC7F;}
  span:hover, span.d1:hover{background: #51d96b;color: #fff;}
  span.d1:hover{border: 1px solid #51D96B;box-sizing: border-box;}
  span.d1{background: linear-gradient(90deg, #51D96B, transparent);box-sizing: border-box;color: #009C37}
}
</style>

<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row
        :gutter="20" justify="start" style="flex-wrap: wrap;"
        type="flex"
      >
        <el-col :span="7">
          <!--创建时间-->
          <el-form-item :label="$t('GlobalSetObj.operationTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  操作人   -->
          <el-form-item :label="$t('GlobalSetObj.operator')">
            <el-select
              v-model.trim="queryForm.params.operateUserName"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="getUserList"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in userList"
                :key="index"
                :label="item.operateUserName"
                :value="item.keyId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   操作人网点   -->
          <el-form-item :label="$t('GlobalSetObj.OperationSite')">
            <el-select
              v-model="queryForm.params.operateSiteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
            >
              <el-option
                v-for="item in opearateSitelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item :label="$t('newOrder.waybillNumber')">
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  客户   -->
          <el-form-item :label="$t('orderImport.client')">
            <el-select
              v-model="queryForm.params.customerIds" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
              filterable multiple
              style="width:100%"
            >
              <el-option
                v-for="(item, index) in customerList"
                :key="index"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  操作类型   -->
          <el-form-item :label="$t('orderCenterCont.typeOperation')">
            <el-select
              v-model="queryForm.params.operationType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
              style="width:100%"
            ><!-- 1 退仓发件，2退仓到件 3逆向派件 4逆向签收 -->
              <el-option :label="$t('navbar.ReturnToSender')" :value="1" />
              <el-option :label="$t('GlobalSetObj.ReturnToStorage')" :value="2" />
              <el-option :label="$t('navbar.reverseShipping')" :value="3" />
              <el-option :label="$t('navbar.reverseSigning')" :value="4" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  操作人网点所属州   -->
          <el-form-item :label="$t('GlobalSetObj.TheStateOperatorOutlet')">
            <el-select
              v-model="queryForm.params.operateStateList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width:100%"
            >
              <el-option
                v-for="item in allTraceStateList"
                :key="item.id"
                :label="item.nameEn"
                :value="item.twoCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{
            $t('newOrder.search')
          }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small" type="info"
            @click="resetForm()"
          >{{
            $t('newOrder.reset')
          }}
          </el-button>
          <el-button
            icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
            @click="allExportOrder"
          >{{
            $t('newOrder.export')
          }}
          </el-button>
          <el-radio-group
            v-model="isTest"
            v-permit:remove="'btn:SortingCenter:outGoRecordData'"
            size="small"
            style="margin-left: 10px"
            @change="handleTest"
          >
            <el-radio-button :label="0">{{ $t("newOrder.Formal") }}</el-radio-button>
            <el-radio-button :label="1">{{ $t("newOrder.test1") }}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="`${$t('GlobalSetObj.serial', 'zh')}（${$t('GlobalSetObj.serial', 'pu')}）`"
        align="center"
        type="index"
        width="80"
      />
      <el-table-column
        :label="`${$t('newOrder.waybillNumber', 'zh')}（${$t('newOrder.waybillNumber', 'pu')}）`"
        align="center"
        min-width="160"
        prop="waybillNumber"
      >
        <template slot-scope="scope">
          <div>
            <el-popover placement="top" popper-class="copy" trigger="hover">
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copy(scope.row.waybillNumber)"
              >{{ `${$t('orderDetails.copy', 'zh')}（${$t('orderDetails.copy', 'pu')}）` }}
              </el-button>
              <div slot="reference" class="WaybillNumber">
                {{ scope.row.waybillNumber || '--' }}
              </div>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="`${$t('newOrder.waybillStatus', 'zh')}（${$t('newOrder.waybillStatus', 'pu')}）`"
        align="center"
        min-width="160"
        prop="statusNamewaybill"
      />
      <el-table-column
        :label="`${$t('collectionCenter.operationType', 'zh')}（${$t('collectionCenter.operationType', 'pu')}）`"
        align="center"
        min-width="150"
        prop="operationTypeName"
      />
      <el-table-column
        :label="`${$t('orderImport.client', 'zh')}（${$t('orderImport.client', 'pu')}）`"
        align="center"
        prop="customerName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.operationTime', 'zh')}（${$t('GlobalSetObj.operationTime', 'pu')}）`"
        align="center"
        min-width="170"
        prop="operationTime"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.nextStation', 'zh')}（${$t('GlobalSetObj.nextStation', 'pu')}）`"
        align="center"
        min-width="170"
        prop="nextSiteName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.operator', 'zh')}（${$t('GlobalSetObj.operator', 'pu')}）`"
        align="center"
        min-width="150"
        prop="operateUserName"
      />
      <el-table-column
        :label="`${$t('GlobalSetObj.OperationSite', 'zh')}（${$t('GlobalSetObj.OperationSite', 'pu')}）`"
        align="center"
        min-width="120"
        prop="operateSiteName"
      />
      <!-- 操作人所属州-->
      <el-table-column
        :label="`${$t('GlobalSetObj.TheStateOperatorOutlet', 'zh')}（${$t('GlobalSetObj.TheStateOperatorOutlet', 'pu')}）`"
        align="center"
        min-width="120"
        prop="operateState"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  getCustomer,
  getLgAreaListData,
  queryOperationUser,
  returnOperationRecordexportAll,
  returnOperationRecordpageQuery,
  selectInfoByStateTwo
} from '@/api/orderApi';
import { copyData } from '@/utils/copyData';
import dayjs from 'dayjs';
import { waybillStatusObj } from '@/utils/enumData';

export default {
  name: 'OutGoRecord',
  data() {
    return {
      customerList: [],
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      queryTrackParam: {},
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          inputValue: '',
          operateSiteId: null, // 操作人网点id
          isTest: 0, // 正式传0，测试传1
          expressId: null,
          supplierId: null,
          customerIds: [],
          operationType: '',
          operateStateList: [] // 操作人网点所属州
        }
      },
      tableData: [],
      userList: [], // 用户列表
      isTest: 0,
      opearateSitelist: [], // 操作人网点
      allTraceStateList: [] // 全部州
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '190px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getUserList('');
    this.getSiteList();
    this.getSearch(1);
    this.getCustomerPage();
    this.getTraceStateList();
  },
  methods: {
    // 获取轨迹州列表
    getTraceStateList() {
      const param = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(param, false).then(res => {
        if (res.status === 'OK') {
          this.allTraceStateList = res.data;
        }
      });
    },
    // 下拉到底加载下一页
    getCustomerPage() {
      this.customerList = [];
      const customerPageDate = {
        pageNumber: 1,
        pageSize: 10000,
        params: {
          company: '',
          source: '',
          username: ''
        }
      };
      getCustomer(customerPageDate).then(res => {
        res.data.records.map(arr => {
          this.customerList.push({
            id: arr.id,
            username: arr.username
          });
        });
      });
    },
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.isTest = 0;
      this.getTimer();
      this.queryForm.params = {
        inputValue: '',
        operateSiteId: null, // 操作人网点id
        isTest: 0, // 正式传0，测试传1
        expressId: null,
        supplierId: null,
        customerIds: [],
        operationType: '',
        operateStateList: [] // 操作人所属州
      };
      this.getSearch();
    },
    handleTest() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 查询
    getSearch(val) {
      console.log('this.queryForm', this.queryForm);
      const em_str = {
        1: this.$t('navbar.ReturnToSender'),
        2: this.$t('GlobalSetObj.ReturnToStorage'),
        3: this.$t('navbar.reverseShipping'),
        4: this.$t('navbar.reverseSigning')
      };
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.operationStartTime = this.timeValue[0];
        data.params.operationEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      data.params.isTest = this.isTest;
      data.params.operateSiteId = this.queryForm.params.operateSiteId;
      if (this.queryForm.params.operateStateList && this.queryForm.params.operateStateList.length > 0) {
        data.params.operateStateList = this.queryForm.params.operateStateList;
      } else {
        data.params.operateStateList = [];
      }
      data.params.waybillNumberList = inputValList;
      const obj = this.userList.find(item => {
        return item.keyId === this.queryForm.params.operateUserName;
      });
      if (obj) {
        if (obj.expressId) {
          data.params.expressId = obj.expressId;
          data.params.supplierId = null;
        } else {
          data.params.supplierId = obj.supplierId;
          data.params.expressId = null;
        }
      }
      data.params.customerIds = this.queryForm.params.customerIds;
      data.params.operationType = this.queryForm.params.operationType;
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      returnOperationRecordpageQuery(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map(item => {
            if (waybillStatusObj[item.waybillStatus].label) {
              item.statusNamewaybill = waybillStatusObj[item.waybillStatus].label;
            }
            item.operationTypeName = em_str[item.operationType];
          });
          if (val === 1) {
            loading.close();
          }
          console.log(this.tableData, 'tableData');
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
        if (inputValList.length > 2000) {
          this.$message.warning(this.$t('newOrder.searchMax1000'));
          inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
        }
      }
      const data = {};
      if (this.timeValue && this.timeValue.length > 0) {
        data.operationStartTime = this.timeValue[0];
        data.operationEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      data.isTest = this.isTest;
      data.operateSiteId = this.queryForm.params.operateSiteId;
      data.waybillNumberList = inputValList;
      if (this.queryForm.params.operateStateList && this.queryForm.params.operateStateList.length > 0) {
        data.operateStateList = this.queryForm.params.operateStateList;
      } else {
        data.operateStateList = [];
      }
      const obj = this.userList.find(item => {
        return item.keyId === this.queryForm.params.operateUserName;
      });
      if (obj) {
        if (obj.expressId) {
          data.expressId = obj.expressId;
          data.supplierId = null;
        } else {
          data.supplierId = obj.supplierId;
          data.expressId = null;
        }
      }
      returnOperationRecordexportAll(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              if (res.data.taskId) {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              } else {
                this.$router.push({ path: '/views/resource/exportList' });
              }
            }
          }).catch(() => {
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      console.log('handleCurrentChange', val);
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(6, 'day').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.queryTrackParam = row;
      this.waybillDescription = true;
      this.detailKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', row.waybillId);
      }, 100);
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 获取网点列表
    getSiteList() {
      const data = {};
      selectInfoByStateTwo(data).then((res) => {
        if (res.status === 'OK') {
          this.opearateSitelist = res.data.siteList;
        }
      });
    },
    // 获取用户列表
    getUserList(val) {
      const data = {
        operateUserName: val
      };
      queryOperationUser(data).then((res) => {
        if (res.status === 'OK') {
          this.userList = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}
</style>

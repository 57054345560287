<template>
  <!-- 服务商轨迹匹配 -->
  <div class="Box">
    <el-card class="box-card">
      <el-form
        ref="searchForm"
        v-model="queryForm"
        :inline="false"
        label-position="top"
      >
        <el-row
          type="flex" justify="start" style="flex-wrap: wrap;"
          class="selectClass"
        >
          <el-col :span="7">
            <!--服务商名称-->
            <el-form-item :label="$t('navbar.providerName')">
              <el-select
                v-model="queryForm.params.providerId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                size="small"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in provideNameList"
                  :key="item.id"
                  :label="item.providerChannelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--匹配快递运单状态-->
            <el-form-item :label="$t('collectionCenter.matchExpressWaybillStatus')">
              <el-select
                v-model="queryForm.params.waybillStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                size="small"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in waybillStatusList"
                  :key="ind"
                  :label="item.label"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--创建时间-->
            <el-form-item>
              <template slot="label">
                <el-select
                  v-model="searchTimeType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  class="selectTimeClass"
                  size="small"
                >
                  <!--创建时间-->
                  <el-option key="1" :label="$t('newOrder.createTime')" value="1" />
                </el-select>
              </template>
              <el-date-picker
                v-model="timeValue"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('operationCenter.endDate')"
                :format="$i18n.locale==='zh'?zhDate:ptDate"
                :picker-options="pickerOptions"
                :start-placeholder="$t('operationCenter.startDate')"
                range-separator="~"
                size="small"
                style="width: 100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 服务商轨迹描述： -->
            <el-form-item :label="$t('collectionCenter.serviceProviderTrailDescription')">
              <el-input
                v-model.trim="queryForm.params.providerTrackStatus" :placeholder="$t('collectionCenter.pleaseInput')"
                clearable size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 是否匹配： -->
            <el-form-item :label="$t('collectionCenter.matchOrNot')">
              <el-select
                v-model="queryForm.params.normal"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                size="small"
                style="width: 100%;"
              >
                <el-option key="1" :label="$t('GlobalSetObj.yes')" :value="0" />
                <el-option key="2" :label="$t('GlobalSetObj.No')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 匹配快递子状态： -->
            <el-form-item :label="$t('collectionCenter.matchExpressSubState')">
              <el-select
                v-model="queryForm.params.problemTypeId" :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable
              >
                <el-option
                  v-for="(item, index) in problemList"
                  :key="index"
                  :label="item.dictDescribe"
                  :value="item.problemId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <!--搜索-->
        <el-button size="small" type="primary" @click="Search">
          {{ $t('newOrder.search') }}
        </el-button>
        <!--重置-->
        <el-button
          size="small" style="margin-left: 20px" type="info"
          @click="resetData"
        >
          {{ $t('orderCenterCont.reset') }}
        </el-button>
      </div>
    </el-card>
    <div style="margin: 20px 0">
      <!-- 批量匹配 -->
      <el-button size="small" type="success" @click="batchMatchClick">{{ $t('collectionCenter.batchMatching') }}</el-button>
      <!-- 批量修改 -->
      <el-button size="small" type="danger" @click="batchEditClick">{{ $t('collectionCenter.batchEdit') }}</el-button>
      <!-- 批量导入 -->
      <el-popover
        class="popoverClass"
        placement="bottom-start"
        title=""
        trigger="hover"
        content="x"
      >
        <el-button
          slot="reference"
          size="small"
          type="success"
        >{{ $t('basicData.BulkImport') }}</el-button><!-- 批量导入 -->
        <div>
          <div>
            <el-button
              size="small"
              style="margin:10px;"
              @click="downloadClick"
            >{{ $t('collectionCenter.TemplateDownload') }}</el-button><!-- 下载模版 -->
          </div>
          <div style="text-align: center;">
            <el-upload
              class="uploadFileClass"
              action
              :limit="1"
              :show-file-list="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :http-request="BulkImportClick"
            >
              <el-button
                size="small"
                type="success"
              >{{ $t('basicData.BulkImport') }}</el-button>
            </el-upload><!-- 批量导入 -->
          </div>
        </div>
      </el-popover>
    </div>
    <el-card>
      <el-table
        ref="multipleRef"
        :data="tableData"
        border
        :max-height="600"
        style="width: 100%"
        @select="batchSelect"
        @select-all="batchSelect"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          :label="$t('collectionCenter.ind')" align="center" type="index"
          width="70"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 服务商轨迹描述 -->
        <el-table-column :label="$t('collectionCenter.serviceProviderTrailDescription')" prop="providerTrackStatus" align="center" />
        <!-- 轨迹状态码 -->
        <el-table-column :label="$t('collectionCenter.trajectoryStatusCode')" prop="providerStatus" align="center" />
        <!-- 服务商名称 -->
        <el-table-column :label="$t('navbar.providerName')" prop="providerName" align="center" />
        <!-- 是否匹配 -->
        <el-table-column :label="$t('collectionCenter.matchOrNot')" prop="normal" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.normal == 0 ? $t('GlobalSetObj.yes') : scope.row.normal == 1 ? $t('GlobalSetObj.No') : '' }}</div>
          </template>
        </el-table-column>
        <!-- 创建时间 -->
        <el-table-column :label="$t('newOrder.createTime')" prop="createTime" align="center" />
        <!-- 匹配快递描述 -->
        <el-table-column :label="$t('collectionCenter.matchingExpressDescription')" prop="expressTrackStatus" align="center" />
        <!-- 匹配快递状态码 -->
        <el-table-column :label="$t('collectionCenter.matchDeliveryStatusCode')" prop="expressStatus" align="center" />
        <!-- 匹配快递运单状态 -->
        <el-table-column :label="$t('collectionCenter.matchExpressWaybillStatus')" prop="waybillStatus" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.waybillStatus">{{ scope.row.waybillStatusName }}</div>
          </template>
        </el-table-column>
        <!-- 匹配快递子状态 -->
        <el-table-column :label="$t('collectionCenter.matchExpressSubState')" prop="dictValue" align="center" />
      </el-table>
      <div class="right">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100, 500, 1000]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="Search"
          @size-change="getPageSize"
        />
      </div>
    </el-card>
    <!-- 批量匹配 / 批量修改 -->
    <div v-if="visible" class="dialogBox">
      <el-dialog
        :title="dialogTitle"
        :visible.sync="visible"
        width="90%"
        :before-close="handle1Close"
      >
        <div v-for="(item, index) in dialogList" :key="index" class="dialogClass">
          <div style="width: 40%;">
            <div class="titleClass">{{ $t('collectionCenter.ind') }}<span class="idx">{{ index + 1 }}</span></div>
            <el-descriptions
              direction="vertical" :column="2" border
              style="margin-left: 48px;"
            >
              <!-- 服务商轨迹描述 -->
              <el-descriptions-item :label="$t('collectionCenter.serviceProviderTrailDescription')">{{ item.providerTrackStatus }}</el-descriptions-item>
              <el-descriptions-item :label="$t('collectionCenter.trajectoryStatusCode')">{{ item.providerStatus }}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="el-icon-right rightIcon" />
          <div style="width: 40%;margin-right: 48px;">
            <!-- 运单状态匹配 -->
            <div class="titleClass"><span style="color: red">*</span>{{ $t('collectionCenter.waybillStatusMatches') }}</div>
            <el-select
              v-model="item.waybillStatus" :placeholder="$t('collectionCenter.pleaseFindOrSelectWaybillStatus')"
              style="width: 100%" clearable
              @change="changeWbs(item.waybillStatus, index)"
            >
              <el-option
                v-for="(item, ind) in waybillStatusList"
                :key="ind"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
            <el-select
              v-if="item.waybillStatus == 11"
              v-model="item.problemTypeId" :placeholder="$t('collectionCenter.pleaseChoose')"
              clearable filterable
              style="width: 100%"
            >
              <el-option
                v-for="(itm, index) in problemList"
                :key="index"
                :label="itm.dictDescribe"
                :value="itm.problemId"
              />
            </el-select>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveClick">{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
        </span>
      </el-dialog>
    </div>

    <!-- 批量导入 -->
    <div v-if="visibleImport">
      <el-dialog
        :title="$t('spareNumberPoo.ImportData')"
        :visible.sync="visibleImport"
        width="90%"
        :before-close="handle2Close"
      >
        <el-table
          :data="importData"
          border
          :max-height="600"
          style="width: 100%"
          :row-style="tableRowStyle"
        >
          <el-table-column :label="$t('collectionCenter.serviceProviderTrailDescription')" prop="providerTrackStatus" align="center" />
          <el-table-column :label="$t('collectionCenter.trajectoryStatusCode')" prop="providerStatus" align="center" />
          <el-table-column :label="$t('navbar.providerName')" prop="providerName" align="center" />
        </el-table>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {
  providerMatchExpressPage,
  savaBatchNormal,
  updateBatchNormal,
  importData,
  getStartupProviderChannel,
  getAllProblemType
} from '@/api/trackQuery';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export default {
  name: 'ServiceTrajectoryMatching',
  data() {
    return {
      problemList: [],
      importData: [],
      visibleImport: false,
      fileList: [], // 清空缓存
      waybillStatusList: [
        { label: this.$t('newOrder.Collected'), code: 3 }, // 已揽收
        { label: this.$t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        { label: this.$t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        { label: this.$t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        { label: this.$t('newOrder.Delivering'), code: 9 }, // 派送中
        { label: this.$t('newOrder.Signed'), code: 10 }, // 已签收
        { label: this.$t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        // { label: this.$t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
        { label: this.$t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        { label: this.$t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        { label: this.$t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        { label: this.$t('GlobalSetObj.TheReturnComplete'), code: 16 } // 退件完成
        // { label: this.$t('GlobalSetObj.mutilSign'), code: 20 } // 多次签收
      ],
      provideNameList: [],
      timeValue: [],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 365 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      searchTimeType: '1',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerId: '',
          matchExpressStartTime: '',
          matchExpressEndTime: '',
          providerTrackStatus: '',
          normal: '',
          waybillStatus: '',
          problemTypeId: ''
        }
      },
      total: 0,
      tableData: [],
      visible: false,
      dialogTitle: '批量轨迹匹配',
      dialogList: [],
      selectionList: []
    };
  },
  created() {
    this.queryForm.pageNumber = 1;
    this.queryForm.pageSize = 10;
    this.resetData();
    this.problemFind();
    this.Search();
  },
  mounted() {
    this.getProviderList();
  },
  methods: {
    changeWbs(val, ind) {
      // 切换即清空
      this.dialogList[ind].problemTypeId = '';
    },
    // 查询条件“匹配快递子状态”
    // 当匹配快递描述为“问题件”时，弹出二级子菜单可选“问题件的详细原因，即子状态”
    problemFind() {
      const val = 1;
      getAllProblemType(val).then(res => {
        if (res.code === 200 || res.status === 'OK') {
          this.problemList = res.data;
        }
      }).catch({});
    },
    // 获取服务商选项
    getProviderList() {
      getStartupProviderChannel().then((res) => {
        if (res.code === 200) {
          this.provideNameList = res.data;
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.matchExpressStartTime = '';
        this.queryForm.params.matchExpressEndTime = '';
        return;
      }
      this.queryForm.params.matchExpressStartTime = val[0];
      this.queryForm.params.matchExpressEndTime = val[1];
    },
    Search() {
      if ((
        !this.queryForm.params.providerId &&
        !this.queryForm.params.waybillStatus &&
        !this.queryForm.params.providerTrackStatus &&
        !this.queryForm.params.problemTypeId &&
        this.queryForm.params.normal !== 0 &&
        this.queryForm.params.normal !== 1) &&
        (!this.queryForm.params.matchExpressStartTime || !this.queryForm.params.matchExpressEndTime)
      ) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      providerMatchExpressPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.total = res.data.total;
          this.tableData = res.data.records.map(arr => {
            let getName = '';
            if (arr.waybillStatus) {
              getName = this.waybillStatusList.find(li => li.code === arr.waybillStatus);
            }
            return {
              waybillStatusName: getName ? getName.label : '',
              ...arr
            };
          });
          this.dialogList = [];
          this.selectionList = [];
          this.$refs.multipleRef.clearSelection();
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      providerMatchExpressPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.dialogList = [];
          this.selectionList = [];
          this.$refs.multipleRef.clearSelection();
        }
      });
    },
    resetData() {
      const today = dayjs().format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.searchTimeType = '1';
      this.queryForm.params = {
        providerId: '',
        matchExpressStartTime: today + ' 00:00:00',
        matchExpressEndTime: today + ' 23:59:59',
        providerTrackStatus: '',
        normal: '',
        waybillStatus: '',
        problemTypeId: ''
      };
    },
    batchSelect(selection) {
      this.selectionList = selection;
      this.dialogList = selection.map(arr => {
        let getName = '';
        if (arr.waybillStatus) {
          getName = this.waybillStatusList.find(li => li.code === arr.waybillStatus);
        }
        return {
          id: arr.id,
          providerTrackStatus: arr.providerTrackStatus,
          providerStatus: arr.providerStatus,
          waybillStatus: getName ? getName.code : '',
          problemTypeId: arr.problemTypeId || ''
        };
      });
    },
    handle1Close() {
      this.visible = false;
      this.dialogList = [];
      this.selectionList = [];
      this.$refs.multipleRef.clearSelection();
    },
    fil() {
      if (this.$refs.multipleRef && this.dialogList.length > 0) {
        let isTip = false;
        this.selectionList.forEach(row => {
          if (row.waybillStatus) {
            this.$refs.multipleRef.toggleRowSelection(row, false);
            isTip = true;
          }
        });
        let filteredArray = this.selectionList.map(item => {
          if (!item.waybillStatus) {
            return {
              id: item.id,
              providerTrackStatus: item.providerTrackStatus,
              providerStatus: item.providerStatus,
              waybillStatus: '',
              problemTypeId: item.problemTypeId
            };
          }
        });
        if (isTip) {
          this.$message({
            message: this.$t('collectionCenter.filAlreadyMatchedData'), // 已过滤掉已匹配的数据
            type: 'warning'
          });
        }
        filteredArray = filteredArray.filter(itm => itm !== undefined);
        this.dialogList = filteredArray;
      }
      if (this.dialogList.length <= 0) {
        this.dialogList = [];
        this.selectionList = [];
        this.$refs.multipleRef.clearSelection();
      }
    },
    // 批量匹配
    batchMatchClick() {
      this.fil();
      if (this.dialogList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      }
      this.visible = true;
      this.dialogTitle = this.$t('collectionCenter.batchTrackMatching'); // '批量轨迹匹配'
    },
    batchEditClick() {
      if (this.dialogList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      }
      this.visible = true;
      this.dialogTitle = this.$t('collectionCenter.batchEditTrajectoryMatching'); // '批量修改轨迹匹配'
    },
    saveClick() {
      const isTrue = this.dialogList.some(item => item.waybillStatus === '');
      const isNull = this.dialogList.some(item => {
        return item.waybillStatus === 11 && (!item.problemTypeId || item.problemTypeId === '');
      });
      if (isTrue || isNull) {
        this.$message.error(this.$t('operatingPlatform.PleaseFillInTheRequiredFields'));
        return;
      }
      const formList = this.dialogList.map(item => {
        return {
          id: item.id,
          waybillStatus: item.waybillStatus,
          problemTypeId: item.problemTypeId
        };
      });
      const form = {
        providerMatchList: formList
      };
      if (this.dialogTitle === this.$t('collectionCenter.batchTrackMatching')) { // '批量轨迹匹配'
        savaBatchNormal(form).then(res => {
          this.Search();
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            this.visible = false;
            if (res.data.length > 0) {
              const data1 = res.data.join('，');
              this.$message.success(this.$t('collectionCenter.idTip') + data1 + this.$t('collectionCenter.idTip2'));
            }
          }
        });
      }
      if (this.dialogTitle === this.$t('collectionCenter.batchEditTrajectoryMatching')) { // '批量修改轨迹匹配'
        updateBatchNormal(form).then(res => {
          this.Search();
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            this.visible = false;
          }
        });
      }
    },
    downloadClick() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.columns = [
          { // 服务商轨迹描述
            header: that.$t('collectionCenter.serviceProviderTrailDescription'),
            key: 'oneCode',
            width: 50
          },
          { // 服务商轨迹状态码
            header: that.$t('collectionCenter.serviceProviderTrailCode'),
            key: 'twoCode',
            width: 20
          },
          { // 服务商名称
            header: that.$t('collectionCenter.serviceProviderName'),
            key: 'threeCode',
            width: 40
          }
        ];
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
        const language = sessionStorage.getItem('language') || navigatorLanguage;
        const date = new Date().getTime();
        let saveName = '';
        if (language === 'zh') {
          saveName = '轨迹匹配导入模版' + date + '.xlsx';
        } else {
          saveName = 'Modelo de importação de partidas de trilha ' + date + '.xlsx';
        }
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    beforeUpload(file) {
      this.fileList = [];
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['xls', 'xlsx', 'XLS', 'XLSX'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件支持xls、xlsx等格式
        this.$message.error(this.$t('collectionCenter.uploadFileFormat1'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // 上传文件大小不能超过 2MB
        this.$message.error(this.$t('collectionCenter.uploadNot2MB'));
        return false;
      }
    },
    BulkImportClick(option) {
      const upLoadFile = new FormData();
      upLoadFile.append('file', option.file);
      if (option.file) {
        importData(upLoadFile).then(res => {
          const isSome = res.data.some(item => item.errorMessage === 'FAILED');
          if (res.code === 200 && res.data.length > 0) {
            if (!isSome) {
              this.$message.success(this.$t('operationCenter.TheImportWasSuccessful')); // 导入成功
              this.Search();
            } else {
              this.$message.error(this.$t('trajectory.ThereIsDataThatFailedToImport')); // 存在导入失败的数据
            }
            this.visibleImport = true;
            this.importData = res.data;
          } else if (res.code === 200 && res.data.length === 0) {
            if (!isSome) {
              this.$message.success(this.$t('operationCenter.TheImportWasSuccessful')); // 导入成功
              this.Search();
            }
          } else {
            this.$message.error(this.$t('orderCenterCont.ImportFailed')); // 导入失败
          }
        }).catch(() => {
          this.$message.error(this.$t('orderCenterCont.ImportFailed')); // 导入失败
        });
      }
    },
    // 导入之后，根据状态值标红
    tableRowStyle({ row }) {
      if (row.errorMessage === 'FAILED') {
        return {
          'background-color': '#fe4747 !important', 'color': '#fff'
        };
      }
      return {};
    },
    handle2Close() {
      this.visibleImport = false;
      this.Search();
      this.importData = [];
    }
  }
};
</script>
<style>
.el-message {
  z-index: 3000!important;
}
</style>
<style lang="scss" scoped>
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.selectClass ::v-deep .el-col { margin-right: 20px; }
.selectTimeClass ::v-deep .el-input__inner { font-weight: 700 !important; }
.dialogBox ::v-deep .el-dialog__header{ border-bottom: none !important; }
.dialogBox ::v-deep .el-dialog__close { transform: scale(1.5); }
.dialogBox ::v-deep .el-dialog { margin-top: 10vh !important;margin-bottom: 50px !important; }
.dialogBox ::v-deep .el-dialog__body { max-height: 600px;overflow-y: auto }
.dialogBox {
  .dialogClass {
    border: 1px solid #e7e7e7;border-radius: 5px; padding: 20px; box-sizing: border-box;margin-bottom: 20px;
    display: flex;justify-content: space-between;align-items: center;
    .titleClass{
      font-size: 16px;font-weight: bold;display: flex;justify-content: start;align-items: center;margin-bottom: 10px;
      .idx{ border: 1px solid #606266;border-radius: 50%;box-sizing: border-box;width: 20px;height: 20px;display: flex;justify-content: center;align-items: center; }
    }
  }
  .rightIcon { transform: scale(3) }
}
::v-deep .el-table__body tr:hover {
  color: #606266 !important;
}
.popoverClass{
  margin-left: 10px;
}
.uploadFileClass{
  text-align: center;
  margin-right: 10px;
}
</style>

<template>
  <div class="preview-model">
    <div class="mask" />
    <span class="el-image-viewer__btn el-image-viewer__close" @click="close"><i class="el-icon-close" /></span>
    <span class="el-image-viewer__btn el-image-viewer__close el-download" @click="download"><i class="el-icon-download" /></span>

    <el-button
      class="el-image-viewer__btn el-image-viewer__prev" icon="el-icon-arrow-left" :disabled="currentIndex === 0"
      circle
      @click="prevFile"
    />
    <el-button
      class="el-image-viewer__btn el-image-viewer__next" icon="el-icon-arrow-right" :disabled="fileList.length === 0 || currentIndex === fileList.length - 1"
      circle
      @click="nextFile"
    />
    <div v-loading.fullscreen.lock="loading" class="el-image-viewer__canvas">
      <div v-if="fileList.length > 0" class="preview-area">
        <el-image
          v-if="isImage"
          class="preview-image" :src="currentFileUrl"
          fit="fill"
          @load="onImageLoad"
        />
        <pdf
          v-else-if="isPDF"
          :src="currentFileUrl"
          class="preview-pdf"
          @loaded="onPDFLoad"
        />
        <el-empty v-else description="不支持的文件格式" />
      </div>
      <el-empty v-else description="暂无文件可预览" />
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import { debounce } from 'lodash-es';

export default {
  components: {
    pdf
  },
  props: {
    fileList: {
      type: Array,
      default: () => [
        // 'https://express-1313947203.cos.sa-saopaulo.myqcloud.com/upload-file/finance/20250208T171614/f77305ceaf0e4d1735a3d5e001dbbe29c87841f2d3b1ad3be1e4f904f3c59f4d/2-20250208T171614.jpg',
        // 'https://express-v2-1313947203.cos.sa-saopaulo.myqcloud.com/upload/excelExport/finance-admin/pdf/bcdc85491b9d99522c5fade92d4782ce7abed2bbeb89623c6812264df0e90c3f/20250226172518-256.pdf'
      ]
    }
  },
  data() {
    return {
      loading: false,
      currentIndex: 0,
      currentFileUrl: '',
      fileType: ''
    };
  },
  computed: {
    isImage() {
      return ['bmp', 'jpg', 'png', 'gif'].includes(this.fileType);
    },
    isPDF() {
      return this.fileType === 'pdf';
    }
  },
  watch: {
    fileList: {
      immediate: true,
      handler() {
        if (this.fileList.length > 0) {
          this.updatePreview(this.fileList[0]);
        }
      }
    }
  },
  methods: {
    /**
     * 更新预览文件
     * @param file
     */
    updatePreview(file) {
      this.loading = true;
      this.fileType = file.split('.').pop().split('?')[0].toLowerCase();
      this.currentFileUrl = file.startsWith('http') ? file : `https://${file}`;
    },
    /**
     * 上一页
     */
    prevFile() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updatePreview(this.fileList[this.currentIndex]);
      }
    },
    /**
     * 下一页
     */
    nextFile() {
      if (this.currentIndex < this.fileList.length - 1) {
        this.currentIndex++;
        this.updatePreview(this.fileList[this.currentIndex]);
      }
    },
    /**
     * 关闭预览
     */
    close() {
      this.$emit('close');
    },
    /**
     * 下载文件
     */
    download: debounce(async function() {
      const url = this.currentFileUrl;
      const fileName = url.split('/').pop().split('?')[0];
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const blob = await response.blob();

        // 下载逻辑
        const triggerDownload = (dataBlob) => {
          const downloadElement = document.createElement('a');
          const objectURL = window.URL.createObjectURL(dataBlob);

          downloadElement.href = objectURL;
          downloadElement.download = fileName || '';
          document.body.appendChild(downloadElement);
          downloadElement.click();

          // 清理资源
          setTimeout(() => {
            window.URL.revokeObjectURL(objectURL);
            document.body.removeChild(downloadElement);
          }, 0);
        };

        triggerDownload(blob); // 触发下载
      } catch (error) {
        console.log('解析失败:', error);
        this.$message.error('文件链接失效！');
      }
    }, 500),
    /**
     * 图片加载完成
     */
    onImageLoad() {
      this.loading = false;
    },
    /**
     * PDF加载完成
     */
    onPDFLoad() {
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.preview-model {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000000000;

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .5;
    background: #000;
  }

  ::v-deep .el-image-viewer__btn {
    background-color: #606266;
    opacity: .8;
    border: none;
    color: #fff;
  }

  .el-download {
    right: 100px;
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 100000000001;

    .el-icon {
      font-size: 24px;
      color: #fff;
    }

    span {
      display: block;
      margin-top: 8px;
      color: #fff;
    }
  }

  .preview-area {
    z-index: 20000000000;
    position: relative;
    overflow-y: scroll;
    height: 100%;
    max-width: 1000px;

    .preview-image {
      max-width: 100%;
      height: auto;
    }

    .preview-pdf {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

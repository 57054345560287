import request from '@/utils/request';
import newRequest from '@/utils/new-request';

// const baseURL2 = process.env.VUE_APP_BASE_API4;
const baseURL3 = process.env.VUE_APP_BASE_API1;

const baseURLAdmin = process.env.VUE_APP_ADMIN;
/**
 * 更新运单备注
 */
export const OrderRemark = (data) => {
  return request.post('/orderWaybill/update/OrderRemark', data);
};

// 获取运单列表数据
export const orderWaybillPage = (data) => {
  return request.post('/orderWaybill/page', data);
};
// 获取运单详情
export const getOrderInfo = (id) => {
  return request({
    url: `/orderWaybill/${id}`,
    method: 'get',
    baseURL: baseURLAdmin
  });
};
// 查询分配网点列表
export const getDistributionDotList = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/site/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

/**
 * 站点分页查询（新）
 */
export const apiCommonSite = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/common/Site',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 根据运单号打印面单
export const printChangeOrder = (data) => {
  return request.post('/changeOrder/printChangeOrder', data);
};
// 单号打印面单分页
export const printChangeOrderPage = (data) => {
  return request.post('/changeOrder/printChangeOrderPage', data);
};

// 分配网点
export const DistributionDot = (data) => {
  return request.post('/orderWaybill/batch/update', data);
};
// 更新预揽收网点信息
export const preDistributionDot = (data) => {
  return request.post('/orderWaybill/update/orderSite', data);
};

// 换单分页及模糊查询
export const changeOrderPage = (data) => {
  return request.post('/changeOrder/page', data);
};
// 手动取号
export const providerTakeNo = (data) => {
  return request({
    method: 'POST',
    url: '/takeNo',
    data,
    baseURL: process.env.VUE_APP_BASE_API3
  });
};

// 根据运单id修改换单信息
export const updateChangeOrder = (data) => {
  return request.post('/changeOrder/updateChangeOrder', data);
};
// 导出订单列表
export const apiWaybillExport = (data) => {
  return request.post('/order/waybillExport', data);
};
// 获取问题件原因
export const apiGetProblemReason = (data) => {
  return request.post('/problemReason/getProblemReason', data);
};
// 保存登记问题件
export const apiSaveProblemReason = (data) => {
  return request.post('/problemReason/saveProblemReason', data);
};
export const updateInvoice = (data) => {
  return request.post('/orderWaybill/invoice', data);
};
// 全部导出
export const waybillExportAll = (data) => {
  return request.post('/excel' + '/order/waybillExportAll', data);
};

// 订单推送查询
export const orderWaybillPushPage = (data) => {
  return request.post('/orderWaybillPush/page', data);
};

// 手动推送订单
export const manualPush = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/logistics/order/client/pushOrderWaybill',
    baseURL: baseURL3
  });
};
// 运输发票分页查询
export const apiLogisticsInvoicePage = (data) => {
  return request.post('/logisticsInvoice/allInvoicePage', data);
};
// 运输发票cte分页查询
export const apiLogisticsInvoiceCtePage = (data) => {
  return request.post('/logisticsInvoice/ctePage', data);
};
// 运输发票nfse分页查询
export const apiLogisticsInvoiceNfsePage = (data) => {
  return request.post('/logisticsInvoice/nfsePage', data);
};
// cte导出
export const apiLogisticsCteExport = (data) => {
  return request.post('/logisticsInvoice/cteExport', data);
};
// 运输发票nfse重新生成
export const apiStartTask = (data) => {
  return request.post('/invoice/startTask', data);
};
// 获取日志分页
export const waybillOperationLogpage = (data) => {
  return request.post('/waybillOperationLog/page', data);
};
// cte取消发票
export const apiInvoiceCancel = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/invoice/cancle',
    data,
    baseURL: baseURL3
  });
};
// cte重新生成
export const rebuild = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/invoice/rebuild',
    data,
    baseURL: baseURL3
  });
};
export const apiInsurancePage = (data) => {
  return request.post('/insurance/page', data);
};
// 重新推送
export const apiInsuranceRepush = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/insurance/repush',
    data,
    baseURL: baseURL3
  });
};
// 服务商渠道
export const providerchannelall = (data) => {
  return request.post('/providerChannel/get/provider/channel/all', data);
};
export const rebuildMinutaApi = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/minuta/rebuild',
    data,
    baseURL: baseURL3
  });
};
// minuta列表查询
export const waybillMinutaPage = (data) => {
  return request.post('/waybillMinuta/page', data);
};
// 获取取消订单列表
export const apiAbolishPage = (data) => {
  return request.post('/orderWaybill/abolish/page', data);
};
// 获取服务商查询选项
export const apiGetProviderNames = () => {
  return request.get('/orderWaybill/abolish/getProviderNames');
};
// 订单导入
export const apiImportCreateOrder = (data) => {
  return request({
    url: '/order/importCreateOrder',
    data,
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data; boundary=something'
    }
  });
};
// 导出失败订单
export const apiDownloadCreateOrder = (data) => {
  return request({
    url: '/order/downOrderExcel/' + data,
    // data,
    method: 'get'
    // responseType: 'blob'
  });
};
// 更新面单
export const apiUpdateLabel = (data) => {
  return request.post('/orderWaybill/batch/update/label', data);
};
// 逆向订单列表数据查询
export const apiReverseOrderWaybill = (data) => {
  return request.post('/reverseOrderWaybill/page', data);
};
export const apiReverseWaybillExport = (data) => {
  return request.post('/order/reverseWaybillExport', data);
};
// 获取预派送网点选项
export const getProviderSiteTree = (data) => {
  return request.post('/orderWaybillPush/getProviderSiteTree', data);
};
// 修改预派送网点
export const allocationDeliverSite = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/allocationDeliverSite',
    data,
    baseURL: baseURL3
  });
};

// 更新名单接口 ===> cql
export const apiUpdateLabelCql = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/rebuild/label',
    data,
    baseURL: baseURL3
  });
};
// 取消订单
export const apiBatchAbolish = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/abolish',
    data,
    baseURL: baseURL3
  });
  // return request.post('/logistics/order/client/abolish', data);
};
// 中转仓扫描入库
export const apiTransferScanStorage = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/api/scanStorage',
    data,
    baseURL: baseURL3
  });
};
// 待返仓、入库列表
export const apiTransitWarehousePage = (data) => {
  return request.post('/transitWarehouse/page', data);
};

// 查询所有司机
export const getcollectDriver = (data) => {
  return request.get('/collectDriver/queryAll', data);
};
// 查询所有网点
export const getAllsite = (data) => {
  return request.get('/site/queryAll', data);
};
// 获取日志分页
export const sorterMovementsPage = (data) => {
  return request.post('/wms/sorterMovements/page', data);
};
// 退件运单列表分页查询
export const apiOrderWaybillPage = (data) => {
  return request.post('/orderWaybill/return/page', data);
};
// 退件运单导出
export const apiOrderWaybillExport = (data) => {
  return request.post('/orderWaybill/return/export', data);
};
// 收件人地址修改
export const updateReceiveAddress = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/logistics/order/update/receiveAddress',
    baseURL: baseURL3
  });
};
// 退件运单导出
export const getTransitCenter = () => {
  return request.get('/site/getTransitCenter');
};
// 导出子包信息
export const waybillExportAllSubpackage = (data) => {
  return request.post('/excel' + '/order/waybillExportAllSubpackage', data);
};
// 派送员
export const collectDriverQueryCourier = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: '/fa/common/collectDriver/queryCourier/page',
    isLoading: isBool // 是否显示加载中参数
  });
};
// 派送员（新）
export const collectDriverQueryCourierNew = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    data,
    url: '/rpc/pmUser/pageByCondition ',
    isLoading: isBool // 是否显示加载中参数
  });
};
// 签收信息导出
export const exportSignedInfo = (data) => {
  return request.post('/excel' + '/orderWaybill/exportSignedInfo', data);
};
// 查询所有车签号
export const tagNumberAll = (data) => {
  return request.get('/printing/page/tagNumber', data);
};
// 获取问题件原因
export const downloadExpressLabeling = (data) => {
  return request.post('/orderWaybill/downloadExpressLabeling', data);
};
export const getTagNumberAll = (data) => {
  return request.post(`/printing/page/tagNumber`, data);
};
// 导出子包信息
export const liftInterceptType = (data) => {
  return request.post('/packageWhetherIntercept/liftInterceptType', data);
};
// 运单时效监控-批量单号导出
export const orderWaybillTimeRecordexportAll = (data) => {
  return request.post('/orderWaybillTimeRecord/exportAll', data);
};
// 服务商订单分页查询
export const providerorderpage = (data) => {
  return request({
    url: '/provider/order/page',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 服务商订单分页查询  导出
export const providerorderpageExpage = (data) => {
  return request({
    url: '/provider/order/export',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 服务商订单分页查询  重新分配服务商
export const reDistruProvider = (data) => {
  return request({
    url: '/provider/order/provider/assign',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 服务商订单分页查询  申请服务商单号
export const applyProviderNumber = (data) => {
  // return request.post('/provider/order/apply/number', data);
  return request({
    url: '/provider/order/apply/number',
    method: 'post',
    data,
    isLoading: true, // 是否显示加载中参数
    baseURL: baseURLAdmin
  });
};
// 服务商订单分页查询  插入运单服务商订单子表
export const providerapplychangeprovider = (data) => {
  return request.post('/provider/order/change/provider', data);
};
// 服务商订单分页查询  修改地址
export const updatebatchAddress = (data) => {
  return request({
    url: '/provider/order/update/batch',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 根据运单号打印面单
export const printswapscanChangeOrder = (data) => {
  return request.get(`/provider/order/swap/scan/${data}`);
};
// 获取tfkDir
export const getWaybillDirByWayNum = (id) => {
  return request.get(`/orderWaybill/getWaybillDirByWayNum/${id}`);
};
// 分拨扫描记录～增加批量单号导出功能
export const shipmentexportAll = (data) => {
  return request.post('/shipment/export', data);
};

// 次新轨迹导出
export const exportSecondLatestTrack = (data) => {
  return request.post('/excel' + '/orderWaybill/exportSecondLatestTrack', data);
};
// 分拣机看板
export const getSortedRecordRecallBoard = () => {
  // return request.get('/sortedRecordRecall/board');
  return request({
    method: 'get',
    url: '/sortedRecordRecall/board',
    baseURL: baseURLAdmin
  });
};

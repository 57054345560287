<template>
  <div style="margin-bottom: 20px">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div class="today-total-operation-data-box">
            <div>
              <span class="today-total-operation-data-title">{{ $t('newOrder.TotalOperationsToday') }}</span>
            </div>
            <div style="position: relative">
              <el-popover
                placement="bottom"
                width="260"
                trigger="click"
              >
                <span slot="reference" class="el-icon-search" style="margin: 0 5px;" />

                <el-form class="selectClass">
                  <el-row>
                    <el-col :span="24">
                      <el-form-item :label="$t('collectionCenter.state') + '：'">
                        <el-select v-model="xxx" multiple :placeholder="$t('collectionCenter.pleaseChoose')">
                          <el-option
                            v-for="item in xxxList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item :label="$t('collectionCenter.transferCenter') + '：'">
                        <el-select v-model="xxx" multiple :placeholder="$t('collectionCenter.pleaseChoose')">
                          <el-option
                            v-for="item in xxxList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-popover>
              <el-select
                v-model="orderType" size="mini"
                @change="orderTypeChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <el-row
            :gutter="20"
            type="flex" justify="start" style="flex-wrap: wrap;"
          >
            <el-col
              v-for="(item, index) in topMetrics" :key="index" :span="4"
              :md="6"
              :sm="8"
              :lg="4"
            >
              <div class="metric-item">
                <div class="metric-box">
                  <div style="display: inline-block;">
                    <div class="metric-title">{{ item.title }}</div>
                    <div class="metric-value">{{ item.value|formatNumber }}</div>
                  </div>
                  <div class="metric-change">
                    <img :src="item.change>0?getImageUrl('todayGreaterThan'):item.change==0?getImageUrl('todayEquals'):getImageUrl('todayLessThan')">
                    <span style="display: block;text-align: center">{{ item.change|formatNumber(true) }}</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { waybillDashboardOrderOperateData } from '@/api/orderApi';
export default {
  filters: {
    // 数字格式化函数
    formatNumber(num, showPositiveSign = false) {
      // 如果不是数字，直接返回原值
      if (isNaN(num) || num === null) return num;

      // 将数字转换为字符串
      let numStr = Number(num).toString();

      // 处理负数
      const isNegative = numStr.startsWith('-');
      if (isNegative) {
        numStr = numStr.slice(1);
      }

      // 分离整数部分和小数部分
      const parts = numStr.split('.');
      let integerPart = parts[0];
      const decimalPart = parts[1];

      // 添加千分位逗号
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // 重组数字
      let result = integerPart;
      if (decimalPart) {
        result += '.' + decimalPart;
      }

      // 添加正负号
      if (isNegative) {
        result = '-' + result;
      } else if (showPositiveSign && num > 0) {
        result = '+' + result;
      }

      return result;
    }
  },
  data() {
    return {
      orderType: 1, // 1：正式 | 2：测试
      topMetrics: [
        { title: this.$t('newOrder.OrderQuantity'), value: 0, change: 0, valueKey: 'orderTotal', changeKey: 'orderTotalDifference' },
        { title: this.$t('placeOrder.preOrderQuantity'), value: 0, change: 0, valueKey: 'preCollectionOrderTotal', changeKey: 'preCollectionOrderTotalDifference' },
        { title: this.$t('newOrder.FinalCollectionQuantity'), value: 0, change: 0, valueKey: 'collectTotal', changeKey: 'collectTotalDifference' },
        { title: this.$t('placeOrder.Level1HUBArrivalsVotes'), value: 0, change: 0, valueKey: 'receiptTimeTotal', changeKey: 'receiptTimeTotalDifference' },
        { title: this.$t('placeOrder.Level1HUBSentVotes'), value: 0, change: 0, valueKey: 'sendTimeTotal', changeKey: 'sendTimeTotalDifference' },
        { title: this.$t('placeOrder.mainBranchLinesVotes'), value: 0, change: 0, valueKey: 'transitTotal', changeKey: 'transitTotalDifference' },
        { title: this.$t('placeOrder.Level2HUBTotalArrivalsVotes'), value: 0, change: 0, valueKey: 'transferInStorageTimeTotal', changeKey: 'transferInStorageTimeTotalDifference' },
        { title: this.$t('placeOrder.Level2HUBTotalSentVotes'), value: 0, change: 0, valueKey: 'transferOutStorageTimeTotal', changeKey: 'transferOutStorageTimeTotalDifference' },
        { title: this.$t('placeOrder.Level3HUBTotalArrivalsVotes'), value: 0, change: 0, valueKey: 'thirdTransferInStorageTimeTotal', changeKey: 'thirdTransferInStorageTimeTotalDifference' },
        { title: this.$t('placeOrder.Level3HUBTotalSentVotes'), value: 0, change: 0, valueKey: 'thirdTransferOutStorageTimeTotal', changeKey: 'thirdTransferOutStorageTimeTotalDifference' },
        { title: this.$t('newOrder.ReceivedAtStationQuantity'), value: 0, change: 0, valueKey: 'dispatchReceiptTotal', changeKey: 'dispatchReceiptTotalDifference' },
        { title: this.$t('newOrder.DeliveredQuantity'), value: 0, change: 0, valueKey: 'deliveryTotal', changeKey: 'deliveryTotalDifference' },
        { title: this.$t('newOrder.SignedQuantity'), value: 0, change: 0, valueKey: 'deliveredTotal', changeKey: 'deliveredTotalDifference' },
        { title: this.$t('newOrder.ProblematicParcelQuantity'), value: 0, change: 0, valueKey: 'problemTotal', changeKey: 'problemTotalDifference' },
        { title: this.$t('newOrder.CancelledQuantity'), value: 0, change: 0, key: 'orderTotal', valueKey: 'canceledTotal', changeKey: 'canceledTotalDifference' },
        { title: this.$t('newOrder.ReturnedByStationQuantity'), value: 0, change: 0, key: 'orderTotal', valueKey: 'returnTransitTotal', changeKey: 'returnTransitTotalDifference' },
        { title: this.$t('newOrder.ReverseDeliveryQuantity'), value: 0, change: 0, key: 'orderTotal', valueKey: 'returningTotal', changeKey: 'returningTotalDifference' },
        { title: this.$t('newOrder.ReturnedAndSignedQuantity'), value: 0, change: 0, key: 'orderTotal', valueKey: 'reverseDeliveredTotal', changeKey: 'reverseDeliveredTotalDifference' },
        { title: this.$t('newOrder.OrderFailureQuantity'), value: 0, change: 0, key: 'orderTotal', valueKey: 'failOrderTotal', changeKey: 'failOrderTotalDifference' }
      ],
      options: [
        { value: 1, label: this.$t('newOrder.Formal') },
        { value: 2, label: this.$t('newOrder.test1') }
      ],
      xxx: '',
      xxxList: ''
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * @description 获取今日总操作数据
     */
    getData() {
      waybillDashboardOrderOperateData(this.orderType)
        .then((res) => {
          if (res.status === 'OK') {
            this.topMetrics.forEach((item) => {
              item.value = res.data[item.valueKey];
              item.change = res.data[item.changeKey];
            });
          }
        });
    },
    /**
     * @description 环境切换
     */
    orderTypeChange() {
      this.$emit('orderTypeChange', this.orderType);
      this.getData();
    },
    /**
     * @description 动态读取图片
     * @param fileName
     * @returns {*}
     */
    getImageUrl(fileName) {
      return require(`@/assets/images/kanban/${fileName}.png`);
    }
  }
};
</script>

<style lang="scss" scoped>

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
}
.selectClass ::v-deep .el-select{
  width: 100%;
}
.box-card {
  .el-card__header {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
  }
}
.today-total-operation-data-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .today-total-operation-data-title{
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #1C1C1C;
    line-height: 22px;
  }
  .tips{
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #009C37;
    line-height: 20px;
    margin-left: 10px;
  }
}

.metric-item{
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  padding: 10px;
  margin-bottom: 10px;
  .metric-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .metric-title{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #1C1C1C;
      line-height: 20px;
    }
    .metric-value{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 30px;
      color: #1C1C1C;
      line-height: 42px;
    }
    .metric-change{
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 13px;
      color: #848484;
      line-height: 18px;
      display: inline-block;
      img{
        width: 27px;
        height: 25px;
      }
    }
  }
}
</style>

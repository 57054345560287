<template>
  <el-dialog
    :title="tips"
    :visible.sync="isShow"
    width="500px"
  >
    <el-table
      :data="tableData"
      style="width: 100%;max-height: 300px"
    >
      <el-table-column
        prop="name"
        :label="fileName"
      >
        <template slot-scope="scope">
          <el-link type="success" :href="scope.row.url" target="_blank">{{ scope.row.name }}{{ scope.$index + 1 }}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        width="100"
      >
        <template slot-scope="scope">
          <el-button type="danger" size="mini" @click="del(scope.$index)">{{ $t('GlobalSetObj.Delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="upload">
      <UploadCmp
        ref="uploadRef"
        :drag="true"
        :multiple="true"
        :auto-upload="true"
        :limit="5"
        :tips="'仅支持上传格式为bmp，jpg，png，gif，pdf，且大小在5M以内的附件'"
        :upload-text="uploadText"
        @change="onFileChange"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit">{{ $t('GlobalSetObj.determine') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import i18n from '@/lang';
import UploadCmp from '@/views/claimsManagement/claimStatement/cmp/UploadCmp.vue';
import {
  apiSitePayableUpdateFile
} from '@/api/finance/receivables';

const edit = i18n.t('GlobalSetObj.edit'); // 编辑
const document = i18n.t('base.document'); // 文件
const fileName = i18n.t('Resource.fileName'); // 文件名称
const FILE = 'file';

export default {
  name: '',

  components: {
    UploadCmp
  },

  data() {
    return {
      id: 0,
      isShow: false,
      tableData: [],
      fileArr: []
    };
  },

  created() {
    this.tips = edit + document;
    this.fileName = fileName;

    this.uploadText = this.$t('basicData.DragTheFileHereOrClickUpload');
  },

  methods: {
    // 供父元素调用
    open(id, arr) {
      this.isShow = true;
      this.id = id;

      this.fileArr = [];
      setTimeout(() => {
        this.$refs.uploadRef.clearFiles(); // 清空列表
      }, 100);

      this.tableData = arr.map((item, index) => {
        return {
          name: FILE,
          url: item
        };
      });
    },

    onFileChange(arr) {
      this.fileArr = arr.map(item => {
        return item.url;
      });
    },

    del(index) {
      this.tableData.splice(index, 1);
    },

    getData() {
      const obj = {
        id: this.id,
        payFile: ''
      };
      const arr = this.tableData.map(item => item.url);
      // const arr2 = this.fileArr;
      obj.payFile = [...arr, ...this.fileArr].join(',');

      return obj;
    },

    onSubmit() {
      const data = this.getData();

      apiSitePayableUpdateFile(data).then(res => {
        this.isShow = false;
        this.$emit('update');
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style scoped>
.upload {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.upload >>> .el-upload-list {
  width: 450px;
}
</style>

<template>
  <div class="lanShouOrdersBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row class="selectClass">
          <el-col :span="7" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                  @change="changeTime"
                >
                  <template slot="prefix">
                    {{ (timeList.find(item => item.value === selectTime) || {}).time }}
                  </template>
                  <el-option
                    v-for="(item, index) in timeList"
                    :key="index"
                    :label="item.time"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <!-- 订单创建 selectTime == 't0' -->
              <!-- 揽收扫描 selectTime == 't1' -->
              <!-- 实际揽收 selectTime == 't2' -->
              <!-- 签收时间 selectTime == 't3' -->
              <el-col style="width: auto;" class="timeSelectClass">
                <el-form-item class="delMl">
                  <el-date-picker
                    v-model="timeValue"
                    :clearable="false"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    :range-separator="$t('collectionCenter.to')"
                    :start-placeholder="$t('collectionCenter.StartTime')"
                    :end-placeholder="$t('collectionCenter.EndTime')"
                    @change="dateChange"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" class="widthClass">
            <!-- 订单状态： -->
            <el-form-item :label="$t('collectionCenter.orderStatus')">
              <el-select
                v-model="queryForm.params.collectStatus" multiple :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.cStatusName"
                  :value="item.cStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="widthClass">
            <!-- 发票类型： -->
            <el-form-item :label="$t('collectionCenter.invoiceType')">
              <el-select
                v-model="queryForm.params.invoiceType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in invoiceTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="widthClass">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="focusCustomer"
              >
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="widthClass">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectNumber" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                  @change="changeCode"
                >
                  <template slot="prefix">
                    {{ (selectCodeList.find(item => item.value === selectNumber) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in selectCodeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col v-if="selectNumber == 'n1'" class="widthClass"><!-- 揽收订单号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.orderNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @blur="ft1"
                    @keydown.enter.native="BlockNewlineOrder"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectNumber == 'n2'" class="widthClass"><!-- 包裹号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.packageNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @blur="ft2"
                    @keydown.enter.native="BlockNewlinePackage"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectNumber == 'n3'" class="widthClass"><!-- 客户订单号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.customerNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @blur="ft3"
                    @keydown.enter.native="BlockNewlineCNumber"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectNumber == 'n4'" class="widthClass"><!-- 揽收批次号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.batchNo"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @blur="ft4"
                    @keydown.enter.native="BlockNewlineBatchNo"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectNumber == 'n5'" class="widthClass"><!-- 车牌号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.vehicleNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @blur="ft5"
                    @keydown.enter.native="BlockNewline5"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col v-if="selectNumber == 'n6'" class="widthClass"><!-- 袋号 -->
            <el-form-item class="siteClass">
              <el-input
                v-model="queryForm.params.bagNumber"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ft6"
                @keydown.enter.native="BlockNewline6"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="selectNumber == 'n7'" class="widthClass"><!-- 条形码 -->
            <el-form-item class="siteClass">
              <el-input
                v-model="queryForm.params.scanNumber"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ft7"
                @keydown.enter.native="BlockNewline7"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto" class="stateClass">
            <el-form-item>
              <el-select
                v-model="selectState" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                @change="changeState"
              >
                <template slot="prefix">
                  {{ (stateList.find(item => item.value === selectState) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in stateList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-if="selectState == 's1'"
                v-model="queryForm.params.senderState"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @keydown.enter.native="BlockNewlineSenderState"
              /><!-- 支持批量查询(回车换行分割) -->
              <el-input
                v-if="selectState == 's2'"
                v-model="queryForm.params.receiveState"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @keydown.enter.native="BlockNewlineReceiveState"
              /><!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto" class="stateClass">
            <el-form-item>
              <el-select
                v-model="selectCity" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                @change="changeCity"
              >
                <template slot="prefix">
                  {{ (cityList.find(item => item.value === selectCity) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-if="selectCity == 'c1'"
                v-model="queryForm.params.senderCity"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @keydown.enter.native="BlockNewlineSenderCity"
              /><!-- 支持批量查询(回车换行分割) -->
              <el-input
                v-if="selectCity == 'c2'"
                v-model="queryForm.params.receiveCity"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @keydown.enter.native="BlockNewlineReceiveCity"
              /><!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 揽收网点： -->
            <el-form-item :label="$t('collectionCenter.LanShouSite') + '：'">
              <el-select
                v-model="queryForm.params.siteIdList"
                filterable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                style="width: 100%"
                multiple
                @focus="getBranchList"
              >
                <el-option
                  v-for="item in siteIdL"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-popover
            class="popoverClass"
            placement="bottom-start"
            title=""
            trigger="hover"
            content="x"
          >
            <el-button
              slot="reference"
              v-permit:remove="'btn:collection-center:order:receive-data-processing:find'"
              type="primary"
              size="mini"
              icon="el-icon-search"
            >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
            <div>
              <div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="searchClick('part')"
                >{{ $t('collectionCenter.oneselfQuery') }}</el-button><!-- 自定义查询 -->
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="searchClick()"
                >{{ $t('collectionCenter.criterionQuery') }}</el-button><!-- 固定查询 -->
              </div>
            </div>
          </el-popover>
          <el-button
            v-permit:remove="'btn:collection-center:order:receive-data-processing:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:receive-data-processing:allExport'"
            icon="el-icon-upload2"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
          <el-popover
            class="uploadClass"
            placement="bottom-start"
            title=""
            trigger="hover"
            content="x"
          >
            <el-button
              slot="reference"
              v-permit:remove="'btn:collection-center:order:receive-data-processing:import'"
              size="mini"
              icon="el-icon-download"
            >{{ $t('collectionCenter.Import') }}</el-button><!-- 导入 -->
            <div>
              <div style="text-align: center;">
                <el-button
                  size="mini"
                  style="margin:10px;"
                  class="onlyClickOnce2"
                  @click="downloadClick"
                >{{ $t('collectionCenter.TemplateDownload') }}</el-button><!-- 下载模版 -->
              </div>
              <div>
                <el-upload
                  class="uploadFileClass"
                  action
                  :limit="1"
                  :show-file-list="false"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  :http-request="importt"
                >
                  <el-button
                    size="mini"
                  >{{ $t('operationCenter.Import') }}</el-button><!--导入-->
                </el-upload>
              </div>
            </div>
          </el-popover>

        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div class="tableClass">
      <el-table
        ref="tableList"
        v-loading="loading"
        :max-height="600"
        border
        :data="tableDate"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="defaultSelection.length <= 0"
          align="center"
          label=""
        /><!-- 选中项为空时占位 -->
        <el-table-column
          v-if="allSelectList[0].isShow"
          key="k0"
          align="center"
          prop="orderNumber"
          :label="$t('collectionCenter.lanshouOrderNum')"
        /><!-- 揽收订单号 -->
        <el-table-column
          v-if="allSelectList[1].isShow"
          key="k1"
          align="center"
          prop="scanTime"
          :label="$t('collectionCenter.collectScanTime')"
        /><!-- 揽收扫描时间 -->
        <el-table-column
          v-if="allSelectList[2].isShow"
          key="k2"
          align="center"
          prop="signForScanTime"
          :label="$t('collectionCenter.signatureScanTime')"
        /><!-- 签收扫描时间 -->
        <el-table-column
          v-if="allSelectList[3].isShow"
          key="k3"
          align="center"
          prop="uploadTime"
          :label="$t('collectionCenter.collectionScanUploadTime')"
        /><!-- 揽收上传时间 -->
        <el-table-column
          v-if="allSelectList[4].isShow"
          key="k4"
          align="center"
          prop="orderStatus"
          :label="$t('collectionCenter.OrderStatus')"
        /><!-- 订单状态 -->
        <el-table-column
          v-if="allSelectList[5].isShow"
          key="k5"
          align="center"
          prop="trackCreateTime"
          :label="$t('collectionCenter.collectionTrackCreateTime')"
        /><!-- 揽收轨迹创建时间 -->
        <el-table-column
          v-if="allSelectList[6].isShow"
          key="k6"
          align="center"
          prop="trackPushTime"
          :label="$t('collectionCenter.receivingTrackPushTime')"
        /><!-- 揽收轨迹推送时间 -->
        <el-table-column
          v-if="allSelectList[7].isShow"
          key="k7"
          align="center"
          prop="signForUploadTime"
          :label="$t('collectionCenter.signatureUploadTime')"
        /><!-- 签收上传时间 -->
        <el-table-column
          v-if="allSelectList[8].isShow"
          key="k8"
          align="center"
          prop="customerCode"
          :label="$t('collectionCenter.clientID')"
        /><!-- 客户编号 -->
        <el-table-column
          v-if="allSelectList[9].isShow"
          key="k9"
          align="center"
          prop="customerName"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          v-if="allSelectList[10].isShow"
          key="k10"
          align="center"
          prop="signForTrackCreateTime"
          :label="$t('collectionCenter.signatureTrackCreateTime')"
        /><!-- 签收轨迹创建时间 -->
        <el-table-column
          v-if="allSelectList[11].isShow"
          key="k11"
          align="center"
          prop="signForTrackPushTime"
          :label="$t('collectionCenter.signatureTrackPushTime')"
        /><!-- 签收轨迹推送时间 -->
        <el-table-column
          v-if="allSelectList[12].isShow"
          key="k12"
          align="center"
          prop="merchantName"
          :label="$t('collectionCenter.MerchantName')"
        /><!-- 商户名称 -->
        <el-table-column
          v-if="allSelectList[13].isShow"
          key="k13"
          align="center"
          prop="merchantCode"
          :label="$t('collectionCenter.MerchantNum')"
        /><!-- 商户号 -->
        <el-table-column
          v-if="allSelectList[14].isShow"
          key="k14"
          align="center"
          prop="uploadUser"
          :label="$t('collectionCenter.LanShouPeople')"
        /><!-- 揽收人 -->
        <el-table-column
          v-if="allSelectList[15].isShow"
          key="k15"
          align="center"
          prop="siteName"
          :label="$t('collectionCenter.collectionDot')"
        /><!-- 揽收网点 -->
        <el-table-column
          v-if="allSelectList[16].isShow"
          key="k16"
          align="center"
          prop="signForUploadUser"
          :label="$t('collectionCenter.signer')"
        /><!-- 签收人 -->
        <el-table-column
          v-if="allSelectList[17].isShow"
          key="k17"
          align="center"
          prop="signerSiteName"
          :label="$t('collectionCenter.deliveryDot')"
        /><!-- 签收网点 -->
        <el-table-column
          v-if="allSelectList[18].isShow"
          key="k18"
          align="center"
          prop="operationType"
          :label="$t('collectionCenter.actualCollectionMode')"
        /><!-- 实际揽收模式 -->
        <el-table-column
          v-if="allSelectList[19].isShow"
          key="k19"
          align="center"
          prop="collectTypeNmae"
          :label="$t('collectionCenter.receivingMode')"
        /><!-- 揽收模式 -->
        <el-table-column
          v-if="allSelectList[20].isShow"
          key="k20"
          align="center"
          prop="weight"
          :label="$t('collectionCenter.bagWeight')"
        /><!-- 包裹重量 -->
        <el-table-column
          v-if="allSelectList[21].isShow"
          key="k21"
          align="center"
          prop="goodsValue"
          :label="$t('collectionCenter.declaredValue')"
        /><!-- 申明价值 -->
        <el-table-column
          v-if="allSelectList[22].isShow"
          key="k22"
          align="center"
          prop="collectCost"
          :label="$t('collectionCenter.collectionFee')"
        /><!-- 揽收费用 -->
        <el-table-column
          v-if="allSelectList[23].isShow"
          key="k23"
          align="center"
          prop="expectTime"
          :label="$t('collectionCenter.ExpectedCollectionTime')"
        /><!-- 期望揽收时间 -->
        <el-table-column
          v-if="allSelectList[24].isShow"
          key="k24"
          align="center"
          prop="platformName"
          :label="$t('collectionCenter.platform')"
        /><!-- 所属平台 -->
        <el-table-column
          v-if="allSelectList[25].isShow"
          key="k25"
          align="center"
          prop="createTime"
          :label="$t('collectionCenter.orderCreateT')"
        /><!-- 订单创建时间 -->
        <el-table-column
          v-if="allSelectList[26].isShow"
          key="k26"
          align="center"
          prop="receiveCity"
          :label="$t('collectionCenter.receiveCity')"
        /><!-- 收件人城市 -->
        <el-table-column
          v-if="allSelectList[27].isShow"
          key="k27"
          align="center"
          prop="senderCity"
          :label="$t('collectionCenter.SenderCity')"
        /><!-- 发件人城市 -->
        <el-table-column
          v-if="allSelectList[28].isShow"
          key="k28"
          align="center"
          prop="receivePhone"
          :label="$t('collectionCenter.RecipientPhoneNumber')"
        /><!-- 收件人电话 -->
        <el-table-column
          v-if="allSelectList[29].isShow"
          key="k29"
          align="center"
          prop="senderPhone"
          :label="$t('collectionCenter.senderPhone')"
        /><!-- 发件人电话 -->
        <el-table-column
          v-if="allSelectList[30].isShow"
          key="k30"
          align="center"
          prop="senderZipcode"
          :label="$t('collectionCenter.senderZipCode')"
        /><!-- 发件人邮编 -->
        <el-table-column
          v-if="allSelectList[31].isShow"
          key="k31"
          align="center"
          prop="receiveZipcode"
          :label="$t('collectionCenter.Recipientzc')"
        /><!-- 收件人邮编 -->
        <el-table-column
          v-if="allSelectList[32].isShow"
          key="k32"
          align="center"
          prop="senderAddress"
          :label="$t('collectionCenter.SendAddress')"
        ><!-- 发件地址 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.senderAddress }}</div>
              <div class="paramsStyle">{{ scope.row.senderAddress }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allSelectList[33].isShow"
          key="k33"
          align="center"
          prop="receiveAddress"
          :label="$t('collectionCenter.receiptArs')"
        ><!-- 收件地址 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.receiveAddress }}</div>
              <div class="paramsStyle">{{ scope.row.receiveAddress }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allSelectList[34].isShow"
          key="k34"
          align="center"
          prop="receiveName"
          :label="$t('collectionCenter.recipientS')"
        /><!-- 收件人 -->
        <el-table-column
          v-if="allSelectList[35].isShow"
          key="k35"
          align="center"
          prop="senderName"
          :label="$t('collectionCenter.addresser')"
        /><!-- 发件人 -->
        <el-table-column
          v-if="allSelectList[36].isShow"
          key="k36"
          align="center"
          prop="invoiceType"
          :label="$t('collectionCenter.invoiceT')"
        /> <!-- 发票类型 -->
        <el-table-column
          v-if="allSelectList[37].isShow"
          key="k37"
          align="center"
          prop="receiveState"
          :label="$t('collectionCenter.RecipientProvince')"
        /> <!-- 收件人省份 -->
        <el-table-column
          v-if="allSelectList[38].isShow"
          key="k38"
          align="center"
          prop="packageNumber"
          :label="$t('collectionCenter.parcelNum')"
        /> <!-- 包裹号 -->
        <el-table-column
          v-if="allSelectList[39].isShow"
          key="k39"
          align="center"
          prop="closeTime"
          :label="$t('collectionCenter.closeTime')"
        /> <!-- 闭环时间 -->
        <el-table-column
          v-if="allSelectList[40].isShow" key="k40" align="center"
          prop="siteLoadedUserName" :label="$t('collectionCenter.networkLoader')"
        /> <!-- 网点装车人 -->
        <el-table-column
          v-if="allSelectList[41].isShow" key="k41" align="center"
          prop="siteLoadedScanTime" :label="$t('collectionCenter.networkLoadScanTime')"
        /> <!-- 网点装车扫描时间 -->
        <el-table-column
          v-if="allSelectList[42].isShow" key="k42" align="center"
          prop="siteLoadedVehicleNumber" :label="$t('collectionCenter.networkLoadVehicleNum')"
        /> <!-- 网点装车车牌号 -->
        <el-table-column
          v-if="allSelectList[43].isShow" key="k43" align="center"
          prop="siteLoadedUploadTime" :label="$t('collectionCenter.networkLoadUploadingTime')"
        /> <!-- 网点装车上传时间 -->
        <el-table-column
          v-if="allSelectList[44].isShow" key="k44" align="center"
          prop="transferLoadedUserName" :label="$t('collectionCenter.transferCenterLoader')"
        /> <!-- 转运中心装车人 -->
        <el-table-column
          v-if="allSelectList[45].isShow" key="k45" align="center"
          prop="transferLoadedScanTime" :label="$t('collectionCenter.transferLoadScanTime')"
        /> <!-- 转运中心装车扫描时间 -->
        <el-table-column
          v-if="allSelectList[46].isShow" key="k46" align="center"
          prop="transferLoadedVehicleNumber" :label="$t('collectionCenter.transferLoadVehicleNum')"
        /> <!-- 转运中心装车车牌号 -->
        <el-table-column
          v-if="allSelectList[47].isShow" key="k47" align="center"
          prop="transferLoadedUploadTime" :label="$t('collectionCenter.transferLoadUploadingTime')"
        /> <!-- 转运中心装车上传时间 -->
        <el-table-column
          v-if="allSelectList[48].isShow" key="k48" align="center"
          prop="siteUnloadUserName" :label="$t('collectionCenter.outletUnloader')"
        /> <!-- 网点卸车人 -->
        <el-table-column
          v-if="allSelectList[49].isShow" key="k49" align="center"
          prop="siteUnloadTime" :label="$t('collectionCenter.outletUnloadingTime')"
        /> <!-- 网点卸车时间 -->
        <el-table-column
          v-if="allSelectList[50].isShow" key="k50" align="center"
          prop="transferUnloadUserName" :label="$t('collectionCenter.unloadingTransferCenterP')"
        /> <!-- 转运中心卸车人 -->
        <el-table-column
          v-if="allSelectList[51].isShow" key="k51" align="center"
          prop="transferUnloadTime" :label="$t('collectionCenter.unloadingTransferCenterTime')"
        /> <!-- 转运中心卸车时间 -->
        <el-table-column
          v-if="allSelectList[52].isShow"
          key="k52"
          align="center"
          prop="senderState"
          :label="$t('orderCenterCont.SenderProvince')"
        /> <!-- 发件人省份 -->
        <el-table-column
          v-if="allSelectList[53].isShow"
          key="k53"
          align="center"
          prop="finishSignUrl"
          :label="$t('collectionCenter.lanshouImg')"
        > <!-- 揽收图片 -->
          <template slot-scope="scope">
            <el-popover
              :key="`${scope.row.finishSignUrl}`"
              :title="$t('collectionCenter.lanshouImg')"
              content=""
              placement="left"
              trigger="hover"
              width="400"
            >
              <img
                v-show="scope.row.finishSignUrl" slot="reference" :src="scope.row.finishSignUrl"
                alt="" style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.finishSignUrl" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.finishSignUrl" :src="scope.row.finishSignUrl" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allSelectList[54].isShow"
          key="k54"
          align="center"
          prop="signerImg"
          :label="$t('collectionCenter.signImage')"
        > <!-- 签收图片 -->
          <template slot-scope="scope">
            <el-popover
              :key="`${scope.row.signerImg}`"
              :title="$t('collectionCenter.signImage')"
              content=""
              placement="left"
              trigger="hover"
              width="400"
            >
              <img
                v-show="scope.row.signerImg" slot="reference" :src="scope.row.signerImg"
                alt="" style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.signerImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.signerImg" :src="scope.row.signerImg" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allSelectList[55].isShow"
          key="k55"
          align="center"
          prop="customerNumber"
          :label="$t('collectionCenter.customerOrderNum')"
        /> <!-- 客户订单号 -->
        <el-table-column
          v-if="allSelectList[56].isShow"
          key="k56"
          align="center"
          prop="batchNo"
          :label="$t('collectionCenter.receiveBatchNumber')"
        /> <!-- 揽收批次号 -->
        <el-table-column
          v-if="allSelectList[57].isShow"
          key="k57"
          align="center"
          prop="wmsSiteLoadSignImg"
          :label="$t('collectionCenter.networkLoadingSignaturePicture')"
        > <!-- 网点装车签名图片 -->
          <template slot-scope="scope">
            <el-popover
              :key="`${scope.row.wmsSiteLoadSignImg}`"
              :title="$t('collectionCenter.networkLoadingSignaturePicture')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.wmsSiteLoadSignImg" slot="reference" :src="scope.row.wmsSiteLoadSignImg"
                alt="" style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.wmsSiteLoadSignImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.wmsSiteLoadSignImg" :src="scope.row.wmsSiteLoadSignImg" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allSelectList[58].isShow"
          key="k58"
          align="center"
          prop="transferCenterLoadSignImg"
          :label="$t('collectionCenter.transferCenterLoadingSignaturePicture')"
        > <!-- 转运中心装车签名图片 -->
          <template slot-scope="scope">
            <el-popover
              :key="`${scope.row.transferCenterLoadSignImg}`"
              :title="$t('collectionCenter.transferCenterLoadingSignaturePicture')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.transferCenterLoadSignImg" slot="reference" :src="scope.row.transferCenterLoadSignImg"
                alt="" style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.transferCenterLoadSignImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.transferCenterLoadSignImg" :src="scope.row.transferCenterLoadSignImg" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allSelectList[59].isShow"
          key="k59"
          align="center"
          prop="bagNumber"
          :label="$t('collectionCenter.BagNumber')"
        /> <!-- 袋号 -->
        <el-table-column
          v-if="allSelectList[60].isShow"
          key="k60"
          align="center"
          prop="scanNumber"
          :label="$t('collectionCenter.Barcode')"
        /> <!-- 条形码 -->
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getDate"
        @size-change="searchClick('size')"
      />
    </div>
    <div v-if="dialogVisible">
      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="80%"
        @close="closeSelectDialog"
      >
        <div class="dialogBox">
          <div>
            <!-- 全选 -->
            <span>
              <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="allSelect">{{ $t('collectionCenter.allSelect') }}</el-checkbox>
            </span>
            <div style="margin: 15px 0;" />
            <!-- 部分选 -->
            <el-checkbox-group v-model="defaultSelection" @change="partSelect">
              <el-checkbox v-for="(item, index) in allSelectList" :key="index" :label="item.val">{{ item.selVal }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 确定 -->
          <el-button type="primary" @click="dialogConfirm">{{ $t('collectionCenter.confirm') }}</el-button>
          <!-- 取消 -->
          <el-button @click="dialogCancel">{{ $t('collectionCenter.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {
  queryOrderErrorPage,
  userExtendSaveData,
  userExtendQueryExtend,
  importData,
  orderExportAll,
  sectionQueryExport,
  queryOrderErrorAllPage,
  queryAllCustomer,
  searchSiteIdList
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default {
  name: 'ReceiveDataProcessing',
  data() {
    return {
      siteIdL: [], // 网点列表
      isClickAll: false,
      selectCity: 'c1',
      cityList: [
        { value: 'c1', name: this.$t('collectionCenter.sendingCity') }, // '发件城市'
        { value: 'c2', name: this.$t('collectionCenter.receivingCity') } // '收件城市'
      ],
      selectState: 's1',
      stateList: [
        { value: 's1', name: this.$t('collectionCenter.stateOrigin') }, // '发件州'
        { value: 's2', name: this.$t('collectionCenter.receivingState') } // '收件州'
      ],
      notEnter: true, // 是否允许换行
      selectNumber: 'n1',
      selectCodeList: [
        { value: 'n1', label: this.$t('collectionCenter.lanshouOrderNum') }, // '揽收订单号'
        { value: 'n2', label: this.$t('collectionCenter.parcelNum') }, // '包裹号'
        { value: 'n3', label: this.$t('collectionCenter.customerOrderNum') }, // '客户订单号'
        { value: 'n4', label: this.$t('collectionCenter.receiveBatchNumber') }, // '揽收批次号'
        { value: 'n5', label: this.$t('collectionCenter.licensePlateNumber') }, // '车牌号'
        { value: 'n6', label: this.$t('collectionCenter.BagNumber') }, // '袋号'
        { value: 'n7', label: this.$t('collectionCenter.Barcode') } // '条形码'
      ],
      customerIdList: [], // 客户名称列表
      timeValue: [],
      receiveDataProcessingZH: '/static/receiveDataProcessing-zh.xlsx',
      receiveDataProcessingPU: '/static/receiveDataProcessing-pu.xlsx',
      fileList: [], // 清空缓存
      onlyOneSave: 0, // 保存用
      isShow: false, // 全选
      oneselfOrAll: '', // 表格展示的是自定义还是固定
      title: '', // 查询分页区分
      checkAll: false,
      isIndeterminate: false,
      allSelectList: [
        { index: 0, selVal: this.$t('collectionCenter.lanshouOrderNum'), val: 'orderNumber', isShow: true }, // 揽收订单号
        { index: 1, selVal: this.$t('collectionCenter.collectScanTime'), val: 'scanTime', isShow: true }, // 揽收扫描时间
        { index: 2, selVal: this.$t('collectionCenter.signatureScanTime'), val: 'signForScanTime', isShow: true }, // 签收扫描时间
        { index: 3, selVal: this.$t('collectionCenter.collectionScanUploadTime'), val: 'uploadTime', isShow: true }, // 揽收上传时间
        { index: 4, selVal: this.$t('collectionCenter.OrderStatus'), val: 'collectStatus', isShow: true }, // 订单状态
        { index: 5, selVal: this.$t('collectionCenter.collectionTrackCreateTime'), val: 'trackCreateTime', isShow: true }, // 揽收轨迹创建时间
        { index: 6, selVal: this.$t('collectionCenter.receivingTrackPushTime'), val: 'trackPushTime', isShow: true }, // 揽收轨迹推送时间
        { index: 7, selVal: this.$t('collectionCenter.signatureUploadTime'), val: 'signForUploadTime', isShow: true }, // 签收上传时间
        { index: 8, selVal: this.$t('collectionCenter.clientID'), val: 'customerCode', isShow: false }, // 客户编号
        { index: 9, selVal: this.$t('collectionCenter.CustomerName'), val: 'customerName', isShow: false }, // 客户名称
        { index: 10, selVal: this.$t('collectionCenter.signatureTrackCreateTime'), val: 'signForTrackCreateTime', isShow: true }, // 签收轨迹创建时间
        { index: 11, selVal: this.$t('collectionCenter.signatureTrackPushTime'), val: 'signForTrackPushTime', isShow: true }, // 签收轨迹推送时间
        { index: 12, selVal: this.$t('collectionCenter.MerchantName'), val: 'merchantName', isShow: false }, // 商户名称
        { index: 13, selVal: this.$t('collectionCenter.MerchantNum'), val: 'merchantCode', isShow: false }, // 商户号
        { index: 14, selVal: this.$t('collectionCenter.LanShouPeople'), val: 'uploadUser', isShow: false }, // 揽收人
        { index: 15, selVal: this.$t('collectionCenter.collectionDot'), val: 'siteId', isShow: false }, // 揽收网点
        { index: 16, selVal: this.$t('collectionCenter.signer'), val: 'signForUploadUser', isShow: false }, // 签收人
        { index: 17, selVal: this.$t('collectionCenter.deliveryDot'), val: 'signerSiteId', isShow: false }, // 签收网点
        { index: 18, selVal: this.$t('collectionCenter.actualCollectionMode'), val: 'operationType', isShow: false }, // 实际揽收模式
        { index: 19, selVal: this.$t('collectionCenter.receivingMode'), val: 'customerCollectType', isShow: false }, // 揽收模式
        { index: 20, selVal: this.$t('collectionCenter.bagWeight'), val: 'weight', isShow: false }, // 包裹重量
        { index: 21, selVal: this.$t('collectionCenter.declaredValue'), val: 'goodsValue', isShow: false }, // 申明价值
        { index: 22, selVal: this.$t('collectionCenter.collectionFee'), val: 'collectCost', isShow: false }, // 揽收费用
        { index: 23, selVal: this.$t('collectionCenter.ExpectedCollectionTime'), val: 'expectTime', isShow: false }, // 期望揽收时间
        { index: 24, selVal: this.$t('collectionCenter.platform'), val: 'platformName', isShow: false }, // 所属平台
        { index: 25, selVal: this.$t('collectionCenter.orderCreateT'), val: 'createTime', isShow: true }, // 订单创建时间
        { index: 26, selVal: this.$t('collectionCenter.receiveCity'), val: 'receiveCity', isShow: false }, // 收件人城市
        { index: 27, selVal: this.$t('collectionCenter.SenderCity'), val: 'senderCity', isShow: false }, // 发件人城市
        { index: 28, selVal: this.$t('collectionCenter.RecipientPhoneNumber'), val: 'receivePhone', isShow: false }, // 收件人电话
        { index: 29, selVal: this.$t('collectionCenter.senderPhone'), val: 'senderPhone', isShow: false }, // 发件人电话
        { index: 30, selVal: this.$t('collectionCenter.senderZipCode'), val: 'senderZipcode', isShow: false }, // 发件人邮编
        { index: 31, selVal: this.$t('collectionCenter.Recipientzc'), val: 'receiveZipcode', isShow: false }, // 收件人邮编
        { index: 32, selVal: this.$t('collectionCenter.SendAddress'), val: 'senderAddress', isShow: false }, // 发件地址
        { index: 33, selVal: this.$t('collectionCenter.receiptArs'), val: 'receiveAddress', isShow: false }, // 收件地址
        { index: 34, selVal: this.$t('collectionCenter.recipientS'), val: 'receiveName', isShow: false }, // 收件人
        { index: 35, selVal: this.$t('collectionCenter.addresser'), val: 'senderName', isShow: false }, // 发件人
        { index: 36, selVal: this.$t('collectionCenter.invoiceT'), val: 'invoiceType', isShow: false }, // 发票类型
        { index: 37, selVal: this.$t('collectionCenter.RecipientProvince'), val: 'receiveState', isShow: false }, // 收件人省份
        { index: 38, selVal: this.$t('collectionCenter.parcelNum'), val: 'packageNumber', isShow: false }, // 包裹号
        { index: 39, selVal: this.$t('collectionCenter.closeTime'), val: 'closeTime', isShow: false }, // 闭环时间

        // 需求：揽收数据处理界面调整
        { index: 40, selVal: this.$t('collectionCenter.networkLoader'), val: 'siteLoadedUserName', isShow: false }, // 网点装车人
        { index: 41, selVal: this.$t('collectionCenter.networkLoadScanTime'), val: 'siteLoadedScanTime', isShow: false }, // 网点装车扫描时间
        { index: 42, selVal: this.$t('collectionCenter.networkLoadVehicleNum'), val: 'siteLoadedVehicleNumber', isShow: false }, // 网点装车车牌号
        { index: 43, selVal: this.$t('collectionCenter.networkLoadUploadingTime'), val: 'siteLoadedUploadTime', isShow: false }, // 网点装车上传时间
        { index: 44, selVal: this.$t('collectionCenter.transferCenterLoader'), val: 'transferLoadedUserName', isShow: false }, // 转运中心装车人
        { index: 45, selVal: this.$t('collectionCenter.transferLoadScanTime'), val: 'transferLoadedScanTime', isShow: false }, // 转运中心装车扫描时间
        { index: 46, selVal: this.$t('collectionCenter.transferLoadVehicleNum'), val: 'transferLoadedVehicleNumber', isShow: false }, // 转运中心装车车牌号
        { index: 47, selVal: this.$t('collectionCenter.transferLoadUploadingTime'), val: 'transferLoadedUploadTime', isShow: false }, // 转运中心装车上传时间
        { index: 48, selVal: this.$t('collectionCenter.outletUnloader'), val: 'siteUnloadUserName', isShow: false }, // 网点卸车人
        { index: 49, selVal: this.$t('collectionCenter.outletUnloadingTime'), val: 'siteUnloadTime', isShow: false }, // 网点卸车时间
        { index: 50, selVal: this.$t('collectionCenter.unloadingTransferCenterP'), val: 'transferUnloadUserName', isShow: false }, // 转运中心卸车人
        { index: 51, selVal: this.$t('collectionCenter.unloadingTransferCenterTime'), val: 'transferUnloadTime', isShow: false }, // 转运中心卸车时间

        { index: 52, selVal: this.$t('orderCenterCont.SenderProvince'), val: 'senderState', isShow: false }, // 发件人省份
        { index: 53, selVal: this.$t('collectionCenter.lanshouImg'), val: 'finishSignUrl', isShow: false }, // 揽收图片
        { index: 54, selVal: this.$t('collectionCenter.signImage'), val: 'signerImg', isShow: false }, // 签收图片
        { index: 55, selVal: this.$t('collectionCenter.customerOrderNum'), val: 'customerNumber', isShow: false }, // 客户订单号
        { index: 56, selVal: this.$t('collectionCenter.receiveBatchNumber'), val: 'batchNo', isShow: false }, // 揽收批次号
        { index: 57, selVal: this.$t('collectionCenter.networkLoadingSignaturePicture'), val: 'wmsSiteLoadSignImg', isShow: false }, // 网点装车签名图片
        { index: 58, selVal: this.$t('collectionCenter.transferCenterLoadingSignaturePicture'), val: 'transferCenterLoadSignImg', isShow: false }, // 转运中心装车签名图片
        { index: 59, selVal: this.$t('collectionCenter.BagNumber'), val: 'bagNumber', isShow: false }, // 袋号
        { index: 60, selVal: this.$t('collectionCenter.Barcode'), val: 'scanNumber', isShow: false } // 条形码
      ], // 改变位置需同步表格里的下标
      defaultSelection: ['orderNumber', 'collectStatus', 'createTime', 'scanTime', 'uploadTime', 'signForScanTime', 'signForUploadTime', 'trackCreateTime', 'trackPushTime', 'signForTrackCreateTime', 'signForTrackPushTime'], // 默认选项
      selectValList: [], // 选中后点击确定再赋值(defaultSelection)，否则不赋值
      checkboxForm: {}, // 保存栏位权限
      colShowList: {}, // 初始加载——可展示的列
      selectTime: 't0', // 选择时间
      timeList: [
        { value: 't0', time: this.$t('collectionCenter.orderCreateT') }, // '订单创建时间'
        { value: 't1', time: this.$t('collectionCenter.collectScanTime') }, // '揽收扫描时间'
        { value: 't2', time: this.$t('collectionCenter.ActualCollectionTime') }, // '实际揽收时间'
        { value: 't3', time: this.$t('collectionCenter.actualSigningTime') } // '实际签收时间'
      ],
      orderTypeList: [
        { cStatus: 0, cStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { cStatus: 1, cStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { cStatus: 2, cStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        // { cStatus: 3, cStatusName: this.$t('collectionCenter.LanShouing') }, // 揽收中
        { cStatus: 4, cStatusName: this.$t('collectionCenter.scheduled') }, // 已调度
        { cStatus: 5, cStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { cStatus: 6, cStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { cStatus: 7, cStatusName: this.$t('collectionCenter.ProblemPiece') }, // 问题件
        { cStatus: 8, cStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { cStatus: 9, cStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { cStatus: 10, cStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { cStatus: 11, cStatusName: this.$t('collectionCenter.Bagged') }, // 已装袋
        { cStatus: 12, cStatusName: this.$t('collectionCenter.closedLoop') } // 已闭环
      ],
      invoiceTypeList: [
        { val: 'Cte', name: 'Cte' },
        { val: 'NFS', name: 'NFS' },
        { val: 'NFSe', name: 'NFSe' }
      ],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          collectStatus: null,
          invoiceType: '', // 发票类型
          orderNumber: null,
          packageNumber: null,
          customerNumber: null,
          batchNo: null,
          siteIdList: [],
          startCreateTime: '', // 订单创建时间
          endCreateTime: '', // 订单创建时间
          startScanTime: '', // 揽收扫描时间
          endScanTime: '', // 揽收扫描时间
          startUploadTime: '', // 实际揽收时间
          endUploadTime: '', // 实际揽收时间
          startSignForUploadTime: '', // 实际签收时间
          endSignForUploadTime: '', // 实际签收时间
          importBool: false, // 触发查询按钮后传false, 其余情况传true
          customerId: '',
          senderState: '',
          receiveState: '',
          senderCity: '',
          receiveCity: '',
          vehicleNumber: '',
          bagNumber: '',
          scanNumber: ''
        }
      },
      time: {
        startTime: '',
        endTime: ''
      },
      total: 0,
      tableDate: [{}],
      loading: false,
      dialogTitle: this.$t('collectionCenter.queryField'), // '自定义查询展示栏位'
      dialogVisible: false
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        this.queryForm.params.startCreateTime = '';
        this.queryForm.params.endCreateTime = '';
        this.queryForm.params.startScanTime = '';
        this.queryForm.params.endScanTime = '';
        this.queryForm.params.startUploadTime = '';
        this.queryForm.params.endUploadTime = '';
        this.queryForm.params.startSignForUploadTime = '';
        this.queryForm.params.endSignForUploadTime = '';
      }
    },
    'queryForm.params.orderNumber'(val) {
      if (val) {
        const bgh2 = val.split('\n');
        let resetOrderNumber = [];
        if (bgh2.length >= 10000) {
          resetOrderNumber = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.orderNumber = resetOrderNumber;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    },
    'queryForm.params.packageNumber'(val) {
      if (val) {
        const bgh2 = val.split('\n');
        let resetOrderNumber = [];
        if (bgh2.length >= 10000) {
          resetOrderNumber = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.packageNumber = resetOrderNumber;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    },
    'queryForm.params.customerNumber'(val) {
      if (val) {
        const bgh2 = val.split('\n');
        let resetOrderNumber = [];
        if (bgh2.length >= 10000) {
          resetOrderNumber = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.customerNumber = resetOrderNumber;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    },
    'queryForm.params.batchNo'(val) {
      if (val) {
        const bgh2 = val.split('\n');
        let resetOrderNumber = [];
        if (bgh2.length >= 10000) {
          resetOrderNumber = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.batchNo = resetOrderNumber;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    },
    'queryForm.params.vehicleNumber'(val) {
      if (val) {
        const bgh2 = val.split('\n');
        let number = [];
        if (bgh2.length >= 10000) {
          number = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.vehicleNumber = number;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    },
    'queryForm.params.senderState'(val) {
      if (val) {
        const bgh = val.split('\n');
        let stateVal = [];
        if (bgh.length >= 100) {
          stateVal = bgh.slice(0, 100).join('\n');
          this.queryForm.params.senderState = stateVal;
        }
        if (bgh.length > 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
      }
    },
    'queryForm.params.receiveState'(val) {
      if (val) {
        const bgh = val.split('\n');
        let stateVal = [];
        if (bgh.length >= 100) {
          stateVal = bgh.slice(0, 100).join('\n');
          this.queryForm.params.receiveState = stateVal;
        }
        if (bgh.length > 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
      }
    },
    'queryForm.params.senderCity'(val) {
      if (val) {
        const bgh = val.split('\n');
        let stateVal = [];
        if (bgh.length >= 100) {
          stateVal = bgh.slice(0, 100).join('\n');
          this.queryForm.params.senderCity = stateVal;
        }
        if (bgh.length > 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
      }
    },
    'queryForm.params.receiveCity'(val) {
      if (val) {
        const bgh = val.split('\n');
        let cityVal = [];
        if (bgh.length >= 100) {
          cityVal = bgh.slice(0, 100).join('\n');
          this.queryForm.params.receiveCity = cityVal;
        }
        if (bgh.length > 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
      }
    },
    'queryForm.params.bagNumber'(val) {
      console.log(val, '===bagNumber');
      if (val) {
        const bgh2 = val.split('\n');
        let number = [];
        if (bgh2.length >= 10000) {
          number = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.bagNumber = number;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    },
    'queryForm.params.scanNumber'(val) {
      console.log(val, '==scanNumber');
      if (val) {
        const bgh2 = val.split('\n');
        let number = [];
        if (bgh2.length >= 10000) {
          number = bgh2.slice(0, 10000).join('\n');
          this.queryForm.params.scanNumber = number;
          this.notEnter = false;
        } else {
          this.notEnter = true;
        }
        if (bgh2.length > 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
      }
    }
  },
  created() {
    this.default();
    localStorage.setItem('rdpQueryForm', JSON.stringify(this.queryForm));
    this.queryCol(); // 调用分页
    this.focusCustomer();
    this.getBranchList();
    this.isClickAll = false;
  },
  methods: {
    getBranchList() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteIdL = [];
          res.data.forEach(data => {
            this.siteIdL.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    changeState() {
      this.queryForm.params.senderState = '';
      this.queryForm.params.receiveState = '';
    },
    BlockNewlineSenderState() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.senderState.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineReceiveState() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.receiveState.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    changeCity() {
      this.queryForm.params.senderCity = '';
      this.queryForm.params.receiveCity = '';
    },
    BlockNewlineSenderCity() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.senderCity.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineReceiveCity() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.receiveCity.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },

    changeCode() {
      this.queryForm.params.orderNumber = null;
      this.queryForm.params.packageNumber = null;
      this.queryForm.params.customerNumber = null;
      this.queryForm.params.batchNo = null;
      this.queryForm.params.vehicleNumber = null;
      this.queryForm.params.bagNumber = null;
      this.queryForm.params.scanNumber = null;
    },
    ft1() {
      const val = this.queryForm.params.orderNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.orderNumber = bghTrim.join('\n');
    },
    BlockNewlineOrder() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft1();
      const packageNumberLen = this.queryForm.params.orderNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft2() {
      const val = this.queryForm.params.packageNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.packageNumber = bghTrim.join('\n');
    },
    BlockNewlinePackage() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.packageNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft3() {
      const val = this.queryForm.params.customerNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.customerNumber = bghTrim.join('\n');
    },
    BlockNewlineCNumber() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.customerNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft4() {
      const val = this.queryForm.params.batchNo.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.batchNo = bghTrim.join('\n');
    },
    BlockNewlineBatchNo() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.batchNo.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft5() {
      const val = this.queryForm.params.vehicleNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.vehicleNumber = bghTrim.join('\n');
    },
    BlockNewline5() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.vehicleNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft6() {
      const val = this.queryForm.params.bagNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.bagNumber = bghTrim.join('\n');
    },
    ft7() {
      const val = this.queryForm.params.scanNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.scanNumber = bghTrim.join('\n');
    },
    BlockNewline6() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.bagNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewline7() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.scanNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    focusCustomer() {
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.startCreateTime = '';
      this.queryForm.params.endCreateTime = '';
      this.queryForm.params.startScanTime = '';
      this.queryForm.params.endScanTime = '';
      this.queryForm.params.startUploadTime = '';
      this.queryForm.params.endUploadTime = '';
      this.queryForm.params.startSignForUploadTime = '';
      this.queryForm.params.endSignForUploadTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.startCreateTime = val[0];
        this.queryForm.params.endCreateTime = val[1];
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.startScanTime = val[0];
        this.queryForm.params.endScanTime = val[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.startUploadTime = val[0];
        this.queryForm.params.endUploadTime = val[1];
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.startSignForUploadTime = val[0];
        this.queryForm.params.endSignForUploadTime = val[1];
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // '只允许查1个月内的数据'
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
        }
      }
    },
    changeTime() { // 切换所选时间——清空未选时间
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.startCreateTime = '';
      this.queryForm.params.endCreateTime = '';
      this.queryForm.params.startScanTime = '';
      this.queryForm.params.endScanTime = '';
      this.queryForm.params.startUploadTime = '';
      this.queryForm.params.endUploadTime = '';
      this.queryForm.params.startSignForUploadTime = '';
      this.queryForm.params.endSignForUploadTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.startCreateTime = this.timeValue[0];
        this.queryForm.params.endCreateTime = this.timeValue[1];
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.startScanTime = this.timeValue[0];
        this.queryForm.params.endScanTime = this.timeValue[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.startUploadTime = this.timeValue[0];
        this.queryForm.params.endUploadTime = this.timeValue[1];
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.startSignForUploadTime = this.timeValue[0];
        this.queryForm.params.endSignForUploadTime = this.timeValue[1];
      }
    },
    default() { // 默认
      this.selectCity = 'c1';
      this.selectState = 's1';
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.selectTime = 't0';
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        collectStatus: null,
        invoiceType: '', // 发票类型
        orderNumber: null,
        packageNumber: null,
        customerNumber: null,
        batchNo: null,
        siteIdList: [],
        startCreateTime: this.timeValue[0], // 订单创建时间
        endCreateTime: this.timeValue[1], // 订单创建时间
        startScanTime: '', // 揽收扫描时间
        endScanTime: '', // 揽收扫描时间
        startUploadTime: '', // 实际揽收时间
        endUploadTime: '', // 实际揽收时间
        startSignForUploadTime: '', // 实际签收时间
        endSignForUploadTime: '', // 实际签收时间
        importBool: false,
        customerId: '',
        senderState: '',
        receiveState: '',
        senderCity: '',
        receiveCity: '',
        vehicleNumber: '',
        bagNumber: '',
        scanNumber: ''
      };
    },
    resetClick() { // 重置
      this.selectNumber = 'n1';
      this.default();
    },
    getDate() {
      this.loading = true;
      if (this.queryForm.params.collectStatus && this.queryForm.params.collectStatus.length <= 0) {
        this.queryForm.params.collectStatus = null;
      }
      const queryForm = JSON.parse(localStorage.getItem('rdpQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.importBool = this.queryForm.params.importBool;
      const oneselfOrAll = this.title === '' ? 'gud' : this.title === 'part' ? 'zid' : this.oneselfOrAll;
      this.oneselfOrAll = oneselfOrAll;
      if (this.title === '' || this.oneselfOrAll === 'gud') {
        // 固定查询
        queryOrderErrorAllPage(queryForm).then(res => {
          if (res.status === 'OK') {
            this.tableDate = res.data.records;
            this.total = res.data.total;
            this.loading = false;
          }
        }).catch(() => {
          this.loading = false;
          this.tableDate = [];
          this.total = 0;
        });
      }
      if (this.title === 'part' || this.oneselfOrAll === 'zid') {
        // 自定义查询
        queryOrderErrorPage(queryForm).then(res => {
          if (res.status === 'OK') {
            this.tableDate = res.data.records;
            this.total = res.data.total;
            this.loading = false;
          }
        }).catch(() => {
          this.loading = false;
          this.tableDate = [];
          this.total = 0;
        });
      }
    },
    isNumber() {
      const reg = /^\s*$/;
      const on = this.queryForm.params.orderNumber;
      const pn = this.queryForm.params.packageNumber;
      const cn = this.queryForm.params.customerNumber;
      const bn = this.queryForm.params.batchNo;
      const vn = this.queryForm.params.vehicleNumber;
      const dn = this.queryForm.params.bagNumber;
      const sn = this.queryForm.params.scanNumber;
      if (on && reg.test(on) && this.selectNumber === 'n1') {
        this.queryForm.params.orderNumber = null;
      } else if (pn && reg.test(pn) && this.selectNumber === 'n2') {
        this.queryForm.params.packageNumber = null;
      } else if (cn && reg.test(cn) && this.selectNumber === 'n3') {
        this.queryForm.params.customerNumber = null;
      } else if (bn && reg.test(bn) && this.selectNumber === 'n4') {
        this.queryForm.params.batchNo = null;
      } else if (vn && reg.test(vn) && this.selectNumber === 'n5') {
        this.queryForm.params.vehicleNumber = null;
      } else if (dn && reg.test(dn) && this.selectNumber === 'n6') {
        this.queryForm.params.bagNumber = null;
      } else if (sn && reg.test(sn) && this.selectNumber === 'n7') {
        this.queryForm.params.scanNumber = null;
      } else if (on || pn || cn || bn || vn || dn || sn) {
        this.timeValue = [];
        this.queryForm.params.startCreateTime = ''; // 订单创建时间
        this.queryForm.params.endCreateTime = ''; // 订单创建时间
        this.queryForm.params.startScanTime = ''; // 揽收扫描时间
        this.queryForm.params.endScanTime = ''; // 揽收扫描时间
        this.queryForm.params.startUploadTime = ''; // 实际揽收时间
        this.queryForm.params.endUploadTime = ''; // 实际揽收时间
        this.queryForm.params.startSignForUploadTime = ''; // 实际签收时间
        this.queryForm.params.endSignForUploadTime = ''; // 实际签收时间
      } else {
        // console.log('//////////////////////////');
      }
    },
    searchClick(title) { // 查询
      if (this.timeValue &&
        this.timeValue.length <= 0 &&
        !this.queryForm.params.orderNumber &&
        !this.queryForm.params.packageNumber &&
        !this.queryForm.params.customerNumber &&
        !this.queryForm.params.batchNo &&
        !this.queryForm.params.vehicleNumber &&
        !this.queryForm.params.bagNumber &&
        !this.queryForm.params.scanNumber
      ) { // 请选择日期时间
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      if (!title) {
        // 固定查询
        this.oneselfOrAll = 'gud';
        this.title = '';
        this.defaultSelect();
        this.queryForm.pageNumber = 1;
        this.queryForm.params.importBool = false;
        this.isNumber();
        localStorage.setItem('rdpQueryForm', JSON.stringify(this.queryForm));
        this.getDate();
      }
      if (title === 'size') {
        // 切换分页
        this.title = 'size';
        this.queryForm.pageNumber = 1;
        localStorage.setItem('rdpQueryForm', JSON.stringify(this.queryForm));
        this.getDate();
      }
      if (title === 'part') {
        this.queryForm.params.importBool = false;
        // 'part' 部分查询
        this.oneselfOrAll = 'zid';
        this.title = 'part';
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.partSelect();
        });
      }
    },
    defaultSelect() {
      this.defaultSelection = ['orderNumber', 'collectStatus', 'createTime', 'scanTime', 'uploadTime', 'signForScanTime', 'signForUploadTime', 'trackCreateTime', 'trackPushTime', 'signForTrackCreateTime', 'signForTrackPushTime'];
      const arr = this.defaultSelection;
      this.allSelectList.filter(i => {
        if (arr.indexOf(i.val) !== -1) {
          i.isShow = true;
          this.$nextTick(() => {
            this.$refs.tableList.doLayout();
          });
        } else {
          i.isShow = false;
          this.$nextTick(() => {
            this.$refs.tableList.doLayout();
          });
        }
      });
      this.transitionField(); // 保存栏位权限
    },
    allSelect(val) {
      this.onlyOneSave += 1;
      const defaultSelection = [];
      if (this.onlyOneSave === 1) {
        this.selectValList = this.defaultSelection;
      }
      this.isShow = !this.isShow;
      this.allSelectList.forEach((item) => {
        item.isShow = this.isShow;
        if (item.isShow) {
          defaultSelection.push(item.val);
        }
      });
      this.defaultSelection = defaultSelection;
      this.checkAll = this.defaultSelection.length === this.allSelectList.length;
      this.isIndeterminate = this.defaultSelection.length < this.allSelectList.length && this.defaultSelection.length > 0;
    },
    partSelect(val) {
      this.onlyOneSave += 1;
      if (this.onlyOneSave === 1) {
        this.selectValList = this.defaultSelection;
      }
      this.checkAll = this.defaultSelection.length === this.allSelectList.length;
      this.isIndeterminate = this.defaultSelection.length < this.allSelectList.length && this.defaultSelection.length > 0;
    },
    // 转换勾选栏位
    transitionField() {
      if (!this.checkboxForm.userId) {
        this.checkboxForm.userId = this.$store.state.user.id;
      }
      const checkboxList = {};
      this.allSelectList.forEach(item => {
        checkboxList[item.val] = item.isShow;
      });
      this.checkboxForm.authority = JSON.stringify(checkboxList);
      userExtendSaveData(this.checkboxForm).then(res => {
        // console.log(res, 'res', JSON.parse(res.data.authority));
      });
    },
    dialogCancel() {
      this.defaultSelection = this.selectValList;
      const arr = this.selectValList; // 选中
      this.dialogBoth(arr);
    },
    dialogConfirm() {
      const arr = this.defaultSelection; // 选中
      this.dialogBoth(arr);
      this.transitionField(); // 保存栏位权限
      if (this.defaultSelection.length <= 0) {
        this.tableDate = [{}];
        this.total = 0;
      } else {
        this.isNumber();
        this.queryForm.pageNumber = 1;
        localStorage.setItem('rdpQueryForm', JSON.stringify(this.queryForm));
        this.getDate();
      }
    },
    dialogBoth(arr) {
      this.onlyOneSave = 0;
      this.dialogVisible = false;
      this.allSelectList.filter(i => {
        if (arr.indexOf(i.val) !== -1) {
          i.isShow = true;
          this.$nextTick(() => {
            this.$refs.tableList.doLayout();
          });
        } else {
          i.isShow = false;
          this.$nextTick(() => {
            this.$refs.tableList.doLayout();
          });
        }
      });
    },
    closeSelectDialog() {
      // 关闭弹框时触发
      this.isIndeterminate = false;
      this.checkAll = false;
      this.dialogCancel();
    },
    // 默认展示列
    queryCol() {
      // 获取可展示列
      const useridForm = {
        userId: this.$store.state.user.id
      };
      userExtendQueryExtend(useridForm).then(res => {
        if (res.status === 'OK') {
          this.colShowList = JSON.parse(res.data.authority);
          // console.log('要展示的列', this.colShowList);
          if (!this.colShowList) return;
          this.analysis(this.colShowList);
          // this.searchClick('');
          this.getDate();
        }
      });
    },
    // 解析需展示栏位
    analysis(col) {
      const colList = [];
      for (const key in col) {
        // console.log('区分', key, col[key]); // ------属性 key   属性值 Object[key]
        colList.push({
          val: key,
          isShow: col[key]
        });
      }
      const defaultSelection = [];
      for (let i = 0; i < colList.length; i++) {
        for (let j = 0; j < this.allSelectList.length; j++) {
          if (colList[i].val === this.allSelectList[j].val) {
            this.allSelectList[j].isShow = colList[i].isShow;
            if (colList[i].isShow) {
              defaultSelection.push(colList[i].val);
            }
          }
        }
      }
      this.defaultSelection = defaultSelection;
      const db = ['orderNumber', 'collectStatus', 'createTime', 'scanTime', 'uploadTime', 'signForScanTime', 'signForUploadTime', 'trackCreateTime', 'trackPushTime', 'signForTrackCreateTime', 'signForTrackPushTime'];
      const ss = [];
      for (let i = 0; i < db.length; i++) {
        for (let j = 0; j < this.defaultSelection.length; j++) {
          if (db[i] === this.defaultSelection[j]) {
            ss.push(this.defaultSelection[j]);
          }
        }
      }
      if (db.toString() === ss.toString() && db.length === this.defaultSelection.length) {
        console.log('固定栏位');
        this.title = '';
      } else {
        console.log('自定义栏位');
        this.title = 'part';
      }
      this.$nextTick(() => {
        this.$refs.tableList.doLayout();
      });
    },
    beforeUpload(file) {
      this.fileList = [];
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      // const whiteList = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
      const whiteList = ['xls', 'xlsx'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件只能是xlsx格式
        this.$message.error(this.$t('collectionCenter.uploadOnlyXlsx'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // 上传文件大小不能超过 2MB
        this.$message.error(this.$t('collectionCenter.uploadNot2MB'));
        return false;
      }
    },
    downloadClick() {
      // const link = document.createElement('a');
      // link.style.display = 'none';
      // 特殊情况：无法拿到存储语言时，判断是否是中文环境
      const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
      const language = sessionStorage.getItem('language') || navigatorLanguage;
      const date = new Date().getTime();
      let href = '';
      let saveName = '';
      if (language === 'zh') {
        // link.href = this.receiveDataProcessingZH;
        // link.setAttribute('download', '揽收数据处理导入模板' + date + '.xlsx');
        href = this.receiveDataProcessingZH;
        saveName = '揽收数据处理导入模板' + date + '.xlsx';
      } else {
        // link.href = this.receiveDataProcessingPU;
        // link.setAttribute('download', 'Modelo de importação de processamento de dados de coleta.xlsx');
        href = this.receiveDataProcessingPU;
        saveName = 'Modelo de importação de processamento de dados de coleta.xlsx';
      }
      // document.body.appendChild(link);
      // link.click();
      // link.parentNode.removeChild(link);
      saveAs(href, saveName);
    },
    importt(option) { // 导入xlsx模版 // 上传数据
      const files = option.file;
      const upLoadFile = new FormData();
      upLoadFile.append('file', files);
      if (files) {
        importData(upLoadFile).then(response => {
          if (response.status === 'OK') {
            this.queryForm.params.importBool = true;
            this.$message.success({
              message: response.msg,
              offset: 200,
              type: 'success'
            });
            this.loading = true;
            setTimeout(() => {
              const queryForm = JSON.parse(localStorage.getItem('rdpQueryForm')) || this.queryForm;
              this.queryForm.pageNumber = 1;
              queryForm.pageNumber = 1;
              queryForm.pageSize = this.queryForm.pageSize;
              queryForm.params.importBool = true;
              if (this.title === '' || this.oneselfOrAll === 'gud') {
                // 固定查询
                queryOrderErrorAllPage(queryForm).then(res => {
                  if (res.status === 'OK') {
                    this.tableDate = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                  }
                });
              }
              if (this.title === 'part' || this.oneselfOrAll === 'zid') {
                // 自定义查询
                queryOrderErrorPage(queryForm).then(res => {
                  if (res.status === 'OK') {
                    this.tableDate = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                  }
                });
              }
              this.loading = false;
            }, 10000);
          }
        });
      }
    },
    downloadAll() { // 导出
      this.isClickAll = true;
      if (this.tableDate.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.noDataExport')); // 无可导出数据
        return;
      }
      const queryForm = JSON.parse(localStorage.getItem('rdpQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.importBool = this.queryForm.params.importBool;
      if (!this.title || this.oneselfOrAll === 'gud') {
        // 固定项导出
        orderExportAll(queryForm).then(res => {
          if (res.status === 'OK') {
            this.isClickAll = false;
            const h = this.$createElement;
            this.$msgbox({
              // '提示'
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                h('span', this.$t('collectionCenter.exportChangeTip')),
                // '请勿重复导出！'
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // '立即前往'
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // '关闭'
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              }
            }).catch(() => {
            });
          }
        }).catch(err => {
          this.isClickAll = false;
          console.log(err);
        });
      }
      if (this.title === 'part' || this.oneselfOrAll === 'zid') {
        // 自定义导出
        sectionQueryExport(queryForm).then(res => {
          if (res.status === 'OK') {
            this.isClickAll = false;
            const h = this.$createElement;
            this.$msgbox({
              // '提示'
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                h('span', this.$t('collectionCenter.exportChangeTip')),
                // '请勿重复导出！'
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // '立即前往'
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // '关闭'
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              }
            }).catch(() => {
            });
          }
        }).catch(err => {
          console.log(err);
          this.isClickAll = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.paramsStyle{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.stateClass ::v-deep .el-form-item__content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableClass ::v-deep .el-table{
  width: 99.9%;
}
.selectClass ::v-deep .el-date-editor .el-range-separator{
  width: 28px;
}
.uploadClass{
  margin-left: 10px;
}
.uploadFileClass{
  text-align: center;
}
.popoverClass{
  margin-right: 10px;
}
.autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 30px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep input {
  position: absolute;
}
.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lanShouOrdersBox ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.timeSelectClass, .widthClass{
  width: auto;
}
.timeSelectClass ::v-deep .el-form-item__content,
.widthClass ::v-deep .el-form-item__content,
.widthClass ::v-deep .el-select,
.widthClass ::v-deep .el-textarea textarea{
  width: 100% !important;
}
.flexClass{
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.flexClass ::v-deep .el-form-item__label{
  line-height: 39px;
}
.flexClass ::v-deep .el-form-item__content{
  line-height: 34px;
}
.lanShouOrdersBox ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 150px !important;
  min-width: 150px !important;
  max-width: 100% !important;
}
.lanShouOrdersBox ::v-deep textarea{
  resize: both;
}
.col2 {
  width: auto !important;
}
.lanShouOrdersBox{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dialogBox{
  padding: 10px;
  box-sizing: border-box;
}
.dialogBox ::v-deep .el-checkbox__label{
  padding-right: 10px;
  white-space: normal;
  font-size: 15px;
  line-height: 16px;
}
.dialogBox ::v-deep .el-checkbox-group{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.dialogBox ::v-deep .el-checkbox{
  padding-right: 10px;
  padding-bottom: 20px;
  margin: 0 !important;
  width: 25%;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  align-items: self-start;
}
.lanShouOrdersBox ::v-deep .el-date-editor{
  width: 100%;
}
.lanShouOrdersBox ::v-deep .el-dialog__wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.lanShouOrdersBox ::v-deep .el-dialog{
  margin-top: 0 !important;
}
/*.lanShouOrdersBox ::v-deep .el-table{
  font-size: 10px !important;
} */
.lanShouOrdersBox .selectTimeClass{
  margin-left: 2px;
}
.lanShouOrdersBox .delMl ::v-deep .el-form-item__content{
  margin-left: 0 !important;
}

.delMl ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
  width: 100%;
}

.lanShouOrdersBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
  border: none !important;
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  text-align: right;
  padding-left: 0;
  box-sizing: border-box;
}
</style>

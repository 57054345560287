<template>
  <div class="SMSBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row class="SMSTime">
          <el-col :span="10" style="width: auto;">
            <!-- 揽收时间： -->
            <el-form-item :label="$t('collectionCenter.createTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestCustomer" @change="changeCustomer">
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 商户名称： -->
            <el-form-item :label="$t('collectionCenter.MerchantN')">
              <el-select
                v-model="merchantCodeValue"
                filterable
                clearable
                remote
                reserve-keyword
                :placeholder="$t('collectionCenter.pleaseInput')"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="changeValue"
              >
                <el-option
                  v-for="(item, index) in merchantCodeList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 短信类型： -->
            <el-form-item :label="$t('collectionCenter.SMSType')">
              <el-select v-model="queryForm.params.messageType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                <el-option
                  v-for="(item, index) in SMSTypeList"
                  :key="index"
                  :label="item.msg"
                  :value="item.messageType"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 短信状态： -->
            <el-form-item :label="$t('collectionCenter.SMSStatus')">
              <el-select v-model="queryForm.params.sendFlng" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                <el-option
                  v-for="(item, index) in SMSStatusList"
                  :key="index"
                  :label="item.msg"
                  :value="item.sendFlng"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 所属平台： -->
            <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
              <el-select v-model="queryForm.params.platform" clearable :placeholder="$t('collectionCenter.pleaseChoose')">
                <!-- <el-option :label="$t('collectionCenter.collect')" :value="0" />&lt;!&ndash; 揽收 &ndash;&gt;-->
                <!-- <el-option :label="$t('collectionCenter.expressDelivery')" :value="1" />&lt;!&ndash; 快递 &ndash;&gt;-->
                <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
                <el-option label="shein" :value="1" />
                <el-option label="kwai" :value="2" />
                <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:report:SMS:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:SMS:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:SMS:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="exportPage.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:SMS:BatchPush'"
            size="mini"
            :class="batchPushList.length <=0 && isClick1 ? 'onlyClickOnce10' : ''"
            @click="batchPush"
          >
            {{ $t('collectionCenter.BatchPush') }}
          </el-button><!-- 批量补推 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="smsRef"
        :max-height="600"
        border
        :data="tableDate"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerId"
          :label="$t('collectionCenter.clientID')"
        /><!-- 客户编号 -->
        <el-table-column
          prop="customerName"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          prop="merchantCode"
          :label="$t('collectionCenter.MerchantNum')"
        /><!-- 商户号 -->
        <el-table-column
          prop="merchantName"
          :label="$t('collectionCenter.MerchantName')"
        /><!-- 商户名称 -->
        <el-table-column
          prop="receiveUser"
          :label="$t('collectionCenter.recipientS')"
        /><!-- 收件人 -->
        <el-table-column
          prop="platform"
          :label="$t('collectionCenter.platform')"
        ><!-- 所属平台 -->
          <!-- 0:其他 1:shein 2:kwai 3:快递 -->
          <template slot-scope="scope">
            <div>
              {{
                scope.row.platform == 0 ? $t('collectionCenter.other') :
                scope.row.platform == 1 ? 'shein' :
                scope.row.platform == 2 ? 'kwai' :
                scope.row.platform == 3 ? $t('collectionCenter.expressDelivery') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="senderPhone"
          :label="$t('collectionCenter.ReceivingCall')"
        ><!-- 接收电话 -->
          <template slot-scope="scope">
            <div v-show="scope.row.senderPhone" style="padding-right: 10px;box-sizing: border-box;">
              <span v-if="phoneShowIndex == ((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1) && showPhone.isShow">
                {{ showPhone.val }}
              </span>
              <span v-else>{{ scope.row.senderPhone }}</span>
              <div class="el-icon-view isShowSenderPhone" @click="findSenderPhone(scope.row, (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="messageType"
          :label="$t('collectionCenter.messageType')"
        ><!-- 短信类型 -->
          <template slot-scope="scope">
            <!-- 0 揽收  /  1 派件  /  2 签收  /  3 取件(智能柜取件) -->
            <div>
              {{
                scope.row.messageType == 0 ? $t('collectionCenter.lanshou') :
                scope.row.messageType == 1 ? $t('collectionCenter.delivery') :
                scope.row.messageType == 2 ? $t('collectionCenter.signFor') :
                scope.row.messageType == 3 ? $t('collectionCenter.cabinetPickup') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="messageSource"
          :label="$t('collectionCenter.SMSSource')"
        ><!-- 短信来源 -->
          <template slot-scope="scope">
            <!--  短信来源,0:app,1:pc,2:其他,3:智能柜 -->
            <div>
              {{
                scope.row.messageSource == 0 ? 'app' :
                scope.row.messageSource == 1 ? 'pc' :
                scope.row.messageSource == 3 ? $t('collectionCenter.smartCabinet') :
                scope.row.messageSource == 2 ? $t('collectionCenter.other') : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="messageContent"
          :label="$t('collectionCenter.messageContent')"
          :show-overflow-tooltip="true"
        /><!-- 短信内容 -->
        <el-table-column
          prop="packageCount"
          :label="$t('collectionCenter.NumberPackages')"
        /><!-- 包裹数量 -->
        <el-table-column
          prop="createTime"
          :label="$t('collectionCenter.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          prop="sendTime"
          :label="$t('collectionCenter.SendingTime')"
        /><!-- 发送时间 -->
        <el-table-column
          prop="sendError"
          :label="$t('collectionCenter.SendErrorMessage')"
          :show-overflow-tooltip="false"
        ><!-- 发送错误信息 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <pre slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.sendError }}</pre>
              <div class="paramsStyle">{{ scope.row.sendError }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="sendFlng"
          :label="$t('collectionCenter.messageStatus')"
        ><!-- 短信状态 -->
          <template slot-scope="scope">
            <!-- 0 '未发送'   1 '发送中'    2 '已发送'   '发送失败' -->
            <div>{{ scope.row.sendFlng == 0 ? $t('collectionCenter.NotSent') : scope.row.sendFlng == 1 ? $t('collectionCenter.InTransit') : scope.row.sendFlng == 2 ? $t('collectionCenter.sent') : $t('collectionCenter.SendFailure') }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="editSum"
          :label="$t('collectionCenter.operation')"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="sendClick(scope.row)">{{ $t('collectionCenter.ModifyTotalParcelsReceived') }}</el-button><!-- 修改揽收包裹总数 -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getSMSPage"
        @size-change="getPageSize"
      />
    </div>

    <div v-if="dialogVisible">
      <el-dialog
        :title="$t('collectionCenter.tips')"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div>
          <!-- 揽收包裹数为： -->
          <p>{{ $t('collectionCenter.packagesReceivedNumber') }}</p>
          <el-input v-model.number="packageNumber" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
          <span v-show="isNumber" class="enterIntegerClass">{{ $t('collectionCenter.pleaseEnterInteger') }}</span><!-- 请输入整数 -->
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消 -->
          <el-button @click="sendCancel">{{ $t('collectionCenter.cancel') }}</el-button>
          <!-- 确定 -->
          <el-button type="primary" @click="sendOK">{{ $t('collectionCenter.confirm') }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryAllCustomer,
  messageCustomerPage,
  queryAllMerchantCode,
  exportMessageList,
  updatePackageCount,
  sendSms,
  getMessageSenderPhone
} from '@/api/lanshou';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

export default {
  name: 'SMSManagement',
  data() {
    return {
      isClick: false,
      isClick1: false,
      editSum: this.$store.getters.button_permissions.includes('btn:collection-center:report:SMS:editSum'),
      timeValue: [],
      phoneShowIndex: -1,
      showPhone: {
        isShow: false,
        val: ''
      },
      isNumber: false, // 请输入整数
      packageNumber: '', // 发送短信————修改揽收包裹数
      batchPushList: [], // 批量补推
      dialogVisible: false, // 发送短信
      exportPage: [], // 导出数据
      merchantCodeValue: '',
      merchantCodeList: [], // 商户名称列表
      loading: false, // 商户号加载
      customerIdList: [], // 客户名称列表
      SMSTypeList: [ // 短信类型
        { messageType: 0, msg: this.$t('collectionCenter.lanshou') }, // '揽收'
        { messageType: 1, msg: this.$t('collectionCenter.delivery') }, // '派件'
        { messageType: 2, msg: this.$t('collectionCenter.signFor') }, // '签收'
        { messageType: 3, msg: this.$t('collectionCenter.cabinetPickup') } // '取件(智能柜取件)'
      ],
      SMSStatusList: [ // 短信状态
        { sendFlng: 0, msg: this.$t('collectionCenter.NotSent') }, // '未发送'
        { sendFlng: 1, msg: this.$t('collectionCenter.InTransit') }, // '发送中'
        { sendFlng: 2, msg: this.$t('collectionCenter.sent') }, // '已发送'
        { sendFlng: 3, msg: this.$t('collectionCenter.SendFailure') } // '发送失败'
      ],
      tableDate: [],
      total: 0,
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          customerId: '',
          merchantCode: '',
          merchantName: '',
          messageType: '',
          sendFlng: '',
          platform: ''
        }
      },
      sendchangeSumList: {}
    };
  },
  watch: {
    packageNumber() {
      const reg = /^[0-9]*[1-9][0-9]*$/g;
      if (reg.test(this.packageNumber)) {
        this.isNumber = false;
      } else {
        this.isNumber = true;
      }
    }
  },
  created() {
    this.getNewestCustomer();
    this.default();
    this.searchClick();
    this.isClick = false;
    this.isClick1 = false;
  },
  methods: {
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.beginTime = val[0] + ' 00:00:00';
      this.queryForm.params.endTime = val[1] + ' 23:59:59';

      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.beginTime).subtract(-3, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // '只允许查3个月内的数据'
            message: this.$t('collectionCenter.OnlyAllowedThreeMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.beginTime = today + ' 00:00:00';
          this.queryForm.params.endTime = today + ' 23:59:59';
        }
      }
    },
    findSenderPhone(row, index) {
      // tableDate
      getMessageSenderPhone(row.id).then(res => {
        if (res.status === 'OK') {
          this.showPhone = {
            isShow: this.phoneShowIndex === index ? !this.showPhone.isShow : true,
            val: res.data
          };
          this.phoneShowIndex = index;
          // this.tableDate[index].senderPhone = res.data;
        } else {
          this.showPhone = {
            isShow: false,
            val: ''
          };
        }
      });
    },
    remoteMethod(value) {
      const merchantCodeList = [];
      if (this.queryForm.params.customerId) {
        if (value !== '') {
          this.loading = true;
          let merchantForm = {};
          merchantForm = {
            customerId: this.queryForm.params.customerId,
            merchantCode: '',
            merchantName: value
          };
          setTimeout(() => {
            queryAllMerchantCode(merchantForm).then(res => {
              if (res.status === 'OK') {
                res.data.records.forEach(list => {
                  if (list.merchantName) {
                    merchantCodeList.push({
                      name: list.merchantCode + ' / ' + list.merchantName
                    });
                  } else {
                    merchantCodeList.push({
                      name: list.merchantCode
                    });
                  }
                });
              }
            });
            this.merchantCodeList = merchantCodeList;
            this.loading = false;
          }, 200);
        } else {
          this.merchantCodeList = [];
        }
      } else {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
      }
    },
    changeValue(value) {
      value = value.split(' / ');
      this.queryForm.params.merchantCode = value[0];
      this.queryForm.params.merchantName = value[1];
    },
    changeCustomer() {
      this.merchantCodeValue = '';
      this.merchantCodeList = [];
      this.queryForm.params.merchantCode = '';
      this.queryForm.params.merchantName = '';
    },
    default() { // 默认
      this.merchantCodeValue = '';
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        beginTime: today + ' 00:00:00',
        endTime: today + ' 23:59:59',
        customerId: '',
        merchantCode: '',
        merchantName: '',
        messageType: '',
        sendFlng: '',
        platform: ''
      };
    },
    getSMSPage() {
      const queryForm = JSON.parse(localStorage.getItem('smsQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      messageCustomerPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('smsQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      messageCustomerPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    searchClick() {
      if (this.queryForm.params.beginTime && this.queryForm.params.endTime) {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('smsQueryForm', JSON.stringify(this.queryForm));
        this.getSMSPage();
      } else {
        this.$message({
          message: this.$t('collectionCenter.PleaseSelectTime'),
          type: 'warning'
        });
      }
    },
    resetClick() {
      this.default();
    },
    download() { // 导出
      this.isClick = true;
      if (this.exportPage.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.checkExportList'), // '请选择需要导出的数据'
          type: 'warning'
        });
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.clientID'), // '客户编号'
            key: 'customerId',
            width: 15
          },
          {
            header: that.$t('collectionCenter.MerchantNum'), // '商户号'
            key: 'merchantCode',
            width: 15
          },
          {
            header: that.$t('collectionCenter.recipientS'), // '收件人'
            key: 'receiveUser',
            width: 15
          },
          {
            header: that.$t('collectionCenter.platform'), // '所属平台'
            key: 'platform',
            width: 15
          },
          {
            header: that.$t('collectionCenter.ReceivingCall'), // '接收电话'
            key: 'senderPhone',
            width: 15
          },
          {
            header: that.$t('collectionCenter.messageType'), // '短信类型'
            key: 'messageType',
            width: 15
          },
          {
            header: that.$t('collectionCenter.SMSSource'), // '短信来源'
            key: 'messageSource',
            width: 15
          },
          {
            header: that.$t('collectionCenter.messageContent'), // '短信内容'
            key: 'messageContent',
            width: 20
          },
          {
            header: that.$t('collectionCenter.NumberPackages'), // '包裹数量'
            key: 'packageCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.CreationTime'), // '创建时间'
            key: 'createTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.SendingTime'), // '发送时间'
            key: 'sendTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.SendErrorMessage'), // '发送错误信息'
            key: 'sendError',
            width: 20
          },
          {
            header: that.$t('collectionCenter.messageStatus'), // '短信状态'
            key: 'sendFlng',
            width: 15
          }
        ];

        Sheet1_data = [];
        that.exportPage.map((item) => {
          const messageType = item.messageType === 0 ? that.$t('collectionCenter.lanshou') : item.messageType === 1 ? that.$t('collectionCenter.delivery') : item.messageType === 2 ? that.$t('collectionCenter.signFor') : item.messageType === 3 ? that.$t('collectionCenter.cabinetPickup') : '';
          const messageSource = item.messageSource === 0 ? 'app' : item.messageSource === 1 ? 'pc' : item.messageSource === 3 ? that.$t('collectionCenter.smartCabinet') : item.messageSource === 2 ? that.$t('collectionCenter.other') : '';
          const sendFlng = item.sendFlng === 0 ? that.$t('collectionCenter.NotSent') : item.sendFlng === 1 ? that.$t('collectionCenter.InTransit') : item.sendFlng === 2 ? that.$t('collectionCenter.sent') : that.$t('collectionCenter.SendFailure');
          Sheet1_data.push({
            customerId: item.customerId,
            merchantCode: item.merchantCode,
            receiveUser: item.receiveUser,
            platform: item.platform,
            senderPhone: item.senderPhone,
            messageType,
            messageSource,
            messageContent: item.messageContent,
            packageCount: item.packageCount,
            createTime: item.createTime,
            sendTime: item.sendTime,
            sendError: item.sendError,
            sendFlng
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('collectionCenter.SMSManagement') + '.xlsx'; // '短信管理.xlsx'
        saveAs(blob, saveName);
        return;
      }
      ddd();
      this.exportPage = [];
      this.$refs.smsRef.clearSelection();
      this.isClick = false;
    },
    SMSexportList(obj) {
      exportMessageList(obj).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // 导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载,
              h('span', this.$t('collectionCenter.ExportSuccessful')),
              // 请勿重复导出!
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            this.exportPage = [];
            this.$refs.smsRef.clearSelection();
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportList' });
            }
          }).catch(() => {
            this.exportPage = [];
            this.$refs.smsRef.clearSelection();
          });
        } else {
          this.exportPage = [];
        }
      }).catch(err => {
        this.exportPage = [];
        console.log(err);
      });
    },
    // 批量补推
    batchPush() {
      this.isClick1 = true;
      if (this.batchPushList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      } else {
        this.batchPushList.forEach(arr => {
          sendSms(arr).then(res => {
            if (res.status === 'OK') {
              this.isClick1 = false;
              this.getSMSPage();
              this.batchPushList = [];
              this.$refs.smsRef.clearSelection();
            }
          }).catch({});
        });
      }
    },
    sendClick(row) { // 修改揽收包裹总数
      this.sendchangeSumList.id = row.id;
      this.dialogVisible = true;
      this.isNumber = false;
    },
    sendOK() { // 修改揽收包裹总数——确认
      if (this.isNumber === false) {
        this.sendchangeSumList.packageCount = this.packageNumber;
        updatePackageCount(this.sendchangeSumList).then(res => {
          if (res.status === 'OK') {
            this.getSMSPage();
            this.packageNumber = '';
            this.sendchangeSumList = {};
            this.dialogVisible = false;
          }
        }).catch({});
      }
    },
    sendCancel() { // 修改揽收包裹总数——取消
      this.dialogVisible = false;
      this.packageNumber = '';
      this.sendchangeSumList = {};
      this.isNumber = false;
    },
    selectGetDate(selection) {
      this.exportPage = selection;
      const batchPushList = [];
      selection.forEach(list => {
        batchPushList.push({
          senderPhone: list.senderPhone,
          scanTime: list.scanTime,
          merchantName: list.merchantName,
          merchantCode: list.merchantCode,
          driverName: list.driverName,
          packageCount: list.packageCount,
          id: list.id
        });
      });
      this.batchPushList = batchPushList;
    },
    batchSelect(selection, row) {
      this.selectGetDate(selection);
    },
    batchSelectAll(selection) {
      this.selectGetDate(selection);
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .SMSTime ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .SMSTime ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .SMSTime ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .isShowSenderPhone{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .enterIntegerClass{
    color: #F56C6C;
    font-size: 12px;
  }

.paramsStyle{
  width: 100%;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
  .SMSBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .SMSBox .SMSTime .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
</style>

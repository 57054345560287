const home = {
  anJun: {
    zh: '安骏跨境物流系统',
    en: '',
    pu: 'Sistema Logístico Cross Border de Anjun'
  },
  welcomeAnJun: {
    zh: '欢迎使用安骏跨境物流系统',
    en: '',
    pu: 'Bem-vindo ao Sistema Logístico Cross Border de Anjun'
  },
  welcomeanjunKD: {
    zh: '欢迎使用安骏快递系统',
    en: '',
    pu: 'Bem-vindo ao Sistema Anjun Express'
  },
  brazilOrder: {
    zh: '巴西本地快递下单',
    en: '',
    pu: 'Fazer pedido por Express local do Brasil '
  },
  test: {
    zh: '请输入旧密码',
    en: '',
    pu: 'Favor digitar a senha antiga'
  },
  coverCountry: {
    zh: '覆盖巴西全国地区',
    en: '',
    pu: 'Cobrir todo o Brasil'
  },
  placeOrder: {
    zh: '立即下单',
    en: '',
    pu: 'Fazer pedido agora'
  },
  finalState: {
    zh: '最终状态',
    en: '',
    pu: 'Estado final'
  },
  failNode: {
    zh: '推送失败节点',
    en: '',
    pu: 'Nó de envio falhado'
  },
  inputFiller: {
    zh: '输入关键字进行过滤',
    en: '',
    pu: 'Digite palavras-chave para filtrar'
  },
  configInfo: {
    zh: '配置信息',
    en: '',
    pu: 'Informações de configuração'
  },
  initNumber: {
    zh: '初始数量',
    en: '',
    pu: 'Número inicial'
  },
  minlimitcount: {
    zh: '最小限制数',
    en: '',
    pu: 'Número mínimo de limites'
  },
  maxLimitcount: {
    zh: '最大限制数',
    en: '',
    pu: 'Limite máximo'
  },
  balanceCount: {
    zh: '结余总数量',
    en: '',
    pu: 'Saldo total'
  },
  tip01: {
    zh: '最小限制不能大于等于最大限制数',
    en: '',
    pu: 'O limite mínimo não pode ser maior ou igual ao limite máximo'
  },
  limitMaxNumber: {
    zh: '超过最大限制数',
    en: '',
    pu: 'Limite máximo excedido'
  },
  limitMinxNumber: {
    zh: '低于最小限制数',
    en: '',
    pu: 'Abaixo do limite mínimo'
  },
  returnToRegistration: {
    zh: '返回登记',
    en: '',
    pu: 'Retornar ao registro'
  },
  huiRegistration: {
    zh: '回收登记',
    en: '',
    pu: 'Registro de reciclagem'
  },
  urgeRecovery: {
    zh: '催回收',
    en: '',
    pu: 'Acelerar a recuperação de algo'
  },
  clickException: {
    zh: '异常调整',
    en: '',
    pu: 'Ajuste de exceção'
  },
  registerCount: {
    zh: '返回数量',
    en: '',
    pu: 'Número de devoluções'
  },
  homeCount: {
    zh: '数量',
    en: '',
    pu: 'Quantidades'
  },
  tip02: {
    zh: '说明：输入负数，减少结余总数量；输入正数，增加结余总数量',
    en: '',
    pu: 'Observação: digite um número negativo para diminuir o saldo total; digite um número positivo para aumentar o saldo total.'
  },
  operationTotal: {
    zh: '操作总数',
    en: '',
    pu: 'Número total de operações'
  },
  siteType: {
    zh: '网点类型',
    en: '',
    pu: 'Tipo de saída'
  },
  tip03: {
    zh: '确认返回数量不能大于应返回数量',
    en: '',
    pu: 'Confirmação de que a quantidade devolvida não pode ser maior do que a quantidade a ser devolvida'
  },
  lijiReturn: {
    zh: '立即前往返回',
    en: '',
    pu: 'Voltar agora'
  },
  inputNumberdayu0: {
    zh: '输入大于零的整数',
    en: '',
    pu: 'Digite um número inteiro maior que zero'
  },
  uploadTime: {
    zh: '上传时间',
    en: '',
    pu: 'Tempo de carregamento'
  },
  applivefail: {
    zh: '审核失败',
    en: '',
    pu: 'Falha de uma auditoria'
  },
  clickSee: {
    zh: '点击查看',
    en: '',
    pu: 'Clique para ver'
  },
  qiangzhiApplive: {
    zh: '强制签收审核',
    en: '',
    pu: 'Auditoria de assinatura obrigatória'
  },
  seesingPhoto: {
    zh: '查看签收照片',
    en: '',
    pu: 'Ver fotografias assinadas'
  },
  ViewSignature: {
    zh: '查看签收签名',
    en: '',
    pu: 'Ver assinatura'
  },
  signedPhoto: {
    zh: '签收拍照',
    en: '',
    pu: 'Assinatura e fotografia'
  },
  signedNamePhoto: {
    zh: '签收签名',
    en: '',
    pu: 'Assinatura'
  },
  appliveResult: {
    zh: '审核结果',
    en: '',
    pu: 'Resultados da auditoria'
  },
  pass: {
    zh: '通过',
    en: '',
    pu: 'Aprovar'
  },
  noPass: {
    zh: '不通过',
    en: '',
    pu: 'Falhar'
  },
  unapplivefail: {
    zh: '审核未通过',
    en: '',
    pu: 'Reprovado na análise'
  },
  photoStandard: {
    zh: '照片不合格',
    en: '',
    pu: 'Foto não conforme'
  },
  signStandard: {
    zh: '签名不合格',
    en: '',
    pu: 'Assinatura não conforme'
  },
  tip1002: {
    zh: '请选择不通过原因',
    en: '',
    pu: 'Por favor, selecione o motivo da não aprovação'
  },
  batchConfiguration: {
    zh: '批量配置',
    en: '',
    pu: 'Configuração em lote'
  },
  justSearchConditionCenter: {
    zh: '仅查询下属中心',
    en: '',
    pu: 'Query Only Subordinate Centers'
  },
  batchConfigurationSite: {
    zh: '仅查下属网点',
    en: '',
    pu: 'Query Only Subordinate Branches'
  },
  adjustSiteName: {
    zh: '被调整网点',
    en: '',
    pu: 'Agências Ajustadas'
  },
  adjustCount: {
    zh: '调整数量',
    en: '',
    pu: 'Ajustar Quantidade'
  },
  adjustReason: {
    zh: '调整原因',
    en: '',
    pu: 'Motivo do Ajuste'
  },
  adjustMan: {
    zh: '调整人',
    en: '',
    pu: 'Ajustador'
  },
  adjustTime: {
    zh: '调整日期',
    en: '',
    pu: 'Data do Ajuste'
  },
  vehicleNumber: {
    zh: '末端车牌号',
    en: '',
    pu: 'Número final de registo do veículo'
  },
  noPrintNumber: {
    zh: '没有需要打印的运单号',
    en: '',
    pu: 'Não há números de remessa que precisam ser impressos'
  },
  noOperator: {
    zh: '选择的订单状态不可操作',
    en: '',
    pu: 'O status do pedido selecionado não permite operações'
  },
  goodsNOfuhe: {
    zh: '商品与实际不符',
    en: '',
    pu: 'Os produtos não correspondem à realidade'
  },
  stopTime: {
    zh: '请选择截止时间',
    en: '',
    pu: 'Por favor, selecione a data limite'
  },
  infoError: {
    zh: '收件人信息错误,无法核实',
    en: '',
    pu: 'As informações do destinatário estão erradas e não podem ser verificadas'
  },
  fixTime: {
    zh: '修改截止时间',
    en: '',
    pu: 'Modificar o prazo limite'
  },
  receiveMsgType: {
    zh: '消息接收类型',
    en: '',
    pu: 'Tipo de recepção de mensagens'
  },
  receiveMsgType00: {
    zh: '编织袋回收消息',
    en: '',
    pu: 'Mensagem de reciclagem de sacos tecidos'
  },
  notExport1000: {
    zh: '单次导出不能超过1000条数据',
    en: '',
    pu: 'A exportação única não pode exceder 1000 registros de dados'
  },
  exportPicture: {
    zh: '导出图片',
    en: '',
    pu: 'Modificar o prazo limite'
  }
};
export { home };

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.tips, visible: _vm.isShow, width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "max-height": "300px" },
          attrs: { data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: _vm.fileName },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "success",
                          href: scope.row.url,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.name) + _vm._s(scope.$index + 1)
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.$index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "upload" },
        [
          _c("UploadCmp", {
            ref: "uploadRef",
            attrs: {
              drag: true,
              multiple: true,
              "auto-upload": true,
              limit: 5,
              tips: "仅支持上传格式为bmp，jpg，png，gif，pdf，且大小在5M以内的附件",
              "upload-text": _vm.uploadText,
            },
            on: { change: _vm.onFileChange },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.isShow = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
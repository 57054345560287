<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row>
        <el-col :span="10" style="width: auto;display: flex;justify-content: flex-start;align-items: flex-start;">
          <el-select
            v-model="selectTime"
            :placeholder="$t('collectionCenter.pleaseChoose')"
            class="selectTimeClass autoWidth"
            @change="changeTime"
          >
            <template slot="prefix">
              {{ (timeList.find(item => item.value === selectTime) || {}).time }}
            </template>
            <el-option
              v-for="(item, index) in timeList"
              :key="index"
              :label="item.time"
              :value="item.value"
            />
          </el-select>
          <el-form-item>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start" style="flex-wrap: wrap;">
        <!-- xxx： -->
        <el-col :span="6" style="width: auto;">
          <el-form-item>
            <div class="flex-start">
              <el-select
                v-model="chooseNum" class="autoWidth" :placeholder="$t('collectionCenter.pleaseChoose')"
                @change="chooseON"
              >
                <template slot="prefix">
                  {{ (orderList.find(item => item.value === chooseNum) || {}).label }}
                </template>
                <el-option
                  v-for="(item, index) in orderList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-if="chooseNum == 1"
                v-model="queryForm.params.orderNumber"
                :placeholder="$t('collectionCenter.pleaseInput')"
                type="textarea"
                clearable
                @blur="ft1"
                @keydown.enter.native="BlockNewline1"
              />
              <el-input
                v-if="chooseNum == 2"
                v-model="queryForm.params.scanNumber"
                :placeholder="$t('collectionCenter.pleaseInput')"
                type="textarea"
                clearable
                @blur="ft2"
                @keydown.enter.native="BlockNewline2"
              />
              <el-input
                v-if="chooseNum == 3"
                v-model="queryForm.params.oneLicencePlate"
                :placeholder="$t('collectionCenter.pleaseInput')"
                type="textarea"
                clearable
                @blur="ft3"
                @keydown.enter.native="BlockNewline3"
              />
              <el-input
                v-if="chooseNum == 4"
                v-model="queryForm.params.twoLicencePlate"
                :placeholder="$t('collectionCenter.pleaseInput')"
                type="textarea"
                clearable
                @blur="ft4"
                @keydown.enter.native="BlockNewline4"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 所属平台： -->
          <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
            <el-select v-model="queryForm.params.platform" clearable :placeholder="$t('collectionCenter.pleaseChoose')">
              <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
              <el-option label="shein" :value="1" />
              <el-option label="kwai" :value="2" />
              <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 揽收模式： -->
          <el-form-item :label="$t('collectionCenter.receivingMode') + '：'">
            <el-select
              v-model="queryForm.params.customerCollectType"
              filterable
              clearable
              :placeholder="$t('collectionCenter.pleaseInput')"
            >
              <!--全部-->
              <el-option :key="0" :label="$t('collectionCenter.tudo')" :value="null" />
              <!--上门揽收-->
              <el-option :key="2" :label="$t('collectionCenter.toCollect')" :value="2" />
              <!--上门交件-->
              <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 订单状态： -->
          <el-form-item :label="$t('collectionCenter.orderStatus')">
            <el-select
              v-model="queryForm.params.collectStatusList"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              clearable
              filterable
              multiple
            >
              <el-option
                v-for="(item, index) in orderTypeList"
                :key="index"
                :label="item.collectStatusName"
                :value="item.collectStatus"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 寄件州： -->
          <el-form-item :label="$t('collectionCenter.postState')">
            <el-input
              v-model="queryForm.params.senderState"
              :placeholder="$t('collectionCenter.BatchQuery')"
              autosize
              type="textarea"
              @blur="ftS"
              @keydown.enter.native="BlockNewlineS"
            /><!-- 支持批量查询(回车换行分割) -->
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 寄件城市： -->
          <el-form-item :label="$t('collectionCenter.postCity')">
            <el-input
              v-model="queryForm.params.senderCity"
              :placeholder="$t('collectionCenter.BatchQuery')"
              autosize
              type="textarea"
              @blur="ftC"
              @keydown.enter.native="BlockNewlineC"
            /><!-- 支持批量查询(回车换行分割) -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:collect-time-report:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:collect-time-report:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:collect-time-report:exportAll'"
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="downloadAll"
        >{{ $t('collectionCenter.allExport') }}</el-button>
        <!-- 全部导出 -->
      </el-col>
    </el-row>
    <el-divider />
    <el-table
      ref="tableRef"
      :max-height="600"
      border
      :data="tableDate"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="orderNumber"
        :label="$t('collectionCenter.lanshouOrderNum')"
      /><!-- 揽收订单号 -->
      <el-table-column
        align="center"
        prop="scanNumber"
        :label="$t('collectionCenter.Barcode')"
      /><!-- 条形码 -->
      <el-table-column
        align="center"
        prop="collectStatusName"
        :label="$t('collectionCenter.OrderStatus')"
      /><!-- 订单状态 -->
      <el-table-column
        align="center"
        prop="customerId"
        :label="$t('collectionCenter.clientID')"
      /><!-- 客户编号 -->
      <el-table-column
        align="center"
        prop="customerName"
        :label="$t('collectionCenter.CustomerName')"
      /><!-- 客户名称 -->
      <el-table-column
        align="center"
        prop="merchantCode"
        :label="$t('collectionCenter.MerchantNumber')"
      /><!-- 商家编号 -->
      <el-table-column
        align="center"
        prop="merchantName"
        :label="$t('collectionCenter.MerchantName')"
      /><!-- 商家名称 -->
      <el-table-column
        align="center"
        prop="senderState"
        :label="$t('collectionCenter.sendState')"
      /><!-- 寄件州 -->
      <el-table-column
        align="center"
        prop="senderCity"
        :label="$t('collectionCenter.sendCity')"
      /><!-- 寄件城市 -->
      <el-table-column
        align="center"
        prop="senderZipcode"
        :label="$t('collectionCenter.postZ')"
      /><!-- 寄件人邮编 -->
      <el-table-column
        align="center"
        prop="sendMacroRegion"
        :label="$t('collectionCenter.senderSubregion')"
      /><!-- 寄件人所属大区 -->
      <el-table-column
        align="center"
        prop="sendMicroRegion"
        :label="$t('collectionCenter.senderOwningRegion')"
      /><!-- 寄件人所属区域 -->
      <el-table-column
        align="center"
        prop="oneLoadedNumber"
        :label="$t('collectionCenter.firstLoadingLicensePlate')"
      /><!-- 第一次装车车牌号 -->
      <el-table-column
        align="center"
        prop="twoLoadedNumber"
        :label="$t('collectionCenter.secondLoadingLicensePlate')"
      /><!-- 第二次装车车牌号 -->
      <el-table-column
        align="center"
        prop="customerCollectTypeName"
        :label="$t('collectionCenter.receivingMode')"
      /><!-- 揽收模式 -->
      <el-table-column
        align="center"
        prop="platform"
        :label="$t('collectionCenter.platform')"
      ><!-- 所属平台 -->
        <!-- 0:其他 1:shein 2:kwai 3:快递 -->
        <template slot-scope="scope">
          <div>
            {{
              scope.row.platform == 0 ? $t('collectionCenter.other') :
              scope.row.platform == 1 ? 'shein' :
              scope.row.platform == 2 ? 'kwai' :
              scope.row.platform == 3 ? $t('collectionCenter.expressDelivery') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="siteName"
        :label="$t('collectionCenter.LanShouSite')"
      /><!-- 揽收网点 -->
      <el-table-column
        align="center"
        prop="collectMacroRegion"
        :label="$t('collectionCenter.collectionNetworkSubregion')"
      /><!-- 揽收网点所属大区 -->
      <el-table-column
        align="center"
        prop="collectMicroRegion"
        :label="$t('collectionCenter.collectionNetworkOwningRegion')"
      /><!-- 揽收网点所属区域 -->
      <el-table-column
        align="center"
        prop="scanTime"
        :label="$t('collectionCenter.ActualCollectionTime')"
      /><!-- 实际揽收时间 -->
      <el-table-column
        align="center"
        prop="oneLoadedTime"
        :label="$t('collectionCenter.firstLoadingTime')"
      /><!-- 第一次装车时间 -->
      <el-table-column
        align="center"
        prop="oneUnloadTime"
        :label="$t('collectionCenter.firstUnloadingTime')"
      /><!-- 第一次卸车时间 -->
      <el-table-column
        align="center"
        prop="twoLoadedTime"
        :label="$t('collectionCenter.secondLoadingTime')"
      /><!-- 第二次装车时间 -->
      <el-table-column
        align="center"
        prop="twoUnloadTime"
        :label="$t('collectionCenter.SecondUnloadingTime')"
      /><!-- 第二次卸车时间 -->
      <el-table-column
        align="center"
        prop="closeTime"
        :label="$t('collectionCenter.closeTime')"
      /><!-- 闭环时间 -->
      <el-table-column
        align="center"
        prop="signerTime"
        :label="$t('collectionCenter.signT')"
      /><!-- 签收时间 -->
      <el-table-column
        align="center"
        prop="reachTime"
        :label="$t('collectionCenter.vehicleArrivalTime')"
      /><!-- 车辆到达时间 -->
      <el-table-column
        align="center"
        prop="collectDuration"
        :label="$t('collectionCenter.collectDuration')"
      /><!-- 揽收时长(小时) -->
      <el-table-column
        align="center"
        prop="transferDuration"
        :label="$t('collectionCenter.transferDuration')"
      /><!-- 中转时长(小时) -->
      <el-table-column
        align="center"
        prop="transportDuration"
        :label="$t('collectionCenter.transportDuration')"
      /><!-- 运输时长(小时) -->
      <el-table-column
        align="center"
        prop="arrivalDuration"
        :label="$t('collectionCenter.TransportationArrivalHours')"
      /><!-- 运抵操作时长(小时) -->
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import {
  queryAgeingPage,
  queryAgeingExportAll
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      isClickAll: false,
      timeValue: [],
      chooseNum: 1, // 控制有值的单号
      orderList: [
        { label: this.$t('collectionCenter.lanshouOrderNum'), value: 1 }, // 揽收订单号
        { label: this.$t('collectionCenter.Barcode'), value: 2 }, // 条形码
        { label: this.$t('collectionCenter.firstLoadingLicensePlate'), value: 3 }, // 第一次装车车牌号
        { label: this.$t('collectionCenter.secondLoadingLicensePlate'), value: 4 } // 第二次装车车牌号
      ],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          scanStartTime: '',
          scanEndTime: '',
          oneLoadedStartTime: '',
          oneLoadedEndTime: '',
          oneUnloadStartTime: '',
          oneUnloadEndTime: '',
          twoLoadedStartTime: '',
          twoLoadedEndTime: '',
          signStartTime: '',
          signEndTime: '',
          closeStartTime: '',
          closeEndTime: '',
          platform: '', // 所属平台：0:其他 1:shein 2:kwai 3:快递
          customerCollectType: '', // 客户揽收类型 '2：pick-up（上门取件 ) 1: drop-off (上门交件)'
          scanNumber: '', // 条形码
          orderNumber: '', // 订单号
          oneLicencePlate: '', // 第一次装车车牌
          twoLicencePlate: '', // 第二次装车车牌
          collectStatusList: [], // 订单状态
          senderState: '',
          senderCity: ''
        }
      },
      total: 0,
      tableDate: [],
      notEnter1: true,
      notEnter2: true,
      notEnter3: true,
      notEnterS: true,
      notEnterC: true,

      orderTypeList: [
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        // { collectStatus: 3, collectStatusName: this.$t('collectionCenter.LanShouing') }, // 揽收中
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.scheduled') }, // 已调度
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { collectStatus: 6, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 7, collectStatusName: this.$t('collectionCenter.ProblemPiece') }, // 问题件
        { collectStatus: 8, collectStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { collectStatus: 9, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 10, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 11, collectStatusName: this.$t('collectionCenter.Bagged') }, // 已装袋
        { collectStatus: 12, collectStatusName: this.$t('collectionCenter.closedLoop') }, // 已闭环
        { collectStatus: 13, collectStatusName: this.$t('collectionCenter.unclaimed') }, // 待取件
        { collectStatus: 14, collectStatusName: this.$t('collectionCenter.collectionPointInventory') }// 揽收网点入库
      ],
      selectTime: 't0',
      timeList: [
        { value: 't0', time: this.$t('collectionCenter.ActualCollectionTime') }, // '实际揽收时间'
        { value: 't1', time: this.$t('collectionCenter.firstLoadingTime') }, // '第一次装车时间'
        { value: 't2', time: this.$t('collectionCenter.firstUnloadingTime') }, // '第一次卸车时间'
        { value: 't3', time: this.$t('collectionCenter.secondLoadingTime') }, // '第二次装车时间'
        { value: 't4', time: this.$t('collectionCenter.signT') }, // '签收时间'
        { value: 't5', time: this.$t('collectionCenter.closeTime') } // '闭环时间'
      ]
    };
  },
  watch: {
    'queryForm.params.orderNumber'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.orderNumber = number;
        this.notEnter1 = false;
      } else {
        this.notEnter1 = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.scanNumber'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.scanNumber = number;
        this.notEnter2 = false;
      } else {
        this.notEnter2 = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.oneLicencePlate'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.oneLicencePlate = number;
        this.notEnter3 = false;
      } else {
        this.notEnter3 = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.twoLicencePlate'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.twoLicencePlate = number;
        this.notEnter3 = false;
      } else {
        this.notEnter3 = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.senderState'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // // 去除空格
      // const numTrim = bgh.map(item => {
      //   return item.replace(/\s/g, '').trim();
      // });
      let number = [];
      if (bgh.length >= 100) {
        number = bgh.slice(0, 100).join('\n');
        this.queryForm.params.senderState = number;
        this.notEnterS = false;
      } else {
        this.notEnterS = true;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    },
    'queryForm.params.senderCity'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // // 去除空格
      // const numTrim = bgh.map(item => {
      //   return item.replace(/\s/g, '').trim();
      // });
      let number = [];
      if (bgh.length >= 100) {
        number = bgh.slice(0, 100).join('\n');
        this.queryForm.params.senderCity = number;
        this.notEnterC = false;
      } else {
        this.notEnterC = true;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    }
  },
  created() {
    this.default();
    this.searchClick();
    this.isClickAll = false;
  },
  methods: {
    ftS() {
      const val = this.queryForm.params.senderState.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // // 去除空格
      // const numTrim = numLen.map(item => {
      //   return item.replace(/\s/g, '').trim();
      // });
      this.queryForm.params.senderState = numLen.join('\n');
    },
    BlockNewlineS() {
      const e = window.event || arguments[0];
      this.ftS();
      const numberLen = this.queryForm.params.senderState.split('\n');
      if (!this.notEnterS) {
        if (numberLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ftC() {
      const val = this.queryForm.params.senderCity.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // // 去除空格
      // const numTrim = numLen.map(item => {
      //   return item.replace(/\s/g, '').trim();
      // });
      this.queryForm.params.senderCity = numLen.join('\n');
    },
    BlockNewlineC() {
      const e = window.event || arguments[0];
      this.ftC();
      const numberLen = this.queryForm.params.senderCity.split('\n');
      if (!this.notEnterC) {
        if (numberLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    changeTime() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.scanStartTime = ''; // 实际揽收开始时间
      this.queryForm.params.scanEndTime = '';
      this.queryForm.params.oneLoadedStartTime = ''; // 第一次装车开始时间
      this.queryForm.params.oneLoadedEndTime = '';
      this.queryForm.params.oneUnloadStartTime = ''; // 第一卸车开始时间
      this.queryForm.params.oneUnloadEndTime = '';
      this.queryForm.params.twoLoadedStartTime = ''; // 第二次装车开始时间
      this.queryForm.params.twoLoadedEndTime = '';
      this.queryForm.params.signStartTime = ''; // 签收开始时间
      this.queryForm.params.signEndTime = '';
      this.queryForm.params.closeStartTime = ''; // 闭环查询开始时间
      this.queryForm.params.closeEndTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.scanStartTime = today + ' 00:00:00'; // 实际揽收开始时间
        this.queryForm.params.scanEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.oneLoadedStartTime = today + ' 00:00:00'; // 第一次装车开始时间
        this.queryForm.params.oneLoadedEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.oneUnloadStartTime = today + ' 00:00:00'; // 第一卸车开始时间
        this.queryForm.params.oneUnloadEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.twoLoadedStartTime = today + ' 00:00:00'; // 第二次装车开始时间
        this.queryForm.params.twoLoadedEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't4') {
        this.queryForm.params.signStartTime = today + ' 00:00:00'; // 签收开始时间
        this.queryForm.params.signEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't5') {
        this.queryForm.params.closeStartTime = today + ' 00:00:00'; // 闭环查询开始时间
        this.queryForm.params.closeEndTime = today + ' 23:59:59';
      }
    },
    ft1() {
      const val = this.queryForm.params.orderNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.orderNumber = bghTrim.join('\n');
    },
    BlockNewline1() {
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.orderNumber.split('\n');
      if (!this.notEnter1) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft2() {
      const val = this.queryForm.params.scanNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.scanNumber = bghTrim.join('\n');
    },
    BlockNewline2() {
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.scanNumber.split('\n');
      if (!this.notEnter2) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft3() {
      const val = this.queryForm.params.oneLicencePlate.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.oneLicencePlate = bghTrim.join('\n');
    },
    BlockNewline3() {
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.oneLicencePlate.split('\n');
      if (!this.notEnter3) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ft4() {
      const val = this.queryForm.params.twoLicencePlate.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.twoLicencePlate = bghTrim.join('\n');
    },
    BlockNewline4() {
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.twoLicencePlate.split('\n');
      if (!this.notEnter3) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },

    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.scanStartTime = ''; // 实际揽收开始时间
      this.queryForm.params.scanEndTime = '';
      this.queryForm.params.oneLoadedStartTime = ''; // 第一次装车开始时间
      this.queryForm.params.oneLoadedEndTime = '';
      this.queryForm.params.oneUnloadStartTime = ''; // 第一卸车开始时间
      this.queryForm.params.oneUnloadEndTime = '';
      this.queryForm.params.twoLoadedStartTime = ''; // 第二次装车开始时间
      this.queryForm.params.twoLoadedEndTime = '';
      this.queryForm.params.signStartTime = ''; // 签收开始时间
      this.queryForm.params.signEndTime = '';
      this.queryForm.params.closeStartTime = ''; // 闭环查询开始时间
      this.queryForm.params.closeEndTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.scanStartTime = val[0]; // 实际揽收开始时间
        this.queryForm.params.scanEndTime = val[1];
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.oneLoadedStartTime = val[0]; // 第一次装车开始时间
        this.queryForm.params.oneLoadedEndTime = val[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.oneUnloadStartTime = val[0]; // 第一卸车开始时间
        this.queryForm.params.oneUnloadEndTime = val[1];
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.twoLoadedStartTime = val[0]; // 第二次装车开始时间
        this.queryForm.params.twoLoadedEndTime = val[1];
      }
      if (this.selectTime === 't4') {
        this.queryForm.params.signStartTime = val[0]; // 签收开始时间
        this.queryForm.params.signEndTime = val[1];
      }
      if (this.selectTime === 't5') {
        this.queryForm.params.closeStartTime = val[0]; // 闭环查询开始时间
        this.queryForm.params.closeEndTime = val[1];
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-14, 'day').format('YYYY-MM-DD 23:59:59');
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到15天的数据
            message: this.$t('collectionCenter.max15DaysDate'),
            type: 'warning'
          });
          this.changeTime();
        }
      }
    },
    chooseON() {
      this.queryForm.params.scanNumber = '';
      this.queryForm.params.orderNumber = '';
      this.queryForm.params.oneLicencePlate = '';
      this.queryForm.params.twoLicencePlate = '';
    },
    default() {
      this.selectTime = 't0';
      this.chooseNum = 1;
      this.chooseON();
      const d = new Date();
      const start = dayjs(d).format('YYYY-MM-DD 00:00:00');
      const end = dayjs(d).format('YYYY-MM-DD 23:59:59');
      this.timeValue = [start, end];
      this.queryForm.params = {
        scanStartTime: start,
        scanEndTime: end,
        oneLoadedStartTime: '',
        oneLoadedEndTime: '',
        oneUnloadStartTime: '',
        oneUnloadEndTime: '',
        twoLoadedStartTime: '',
        twoLoadedEndTime: '',
        signStartTime: '',
        signEndTime: '',
        closeStartTime: '',
        closeEndTime: '',
        platform: '', // 所属平台：0:其他 1:shein 2:kwai 3:快递
        customerCollectType: '', // 客户揽收类型 '2：pick-up（上门取件 ) 1: drop-off (上门交件)'
        scanNumber: '', // 条形码
        orderNumber: '', // 订单号
        oneLicencePlate: '', // 第一次装车车牌
        twoLicencePlate: '', // 第二次装车车牌
        collectStatusList: [], // 订单状态
        senderState: '',
        senderCity: ''
      };
    },
    resetClick() {
      this.default();
    },
    getPage() {
      const queryForm = JSON.parse(localStorage.getItem('collectTimeReportForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryAgeingPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('collectTimeReportForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      queryAgeingPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    searchClick() {
      this.queryForm.pageNumber = 1;
      if (this.queryForm.params.scanNumber || this.queryForm.params.orderNumber || this.queryForm.params.oneLicencePlate || this.queryForm.params.twoLicencePlate) {
        this.timeValue = [];
        this.queryForm.params.scanStartTime = '';
        this.queryForm.params.scanEndTime = '';
        this.queryForm.params.platform = '';
        this.queryForm.params.customerCollectType = '';
        this.queryForm.params.collectStatusList = [];
        this.queryForm.params.oneLoadedStartTime = ''; // 第一次装车开始时间
        this.queryForm.params.oneLoadedEndTime = '';
        this.queryForm.params.oneUnloadStartTime = ''; // 第一卸车开始时间
        this.queryForm.params.oneUnloadEndTime = '';
        this.queryForm.params.twoLoadedStartTime = ''; // 第二次装车开始时间
        this.queryForm.params.twoLoadedEndTime = '';
        this.queryForm.params.signStartTime = ''; // 签收开始时间
        this.queryForm.params.signEndTime = '';
        this.queryForm.params.closeStartTime = ''; // 闭环查询开始时间
        this.queryForm.params.closeEndTime = '';
        this.queryForm.params.senderState = '';
        this.queryForm.params.senderCity = '';
      } else {
        if (!this.timeValue[0] || !this.timeValue[1]) {
          this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
          return;
        }
      }
      localStorage.setItem('collectTimeReportForm', JSON.stringify(this.queryForm));
      this.getPage();
    },
    downloadAll() {
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('collectTimeReportForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryAgeingExportAll(queryForm).then(res => {
        if (res.status === 'OK') {
          this.isClickAll = false;
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .flex-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .flex-start ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 150px !important;
    min-width: 200px;
  }
  .autoWidth{
    width: auto !important;
  }
  .autoWidth ::v-deep .el-input__prefix { // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep.el-input--suffix .el-input__inner {
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

</style>

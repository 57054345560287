<template>
  <div>
    <div>
      <div v-if="gridData.length>0">
        <div v-infinite-scroll="getUnreadMsgList" class="msgContainer">
          <div
            v-for="(item, index) in gridData"
            :key="index"
            class="msgItemContainer"
            @click="checkMsg(item)"
          >
            <div v-if="item.msgType !== 10" style="padding-left: 50px;">
              <span
                class="msgTag"
                :class="item.msgType === 0 ? 'msgTagDefault' : (item.msgType === 1 ? 'msgTagInfo' :
                  (item.msgType === 2 ? 'msgTagDanger' : (item.msgType === 3 ? 'msgTagWarning' : 'msgTagSuccess')))"
              >
                {{ item.msgType | msgType }}
              </span>
              <div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.title"
                  placement="top"
                >
                  <p
                    style="margin: 15px 0;"
                    class="ellipsis"
                  >{{ item.title }}</p>
                </el-tooltip>
                <p>{{ item.receiveTime }}</p>
              </div>
            </div>
            <div v-else style="padding: 10px;display: flex;align-items: center">
              <div style="flex: 1">
                <span>{{ item.receiveTime }}</span>
              </div>
              <div style="text-align: center;flex: 1">
                <span style="margin-right: 10px;">{{ item.title }}</span>
                <span style="margin-right: 10px;">{{ item.msgData.successCount }}{{ $t('GlobalSetObj.succeed') }}</span>
                <span>{{ item.msgData.failureCount }}{{ $t('GlobalSetObj.Lose') }}</span>
              </div>
              <div style="text-align: right;flex: 1">
                <el-button
                  type="text"
                  size="small"
                >{{ $t('orderCenterCont.ViewTheImportResults') }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="text-align: center;"
      >{{ $t('GlobalSetObj.NoNewsYet') }}</div>
    </div>
    <!--    :close-on-press-escape="false"-->
    <el-dialog
      v-if="dialogTableVisible"
      :title="updateForm.msgType | msgType"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="70%"
      :append-to-body="true"
    >
      <messages-detail
        :key="newKey"
        :item-detail="updateForm"
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogTableVisible = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button
          type="primary"
          @click="startUploadFile"
        >{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showImportOrderReturn"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderCenterCont.ImportOrdersBulk')"
      :visible.sync="showImportOrderReturn"
      width="400px"
      @close="cloceMessage"
    >
      <div :key="showImportOrderReturn">
        <!--订单导入完成-->
        <p>{{ $t('orderCenterCont.TheOrderImportComplete') }}!</p>
        <div style="display: flex;">
          <!--全部-->
          <div style="flex: 1">{{ $t('GlobalSetObj.all') }}: {{ resultData.msgData.allData }}</div>
          <!--导入成功-->
          <div style="flex: 1">{{ $t('orderCenterCont.ImportWasSuccessful') }}: {{ resultData.msgData.successCount }}</div>
          <!--导入失败-->
          <div style="flex: 1">{{ $t('orderCenterCont.ImportFailed') }}: {{ resultData.msgData.failureCount }}</div>
        </div>
        <div style="margin-top: 40px;">
          <!--下载导入失败订单明细-->
          <el-button size="small" type="primary" @click="operationBtn()">{{ $t('orderCenterCont.DownloadImportFailureOrderDetails') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNoReadMsg, setMsgRead } from '@/api/messageCenter';
import messagesDetail from '@/views/messageCenter/components/messageDetail';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'MsgCenter',
  components: {
    messagesDetail
  },
  filters: {
    msgType: (value) => {
      if (value === null || value === '' || value === undefined) return '';
      value = value.toString();
      let typeName = '';
      switch (value) {
        case '0':
          typeName = '公告';
          break;
        case '1':
          typeName = '站内';
          break;
        case '2':
          typeName = '系统';
          break;
        case '3':
          typeName = '工单';
          break;
        case '4':
          typeName = '任务';
          break;
        case '5':
          typeName = '短信';
          break;
        case '6':
          typeName = '邮箱';
          break;
        case '7':
          typeName = '钉钉';
          break;
        case '8':
          typeName = '微信';
          break;
        case '10':
          typeName = '订单导入';
          break;
        default:
          typeName = '其他';
      }
      return typeName;
    }
  },
  props: {
    gridData: {
      type: Array,
      default() {
        return [];
      }
    },
    allTotal: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      // gridData: [],
      updateForm: null,
      newKey: new Date().getTime(),
      userId: '',
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {}
      },
      resultData: null,
      showImportOrderReturn: false,
      total: 0
    };
  },
  created() {
    this.total = this.allTotal;
    this.userId = this.$store.state.user.id;
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.getUnreadMsg();
    // });
    // this.bus.$on('upDateMsg', () => {
    //   this.getUnreadMsg(1);
    // });
  },
  methods: {
    goType(type) {
      const obj = {
        // 公告
        0() {},
        // 站内信
        1() {},
        // 系统
        2() {},
        // 工单
        3: () => {
          this.$router.push({ path: '/work/myMission' });
        },
        // 任务
        4: () => {
          this.$router.push({ path: '/work/myestablish' });
        },
        // 短信
        5() {},
        // 邮箱
        6() {},
        // 钉钉
        7() {},
        // 微信
        8() {},
        // 微工单
        9: () => {
          this.$router.push({
            path: '/work/MiniWorkOrder'
          });
        },
        10: () => {
          this.$router.push({ path: '/views/resource/exportList' });
        },
        default() {
          console.error('消息类型错误没有匹配');
        }
      };

      obj[type] ? obj[type]() : obj.default();
    },

    // 一键已读
    oneKeyRead() {
      this.$confirm('确定一键已读?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const gridData = this.gridData;
          gridData.forEach((item, index) => {
            const { msgId } = item;
            this.setMsgIsRead(msgId, this.userId);
            if (index === gridData.length - 1) {
              this.gridData = [];
              setTimeout(() => {
                this.queryForm.pageNumber = 1;
                this.getUnreadMsg();
              }, 1000);
              // this.$emit('getMsg', { num: gridData.length, show: false });
            }
          });
        })
        .catch(() => {});
    },
    // 设置已读
    setMsgIsRead(msgId, userId) {
      setMsgRead(msgId, userId)
        .then((data) => {
          const { status } = data;
          if (status !== 'OK') {
            return;
          }
          this.$emit('getMsg');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭订单消息已读弹窗
    cloceMessage() {
      this.setMsgIsRead(this.updateForm.msgId, this.userId);
    },
    // 滚动加载
    getUnreadMsgList() {
      this.queryForm.pageNumber += 1;
      if (this.gridData.length >= this.total) {
        return;
      }
      this.getUnreadMsg();
    },
    // 获取未读信息列表
    getUnreadMsg() {
      getNoReadMsg(this.queryForm)
        .then((res) => {
          const { status, data } = res;
          if (status !== 'OK') {
            return;
          }
          const resultData = data.records.map(item => {
            if (item.msgType === 10) {
              item.msgData = JSON.parse(item.msgData);
            }
            return item;
          });
          this.gridData = [...this.gridData, ...resultData];
          this.total = data.total;
          this.$emit('getMsg', { num: this.total, show: false });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 循环消息列表找出未读弹窗消息
    searchUnread() {
      if (this.gridData.length > 0) {
        for (let i = 0; i < this.gridData.length; i++) {
          if (this.gridData[i].isPop === true) {
            this.updateForm = this.gridData[i];
            this.dialogTableVisible = true;
            break;
          } else {
            this.dialogTableVisible = false;
          }
        }
      } else {
        this.dialogTableVisible = false;
      }
    },
    // 从消息列表中删除已读消息
    deleteRead(type) {
      const gridData = this.gridData;
      const { msgId: updateFormMsgId } = this.updateForm;
      gridData.forEach((item, index) => {
        const { msgId } = item;
        if (msgId === updateFormMsgId) {
          gridData.splice(index, 1);
          this.$emit('getMsg', { num: this.total - 1, show: type });
        }
      });
      // for (let i = 0; i < this.gridData.length; i++) {
      //   if (this.gridData[i].msgId === this.updateForm.msgId) {
      //     this.gridData.splice(i, 1);
      //     this.$emit('getMsg', { num: this.gridData.length, show: type });
      //     break;
      //   }
      // }
    },
    // 弹窗消息确认已读
    startUploadFile() {
      this.dialogTableVisible = false;
      this.newKey = new Date().getTime();
      // this.deleteRead(false);
      this.setMsgIsRead(this.updateForm.msgId, this.userId);
      const { msgType } = this.updateForm;
      // if (this.updateForm.msgType === 3 || this.updateForm.msgType === 4) {
      //   let path;
      //   if (this.updateForm.msgType === 4) {
      //     path = '/work/myMission';
      //     this.$router.push({ path: path });
      //   } else if (this.updateForm.msgType === 3) {
      //     path = '/work/myestablish';
      //     this.$router.push({ path: path });
      //   }
      // } else {
      //   this.searchUnread();
      // }
      this.goType(msgType);
    },
    // 点击消息查看详情
    checkMsg(msg) {
      this.updateForm = msg;
      this.newKey = new Date().getTime();
      if (msg.msgType === 10) {
        // this.gridData = [];
        // this.
        // this.deleteRead(false);
        this.showImportOrderReturn = true;
        this.resultData = cloneDeep(msg);
      } else {
        this.dialogTableVisible = true;
      }
      // console.log(1);
      // this.goType(msg.msgType);
    },
    // 进入资源导出列表
    operationBtn() {
      this.showImportOrderReturn = false;
      this.setMsgIsRead(this.updateForm.msgId, this.userId);
      this.goType(10);
    }
  }
};
</script>

<style scoped>
.msgContainer {
  max-height: 400px;
  overflow: auto;
  padding-right: 10px;
  padding-left: 5px;
}

.msgItemContainer {
  overflow: hidden;
  position: relative;
  padding-right: 10px;
  border-radius: 3px;
  cursor: pointer;
  margin: 10px 0;
  box-shadow: 0 0 5px 2px #eee;
}

.msgItemContainer:hover {
  background: #eee;
}

.msgTag {
  position: absolute;
  top: 6px;
  left: -16px;
  color: #fff;
  font-size: 12px;
  padding: 2px 20px;
  transform: rotate(-45deg);
}

.msgTagDefault {
  background: #409eff;
}

.msgTagInfo {
  background: #909399;
}

.msgTagSuccess {
  background: #67c23a;
}

.msgTagDanger {
  background: #f56c6c;
}

.msgTagWarning {
  background: #e6a23c;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pEditor p {
  margin: 0 !important;
}

.detailStyle .el-form-item {
  margin-bottom: 0;
}

.el-dialog__body {
  padding: 0 20px;
  margin-top: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>

// 系统管理设置

const Resource = {
  confirmCheck: {
    zh: '确定同意审核？',
    en: '',
    pu: 'tem certeza de aprovar uma revisão?'
  },
  Approval: {
    zh: '审核',
    en: 'Approval',
    pu: 'aprovação'
  },
  AuditSuccess: {
    zh: '审核成功',
    en: '',
    pu: 'Sucesso de revisão'
  },
  importSuccessNotErr: {
    zh: '导入成功无错误数据',
    en: '',
    pu: 'Importação bem sucedida sem dados de erro'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitir'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher faixa de pesquisa'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expanda o botão Consulta'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'No.'
  },
  yes: {
    zh: '是',
    en: 'yes',
    pu: 'Sim'
  },
  No: {
    zh: '否',
    en: 'No',
    pu: 'Não'
  },
  completed: {
    zh: '已完成',
    en: 'completed',
    pu: 'Concluído'
  },
  ApprovalStatus: {
    zh: '审核状态',
    en: 'Approval Status',
    pu: 'Status de aprovação'
  },
  underReview: {
    zh: '审核中',
    en: 'under review',
    pu: 'Sob revisão'
  },
  Approved: {
    zh: '已审核',
    en: 'Approved',
    pu: 'Aprovado'
  },
  name: {
    zh: '名称',
    en: 'name',
    pu: 'Nome'
  },
  reviewed: {
    zh: '是否需要审核',
    en: 'Does it need to be reviewed',
    pu: 'Precisa ser revisto'
  },
  statusOfExportFile: {
    zh: '文件导出状态',
    en: 'status of export file ',
    pu: 'status de exportação do arquivo'
  },
  documentExportResults: {
    zh: '文档导出结果',
    en: 'Document export results',
    pu: 'Resultados de exportação de documentos'
  },
  exportErr: {
    zh: '导出失败',
    en: '',
    pu: 'a exportação falhou'
  },
  exporting: {
    zh: '正在导出',
    en: '',
    pu: 'exportando'
  },

  fileName: {
    zh: '文件名称',
    en: 'file name',
    pu: 'nome do arquivo'
  },
  fileType: {
    zh: '文件类型',
    en: 'file type',
    pu: 'tipo de arquivo'
  },
  applicant: {
    zh: '申请人',
    en: 'applicant',
    pu: 'requerente'
  },
  DateOfRequest: {
    zh: '申请日期',
    en: 'Date of request',
    pu: 'Data da solicitação'
  },
  Reviewer: {
    zh: '审核人',
    en: 'Reviewer',
    pu: 'Revisor'
  },
  download: {
    zh: '下载',
    en: 'download',
    pu: 'download'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  noMore100Code: {
    zh: '输入不能超出100个字符',
    en: '',
    pu: 'A entrada não pode exceder 100 caracteres'
  },
  inputChinese: {
    zh: '请输入中文',
    en: '',
    pu: 'favor inserir em chinês'
  },
  notEnglish: {
    zh: '不能有英文字符',
    en: '',
    pu: 'Sem caracteres ingleses'
  },
  inputEnglish: {
    zh: '请输入英文',
    en: '',
    pu: 'favor inserir em inglês'
  },
  notChinese: {
    zh: '不能有中文字符',
    en: '',
    pu: 'Sem caracteres chineses'
  },
  ResourceN: {
    zh: '资源名称：',
    en: '',
    pu: 'nome dos recursos：'
  },
  ResourceT: {
    zh: '资源类型：',
    en: '',
    pu: 'tipo dos recursos：'
  },
  ResourceType: {
    zh: '资源类型',
    en: '',
    pu: 'tipo dos recursos '
  },
  WhetherForceUpdate: {
    zh: '是否强制更新',
    en: '',
    pu: 'atualização forçado ou não?'
  },
  ResourceName: {
    zh: '资源名称',
    en: '',
    pu: 'Nome dos recursos '
  },
  uploader: {
    zh: '上传者',
    en: '',
    pu: 'carregador '
  },
  VersionNum: {
    zh: '版本号',
    en: '',
    pu: 'número da versão '
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  ResourceLinks: {
    zh: '资源链接：',
    en: '',
    pu: 'links dos recursos：'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  WhetherToUpdateTheVersion: {
    zh: '是否更新版本：',
    en: '',
    pu: 'atualização da versão ou não?'
  },
  WhetherToForceUpdate: {
    zh: '是否强制更新：',
    en: '',
    pu: 'atualização obrigatória ou não?'
  },
  VersionNumber: {
    zh: '版本号：',
    en: '',
    pu: 'número da versão：'
  },
  ActualVersionNumber: {
    zh: '实际版本号：',
    en: '',
    pu: 'número da versão atual：'
  },
  UpdateTips: {
    zh: '更新提示：',
    en: '',
    pu: 'dicas da atualização e：'
  },
  note: {
    zh: '备注：',
    en: 'remark: ',
    pu: 'observações: '
  },
  file: {
    zh: '文件',
    en: 'file',
    pu: 'arquivo'
  },
  video: {
    zh: '视频',
    en: '',
    pu: 'vídeo '
  },
  picture: {
    zh: '图片',
    en: '',
    pu: 'fotos'
  },
  tape: {
    zh: '录音',
    en: '',
    pu: 'gravação '
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  licensePlateNumber: {
    zh: '车牌号码',
    en: '',
    pu: 'Placa'
  },
  vehicleBrand: {
    zh: '车辆品牌',
    en: '',
    pu: 'Marca'
  },
  vehicleIde: {
    zh: '车架号',
    en: '',
    pu: 'NIV'
  },
  sshop: {
    zh: '购买4S店',
    en: '',
    pu: 'Local de compra/aluguel'
  },
  vehicleCondition: {
    zh: '车辆状况',
    en: '',
    pu: 'Estado do veículo'
  },
  addNewVehicle: {
    zh: '新增车辆',
    en: '',
    pu: 'Adiciona'
  },
  registrationDate: {
    zh: '上牌时间',
    en: '',
    pu: 'tempo de licenciamento'
  },
  vehicleModel: {
    zh: '车辆型号',
    en: '',
    pu: 'Modelo do veículo'
  },
  departmentInUse: {
    zh: '使用部门',
    en: '',
    pu: 'departamento de usuário'
  },
  currentDriver: {
    zh: '当前司机',
    en: '',
    pu: 'o presente motorista'
  },
  latestMaintenance: {
    zh: '最新保养',
    en: '',
    pu: 'Última manutenção'
  },
  latestRepair: {
    zh: '最新维修',
    en: '',
    pu: 'Último reparo'
  },
  InsuranceExpirationDate: {
    zh: '保险到期',
    en: '',
    pu: 'Vencimento do seguro'
  },
  latestInventoryCheck: {
    zh: '最新盘点',
    en: '',
    pu: 'Último inventário'
  },
  addNewRecord: {
    zh: '新增记录',
    en: '',
    pu: 'adiciona registo'
  },
  maintenance: {
    zh: '保养',
    en: '',
    pu: 'manutenção'
  },
  repair: {
    zh: '维修',
    en: '',
    pu: 'reparo'
  },
  shop: {
    zh: '店铺',
    en: '',
    pu: 'Loja'
  },
  handler: {
    zh: '处理人',
    en: '',
    pu: 'operador'
  },
  addNewMain: {
    zh: '新增保养/维修',
    en: '',
    pu: 'adiciona manutenção/ reparo'
  },
  vehicleInventory: {
    zh: '车辆盘点',
    en: '',
    pu: 'inventário de veículo'
  },
  currentMileage: {
    zh: '当前里程',
    en: '',
    pu: 'quilometragem atual'
  },
  locationOfUse: {
    zh: '使用地点',
    en: '',
    pu: 'local de uso'
  },
  inventoryClerk: {
    zh: '盘点人',
    en: '',
    pu: 'inventariente'
  },
  frontOfTheCar: {
    zh: '车头',
    en: '',
    pu: 'Parte da frente do veículo'
  },
  carInterior: {
    zh: '车内',
    en: '',
    pu: 'Interior do veículo'
  },
  trunk: {
    zh: '车厢',
    en: '',
    pu: 'Cabine'
  },
  exterior: {
    zh: '外观',
    en: '',
    pu: 'Aparência do veículo'
  },
  saveInformation: {
    zh: '保存信息',
    en: '',
    pu: 'Salva informações'
  },
  insuranceExpiration: {
    zh: '保险到期',
    en: '',
    pu: 'Vencimento do seguro'
  },
  vehicleDetails: {
    zh: '车辆详情',
    en: '',
    pu: 'repair'
  },
  maintenanceRecords: {
    zh: '保养维修记录',
    en: '',
    pu: 'Registro de manutenção e reparo'
  },
  inventoryRecords: {
    zh: '盘点记录',
    en: '',
    pu: 'Registro de inventário'
  },
  records: {
    zh: '盘点',
    en: '',
    pu: 'inventário'
  },
  expenses: {
    zh: '费用',
    en: 'expenses',
    pu: 'Despesas'
  },
  register: {
    zh: '登记',
    en: '',
    pu: 'register'
  },
  content: {
    zh: '内容',
    en: '',
    pu: 'Motivo'
  },
  taskId: {
    zh: '任务ID',
    en: '',
    pu: 'ID da tarefa'
  },
  taskType: {
    zh: '类型',
    en: '',
    pu: 'Tipo'
  },
  taskStatus: {
    zh: '状态',
    en: '',
    pu: 'Estado'
  },
  schedule: {
    zh: '进度',
    en: '',
    pu: 'Calendário'
  },
  total: {
    zh: '总记录数',
    en: '',
    pu: 'Número de registros'
  },
  successCount: {
    zh: '成功数',
    en: '',
    pu: 'Número de sucessos"'
  },
  failedMessage: {
    zh: '异常说明',
    en: '',
    pu: 'Descrição anormal'
  },
  import: {
    zh: '导入',
    en: '',
    pu: 'Importar'
  },
  export: {
    zh: '导出',
    en: '',
    pu: 'Exportar'
  },
  init: {
    zh: '排队中',
    en: '',
    pu: 'Na fila'
  },
  action: {
    zh: '进行中',
    en: '',
    pu: 'Em progresso'
  },
  complete: {
    zh: '完成',
    en: '',
    pu: 'Completa'
  },
  fail: {
    zh: '失败',
    en: '',
    pu: 'Falham'
  },
  timeout: {
    zh: '超时',
    en: '',
    pu: 'horas extra'
  },
  operator: {
    zh: '操作人',
    en: '',
    pu: 'Operador'
  },
  endTime: {
    zh: '结束时间',
    en: '',
    pu: 'Horário final'
  },
  startTime: {
    zh: '开始时间',
    en: '',
    pu: 'Horário de início'
  },
  执行时间: {
    zh: '执行时间',
    en: '',
    pu: 'Horário de execução'
  },
  SMSConfiguration: {
    zh: '短信配置',
    en: 'SMS configuration',
    pu: 'Mensagem de configuração'
  },
  SMSTemplate: {
    zh: '短信模板',
    en: 'SMS template',
    pu: 'Modelo de SMS'
  },
  Templateconfiguration: {
    zh: '模板配置',
    en: 'Template configuration',
    pu: 'Configurações de modelo'
  },
  OperationNode: {
    zh: '操作节点',
    en: 'Operation node',
    pu: 'Nó operacional'
  },
  OrderCreation: {
    zh: '订单创建',
    en: 'Order creation',
    pu: 'Criação de pedido'
  },
  WarehouseEntryScanning: {
    zh: '网点入库扫描',
    en: 'Warehouse entry scanning',
    pu: 'Bipagem de entrada do ponto'
  },
  TaskAssignment: {
    zh: '任务分配',
    en: 'Task assignment',
    pu: 'Distribuição de tarefa'
  },
  signForScan: {
    zh: '签收扫描',
    en: 'Sign for scan',
    pu: 'Escaneado ao assinar.'
  },
  SelectSMSTemplate: {
    zh: '选择短信模板',
    en: 'Select SMS template',
    pu: 'Selecione o modelo de mensagem'
  },
  TemplateName: {
    zh: '模板名称',
    en: 'Template name',
    pu: 'Nome do modelo'
  },
  TemplateText: {
    zh: '模板文案',
    en: 'Template text',
    pu: 'Texto do modelo'
  },
  AddSMSConfiguration: {
    zh: '新增短信配置',
    en: 'Add SMS configuration',
    pu: 'Adiciona configuração de SMS'
  },
  underReviewNEW: {
    zh: '待审核',
    en: 'under review',
    pu: 'Aguardando análise'
  }
};

export { Resource };

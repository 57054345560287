var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-model" },
    [
      _c("div", { staticClass: "mask" }),
      _c(
        "span",
        {
          staticClass: "el-image-viewer__btn el-image-viewer__close",
          on: { click: _vm.close },
        },
        [_c("i", { staticClass: "el-icon-close" })]
      ),
      _c(
        "span",
        {
          staticClass:
            "el-image-viewer__btn el-image-viewer__close el-download",
          on: { click: _vm.download },
        },
        [_c("i", { staticClass: "el-icon-download" })]
      ),
      _c("el-button", {
        staticClass: "el-image-viewer__btn el-image-viewer__prev",
        attrs: {
          icon: "el-icon-arrow-left",
          disabled: _vm.currentIndex === 0,
          circle: "",
        },
        on: { click: _vm.prevFile },
      }),
      _c("el-button", {
        staticClass: "el-image-viewer__btn el-image-viewer__next",
        attrs: {
          icon: "el-icon-arrow-right",
          disabled:
            _vm.fileList.length === 0 ||
            _vm.currentIndex === _vm.fileList.length - 1,
          circle: "",
        },
        on: { click: _vm.nextFile },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.loading,
              expression: "loading",
              modifiers: { fullscreen: true, lock: true },
            },
          ],
          staticClass: "el-image-viewer__canvas",
        },
        [
          _vm.fileList.length > 0
            ? _c(
                "div",
                { staticClass: "preview-area" },
                [
                  _vm.isImage
                    ? _c("el-image", {
                        staticClass: "preview-image",
                        attrs: { src: _vm.currentFileUrl, fit: "fill" },
                        on: { load: _vm.onImageLoad },
                      })
                    : _vm.isPDF
                    ? _c("pdf", {
                        staticClass: "preview-pdf",
                        attrs: { src: _vm.currentFileUrl },
                        on: { loaded: _vm.onPDFLoad },
                      })
                    : _c("el-empty", {
                        attrs: { description: "不支持的文件格式" },
                      }),
                ],
                1
              )
            : _c("el-empty", { attrs: { description: "暂无文件可预览" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                model: {
                                  value: _vm.queryForm.params.selectType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("newOrder.waybillNumber"),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.ScanNumber"),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("newOrder.PreciseSearch1"),
                          rows: 1,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.queryForm.params.inputValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "inputValue", $$v)
                          },
                          expression: "queryForm.params.inputValue",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectTimeType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth01,
                                model: {
                                  value: _vm.queryForm.params.selectTimeType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectTimeType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectTimeType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("home.uploadTime"),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t(
                                      "receivablePayable.ReviewTime"
                                    ),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: false,
                          "default-time": ["00:00:00", "23:59:59"],
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          "range-separator": "~",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.status") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.queryForm.params.forceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "forceType", $$v)
                            },
                            expression: "queryForm.params.forceType",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "0",
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _c("el-option", {
                            key: "1",
                            attrs: {
                              label: _vm.$t("Resource.underReviewNEW"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            key: "2",
                            attrs: {
                              label: _vm.$t("customerManagements.approved"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            key: "3",
                            attrs: {
                              label: _vm.$t("home.unapplivefail"),
                              value: 3,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.stateSite1") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.preState,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "preState", $$v)
                            },
                            expression: "queryForm.params.preState",
                          },
                        },
                        _vm._l(_vm.StateList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.preEntrega") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.remoteGetSiteList,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.remoteGetSiteList(_vm.searchsiteStr)
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.preDeliverSiteId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "preDeliverSiteId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.preDeliverSiteId",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.deliveryPerson"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.getallDriver,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getallDriver(_vm.seachDriver)
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.delivererId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "delivererId", $$v)
                            },
                            expression: "queryForm.params.delivererId",
                          },
                        },
                        _vm._l(_vm.userList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.waybillStatus") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.all"),
                            clearable: "",
                            "collapse-tags": "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.waybillStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "waybillStatus",
                                $$v
                              )
                            },
                            expression: "queryForm.params.waybillStatus",
                          },
                        },
                        _vm._l(_vm.waybillStatusList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.label, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "28px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-search",
                          size: "small",
                          type: "primary",
                        },
                        on: { click: _vm.enterSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-refresh-left",
                          size: "small",
                          type: "info",
                        },
                        on: { click: _vm.resetForm },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.allExportOrder },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.export")))]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value:
                              "btn:qualityService:signatureVerification:UrgeToDo",
                            expression:
                              "'btn:qualityService:signatureVerification:UrgeToDo'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.UrgeToDo },
                      },
                      [_vm._v(_vm._s(_vm.$t("changepwd.UrgeToDo")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              align: "center",
              fixed: "left",
              type: "index",
              width: "80",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: item.cloCenter,
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.prop === "signedPhoto"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "verifier",
                                  on: {
                                    click: function ($event) {
                                      return _vm.imageSee(scope.row.signedPhoto)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("home.clickSee")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : item.prop === "signedNamePhoto"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "verifier",
                                  on: {
                                    click: function ($event) {
                                      return _vm.imageSee(
                                        scope.row.signedNamePhoto
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("home.clickSee")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : item.prop === "forceType"
                          ? _c(
                              "div",
                              [
                                scope.row.forceType === 1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("Resource.underReviewNEW")
                                          ) + " "
                                        ),
                                      ]
                                    )
                                  : scope.row.forceType === 2
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "customerManagements.approved"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("home.unapplivefail"))
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row[item.prop]
                                    ? "" + scope.row[item.prop]
                                    : "--"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operate"),
              align: "center",
              fixed: "right",
              "min-width": "90",
              prop: "varianceNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", {
                      staticClass: "el-icon-user-solid verifier",
                      on: {
                        click: function ($event) {
                          return _vm.seeDetial(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("home.qiangzhiApplive"),
            visible: _vm.waybillDescription,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.waybillDescription = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "calc(100vh - 67px)",
                overflow: "auto",
                padding: "10px 20px",
              },
            },
            [
              _c("detailPage", {
                key: _vm.detailKey,
                attrs: { "row-data": _vm.rowData },
                on: { "call-parent-method": _vm.parentMethod },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("navbar.seeImage"),
            visible: _vm.dialogVisible,
            top: "3vh",
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _vm.imageList.length > 0
              ? _c(
                  "div",
                  { staticStyle: { width: "500px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          color: "#409eff",
                          "font-size": "18px",
                        },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.currentIndex + 1))]),
                        _c("span", [_vm._v("/")]),
                        _c("span", [_vm._v(_vm._s(_vm.imageList.length))]),
                      ]
                    ),
                    _c(
                      "el-carousel",
                      {
                        ref: "carousel",
                        attrs: { height: "750px", trigger: "click" },
                        on: { change: _vm.handlecarousel },
                      },
                      _vm._l(_vm.imageList, function (item) {
                        return _c("el-carousel-item", { key: item }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "700px" },
                                attrs: { src: item, fit: "fill" },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      width: "500px",
                      "text-align": "center",
                      height: "750px",
                    },
                  },
                  [
                    _c("el-image", { staticStyle: { width: "100%" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-picture-outline",
                            staticStyle: { "font-size": "30px" },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.tipDialog1,
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tipDialog1 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flx-between",
              staticStyle: { "font-size": "16px" },
            },
            [
              _c("div", [
                _vm._v(" " + _vm._s(_vm.$t("home.operationTotal")) + ":"),
                _c("span", { staticClass: "tipclass" }, [
                  _vm._v(_vm._s(_vm.resultsObj.operateTotal)),
                ]),
              ]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("operationCenter.NumberOfSuccesses")) +
                    ":"
                ),
                _c("span", { staticClass: "tipclass" }, [
                  _vm._v(_vm._s(_vm.resultsObj.operateSuccesses)),
                ]),
              ]),
              _c("div", [
                _vm._v(
                  " " + _vm._s(_vm.$t("operationCenter.NumberOfFailures")) + ":"
                ),
                _c(
                  "span",
                  { staticClass: "tipclass", staticStyle: { color: "red" } },
                  [_vm._v(_vm._s(_vm.resultsObj.operateFailures))]
                ),
              ]),
            ]
          ),
          _vm.resultsObj.operateFailures
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "15px",
                    "font-size": "16px",
                    "line-height": "30px",
                    height: "200px",
                    "overflow-y": "auto",
                  },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("GlobalSetObj.ReasonForFailure")) + ":"
                    ),
                  ]),
                  _vm._l(
                    _vm.resultsObj.remindersDetailsVOList,
                    function (item, index) {
                      return _c("div", { key: index }, [
                        _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                        _c("span", [_vm._v(_vm._s(item.reason))]),
                      ])
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.tipDialog1 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request';

const baseURLAdmin = process.env.VUE_APP_ADMIN;
// 投诉工单列表分页查询
export const apiGetComplaintWorkOrderPage = (data) => {
  return request({
    method: 'post',
    url: '/complaintWorkOrder/page',
    data,
    baseURL: baseURLAdmin
  });
};
// 批量导入投诉工单
export const apiGetComplaintWorkOrderImport = (data) => {
  return request({
    method: 'post',
    url: '/complaintWorkOrder/import',
    data,
    baseURL: baseURLAdmin
  });
};
// 关闭工单
export const apiGetComplaintWorkCloseWork = (data) => {
  return request({
    method: 'post',
    url: '/complaintWorkOrder/closeWork',
    data,
    baseURL: baseURLAdmin
  });
};
// 获取消息记录
export const apiGetComplaintWorkGetMessage = (data, isBool = true) => {
  return request({
    method: 'post',
    url: '/complaintWorkOrder/getMessage',
    data,
    baseURL: baseURLAdmin,
    isLoading: isBool
  });
};
// 发送消息
export const apiGetComplaintWorkSendMessage = (data, isBool = true) => {
  return request({
    method: 'post',
    url: '/complaintWorkOrder/sendMessage',
    data,
    baseURL: baseURLAdmin,
    isLoading: isBool
  });
};
// 获取商户
export const apiGetComplaintGetMerchantName = (data, isBool = true) => {
  return request({
    method: 'post',
    url: '/complaintWorkOrder/getMerchantName',
    data,
    baseURL: baseURLAdmin,
    isLoading: isBool
  });
};
// 明细管理  分页查询“我返回的/我回收的”列表
export const apiGetBagRecycleDetailMyList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/myList',
    data
  });
};
// 明细管理 分页查询“调整明细”列表
export const apiGetBagRecycleDetailAdjustList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/adjustList',
    data
  });
};
// 明细管理 分页查询“流出明细/流入明细”列表
export const apiGetBagRecycleDetailFlowDataList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/flowDataList',
    data
  });
};
// 明细管理 我返回的-打印运单
export const apiGetBagRecycleDetailPrintWaybill = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/printWaybill',
    data
  });
};
// 明细管理 删除“我返回的”明细记录
export const apiGetBagRecycleDetailDelete = (data) => {
  return request({
    baseURL: baseURLAdmin,
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/bagRecycleDetail/delete',
    data
  });
};
// 明细管理 回收确认
export const apiGetBagRecycleDetailConfirm = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/confirm',
    data
  });
};
// 明细管理 批量回收确认
export const apiGetBagRecycleDetailBatchConfirm = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/batchConfirm',
    data
  });
};
// 回收汇总分页查询
export const apiGetBagRecycleSummarySummaryList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleSummary/summaryList',
    data
  });
};
// 按月汇总分页查询
export const apiGetBagRecycleSummaryMonthlyList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleSummary/monthlyList',
    data
  });
};
// 催回收
export const apiGetBagRecycleUrge = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycle/urge',
    data
  });
};
// 我返回的、我回收的 -> 导出
export const apiGetBagRecycleDetailExportMyList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/exportMyList',
    data
  });
};
// 调整明细导出
export const apiGetBagRecycleDetailExportAdjustList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/exportAdjustList',
    data
  });
};
// 流出\流入明细导出
export const apiGetBagRecycleDetailExportFlowDataList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleDetail/exportFlowDataList',
    data
  });
};
// 汇总导出
export const apiBagRecycleSummaryExportSummaryList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleSummary/exportSummaryList',
    data
  });
};
// 导出按月汇总
export const apiBagRecycleSummaryExportMonthlyList = (data) => {
  return request({
    baseURL: baseURLAdmin,
    method: 'post',
    url: '/bagRecycleSummary/exportMonthlyList',
    data
  });
};
// 分页查询网点编织袋使用量配置列表
export const bagUsageConfigPage = (data) => {
  return request({
    method: 'POST',
    url: '/bagUsageConfig/pageList',
    data,
    baseURL: baseURLAdmin
  });
};
// 编辑网点编织袋使用量配置
export const bagUsageConfig = (data) => {
  return request({
    method: 'POST',
    url: '/bagUsageConfig/edit',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋回收管理列表
export const bagRecyclePage = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycle/pageList',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋 返回登记
export const returnRegister = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycle/returnRegister',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋 回收登记
export const recycleRegister = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycle/recycleRegister',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋 催回收
export const urgeRegister = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycle/urge',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋 催回收
export const abnormalAdjust = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycle/abnormalAdjust',
    data,
    baseURL: baseURLAdmin
  });
};
// 投诉工单导出
export const complaintWorkOrderExport = (data) => {
  return request({
    method: 'POST',
    url: '/complaintWorkOrder/export',
    data,
    baseURL: baseURLAdmin
  });
};
export const bagUsagebatchConfig = (data) => {
  return request({
    method: 'POST',
    url: '/bagUsageConfig/batchConfig',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋 查看汇总数据明细
export const bagRecycleSummaryDetails = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycleSummary/details',
    data,
    baseURL: baseURLAdmin
  });
};
// 分页查询编织袋 导出汇总数据明细
export const bagRecycleSummaryDetailsExport = (data) => {
  return request({
    method: 'POST',
    url: '/bagRecycleSummary/exportSummaryDataDetails',
    data,
    baseURL: baseURLAdmin
  });
};
// 投诉工单 修改截止时间
export const updateDeadline = (data) => {
  return request({
    method: 'POST',
    url: '/complaintWorkOrder/updateDeadline',
    data,
    baseURL: baseURLAdmin
  });
};
// 强制签收审核导出
export const lgStorageForceSignexport = (data) => {
  return request({
    method: 'POST',
    url: '/lgStorageForceSign/export',
    data,
    baseURL: baseURLAdmin
  });
};
// 强制签收审核导出
export const lgStorageForceSignReminders = (data) => {
  return request({
    method: 'POST',
    url: '/lgStorageForceSign/reminders',
    data,
    baseURL: baseURLAdmin
  });
};
// 投诉工单  == >   导出图片
export const complaintWorkOrderexportImage = (data) => {
  return request({
    method: 'POST',
    url: '/complaintWorkOrder/exportImage',
    data,
    baseURL: baseURLAdmin,
    isLoading: true
  });
};
